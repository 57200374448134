import React, { useEffect, useState } from "react";
import { Button, Nav, Tab, Table } from "react-bootstrap";
import { Loader } from "../../components";
import { ReturnpromoMats } from "../../components/Modals";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  reqToGetPromoMatList,
  reqToGetPromoMatsReasons,
  updateMaterial,
} from "../../store/slice/promoMaterialSlice";
import { returnPromoMatsValidations } from "../../helpers/formValidations";
import { useForm } from "../../hooks";
import moment from "moment";
import { numberFormatting } from "../../helpers/commonFunction";
import { SearchIcon, returnIcon } from "../../icon/icon";

const PromoMaterials = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // Get reducer data
  const promoMaterialReducer = useSelector(
    (state) => state.promoMaterialReducer
  );
  const promoMaterialList = promoMaterialReducer.promoMaterialsList;
  const isLoading = promoMaterialReducer.loader;

  const [promoMatsModal, setPromoMatsModal] = useState(false);
  const [materialObj, setMaterialObj] = useState({});
  const [updatedList, setUpdatedList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [currProductType, setCurrProductType] = useState(
    location?.state?.currPType ? location?.state?.currPType : "SAMPLES"
  );

  // Handle show return promo materials modal
  const handleShowModal = (item) => {
    setPromoMatsModal(!promoMatsModal);
    if (promoMatsModal === false) {
      setMaterialObj(item);
    } else {
      setMaterialObj({});
    }
  };

  // Handle hide return promo materials modal
  const handleHideModal = () => {
    setErrors({});
    setValues({
      ...values,
      return_qty: "",
      reason: "",
    });
    setMaterialObj({});
    setPromoMatsModal(false);
  };

  useEffect(() => {
    dividePromoMaterials();
  }, [promoMaterialList]);

  useEffect(() => {
    handleRefreshPage();
  }, []);

  const handleRefreshPage = () => {
    dispatch(reqToGetPromoMatList({ dispatch }));
    dispatch(reqToGetPromoMatsReasons({ dispatch }));
  };

  // Initial values
  const initialFValues = {
    return_qty: "",
    reason: null,
  };

  useEffect(() => {
    setValues({
      ...values,
      return_qty: "",
      quantity: materialObj && materialObj.quantity ? materialObj.quantity : "",
      received_qty:
        materialObj && materialObj.received_qty ? materialObj.received_qty : "",
    });
  }, [materialObj]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return returnPromoMatsValidations(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );
  //

  // save single Material
  const saveMatsHandler = () => {
    if (validate()) {
      const data = {
        promomat_id: +materialObj.promomat_id,
        qty: +values.return_qty,
        notes_id: +values.reason,
        received_qty: +materialObj.received_qty,
        lot_number: materialObj.lot_number,
      };

      if (materialObj.expiry_date) {
        data.expiry_date = materialObj.expiry_date;
      }

      dispatch(
        updateMaterial({
          data,
          cb: (err, res) => {
            if (err) {
            } else {
              setPromoMatsModal(false);
              setErrors({});
              dispatch(reqToGetPromoMatList({ dispatch }));
              setValues({
                return_qty: "",
                reason: "",
              });
            }
          },
        })
      );
    }
  };

  const dividePromoMaterials = () => {
    const updatedArrList = {
      samplesArr: [],
      giveawaysArr: [],
      literaturesArr: [],
      otherArr: [],
    };

    for (let i = 0; i < promoMaterialList?.length; i++) {
      if (promoMaterialList[i].product_type_name.includes("Samples")) {
        updatedArrList.samplesArr.push(promoMaterialList[i]);
      } else if (promoMaterialList[i].product_type_name.includes("Giveaways")) {
        updatedArrList.giveawaysArr.push(promoMaterialList[i]);
      } else if (
        promoMaterialList[i].product_type_name.includes("Literature")
      ) {
        updatedArrList.literaturesArr.push(promoMaterialList[i]);
      } else {
        updatedArrList.otherArr.push(promoMaterialList[i]);
      }
    }

    setUpdatedList(updatedArrList);
    setFilteredList(updatedArrList);
  };

  useEffect(() => {
    setValues({
      ...values,
      lot_number: "",
    });
  }, [values.return_qty]);

  const handleOnChange = (e) => {
    if (e.target.value === "") {
      handleSearch("");
    }
    setSearchInput(e.target.value);
  };

  const handleSearch = (searchValue) => {
    // Filter samplesArr
    if (searchValue) {
      const filteredSamples = updatedList.samplesArr.filter((item) =>
        item.product_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      );

      // Filter giveawaysArr
      const filteredGiveaways = updatedList.giveawaysArr.filter((item) =>
        item.product_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      );

      // Filter literaturesArr
      const filteredLiteratures = updatedList.literaturesArr.filter((item) =>
        item.product_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      );

      // Filter literaturesArr
      const otherArr = updatedList.otherArr.filter((item) =>
        item.product_name
          .toLowerCase()
          .includes(searchValue.trim().toLowerCase())
      );

      const allFilteredItems = {
        samplesArr: filteredSamples,
        giveawaysArr: filteredGiveaways,
        literaturesArr: filteredLiteratures,
        otherArr: otherArr,
      };

      setFilteredList(allFilteredItems);
    } else {
      setFilteredList(updatedList);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="tc-body">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="tc-profile-wrapper mb-0">
            <span className="tc-profile-name">PROMO MATERIALS</span>
          </div>
          <div className="text-end fs-18">
            <p className="mb-0 fw-semibold">{moment().format("MMMM YYYY")}</p>
            <p className="mb-0">As of {moment().format("dddd, MMMM DD")}</p>
          </div>
        </div>
        <div className="add-scroll">
          <div className="tc-content-wrapper">
            <div className="tc-promo-content tc-promo-materials-content">
              <Tab.Container id="tc-promo-material" defaultActiveKey="samples">
                <div className="tc-calender-search-input-row">
                  <Nav variant="pills" className="gap-2">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="samples"
                        active={currProductType === "SAMPLES"}
                        onClick={() => setCurrProductType("SAMPLES")}
                      >
                        SAMPLES
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="giveaways"
                        active={currProductType === "GIVEAWAYS"}
                        onClick={() => setCurrProductType("GIVEAWAYS")}
                      >
                        GIVEAWAYS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="literature"
                        active={currProductType === "LITERATURE"}
                        onClick={() => setCurrProductType("LITERATURE")}
                      >
                        LITERATURE
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="tc-search-input-content d-flex">
                    <input
                      id="text"
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={(e) => handleOnChange(e)}
                    />
                    <button
                      type="button"
                      className="tc-search-input-btn"
                      onClick={() => handleSearch(searchInput)}
                    >
                      {SearchIcon}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <h5 className="mb-0">{currProductType} ON-HAND</h5>
                  <div className="ms-auto">
                    <Button
                      variant="link"
                      size="sm"
                      type="button"
                      className="p-0 active"
                    >
                      ON-HAND
                    </Button>{" "}
                    |{" "}
                    <Button
                      variant="link"
                      size="sm"
                      type="button"
                      className="p-0"
                      onClick={() =>
                        navigate(`${routes.promoMaterials}/receive`, {
                          state: {
                            currPType: currProductType,
                          },
                        })
                      }
                    >
                      RECEIVED
                    </Button>
                  </div>
                </div>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="samples"
                    active={currProductType === "SAMPLES"}
                  >
                    <div className="tc-table-wrapper">
                      <Table
                        responsive
                        className="tc-table tc-promo-mats-table"
                      >
                        <thead>
                          <tr>
                            <th>PRODUCT</th>
                            <th>TYPE</th>
                            <th>LOT #</th>
                            <th>QUANTITY</th>
                            <th>EXPIRY DATE</th>
                            <th>RETURN</th>
                          </tr>
                        </thead>
                        {filteredList?.samplesArr?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={6}>No data found</td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {filteredList?.samplesArr?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className={`${
                                      item.product_name && "min-width-350"
                                    }`}
                                  >
                                    {item.product_name ? item.product_name : ""}
                                  </td>
                                  <td>{item.type ? item.type : "-"}</td>
                                  <td>
                                    {item.lot_number ? item.lot_number : "-"}
                                  </td>
                                  <td>
                                    {item.quantity
                                      ? numberFormatting(+item.quantity)
                                      : "-"}
                                  </td>
                                  <td>
                                    <div>
                                      <span>
                                        {item.expiry_date
                                          ? moment(item.expiry_date).format(
                                              "MM/DD/YYYY"
                                            )
                                          : "-"}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      onClick={() => handleShowModal(item)}
                                      className="tc-cursor-pointer"
                                    >
                                      {returnIcon}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="giveaways"
                    active={currProductType === "GIVEAWAYS"}
                  >
                    <div className="tc-table-wrapper">
                      <Table
                        responsive
                        className="tc-table tc-promo-mats-table"
                      >
                        <thead>
                          <tr>
                            <th>PRODUCT</th>
                            <th>QUANTITY</th>
                            <th>RETURN</th>
                          </tr>
                        </thead>
                        {filteredList?.giveawaysArr?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={3}>No data found</td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {filteredList?.giveawaysArr?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className={`${
                                      item.product_name && "min-width-350"
                                    }`}
                                  >
                                    {item.product_name}
                                  </td>
                                  <td>
                                    <div>
                                      <span>
                                        {item.quantity
                                          ? numberFormatting(+item.quantity)
                                          : ""}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      onClick={() => handleShowModal(item)}
                                      className="tc-cursor-pointer"
                                    >
                                      {returnIcon}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="literature"
                    active={currProductType === "LITERATURE"}
                  >
                    <div className="tc-table-wrapper">
                      <Table
                        responsive
                        className="tc-table tc-promo-mats-table"
                      >
                        <thead>
                          <tr>
                            <th>PRODUCT</th>
                            <th>QUANTITY</th>
                            <th>RETURN</th>
                          </tr>
                        </thead>
                        {filteredList?.literaturesArr?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={3}>No data found</td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {filteredList?.literaturesArr?.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      className={`${
                                        item.product_name && "min-width-350"
                                      }`}
                                    >
                                      {item.product_name}
                                    </td>
                                    <td>
                                      <div>
                                        <span>
                                          {item.quantity
                                            ? numberFormatting(+item.quantity)
                                            : ""}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div
                                        onClick={() => handleShowModal(item)}
                                        className="tc-cursor-pointer"
                                      >
                                        {returnIcon}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>

              {filteredList?.otherArr?.length > 0 && (
                <div className="tc-table-content">
                  <h2 className="tc-table-title">Others</h2>
                  <div className="tc-table-wrapper">
                    <Table responsive className="tc-table tc-promo-mats-table">
                      <thead>
                        <tr>
                          <th>PRODUCT</th>
                          <th>QUANTITY</th>
                          <th>RETURN</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredList?.otherArr?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                style={{
                                  maxWidth: "40ch",
                                  wordBreak: "break-word",
                                }}
                              >
                                {item.product_name}
                              </td>
                              <td>
                                <div>
                                  <span>
                                    {item.quantity
                                      ? numberFormatting(+item.quantity)
                                      : ""}
                                  </span>
                                </div>
                              </td>
                              <td>
                                <div
                                  onClick={() => handleShowModal(item)}
                                  className="tc-cursor-pointer"
                                >
                                  {returnIcon}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              )}

              {promoMatsModal && (
                <ReturnpromoMats
                  show={promoMatsModal}
                  onHide={handleHideModal}
                  values={values}
                  errors={errors}
                  saveMatsHandler={saveMatsHandler}
                  handleInputChange={handleInputChange}
                  materialObj={materialObj}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoMaterials;
