import React from "react";
import ReactDatePicker from "react-datepicker";

const CustomDatePicker = (props) => {
  const {
    type,
    selectedDate,
    dateFormat,
    onChange,
    maxDate,
    minDate,
    showMonthDropdown,
    showYearDropdown,
    yearDropdownItemNumber,
  } = props;

  return (
    <>
      {type === "custom" ? (
        <ReactDatePicker
          name="date"
          className="d-none tc-custom-date-picker"
          selected={selectedDate ? new Date(selectedDate) : new Date()}
          dateFormat={dateFormat ? dateFormat : "yyyy-MM-dd"}
          onKeyDown={(e) => e.preventDefault()}
          onChange={onChange}
          minDate={minDate ? minDate : ""}
          maxDate={maxDate ? maxDate : new Date()}
          disabledKeyboardNavigation
          showMonthDropdown={showMonthDropdown ? showMonthDropdown : false}
          showYearDropdown={showYearDropdown ? showYearDropdown : false}
          yearDropdownItemNumber={yearDropdownItemNumber}
          scrollableYearDropdown
          autoComplete="off"
          {...props}
        />
      ) : null}
    </>
  );
};

export default CustomDatePicker;
