import Dexie from "dexie";
import moment from "moment";
import { notify, nameFormatting } from "./commonFunction";
import { reqToStartSyncProcess } from "../store/slice/offlineSlice";

export const db = new Dexie("etmsapp");
db.version(11).stores({
  dashBoardMonthly: "++id",
  dashBoardPerDay: "++id",
  callConcentration: "++id",
  promoMatsInventory: "++id",
  incidentalCalls: "++id",
  monthlyCallRate: "++id",
  monthlyMDReach: "++id",
  mdList: "++id",
  products: "++id",
  activities: "++id",
  callRecording: "++id",
  disabled_mds: "++id",
});
db.version(12)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
  })
  .upgrade(async (tx) => {});
db.version(13)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
  })
  .upgrade(async (tx) => {});
db.version(14)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
  })
  .upgrade(async (tx) => {});
db.version(15)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
  })
  .upgrade(async (tx) => {});
db.version(16)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
  })
  .upgrade(async (tx) => {});
db.version(17)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
  })
  .upgrade(async (tx) => {});
db.version(18)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
  })
  .upgrade(async (tx) => {});
db.version(19)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
  })
  .upgrade(async (tx) => {});
db.version(20)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
  })
  .upgrade(async (tx) => {});
db.version(21)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
  })
  .upgrade(async (tx) => {});
db.version(22)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
  })
  .upgrade(async (tx) => {});
db.version(23)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
    classes: "++id, class_id",
    clinic: "++id, clinic_id",
    specialty: "++id, specialty_id",
    mdRequest: "++id",
    addedMD: "++id",
  })
  .upgrade(async (tx) => {});
db.version(24)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    universalMDList: "++id, md_id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
    classes: "++id, class_id",
    clinic: "++id, clinic_id",
    specialty: "++id, specialty_id",
    mdRequest: "++id",
    addedMD: "++id",
  })
  .upgrade(async (tx) => {});
db.version(25)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id",
    universeMDList: "++id",
    universalMDList: "++id, md_id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
    classes: "++id, class_id",
    clinic: "++id, clinic_id",
    specialty: "++id, specialty_id",
    mdRequest: "++id",
    addedMD: "++id",
    cumulativeCalls: "++id",
    addedCumulativeCalls: "++id",
  })
  .upgrade(async (tx) => {});
db.version(26)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id, signature_url",
    universeMDList: "++id",
    universalMDList: "++id, md_id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
    classes: "++id, class_id",
    clinic: "++id, clinic_id",
    specialty: "++id, specialty_id",
    mdRequest: "++id",
    addedMD: "++id",
    cumulativeCalls: "++id",
    addedCumulativeCalls: "++id",
  })
  .upgrade(async (tx) => {});
db.version(27)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id, signature_url",
    universeMDList: "++id",
    universalMDList: "++id, md_id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
    classes: "++id, class_id",
    clinic: "++id, clinic_id",
    specialty: "++id, specialty_id",
    mdRequest: "++id",
    addedMD: "++id",
    cumulativeCalls: "++id",
    addedCumulativeCalls: "++id",
    mediaFiles: "++id, file",
  })
  .upgrade(async (tx) => {});
db.version(28)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id, signature_url",
    universalMDList: "++id, md_id",
    universalNMonthMD: "++id, md_id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
    classes: "++id, class_id",
    clinic: "++id, clinic_id",
    specialty: "++id, specialty_id",
    mdRequest: "++id",
    addedMD: "++id",
    cumulativeCalls: "++id",
    addedCumulativeCalls: "++id",
    mediaFiles: "++id, file",
  })
  .upgrade(async (tx) => {});
db.version(29)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id, signature_url",
    universalMDList: "++id, md_id",
    universalNMonthMD: "++id, md_id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
    classes: "++id, class_id",
    clinic: "++id, clinic_id",
    specialty: "++id, specialty_id",
    mdRequest: "++id",
    addedMD: "++id",
    cumulativeCalls: "++id",
    addedCumulativeCalls: "++id",
    mediaFiles: "++id, file",
    specialCallData: "++id",
  })
  .upgrade(async (tx) => {});
db.version(30)
  .stores({
    dashBoardMonthly: "++id",
    dashBoardPerDay: "++id",
    callConcentration: "++id",
    promoMatsInventory: "++id",
    incidentalCalls: "++id",
    monthlyCallRate: "++id",
    monthlyMDReach: "++id",
    mdList: "++id, md_id",
    products: "++id",
    activities: "++id",
    callRecording: "++id",
    disabled_mds: "++id",
    activeCycle: "++id",
    quickSign: "++id, signature_url",
    universalMDList: "++id, md_id",
    universalNMonthMD: "++id, md_id",
    addedQuickSign: "++id",
    deletedQuickSign: "++id",
    holidays: "++id",
    mdMasterList: "++id",
    editableCalls: "++id",
    oofListData: "++id",
    oofCycle: "++id",
    oofNotes: "++id",
    oofTypes: "++id",
    oofMissedCalls: "++id",
    lastAPICallTime: "++id",
    timeInOut: "++id",
    addedTimeInOut: "++id",
    userData: "++id",
    notificationCount: "++id",
    notificationType: "++id",
    adminNotification: "++id",
    dmNotification: "++id",
    mdBirthNotification: "++id",
    addedMDBirthday: "++id, md_id",
    frequency: "++id",
    itinerary: "++id, md_id",
    itineraryMDs: "++id, md_id",
    addedItinerary: "++id, md_id",
    itineraryRequest: "++id, md_id",
    daysField: "++id",
    errorLogs: "++id",
    classes: "++id, class_id",
    clinic: "++id, clinic_id",
    specialty: "++id, specialty_id",
    mdRequest: "++id",
    addedMD: "++id",
    cumulativeCalls: "++id",
    addedCumulativeCalls: "++id",
    mediaFiles: "++id, file",
    productImages: "++id, file",
    specialCallData: "++id",
  })
  .upgrade(async (tx) => {});

// let fetchCount = 0;

// // Utility to wrap and track fetch methods
// const trackFetch = (originalMethod) => {
//   return function (...args) {
//     fetchCount++;
//     console.log(`Fetch Count: ${fetchCount}`);
//     return originalMethod.apply(this, args);
//   };
// };

// // Override common fetch methods
// const methodsToTrack = ["toArray", "get", "where"];
// methodsToTrack.forEach((method) => {
//   const originalMethod = db.Table.prototype[method];
//   if (originalMethod) {
//     db.Table.prototype[method] = trackFetch(originalMethod);
//   }
// });

const isTableExists = (table) => {
  return db.tables.some((t) => t.name === table);
};
const isFieldIndexed = (table, field) => {
  const tableSchema = db.tables.find((t) => t.name === table).schema;
  return (
    tableSchema.indexes.some((index) => index.name === field) ||
    tableSchema.primKey.name === field
  );
};

// GET ENTRIES FROM DB
export const getAllEntriesFromDB = async (table) => {
  try {
    if (!isTableExists(table)) {
      throw new Error(`Table ${table} does not exist in the database`);
    }
    if (!table) {
      throw new Error(`Invalid or missing data!`);
    }
    const results = await db.table(table).toArray();
    return results;
  } catch (error) {
    console.error(`Error getting entries from ${table}:`, error);
    return [];
  }
};
export const getEntryFromDB = async (table, id) => {
  try {
    if (!isTableExists(table)) {
      throw new Error(`Table ${table} does not exist in the database`);
    }
    if (!table || !id) {
      throw new Error(`Invalid or missing data!`);
    }
    const entry = await db.table(table).get(id);
    return entry;
  } catch (error) {
    console.error(`Error retrieving entry from ${table} with id ${id}:`, error);
    return null;
  }
};
export const getEntryByFieldFromDB = async (table, fields, values) => {
  try {
    if (!isTableExists(table)) {
      throw new Error(`Table ${table} does not exist in the database`);
    }
    if (!table || !fields || !values) {
      throw new Error(`Invalid or missing data!`);
    }

    // Handle single field
    if (typeof fields === "string" && typeof values !== "object") {
      if (!isFieldIndexed(table, fields)) {
        throw new Error(
          `KeyPath ${fields} on object store ${table} is not indexed`
        );
      }
      const entries = await db
        .table(table)
        .where(fields)
        .equals(values)
        .toArray();
      return entries;
    }
    // Handle multiple fields
    if (Array.isArray(fields) && Array.isArray(values)) {
      if (fields.length !== values.length) {
        throw new Error(`Fields and values arrays must have the same length`);
      }
      for (const field of fields) {
        if (!isFieldIndexed(table, field)) {
          throw new Error(
            `KeyPath ${field} on object store ${table} is not indexed`
          );
        }
      }
      let query = db.table(table);
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const value = values[i];
        query = query.and((entry) => entry[field] === value);
      }
      const entries = await query.toArray();
      return entries;
    }
    throw new Error(`Invalid data types for fields and values!`);
  } catch (error) {
    console.error(
      `Error getting entries from ${table} where ${fields} equals ${values}:`,
      error
    );
    return [];
  }
};

// ADD ENTRY FROM DB
export const insertEntryInDB = async (table, newData) => {
  try {
    if (!isTableExists(table)) {
      throw new Error(`Table ${table} does not exist in the database`);
    }
    if (!table || !newData) {
      throw new Error(`Invalid or missing data!`);
    }
    const id = await db.table(table).add(newData);
    return id;
  } catch (error) {
    console.error(`Error adding data to ${table}:`, error);
    return null;
  }
};
export const insertEntriesInDB = async (table, newData) => {
  try {
    if (!isTableExists(table)) {
      throw new Error(`Table ${table} does not exist in the database`);
    }
    if (!table || !newData || !Array.isArray(newData)) {
      throw new Error(`Invalid or missing data!`);
    }
    const result = await db.table(table).bulkAdd(newData);
    return result;
  } catch (error) {
    console.error(`Error adding data to ${table}:`, error);
    return null;
  }
};

// UPDATE ENTRY FROM DB
export const updateEntryInDB = async (table, field, value, newData) => {
  try {
    if (!isTableExists(table)) {
      throw new Error(`Table ${table} does not exist in the database`);
    }
    if (!isFieldIndexed(table, field)) {
      throw new Error(
        `KeyPath ${field} on object store ${table} is not indexed`
      );
    }
    if (!table || !field || !value || !newData) {
      throw new Error(`Invalid or missing data!`);
    }
    const isExist = await getEntryByFieldFromDB(table, field, value);
    if (isExist && isExist?.length > 0) {
      await db.table(table).update(isExist[0]?.id, newData);
      return isExist[0]?.id;
    } else {
      let id = await db.table(table).add(newData);
      return id;
    }
  } catch (error) {
    console.error(
      `Error updating entry in ${table} with value ${value}:`,
      error
    );
    return false;
  }
};

// IMPORT TABLE DATA
export const reqToImportTableData = async (file, dispatch) => {
  try {
    dispatch(reqToStartSyncProcess(true));
    const reader = new FileReader();
    reader.onload = async (event) => {
      const importData = JSON.parse(event.target.result);
      await db.transaction(
        "rw",
        Object.keys(importData).map((name) => db.table(name)),
        async () => {
          for (const [tableName, records] of Object.entries(importData)) {
            const table = db.table(tableName);
            if (table) {
              await table.clear();
              await table.bulkAdd(records);
            } else {
              notify(
                `Table "${tableName}" does not exist in the database.`,
                "warning"
              );
            }
          }
        }
      );
      dispatch(reqToStartSyncProcess(false));
      notify("Database imported successfully!", "success");
    };
    reader.onerror = (error) => {
      dispatch(reqToStartSyncProcess(false));
      notify("Failed to read the file!", "error");
      console.error("Error reading file:", error);
    };
    reader.readAsText(file);
  } catch (error) {
    dispatch(reqToStartSyncProcess(false));
    notify("Failed to import database!", "error");
    console.error("Error importing database:", error);
  }
};

// EXPORT TABLE DATA
export const reqToExportTableData = async (tables, dispatch) => {
  try {
    dispatch(reqToStartSyncProcess(true));
    const [userData] = await Promise.all([getAllEntriesFromDB("userData")]);
    const newUserData = userData?.length > 0 ? userData[0] : "";
    const tablesToExport = tables.map((name) => db.table(name));
    const data = await db.transaction("r", tablesToExport, async () => {
      const exportData = {};
      for (const table of tablesToExport) {
        exportData[table.name] = await table.toArray();
      }
      return exportData;
    });

    const blob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${
      newUserData
        ? `${nameFormatting(newUserData)}_${moment().format("YYYY-MM-DD")}_`
        : `${moment().format("YYYY-MM-DD")}_`
    }database.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    notify("Database exported successfully!", "success");
    setTimeout(() => {
      dispatch(reqToStartSyncProcess(false));
    }, 500);
  } catch (error) {
    dispatch(reqToStartSyncProcess(false));
    notify("Failed to exporting database!", "error");
    console.error("Error exporting database:", error);
  }
};
