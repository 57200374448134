import React from "react";
import { backBtnIcon, cakeIcon2, userIcon } from "../../icon/icon";
import {
  defaultDateFormat,
  getMonthAndDate,
  manageStatus,
  nameFormatting,
  timeWithHoursFormat,
} from "../../helpers/commonFunction";
import { DataNotFound } from "../../components";
import InfinitScroll from "react-infinite-scroll-component";
import { Tab, Table, Tabs } from "react-bootstrap";

const ViewNotifications = (props) => {
  const { user, show, onHide, notifications, selectedNotifiType } = props;

  return (
    <div className={`tc-sidebar-section ${show && "zl-sidebar-open"}`}>
      <div className="tc-sidebar-navbar">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => onHide()}
        >
          {backBtnIcon}
        </button>
        <p className="tc-navbar-title">NOTIFICATIONS</p>
        <div className="tc-sidebar-right-btn"></div>
      </div>
      <div className="tc-sidebar-body-wrapper">
        <div className="tc-sidebar-body">
          <div className="add-scroll">
            <div className="tc-content-wrapper pt-2">
              <div className="tc-view-notification-type">
                <span>
                  {selectedNotifiType
                    ? selectedNotifiType === "BIRTHDAY"
                      ? cakeIcon2
                      : userIcon
                    : userIcon}
                </span>
                <h4 className="p-0 m-0">
                  {selectedNotifiType
                    ? selectedNotifiType === "BIRTHDAY"
                      ? "MD Birthdays"
                      : selectedNotifiType === "DM"
                      ? "District Manager"
                      : selectedNotifiType
                    : ""}
                </h4>
              </div>
              {selectedNotifiType !== "BIRTHDAY" ? (
                <>
                  {notifications && notifications?.length === 0 ? (
                    <span className="m-auto">
                      <DataNotFound name={"No Notification yet"} />
                    </span>
                  ) : (
                    <InfinitScroll
                      dataLength={notifications?.length}
                      hasMore={true}
                      scrollableTarget="scrollableDiv"
                      className="tc-notification-container"
                    >
                      {notifications &&
                        notifications.map((notification, i) => (
                          <div
                            key={i}
                            className="tc-notification-outer-content"
                          >
                            <div className="tc-notification-content">
                              <span className="tc-notification-date">
                                {defaultDateFormat(notification.created_at) +
                                  " - " +
                                  timeWithHoursFormat(notification.created_at)}
                              </span>
                              <div>
                                {notification?.notification_type === "OOF" ? (
                                  <>
                                    {`Hello `}
                                    <span className="tc_notification_desc">
                                      {nameFormatting(user, "FLC")},
                                    </span>
                                    {` Your Request for `}
                                    <span className="tc_notification_desc">
                                      Valid Missed Calls
                                    </span>
                                    {` has been `}
                                    <span className="tc_notification_desc">
                                      {manageStatus(
                                        notification?.approve_request
                                      )}
                                      .
                                    </span>
                                    {selectedNotifiType === "DM" && (
                                      <>
                                        {` and forwarded to Admin for `}
                                        <span className="tc_notification_desc">
                                          Final approval.
                                        </span>
                                      </>
                                    )}
                                  </>
                                ) : notification?.notification_type ===
                                  "ITINERARY" ? (
                                  <>
                                    {`Hello `}
                                    <span className="tc_notification_desc">
                                      {nameFormatting(user, "LF")}!
                                    </span>
                                    {` Your request to ${
                                      notification.type_request === "INSERT"
                                        ? "ADD"
                                        : notification.type_request === "DELETE"
                                        ? "REMOVE"
                                        : "EDIT"
                                    } `}
                                    <span className="tc_notification_desc">
                                      Dr. {nameFormatting(notification, "LF")}
                                    </span>
                                    {` Itinerary has been `}
                                    <span className="tc_notification_desc">
                                      {manageStatus(
                                        notification?.approve_request
                                      )}
                                      .
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {`Hello `}
                                    <span className="tc_notification_desc">
                                      {nameFormatting(user, "FLC")},
                                    </span>
                                    {` Your request to `}
                                    <span className="tc_notification_desc">
                                      {`${
                                        notification.type_request === "INSERT"
                                          ? "ADD"
                                          : notification.type_request ===
                                            "DELETE"
                                          ? "REMOVE"
                                          : "EDIT"
                                      } MD `}
                                    </span>
                                    {selectedNotifiType === "DM" ? (
                                      <>
                                        {`has been `}
                                        <span className="tc_notification_desc">
                                          {manageStatus(
                                            notification?.approve_request
                                          )}
                                          .
                                        </span>
                                        {` Please check.`}
                                      </>
                                    ) : (
                                      "has been updated. Please check."
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </InfinitScroll>
                  )}
                </>
              ) : (
                <Tabs
                  defaultActiveKey="monthly"
                  id="uncontrolled-tab-example"
                  className="mt-3 mb-3 tabs_underline me-5 ms-5"
                  fill
                >
                  <Tab eventKey="monthly" title="Monthly Celebrants">
                    <div className="tc-table-wrapper mt-4">
                      <Table responsive className="tc-table">
                        <thead>
                          <tr>
                            <th>MD Name</th>
                            <th>Event</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        {notifications &&
                        notifications?.month_birthday?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={3} className="text-center">
                                No Birthday yet
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {notifications &&
                              notifications?.month_birthday?.length > 0 &&
                              notifications?.month_birthday.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="min-width-300 text-start">
                                        {item
                                          ? nameFormatting(item, "LF")
                                          : "-"}
                                      </td>
                                      <td>
                                        <div>Birthday</div>
                                      </td>
                                      <td>
                                        {item.birthdate
                                          ? getMonthAndDate(item.birthdate)
                                          : ""}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Tab>
                  <Tab eventKey="weekly" title="Weekly Celebrants">
                    <div className="tc-table-wrapper mt-4">
                      <Table responsive className="tc-table">
                        <thead>
                          <tr>
                            <th>MD Name</th>
                            <th>Event</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        {notifications &&
                        notifications?.weekly_birthday?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={3} className="text-center">
                                No celebrants found
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {notifications &&
                              notifications?.weekly_birthday?.length > 0 &&
                              notifications?.weekly_birthday.map(
                                (item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="min-width-300 text-start">
                                        {item
                                          ? nameFormatting(item, "LF")
                                          : "-"}
                                      </td>
                                      <td>
                                        <div>Birthday</div>
                                      </td>
                                      <td>
                                        {item.birthdate
                                          ? getMonthAndDate(item.birthdate)
                                          : ""}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Tab>
                </Tabs>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotifications;
