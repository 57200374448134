import {
  notify,
  modifyText,
  mediaToBase64,
  fetchMediaData,
  dateFormatWithTime,
  getMonthStartEndDate,
} from "./commonFunction";
import {
  db,
  getEntryFromDB,
  insertEntryInDB,
  updateEntryInDB,
  insertEntriesInDB,
  getAllEntriesFromDB,
  getEntryByFieldFromDB,
} from "./db";
import moment from "moment";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { apiendpoints } from "./constants";
import { updateUserState } from "../store/slice/userSlice";
import {
  reqToGetActiveCycle,
  reqToResetMDApiMessage,
} from "../store/slice/mdListSlice";
import {
  reqToGetBirthDayData,
  reqToGetNotificationCount,
  reqToGetNotificationType,
  reqToResetNotiApiMessage,
} from "../store/slice/notificationSlice";
import {
  reqToAddMDReliever,
  reqToAddMDRequest,
  reqToChangeMDBirthday,
  reqToFetchDataProcess,
  reqToGetCumulativeCalls,
  reqToGetHolidaysAndFreq,
  reqToGetMDInfoDetails,
  reqToGetMDMasterList,
  reqToGetMDRequest,
  reqToGetMdItineraryData,
  reqToGetNextMonthItinerary,
  reqToGetOffLineQuickSignData,
  reqToGetOffLineReportsData,
  reqToResetApiMessage,
  reqToStartSyncProcess,
  reqToSubmitCallRecordingData,
  reqToSubmitCumulativeCalls,
  reqToSubmitEditableCalls,
  reqToUpdateItinerary,
} from "../store/slice/offlineSlice";
import {
  reqToAddOutOfFieldData,
  reqToGetOutOfFieldData,
  reqToGetVMCModalData,
  reqToResetVMCApiMessage,
} from "../store/slice/outOfFieldSlice";
import {
  reqToAddTimeInOutData,
  reqToGetTimeInOutData,
  reqToResetTimeApiMessage,
} from "../store/slice/timeInOutSlice";
import { reqToGetDaysFieldReport } from "../store/slice/dashboardSlice";
import {
  reqToAddSignature,
  reqToDeleteSignature,
  reqToGetAddressFromLatLong,
} from "../store/slice/quickSignSlice";
import { reqToAddMediaFile } from "../store/slice/callRecordingSlice";

// FETCH LATEST DATA
export const getLatestDataFromDB = async (type, dispatch, user) => {
  try {
    if (
      type === "login" ||
      type === "sync" ||
      type === "isNMonth" ||
      type === "finalUpload"
    ) {
      dispatch(reqToFetchDataProcess(true));
      dispatch(reqToResetApiMessage(""));
      dispatch(reqToResetMDApiMessage(""));
      dispatch(reqToResetVMCApiMessage(""));
      dispatch(reqToResetNotiApiMessage(""));
      dispatch(reqToResetTimeApiMessage(""));
    }
    if (type === "sync" || type === "isNMonth") {
      await getLatestTimeInOutDataFromDB(dispatch);
      await getLatestCycleFromDB(dispatch);
      await getLatestMDDataFromDB(dispatch);
      await getLatestReportsDataFromDB(dispatch);
      await getLatestCumulativeCallsFromDB(dispatch);
      await getLatestNotiCountFromDB(dispatch);
      await getLatestNotiTypeFromDB(dispatch);
      await getLatestMDBirthNotifiFromDB(dispatch);
      await getLatestQuickSignDataFromDB(dispatch);
      await getLatestMDMasterListFromDB(dispatch);
      await getLatestOOFListFromDB(dispatch);
      await getLatestOOFModalDataFromDB(dispatch);
      await getLatestItineraryDataFromDB(dispatch);
      await getLatestMDRequestFromDB(dispatch);
      await getLatestMDInfoFromDB(dispatch);
    } else if (type === "login" || type === "finalUpload") {
      const [
        userData,
        mdList,
        activities,
        mdMasterList,
        quickSign,
        oofListData,
        oofNotes,
        oofTypes,
        itinerary,
        itineraryMDs,
        universalMDList,
        clinic,
        classes,
        frequency,
        specialty,
      ] = await Promise.all([
        getAllEntriesFromDB("userData"),
        getAllEntriesFromDB("mdList"),
        getAllEntriesFromDB("activities"),
        getAllEntriesFromDB("mdMasterList"),
        getAllEntriesFromDB("quickSign"),
        getAllEntriesFromDB("oofListData"),
        getAllEntriesFromDB("oofNotes"),
        getAllEntriesFromDB("oofTypes"),
        getAllEntriesFromDB("itinerary"),
        getAllEntriesFromDB("itineraryMDs"),
        getAllEntriesFromDB("universalMDList"),
        getAllEntriesFromDB("clinic"),
        getAllEntriesFromDB("classes"),
        getAllEntriesFromDB("frequency"),
        getAllEntriesFromDB("specialty"),
      ]);
      await getLatestTimeInOutDataFromDB(dispatch);
      await getLatestCycleFromDB(dispatch);
      await getLatestNotiCountFromDB(dispatch, type);
      await getLatestNotiTypeFromDB(dispatch, type);
      const oldUser = user && user?.length > 0 ? user[0] : "";
      const newUser = userData && userData?.length > 0 ? userData[0] : "";
      const checkUser =
        oldUser && newUser
          ? newUser?.user_id !== oldUser?.user_id ||
            newUser?.username !== oldUser?.username
          : true;
      if (checkUser || mdList?.length === 0) {
        await getLatestMDDataFromDB(dispatch);
      }
      if (checkUser || activities?.length === 0) {
        await getLatestReportsDataFromDB(dispatch);
      }
      if (checkUser || mdMasterList?.length === 0) {
        await getLatestMDMasterListFromDB(dispatch);
      }
      if (checkUser || quickSign?.length === 0) {
        await getLatestQuickSignDataFromDB(dispatch);
      }
      if (checkUser || oofListData?.length === 0) {
        await getLatestOOFListFromDB(dispatch);
      }
      if (checkUser || oofNotes?.length === 0 || oofTypes?.length === 0) {
        await getLatestOOFModalDataFromDB(dispatch);
      }
      if (
        checkUser ||
        itinerary?.length === 0 ||
        itineraryMDs?.length === 0 ||
        universalMDList?.length === 0
      ) {
        await getLatestItineraryDataFromDB(dispatch);
      }
      if (
        checkUser ||
        clinic?.length === 0 ||
        classes?.length === 0 ||
        frequency?.length === 0 ||
        specialty?.length === 0
      ) {
        await getLatestMDInfoFromDB(dispatch);
      }
      if (checkUser) {
        await getLatestCumulativeCallsFromDB(dispatch);
        await getLatestMDBirthNotifiFromDB(dispatch);
        await getLatestMDRequestFromDB(dispatch);
      }
    }
    await db.errorLogs.clear();
    dispatch(reqToFetchDataProcess(false));
    dispatch(reqToResetApiMessage(""));
    dispatch(reqToResetMDApiMessage(""));
    dispatch(reqToResetVMCApiMessage(""));
    dispatch(reqToResetNotiApiMessage(""));
    dispatch(reqToResetTimeApiMessage(""));
  } catch (error) {
    dispatch(reqToFetchDataProcess(false));
    dispatch(reqToResetApiMessage(""));
    dispatch(reqToResetMDApiMessage(""));
    dispatch(reqToResetVMCApiMessage(""));
    dispatch(reqToResetNotiApiMessage(""));
    dispatch(reqToResetTimeApiMessage(""));
    throw error;
  }
};
export const getLatestTimeInOutDataFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetTimeInOutData({
        data: { date: dateFormatWithTime(new Date()) },
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.timeInOut.clear();
          db.addedTimeInOut.clear();
          const [userData] = await Promise.all([
            getAllEntriesFromDB("userData"),
          ]);
          let newData = { ...userData[0] };
          if (res && res?.data && res?.data?.length > 0) {
            res?.data.map(async (item, index) => {
              if (item?.type === "IN" && userData?.length > 0) {
                newData = {
                  ...newData,
                  time_in: true,
                  timein_date: item?.date,
                };
              } else if (item?.type === "OUT" && userData?.length > 0) {
                newData = {
                  ...newData,
                  time_in: true,
                  timeout_date: item?.date,
                };
              }
              const reqObj = {
                ...item,
                id: index + 1,
                timein_id: item?.id,
                timein_date: item?.type === "IN" ? item?.date : "",
                timeout_date: item?.type === "OUT" ? item?.date : "",
              };
              await insertEntryInDB("timeInOut", reqObj);
            });
            await dispatch(updateUserState(newData));
          } else {
            newData = {
              ...userData[0],
              time_in: false,
              timein_date: "",
              timeout_date: "",
            };
            await dispatch(updateUserState(newData));
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    notify("Failed to fetch time in/out data!");
  });
};
export const getLatestNotiCountFromDB = async (dispatch, toast = true) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetNotificationCount({
        data: "",
        onSuccess: async (res) => {
          if (res && res.status === 200 && res?.data) {
            db.notificationCount.clear();
            await insertEntryInDB("notificationCount", res?.data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    if (toast) {
      notify("Failed to fetch notification count!");
    }
  });
};
export const getLatestNotiTypeFromDB = async (dispatch, toast = true) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetNotificationType({
        data: "",
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.adminNotification.clear();
          if (Array.isArray(res?.data?.admin_notification)) {
            const data = res?.data?.admin_notification.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                admin_noti_id: item?.id,
              };
            });
            await insertEntriesInDB("adminNotification", data);
          }
          db.dmNotification.clear();
          if (Array.isArray(res?.data?.dm_notification)) {
            const data = res?.data?.dm_notification.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                dm_noti_id: item?.id,
              };
            });
            await insertEntriesInDB("dmNotification", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    if (toast) {
      notify("Failed to fetch notification!");
    }
  });
};
export const getLatestMDBirthNotifiFromDB = async (dispatch, toast = true) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetBirthDayData({
        data: "",
        onSuccess: async (res) => {
          if (res && res.status === 200 && res?.data) {
            db.mdBirthNotification.clear();
            await insertEntryInDB("mdBirthNotification", {
              ...res?.data,
              id: 1,
            });
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    if (toast) {
      notify("Failed to fetch birthday notification count!");
    }
  });
};
export const getLatestCycleFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetActiveCycle({
        onSuccess: async (res) => {
          if (res && res.status === 200 && res?.data) {
            db.activeCycle.clear();
            await insertEntryInDB("activeCycle", res?.data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
        dispatch,
      })
    );
  }).catch(() => {
    notify("Failed to fetch current cycle!");
  });
};
export const getLatestMDDataFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    db.mdList.clear();
    db.dashBoardPerDay.clear();
    db.dashBoardMonthly.clear();
    dispatch(
      reqToGetMdItineraryData({
        data: { report_date: moment().format("YYYY-MM-DD") },
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.editableCalls.clear();
          db.callRecording.clear();
          if (Array.isArray(res?.data?.md_list)) {
            const data = res?.data?.md_list.map((item, i) => {
              const callSData = item && item?.data ? item?.data : "";
              const saveCData =
                item && item?.save_data ? JSON.parse(item?.save_data) : "";
              if (callSData && callSData?.signature_url) {
                updateMediaFileInDB(callSData?.signature_url, "sign");
              }
              if (callSData && callSData?.jointCall?.signature_url) {
                updateMediaFileInDB(
                  callSData?.jointCall?.signature_url,
                  "sign"
                );
              }
              if (!callSData && saveCData?.mdDetails?.signature_url) {
                updateMediaFileInDB(
                  saveCData?.mdDetails?.signature_url,
                  "sign"
                );
              }
              if (!callSData && saveCData?.jointCall?.signature_url) {
                updateMediaFileInDB(
                  saveCData?.jointCall?.signature_url,
                  "sign"
                );
              }
              if (!callSData && saveCData?.quickSignObj?.signature_url) {
                updateMediaFileInDB(
                  saveCData?.quickSignObj?.signature_url,
                  "sign"
                );
              }
              return {
                ...item,
                id: i + 1,
                today: moment().format("YYYY-MM-DD"),
              };
            });
            await insertEntriesInDB("mdList", data);
          }
          db.holidays.clear();
          if (Array.isArray(res?.data?.holidays)) {
            const data = res?.data?.holidays.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("holidays", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
        dispatch,
      })
    );
  }).catch(() => {
    notify("Failed to fetch md!");
  });
};
export const getLatestCumulativeCallsFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetCumulativeCalls({
        data: { date: moment().format("YYYY-MM-DD") },
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.cumulativeCalls.clear();
          db.addedCumulativeCalls.clear();
          if (Array.isArray(res?.data)) {
            const reports = res?.data.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                data:
                  item?.data && typeof item?.data === "string"
                    ? JSON.parse(item?.data)
                    : item?.data,
                call_id: item?.id,
              };
            });
            await insertEntriesInDB("cumulativeCalls", reports);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
        dispatch,
      })
    );
  }).catch(() => {
    notify("Failed to fetch cumulative reports!");
  });
};
export const getLatestReportsDataFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetOffLineReportsData({
        data: { month: moment().add(0, "months").format("MMMM") },
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.callConcentration.clear();
          db.promoMatsInventory.clear();
          if (Array.isArray(res?.data?.promoMatsInventory)) {
            const data = res?.data?.promoMatsInventory.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("promoMatsInventory", data);
          }
          db.incidentalCalls.clear();
          if (Array.isArray(res?.data?.incidentalCalls?.list)) {
            const data = res?.data?.incidentalCalls?.list.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("incidentalCalls", data);
          }
          db.monthlyCallRate.clear();
          if (Array.isArray(res?.data?.monthlyCallRate)) {
            const data = res?.data?.monthlyCallRate.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("monthlyCallRate", data);
          }
          db.monthlyMDReach.clear();
          if (Array.isArray(res?.data?.monthlyMdRate)) {
            const data = res?.data?.monthlyMdRate.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("monthlyMDReach", data);
          }
          db.activities.clear();
          res?.data?.activity?.length > 0 &&
            res?.data?.activity.map(async (item, i) => {
              const sData = await getEntryFromDB("activities", i + 1);
              const entry = {
                ...item,
                id: i + 1,
              };
              if (sData && modifyText(sData?.name) === modifyText(item?.name)) {
              } else {
                await updateEntryInDB("activities", "id", i + 1, entry);
              }
            });
          if (res?.data?.activity && res?.data?.activity?.length > 0) {
            handleActivitiesData(res?.data?.activity);
          }
          db.products.clear();
          if (Array.isArray(res?.data?.product_sampling)) {
            const data = res?.data?.product_sampling.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                product_id: item.id,
              };
            });
            await insertEntriesInDB("products", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
        dispatch,
      })
    );
  }).catch(() => {
    notify("Failed to fetch report data!");
  });
};
export const getLatestQuickSignDataFromDB = async (dispatch, toast = true) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetOffLineQuickSignData({
        data: "",
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.quickSign.clear();
          db.addedQuickSign.clear();
          db.deletedQuickSign.clear();
          if (Array.isArray(res?.data?.quick_sign?.list)) {
            const data = res?.data?.quick_sign?.list.map((item, i) => {
              updateMediaFileInDB(item?.signature_url, "sign");
              return {
                ...item,
                id: i + 1,
                signature_id: item.id,
                signPadData: "",
              };
            });
            await insertEntriesInDB("quickSign", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
        dispatch,
      })
    );
  }).catch(() => {
    if (toast) {
      notify("Failed to fetch quick sign!");
    }
  });
};
export const getLatestMDMasterListFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetMDMasterList({
        data: "",
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.mdMasterList.clear();
          if (Array.isArray(res?.data)) {
            const data = res?.data?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("mdMasterList", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
        dispatch,
      })
    );
  }).catch(() => {
    notify("Failed to fetch master md!");
  });
};
export const getLatestOOFListFromDB = async (dispatch, toast = true) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetOutOfFieldData({
        data: "",
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.oofListData.clear();
          if (Array.isArray(res?.data?.data)) {
            const data = res?.data?.data?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                md_list:
                  item?.md_list && typeof item?.md_list === "string"
                    ? JSON.parse(item?.md_list)?.length > 0
                      ? JSON.parse(item?.md_list)
                      : []
                    : item?.md_list?.length > 0
                    ? item?.md_list
                    : [],
              };
            });
            await insertEntriesInDB("oofListData", data);
          }
          db.disabled_mds.clear();
          const newDisabledMDs = await concatMdIdsByDate(res?.data?.oof_list);
          if (Array.isArray(newDisabledMDs)) {
            const data = newDisabledMDs?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("disabled_mds", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    if (toast) {
      notify("Failed to fetch activities!");
    }
  });
};
export const getLatestOOFModalDataFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetVMCModalData({
        data: "",
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.oofCycle.clear();
          res?.data?.cycle &&
            (await insertEntryInDB("oofCycle", {
              ...res?.data?.cycle,
              id: 1,
              cycle_id: res?.data?.cycle?.id,
            }));
          db.oofNotes.clear();
          if (Array.isArray(res?.data?.notes)) {
            const data = res?.data?.notes?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                notes_id: item.id,
              };
            });
            await insertEntriesInDB("oofNotes", data);
          }
          db.oofTypes.clear();
          if (Array.isArray(res?.data?.oof_type)) {
            const data = res?.data?.oof_type?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                type_id: item.id,
              };
            });
            await insertEntriesInDB("oofTypes", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    notify("Failed to fetch activitie modal data!");
  });
};
export const getLatestHolidayFreqFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    const newDate = getMonthStartEndDate(1);
    dispatch(
      reqToGetHolidaysAndFreq({
        data: { month: newDate.month, year: newDate.year },
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.holidays.clear();
          if (Array.isArray(res?.data?.data?.dates)) {
            const data = res?.data?.data?.dates?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("holidays", data);
          }
          db.frequency.clear();
          if (Array.isArray(res?.data?.data?.frequency_details)) {
            const data = res?.data?.data?.frequency_details?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                freq_id: item?.id,
              };
            });
            await insertEntriesInDB("frequency", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    notify("Failed to fetch holidays!");
  });
};
export const getLatestItineraryDataFromDB = async (dispatch, toast = true) => {
  return new Promise((resolve, reject) => {
    const newDate = getMonthStartEndDate(1);
    const reqObj = {
      month: newDate.month,
      year: newDate.year,
      start: moment(newDate.start).format("YYYY-MM-DD"),
      end: moment(newDate.end).format("YYYY-MM-DD"),
    };
    db.itinerary.clear();
    db.addedItinerary.clear();
    db.itineraryRequest.clear();
    db.itineraryMDs.clear();
    db.universalMDList.clear();
    db.universalNMonthMD.clear();
    dispatch(
      reqToGetNextMonthItinerary({
        data: reqObj,
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          if (Array.isArray(res?.data?.data)) {
            const data = res?.data?.data?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                itinerary_id: item?.id,
              };
            });
            await insertEntriesInDB("itinerary", data);
          }
          if (Array.isArray(res?.data?.request)) {
            const data = res?.data?.request?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                request_id: item?.id,
              };
            });
            await insertEntriesInDB("itineraryRequest", data);
          }
          if (Array.isArray(res?.data?.mds)) {
            const data = res?.data?.mds?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                itinerary_MDs_id: item?.id,
              };
            });
            await insertEntriesInDB("itineraryMDs", data);
          }
          if (Array.isArray(res?.data?.universal_md_list)) {
            const data = res?.data?.universal_md_list?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("universalMDList", data);
          }
          if (Array.isArray(res?.data?.add_itinerary_md_list)) {
            const data = res?.data?.add_itinerary_md_list?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("universalNMonthMD", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    if (toast) {
      notify("Failed to fetch itinerary!");
    }
  });
};
export const getLatestDaysFieldReportFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetDaysFieldReport({
        data: "",
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.daysField.clear();
          if (Array.isArray(res?.data)) {
            const data = res?.data?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
              };
            });
            await insertEntriesInDB("daysField", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    notify("Failed to fetch DaysField!");
  });
};
export const getLatestMDRequestFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    const newDate = getMonthStartEndDate(1);
    dispatch(
      reqToGetMDRequest({
        data: { month: newDate.month, year: newDate.year },
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.addedMD.clear();
          db.mdRequest.clear();
          if (Array.isArray(res?.data?.data)) {
            const data = res?.data?.data?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                md_request_id: item?.id,
              };
            });
            await insertEntriesInDB("mdRequest", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    notify("Failed to fetch MD request!");
  });
};
export const getLatestMDInfoFromDB = async (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToGetMDInfoDetails({
        data: "",
        onSuccess: async (res) => {
          if (res?.status !== 200) return resolve(res);
          db.classes.clear();
          if (Array.isArray(res?.data?.classData)) {
            const data = res?.data?.classData?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                class_id: item?.id,
              };
            });
            await insertEntriesInDB("classes", data);
          }
          db.clinic.clear();
          if (Array.isArray(res?.data?.clinicData)) {
            const data = res?.data?.clinicData?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                clinic_id: item?.id,
              };
            });
            await insertEntriesInDB("clinic", data);
          }
          db.frequency.clear();
          if (Array.isArray(res?.data?.frequencyData)) {
            const data = res?.data?.frequencyData?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                freq_id: item?.id,
              };
            });
            await insertEntriesInDB("frequency", data);
          }
          db.specialty.clear();
          if (Array.isArray(res?.data?.specialtyData)) {
            const data = res?.data?.specialtyData?.map((item, i) => {
              return {
                ...item,
                id: i + 1,
                specialty_id: item?.id,
              };
            });
            await insertEntriesInDB("specialty", data);
          }
          resolve(res);
        },
        onFailure: (err) => {
          reject(err);
        },
      })
    );
  }).catch(() => {
    notify("Failed to fetch MD info!");
  });
};

// SUBMIT DATA
export const handleOfflineDataSyncing = async (
  dispatch,
  sync,
  syncType = "sync"
) => {
  sync && dispatch(reqToStartSyncProcess(true));
  return new Promise(async (resolve, reject) => {
    const [
      addedCumulativeCalls,
      callRecordingData,
      addedQuickSign,
      deletedQuickSign,
      editableCalls,
      oofMissedCalls,
      addedTimeInOut,
      addedMDBirthday,
      addedItinerary,
      addedMD,
      userData,
    ] = await Promise.all([
      getAllEntriesFromDB("addedCumulativeCalls"),
      getAllEntriesFromDB("callRecording"),
      getAllEntriesFromDB("addedQuickSign"),
      getAllEntriesFromDB("deletedQuickSign"),
      getAllEntriesFromDB("editableCalls"),
      getAllEntriesFromDB("oofMissedCalls"),
      getAllEntriesFromDB("addedTimeInOut"),
      getAllEntriesFromDB("addedMDBirthday"),
      getAllEntriesFromDB("addedItinerary"),
      getAllEntriesFromDB("addedMD"),
      getAllEntriesFromDB("userData"),
    ]);
    if (
      addedCumulativeCalls?.length > 0 ||
      callRecordingData?.length > 0 ||
      addedQuickSign?.length > 0 ||
      deletedQuickSign?.length > 0 ||
      editableCalls?.length > 0 ||
      oofMissedCalls?.length > 0 ||
      addedTimeInOut?.length > 0 ||
      addedMDBirthday?.length > 0 ||
      addedItinerary?.length > 0 ||
      addedMD?.length > 0
    ) {
      await submitIndexedDbData(
        dispatch,
        addedCumulativeCalls,
        callRecordingData,
        addedQuickSign,
        deletedQuickSign,
        editableCalls,
        oofMissedCalls,
        addedTimeInOut,
        addedMDBirthday,
        addedItinerary,
        addedMD,
        userData,
        sync,
        syncType
      )
        .then((res) => {
          dispatch(reqToStartSyncProcess(false));
          resolve(res);
        })
        .catch((error) => {
          dispatch(reqToStartSyncProcess(false));
          reject(error);
        });
    } else {
      if (
        sync &&
        (syncType === "sync" ||
          syncType === "isNMonth" ||
          syncType === "finalUpload")
      ) {
        getLatestDataFromDB(syncType, dispatch, userData);
      }
      dispatch(reqToStartSyncProcess(false));
      resolve();
    }
  });
};
export const submitIndexedDbData = async (
  dispatch,
  addedCumulativeCalls,
  callData,
  addedSign,
  deleteSign,
  editCallData,
  oofMissedCalls,
  addedTimeInOut,
  addedMDBirthday,
  addedItinerary,
  addedMD,
  userData,
  sync,
  syncType
) => {
  return new Promise(async (resolve, reject) => {
    const promises = [];
    if (
      (sync || (!sync && syncType === "sync")) &&
      addedTimeInOut &&
      addedTimeInOut?.length > 0
    ) {
      promises.push(
        submitTimeInOutDataWhenOnline(dispatch, addedTimeInOut, "addTimeInOut")
      );
    }
    if (
      (sync || (!sync && syncType === "sync")) &&
      addedCumulativeCalls &&
      addedCumulativeCalls?.length > 0
    ) {
      promises.push(
        submitCumulativeCallWhenOnline(
          dispatch,
          addedCumulativeCalls,
          "addedCCall"
        )
      );
    }
    if (
      (sync || (!sync && syncType === "sync")) &&
      callData &&
      callData?.length > 0
    ) {
      promises.push(submitCallDataWhenOnline(dispatch, callData, "call"));
    }
    if (
      (sync || (!sync && syncType === "sync")) &&
      editCallData &&
      editCallData?.length > 0
    ) {
      promises.push(
        submitEditCallDataWhenOnline(dispatch, editCallData, "editCall")
      );
    }
    if (sync && addedSign && addedSign?.length > 0) {
      promises.push(submitQuickSignWhenOnline(dispatch, addedSign, "addSign"));
    }
    if (sync && deleteSign && deleteSign?.length > 0) {
      promises.push(
        deleteQuickSignWhenOnline(dispatch, deleteSign, "deleteSign")
      );
    }
    if (sync && addedMDBirthday && addedMDBirthday?.length > 0) {
      promises.push(
        submitMDBirthdayWhenOnline(dispatch, addedMDBirthday, "addMDBirthday")
      );
    }
    if (
      (sync || (!sync && syncType === "sync")) &&
      oofMissedCalls &&
      oofMissedCalls?.length > 0
    ) {
      promises.push(
        submitOFFMissedCallWhenOnline(dispatch, oofMissedCalls, "addMissCall")
      );
    }
    if (
      (sync || (!sync && syncType === "sync")) &&
      addedItinerary &&
      addedItinerary?.length > 0
    ) {
      promises.push(
        submitItineraryWhenOnline(dispatch, addedItinerary, "addedItinerary")
      );
    }
    if (
      (sync || (!sync && syncType === "sync")) &&
      addedMD &&
      addedMD?.length > 0
    ) {
      promises.push(submitNewMDWhenOnline(dispatch, addedMD, "addedMD"));
    }
    await Promise.all(promises)
      .then((res) => {
        if (sync && syncType === "sync") {
          if (res?.includes("addSign")) {
            getLatestQuickSignDataFromDB(dispatch, "").catch(() => {});
          }
          if (res?.includes("addMissCall")) {
            getLatestOOFListFromDB(dispatch).catch(() => {});
          }
          if (res?.includes("addedMD") || res?.includes("addedItinerary")) {
            getLatestItineraryDataFromDB(dispatch).catch(() => {});
          }
          if (res?.includes("addedCCall")) {
            getLatestCumulativeCallsFromDB(dispatch).catch(() => {});
          }
          getLatestCycleFromDB(dispatch).catch(() => {});
          getLatestTimeInOutDataFromDB(dispatch).catch(() => {});
        } else if (
          sync &&
          (syncType === "isNMonth" || syncType === "finalUpload")
        ) {
          getLatestDataFromDB(syncType, dispatch, userData);
        }
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const submitCallDataWhenOnline = async (dispatch, callData, type) => {
  const promises = [];
  for (const newData of callData) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        try {
          let location = "";
          console.log("newData", newData);
          if (newData?.mdDetails?.latitude && newData?.mdDetails?.longitude) {
            location = await reqToGetLocation(newData?.mdDetails, dispatch);
            newData.mdDetails.address = location.address;
            newData.mdDetails.latitude = location.latitude;
            newData.mdDetails.longitude = location.longitude;
          }
          if (newData?.mdDetails?.reliever_md) {
            await submitMDRelieverWhenOnline(newData?.mdDetails, dispatch);
          }
          if (newData?.quickSignObj?.reliever_md) {
            await submitMDRelieverWhenOnline(newData?.quickSignObj, dispatch);
          }

          // UPDATE MD DETAILS
          if (newData?.callActivity?.md_table_id) {
            const mdID = newData.callActivity.md_table_id;
            const selectMD = await db.mdList.get(mdID);
            if (selectMD) {
              await db.mdList.update(selectMD?.id, {
                ...selectMD,
                address:
                  location && location?.address ? location?.address : null,
              });
            }
          }

          // UPDATE INCIDENTAL CALL
          if (newData?.callActivity?.is_incidental) {
            const incCall = newData.callActivity.incidental_table_id;
            const incidental = await db.incidentalCalls.get(incCall);
            if (incidental) {
              await db.incidentalCalls.update(incidental?.id, {
                ...incidental,
                address:
                  location && location?.address ? location?.address : null,
              });
            }
          }

          if (newData.mdDetails?.signature_url) {
            const blobData =
              newData.mdDetails.signature_url?.size ||
              !newData.mdDetails.signature_url.includes("base64")
                ? newData.mdDetails.signature_url
                : dataURLtoBlob(newData.mdDetails.signature_url);
            const mdSign = await processMediaFile(blobData, dispatch);
            newData.mdDetails.signature_url = mdSign;
            await db.callRecording.update(newData.id, newData);
            if (newData.jointCall?.signature_url) {
              const blobData =
                newData.jointCall.signature_url?.size ||
                !newData.jointCall.signature_url.includes("base64")
                  ? newData.jointCall.signature_url
                  : dataURLtoBlob(newData.jointCall.signature_url);
              const dmSign = await processMediaFile(blobData, dispatch);
              newData.jointCall.signature_url = dmSign;
              await db.callRecording.update(newData.id, newData);
            }
            await processCallRecordingData(newData, dispatch);
          } else if (
            newData.quickSignObj?.signature_url?.size ||
            newData.quickSignObj?.signature_url.includes("base64")
          ) {
            const blobData =
              newData.quickSignObj.signature_url?.size ||
              !newData.quickSignObj.signature_url.includes("base64")
                ? newData.quickSignObj.signature_url
                : dataURLtoBlob(newData.quickSignObj.signature_url);
            const mdSign = await processMediaFile(blobData, dispatch, false);
            newData.quickSignObj.signature_url = mdSign;
            await db.callRecording.update(newData.id, newData);
            await processAddQuickSign(
              newData.quickSignObj.signature_url,
              newData.quickSignObj,
              dispatch
            );
            await db.callRecording.update(newData.id, newData);
            if (newData.jointCall?.signature_url) {
              const blobData =
                newData.jointCall.signature_url?.size ||
                !newData.jointCall.signature_url.includes("base64")
                  ? newData.jointCall.signature_url
                  : dataURLtoBlob(newData.jointCall.signature_url);
              const dmSign = await processMediaFile(blobData, dispatch);
              newData.jointCall.signature_url = dmSign;
              await db.callRecording.update(newData.id, newData);
            }
            newData.mdDetails.signature_url =
              newData.quickSignObj.signature_url;
            newData.mdDetails.signature_time =
              newData.quickSignObj.signature_time;
            newData.mdDetails.signature_erase =
              newData.quickSignObj.signature_erase;
            newData.mdDetails.signature_md_time = newData.quickSignObj.date;
            newData.mdDetails.signature_type =
              newData.quickSignObj.signature_type;
            newData.mdDetails.reliever_md = newData?.quickSignObj?.reliever_md;
            await processCallRecordingData(newData, dispatch);
          } else if (!newData.quickSignObj?.quick_sign_id) {
            await processAddQuickSign(
              newData.quickSignObj.signature_url,
              newData.quickSignObj,
              dispatch
            );
            await db.callRecording.update(newData.id, newData);
            if (newData.jointCall?.signature_url) {
              const blobData =
                newData.jointCall.signature_url?.size ||
                !newData.jointCall.signature_url.includes("base64")
                  ? newData.jointCall.signature_url
                  : dataURLtoBlob(newData.jointCall.signature_url);
              const dmSign = await processMediaFile(blobData, dispatch);
              newData.jointCall.signature_url = dmSign;
              await db.callRecording.update(newData.id, newData);
            }
            newData.mdDetails.signature_url =
              newData.quickSignObj.signature_url;
            newData.mdDetails.signature_time =
              newData.quickSignObj.signature_time;
            newData.mdDetails.signature_erase =
              newData.quickSignObj.signature_erase;
            newData.mdDetails.signature_md_time = newData.quickSignObj.date;
            newData.mdDetails.signature_type =
              newData.quickSignObj.signature_type;
            newData.mdDetails.reliever_md = newData?.quickSignObj?.reliever_md;
            await processCallRecordingData(newData, dispatch);
          } else {
            if (newData.jointCall?.signature_url) {
              const blobData =
                newData.jointCall.signature_url?.size ||
                !newData.jointCall.signature_url.includes("base64")
                  ? newData.jointCall.signature_url
                  : dataURLtoBlob(newData.jointCall.signature_url);
              const dmSign = await processMediaFile(blobData, dispatch);
              newData.jointCall.signature_url = dmSign;
              await db.callRecording.update(newData.id, newData);
            }
            newData.mdDetails.signature_url =
              newData.quickSignObj.signature_url;
            newData.mdDetails.signature_time =
              newData.quickSignObj.signature_time;
            newData.mdDetails.signature_erase =
              newData.quickSignObj.signature_erase;
            newData.mdDetails.signature_md_time = newData.quickSignObj.date;
            newData.mdDetails.signature_type =
              newData.quickSignObj.signature_type;
            newData.mdDetails.reliever_md = newData?.quickSignObj?.reliever_md;
            await processCallRecordingData(newData, dispatch);
          }
          resolve(type);
        } catch (error) {
          reject(error);
        }
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const processCallRecordingData = (newData, dispatch) => {
  return new Promise((resolve, reject) => {
    const reqObj = {
      ...newData?.callActivity,
      ...newData?.jointCall,
      ...newData?.mdDetails,
      ...newData?.products,
      ...newData?.researches,
      ...newData?.sampling,
      signature_md_time: newData?.mdDetails?.signature_md_time
        ? newData?.mdDetails?.signature_md_time
        : new Date(),
      reliever_md: newData?.mdDetails?.reliever_md?.md_id,
      product: newData?.products?.product,
      pActivity_id: newData?.products?.activity_id,
      rActivity_id: newData?.researches?.activity_id,
      sActivity_id: newData?.sampling?.activity_id,
      jActivity_id: newData?.jointCall?.activity_id,
      quick_sign_id: newData?.quickSignObj?.quick_sign_id,
      sProduct: newData?.sampling?.product,
      signature_type: newData?.mdDetails?.signature_type
        ? newData?.mdDetails?.signature_type
        : "SIGNATURE",
      joinCall_signature: newData?.jointCall?.signature_url,
      joint_signature_time: newData?.jointCall?.signature_time,
      joint_signature_erase: newData?.jointCall?.signature_erase,
      joint_signature_type: newData?.jointCall?.signature_type,
    };
    dispatch(
      reqToSubmitCallRecordingData({
        data: reqObj,
        onSuccess: async (response) => {
          await db.callRecording.delete(newData?.id);
          resolve(response);
        },
        onFailure: async (error) => {
          if (
            error &&
            error?.response &&
            error?.response?.status === 400 &&
            error?.response?.data?.toLowerCase() === "already exist"
          ) {
            await db.callRecording.delete(newData?.id);
            resolve();
          } else {
            await insertEntryInDB("errorLogs", {
              data: reqObj,
              api: apiendpoints.submitCallRecording,
              error: JSON.stringify(error),
              date: new Date(),
              response: JSON.stringify({
                data: error?.response?.data,
                status: error?.response?.status,
                statusText: error?.response?.statusText,
              }),
            });
            reject(error);
          }
        },
        dispatch,
      })
    );
  });
};
export const submitEditCallDataWhenOnline = async (
  dispatch,
  editableCalls,
  type
) => {
  const promises = [];
  for (const item of editableCalls) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        let newData = { ...item?.data };
        if (
          newData?.mdDetails?.signature_url?.size ||
          newData?.mdDetails?.signPadData?.includes("base64")
        ) {
          const blobData =
            newData?.mdDetails?.signature_url?.size ||
            (newData?.mdDetails?.signature_url &&
              !newData?.mdDetails?.signature_url?.includes("base64"))
              ? newData?.mdDetails?.signature_url
              : dataURLtoBlob(newData?.mdDetails?.signPadData);
          const mdSign = await processMediaFile(blobData, dispatch);
          newData = {
            ...newData,
            mdDetails: {
              ...newData?.mdDetails,
              signPadData: mdSign,
              signature_url: mdSign,
            },
          };
          await db.editableCalls.update(item.id, {
            ...item,
            data: {
              ...item.data,
              ...newData,
            },
          });
        }
        if (
          newData?.jointCall?.signature_url?.size ||
          newData?.jointCall?.signPadData?.includes("base64")
        ) {
          const blobData =
            newData?.jointCall?.signature_url?.size ||
            (newData?.jointCall?.signature_url &&
              !newData?.jointCall?.signature_url?.includes("base64"))
              ? newData?.jointCall?.signature_url
              : dataURLtoBlob(newData?.jointCall?.signPadData);
          const dmSign = await processMediaFile(blobData, dispatch);
          newData = {
            ...newData,
            jointCall: {
              ...newData?.jointCall,
              signPadData: dmSign,
              signature_url: dmSign,
            },
          };
          await db.editableCalls.update(item.id, {
            ...item,
            data: {
              ...item.data,
              ...newData,
            },
          });
        }
        if (
          newData?.quickSignObj?.signature_url?.size ||
          newData?.quickSignObj?.signPadData?.includes("base64")
        ) {
          const blobData =
            newData?.quickSignObj?.signature_url?.size ||
            (newData?.quickSignObj?.signature_url &&
              !newData?.quickSignObj?.signature_url?.includes("base64"))
              ? newData?.quickSignObj?.signature_url
              : dataURLtoBlob(newData?.quickSignObj?.signPadData);
          const mdSign = await processMediaFile(blobData, dispatch, false);
          newData = {
            ...newData,
            quickSignObj: {
              ...newData?.quickSignObj,
              signPadData: mdSign,
              signature_url: mdSign,
            },
          };
          await db.editableCalls.update(item.id, {
            ...item,
            data: {
              ...item.data,
              ...newData,
            },
          });
        }
        // UPDATE MD DETAILS
        if (item?.md_table_id) {
          const selectMD = await getEntryFromDB("mdList", item?.md_table_id);
          if (selectMD) {
            await db.mdList.update(selectMD?.id, {
              ...selectMD,
              save_data: JSON.stringify(newData),
            });
          }
        }
        dispatch(
          reqToSubmitEditableCalls({
            data: { ...item, data: JSON.stringify(newData) },
            onSuccess: async (response) => {
              await db.editableCalls.delete(item?.id);
              resolve(response);
            },
            onFailure: async (error) => {
              if (
                error &&
                error?.response &&
                error?.response?.status === 400 &&
                error?.response?.data?.toLowerCase() === "already exist"
              ) {
                await db.editableCalls.delete(item?.id);
                resolve();
              } else {
                await insertEntryInDB("errorLogs", {
                  data: { ...item, data: JSON.stringify(item.data) },
                  api: apiendpoints.submitEditCall,
                  error: JSON.stringify(error),
                  date: new Date(),
                  response: JSON.stringify({
                    data: error?.response?.data,
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                  }),
                });
                reject(error);
              }
            },
            dispatch,
          })
        );
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const submitCumulativeCallWhenOnline = async (
  dispatch,
  addedCumulativeCalls,
  type
) => {
  const promises = [];
  for (const item of addedCumulativeCalls) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        let newData = { ...item?.data?.summary_data };
        if (
          newData?.mdDetails?.signature_url?.size ||
          newData?.mdDetails?.signPadData?.includes("base64")
        ) {
          const blobData =
            newData?.mdDetails?.signature_url?.size ||
            (newData?.mdDetails?.signature_url &&
              !newData?.mdDetails?.signature_url?.includes("base64"))
              ? newData?.mdDetails?.signature_url
              : dataURLtoBlob(newData?.mdDetails?.signPadData);
          const mdSign = await processMediaFile(blobData, dispatch);
          newData = {
            ...newData,
            mdDetails: {
              ...newData?.mdDetails,
              signature_url: mdSign,
              signPadData: mdSign,
            },
            signature_url: mdSign,
          };
          await db.addedCumulativeCalls.update(item.id, {
            ...item,
            data: {
              ...item.data,
              summary_data: newData,
            },
          });
        }
        if (
          newData?.jointCall?.signature_url?.size ||
          newData?.jointCall?.signPadData?.includes("base64")
        ) {
          const blobData =
            newData?.jointCall?.signature_url?.size ||
            (newData?.jointCall?.signature_url &&
              !newData?.jointCall?.signature_url?.includes("base64"))
              ? newData?.jointCall?.signature_url
              : dataURLtoBlob(newData?.jointCall?.signPadData);
          const dmSign = await processMediaFile(blobData, dispatch);
          newData = {
            ...newData,
            jointCall: {
              ...newData?.jointCall,
              signature_url: dmSign,
              signPadData: dmSign,
            },
          };
          await db.addedCumulativeCalls.update(item.id, {
            ...item,
            data: {
              ...item.data,
              summary_data: newData,
            },
          });
        }
        if (
          newData?.quickSignObj?.signature_url?.size ||
          newData?.quickSignObj?.signPadData?.includes("base64")
        ) {
          const blobData =
            newData?.quickSignObj?.signature_url?.size ||
            (newData?.quickSignObj?.signature_url &&
              !newData?.quickSignObj?.signature_url?.includes("base64"))
              ? newData?.quickSignObj?.signature_url
              : dataURLtoBlob(newData?.quickSignObj?.signPadData);
          const mdSign = await processMediaFile(blobData, dispatch, false);
          newData = {
            ...newData,
            signature_url: mdSign,
            quickSignObj: {
              ...newData?.quickSignObj,
              signature_url: mdSign,
              signPadData: mdSign,
            },
          };
          await db.addedCumulativeCalls.update(item.id, {
            ...item,
            data: {
              ...item.data,
              summary_data: newData,
            },
          });
        }
        if (item?.edit_call_id) {
          const getCall = await getEntryFromDB(
            "editableCalls",
            item?.edit_call_id
          );
          if (getCall) {
            const newCallData = {
              ...getCall,
              data: {
                ...getCall?.data,
                jointCall: {
                  ...getCall?.data?.jointCall,
                  signPadData:
                    newData?.jointCall?.signPadData ||
                    getCall?.data?.jointCall?.signPadData,
                  signature_url:
                    newData?.jointCall?.signature_url ||
                    getCall?.data?.jointCall?.signature_url,
                },
                mdDetails: {
                  ...getCall?.data?.mdDetails,
                  signPadData:
                    newData?.mdDetails?.signPadData ||
                    getCall?.data?.mdDetails?.signPadData,
                  signature_url:
                    newData?.mdDetails?.signature_url ||
                    getCall?.data?.mdDetails?.signature_url,
                },
                quickSignObj: {
                  ...getCall?.data?.quickSignObj,
                  signPadData:
                    newData?.quickSignObj?.signPadData ||
                    getCall?.data?.quickSignObj?.signPadData,
                  signature_url:
                    newData?.quickSignObj?.signature_url ||
                    getCall?.data?.quickSignObj?.signature_url,
                },
              },
            };
            await updateEntryInDB(
              "editableCalls",
              "id",
              item?.edit_call_id,
              newCallData
            );
          }
        }
        if (item?.done_call_id) {
          const getCall = await getEntryFromDB(
            "callRecording",
            item?.done_call_id
          );
          if (getCall) {
            const newCallData = {
              ...getCall,
              jointCall: {
                ...getCall?.jointCall,
                signature_url:
                  newData?.jointCall?.signature_url ||
                  getCall?.jointCall?.signature_url,
              },
              mdDetails: {
                ...getCall?.mdDetails,
                signature_url:
                  newData?.mdDetails?.signature_url ||
                  getCall?.mdDetails?.signature_url,
              },
              quickSignObj: {
                ...getCall?.quickSignObj,
                signPadData:
                  newData?.quickSignObj?.signPadData ||
                  getCall?.quickSignObj?.signPadData,
                signature_url:
                  newData?.quickSignObj?.signature_url ||
                  getCall?.quickSignObj?.signature_url,
              },
            };
            await updateEntryInDB(
              "callRecording",
              "id",
              item?.done_call_id,
              newCallData
            );
          }
        }
        dispatch(
          reqToSubmitCumulativeCalls({
            data: {
              ...item,
              data: JSON.stringify({
                summary_data: newData,
                selectedMD: item?.data?.selectedMD,
              }),
            },
            onSuccess: async (response) => {
              await db.addedCumulativeCalls.delete(item?.id);
              resolve(response);
            },
            onFailure: async (error) => {
              if (
                error &&
                error?.response &&
                error?.response?.status === 400 &&
                error?.response?.data?.toLowerCase() === "already exist"
              ) {
                await db.addedCumulativeCalls.delete(item?.id);
                resolve();
              } else {
                await insertEntryInDB("errorLogs", {
                  data: { ...item, data: JSON.stringify(item.data) },
                  api: apiendpoints.submitCumulativeCall,
                  error: JSON.stringify(error),
                  date: new Date(),
                  response: JSON.stringify({
                    data: error?.response?.data,
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                  }),
                });
                reject(error);
              }
            },
          })
        );
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const submitQuickSignWhenOnline = async (dispatch, addedSign, type) => {
  const promises = [];
  for (const signData of addedSign) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        try {
          if (
            signData?.signature_url?.size ||
            signData?.signPadData?.includes("base64")
          ) {
            const blobData =
              signData?.signature_url?.size ||
              (signData?.signature_url &&
                !signData?.signature_url?.includes("base64"))
                ? signData?.signature_url
                : dataURLtoBlob(signData?.signPadData);

            const quickSign = await processMediaFile(blobData, dispatch, false);
            signData.signature_url = quickSign;
            await db.quickSign.update(signData?.id, signData);
            await db.addedQuickSign.update(signData?.id, signData);
          }
          const res = await processAddQuickSign(
            signData.signature_url,
            signData,
            dispatch
          );
          await db.quickSign.update(signData?.id, {
            ...signData,
            signature_id: res?.id,
            signPadData: "",
          });
          await db.addedQuickSign.delete(signData?.id);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const deleteQuickSignWhenOnline = async (dispatch, deleteSign, type) => {
  const promises = [];
  for (const item of deleteSign) {
    try {
      const result = await new Promise((resolve, reject) => {
        dispatch(
          reqToDeleteSignature({
            data: { quick_sign_id: item.signature_id },
            onSuccess: async (res) => {
              await db.deletedQuickSign.delete(item?.id);
              resolve(res);
            },
            onFailure: async (error) => {
              await insertEntryInDB("errorLogs", {
                data: { quick_sign_id: item.signature_id },
                api: apiendpoints.deleteSignature,
                error: JSON.stringify(error),
                date: new Date(),
                response: JSON.stringify({
                  data: error?.response?.data,
                  status: error?.response?.status,
                  statusText: error?.response?.statusText,
                }),
              });
              reject(error);
            },
          })
        );
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const submitOFFMissedCallWhenOnline = async (
  dispatch,
  oofMissedCalls,
  type
) => {
  const promises = [];
  for (const item of oofMissedCalls) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        let location = "";
        await processMediaFile(item.image, dispatch)
          .then(async (media) => {
            item.image = media;
            if (item?.latitude && item?.longitude) {
              location = await reqToGetLocation(item, dispatch);
              item.address = location.address;
              item.latitude = location.latitude;
              item.longitude = location.longitude;
            }
            await db.oofMissedCalls.update(item?.id, item);
            dispatch(
              reqToAddOutOfFieldData({
                data: item,
                onSuccess: async (res) => {
                  await db.oofMissedCalls.delete(item?.id);
                  resolve(res);
                },
                onFailure: async (error) => {
                  if (
                    error &&
                    error?.response &&
                    error?.response?.status === 400 &&
                    error?.response?.data?.toLowerCase() === "already exist"
                  ) {
                    await db.oofMissedCalls.delete(item?.id);
                    resolve();
                  } else {
                    await insertEntryInDB("errorLogs", {
                      data: item,
                      api: apiendpoints.addOutOfField,
                      error: JSON.stringify(error),
                      date: new Date(),
                      response: JSON.stringify({
                        data: error?.response?.data,
                        status: error?.response?.status,
                        statusText: error?.response?.statusText,
                      }),
                    });
                    reject(error);
                  }
                },
              })
            );
          })
          .catch((error) => {
            reject(error);
          });
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const submitTimeInOutDataWhenOnline = async (
  dispatch,
  timeInOut,
  type
) => {
  const promises = [];
  for (const timeData of timeInOut) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        try {
          if (timeData?.latitude && timeData?.longitude) {
            const location = await reqToGetLocation(timeData, dispatch);
            timeData.location = location.address;
          }
          if (
            timeData?.selfie_image?.size ||
            timeData?.selfie_image.includes("base64")
          ) {
            const selfieBlob = timeData?.selfie_image.includes("base64")
              ? dataURLtoBlob(timeData?.selfie_image)
              : timeData?.selfie_image;
            const selfie = await processMediaFile(selfieBlob, dispatch);
            timeData.selfie_image = selfie;
            await db.timeInOut.update(timeData?.id, timeData);
            await db.addedTimeInOut.update(timeData?.id, timeData);

            const signBlob = timeData?.signature_image.includes("base64")
              ? dataURLtoBlob(timeData?.signature_image)
              : timeData?.signature_image;
            const sign = await processMediaFile(signBlob, dispatch);
            timeData.signature_image = sign;
            await db.timeInOut.update(timeData?.id, timeData);
            await db.addedTimeInOut.update(timeData?.id, timeData);
          } else if (
            timeData?.signature_image?.size ||
            timeData?.signature_image.includes("base64")
          ) {
            const signBlob = timeData?.signature_image.includes("base64")
              ? dataURLtoBlob(timeData?.signature_image)
              : timeData?.signature_image;
            const sign = await processMediaFile(signBlob, dispatch);
            timeData.signature_image = sign;
            await db.timeInOut.update(timeData?.id, timeData);
            await db.addedTimeInOut.update(timeData?.id, timeData);
          }
          await processTimeInOutData(timeData, dispatch);
          resolve(type);
        } catch (error) {
          reject(error);
        }
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const processTimeInOutData = async (timeInOut, dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      reqToAddTimeInOutData({
        data: timeInOut,
        onSuccess: async (res) => {
          const newData = res && res.data;
          await db.timeInOut.update(timeInOut?.id, {
            ...timeInOut,
            location: newData.location,
            timein_id: newData.id,
          });
          await db.addedTimeInOut.delete(timeInOut?.id);
          resolve(res);
        },
        onFailure: async (error) => {
          if (
            error &&
            error?.response &&
            error?.response?.status === 400 &&
            error?.response?.data?.toLowerCase() === "already exist"
          ) {
            await db.addedTimeInOut.delete(timeInOut?.id);
            resolve();
          } else {
            await insertEntryInDB("errorLogs", {
              data: timeInOut,
              api: apiendpoints.addTimeInOut,
              error: JSON.stringify(error),
              date: new Date(),
              response: JSON.stringify({
                data: error?.response?.data,
                status: error?.response?.status,
                statusText: error?.response?.statusText,
              }),
            });
            reject(error);
          }
        },
      })
    );
  });
};
export const processAddQuickSign = (url, newData, dispatch) => {
  return new Promise((resolve, reject) => {
    const reqObj = {
      signature_url: url,
      signature_url_time: newData?.date ? newData?.date : new Date(),
      signature_type: newData?.signature_type
        ? newData?.signature_type
        : "SIGNATURE",
      signature_id: newData?.signature_id,
      signature_time: newData?.signature_time,
      signature_erase: newData?.signature_erase,
    };
    dispatch(
      reqToAddSignature({
        data: reqObj,
        onSuccess: (res) => {
          newData.quick_sign_id = res?.id;
          resolve(res);
        },
        onFailure: async (error) => {
          if (
            error &&
            error?.response &&
            error?.response?.status === 400 &&
            error?.response?.data?.toLowerCase() === "already exist"
          ) {
            resolve(newData);
          } else {
            await insertEntryInDB("errorLogs", {
              data: reqObj,
              api: apiendpoints.addSignature,
              error: JSON.stringify(error),
              date: new Date(),
              response: JSON.stringify({
                data: error?.response?.data,
                status: error?.response?.status,
                statusText: error?.response?.statusText,
              }),
            });
            reject(error);
          }
        },
      })
    );
  });
};
export const submitMDBirthdayWhenOnline = async (
  dispatch,
  mdBirthday,
  type
) => {
  const promises = [];
  for (const item of mdBirthday) {
    try {
      const result = await new Promise((resolve, reject) => {
        const reqObj = {
          md_id: item.md_id,
          birth_date: item?.birth_date,
        };
        dispatch(
          reqToChangeMDBirthday({
            data: reqObj,
            onSuccess: async (res) => {
              await db.addedMDBirthday.delete(item.id);
              resolve(res);
            },
            onFailure: async (error) => {
              if (
                error &&
                error?.response &&
                error?.response?.status === 400 &&
                error?.response?.data?.toLowerCase() === "already exist"
              ) {
                await db.addedMDBirthday.delete(item?.id);
                resolve();
              } else {
                await insertEntryInDB("errorLogs", {
                  data: reqObj,
                  api: apiendpoints.changeMDBirthday,
                  error: JSON.stringify(error),
                  date: new Date(),
                  response: JSON.stringify({
                    data: error?.response?.data,
                    status: error?.response?.status,
                    statusText: error?.response?.statusText,
                  }),
                });
                reject(error);
              }
            },
          })
        );
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const submitMDRelieverWhenOnline = async (newData, dispatch) => {
  return new Promise((resolve, reject) => {
    if (newData?.reliever_md?.md_id) return resolve();
    if (newData && newData?.reliever_md) {
      const reqObj = {
        firstname: newData?.reliever_md?.firstname,
        lastname: newData?.reliever_md?.lastname,
      };
      dispatch(
        reqToAddMDReliever({
          data: reqObj,
          onSuccess: (res) => {
            newData.reliever_md = {
              ...newData.reliever_md,
              md_id: res?.id,
            };
            resolve();
          },
          onFailure: async (error) => {
            if (
              error &&
              error?.response &&
              error?.response?.status === 400 &&
              error?.response?.data?.toLowerCase() === "already exist"
            ) {
              resolve();
            } else {
              await insertEntryInDB("errorLogs", {
                data: reqObj,
                api: apiendpoints.addMDReliever,
                error: JSON.stringify(error),
                date: new Date(),
                response: JSON.stringify({
                  data: error?.response?.data,
                  status: error?.response?.status,
                  statusText: error?.response?.statusText,
                }),
              });
              reject(error);
            }
          },
        })
      );
    }
  });
};
export const submitItineraryWhenOnline = async (dispatch, itinerary, type) => {
  const promises = [];
  for (const item of itinerary) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        await processMediaFile(item.attachment, dispatch)
          .then(async (media) => {
            item.attachment = media;
            await db.addedItinerary.update(item?.id, item);
            await db.itineraryRequest.update(item?.id, item);
            dispatch(
              reqToUpdateItinerary({
                data: { ...item, excel_file: item?.attachment },
                onSuccess: async (res) => {
                  await db.addedItinerary.delete(item.id);
                  resolve(res);
                },
                onFailure: async (error) => {
                  if (
                    error &&
                    error?.response &&
                    error?.response?.status === 400 &&
                    error?.response?.data?.toLowerCase() === "already exist"
                  ) {
                    await db.addedItinerary.delete(item.id);
                    resolve();
                  } else {
                    await insertEntryInDB("errorLogs", {
                      data: { ...item, excel_file: item?.attachment },
                      api: apiendpoints.addItinerary,
                      error: JSON.stringify(error),
                      date: new Date(),
                      response: JSON.stringify({
                        data: error?.response?.data,
                        status: error?.response?.status,
                        statusText: error?.response?.statusText,
                      }),
                    });
                    reject(error);
                  }
                },
              })
            );
          })
          .catch((error) => {
            reject(error);
          });
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const submitNewMDWhenOnline = async (dispatch, addedMD, type) => {
  const promises = [];
  for (const item of addedMD) {
    try {
      const result = await new Promise(async (resolve, reject) => {
        await processMediaFile(item.file, dispatch)
          .then(async (media) => {
            item.file = media;
            await db.addedMD.update(item?.id, item);
            await db.mdRequest.update(item?.id, item);
            dispatch(
              reqToAddMDRequest({
                data: item,
                onSuccess: async (res) => {
                  await db.addedMD.delete(item?.id);
                  resolve(res);
                },
                onFailure: async (error) => {
                  if (
                    error &&
                    error?.response &&
                    error?.response?.status === 400 &&
                    error?.response?.data?.toLowerCase() === "already exist"
                  ) {
                    await db.addedMD.delete(item?.id);
                    resolve();
                  } else {
                    await insertEntryInDB("errorLogs", {
                      data: item,
                      api: apiendpoints.addMDRequest,
                      error: JSON.stringify(error),
                      date: new Date(),
                      response: JSON.stringify({
                        data: error?.response?.data,
                        status: error?.response?.status,
                        statusText: error?.response?.statusText,
                      }),
                    });
                    reject(error);
                  }
                },
              })
            );
          })
          .catch((error) => {
            reject(error);
          });
      });
      promises.push({ data: result, status: true });
    } catch (error) {
      promises.push({ data: error, status: false });
    }
  }
  const failedCalls = promises.filter((result) => !result.status);
  if (failedCalls?.length === 0) {
    return Promise.resolve(type);
  } else {
    return Promise.reject(failedCalls[0].data);
  }
};
export const processMediaFile = (url, dispatch, cache = true) => {
  return new Promise((resolve, reject) => {
    if (url && url?.size) {
      const formData = new FormData();
      if (url && url?.name && url?.name.includes(".xls")) {
        formData.append("file", url);
      } else {
        formData.append("file", url, "signature.png");
      }
      dispatch(
        reqToAddMediaFile({
          data: formData,
          onSuccess: async (res) => {
            if (res && res?.data?.length > 0) {
              if (url?.name && url?.name.includes(".xls")) {
                let excelUrl = res?.data[0]?.videoUrl.split("/");
                let excelName = excelUrl && excelUrl[excelUrl?.length - 1];
                resolve(excelName);
              } else {
                let imagesUrl = res?.data[0]?.imageUrlBigUrl.split("/");
                let imageName = imagesUrl && imagesUrl[imagesUrl?.length - 1];
                await updateMediaFileInDB(imageName, "sign", url);
                resolve(imageName);
              }
            }
          },
          onFailure: async (error) => {
            const base64 = await mediaToBase64(url);
            await insertEntryInDB("errorLogs", {
              data: base64,
              api: apiendpoints.media,
              error: JSON.stringify(error),
              date: new Date(),
              response: JSON.stringify({
                data: error?.response?.data,
                status: error?.response?.status,
                statusText: error?.response?.statusText,
              }),
            });
            reject(error);
          },
        })
      );
    } else {
      resolve(url);
    }
  });
};
export const reqToGetLocation = async (newData, dispatch) => {
  return new Promise((resolve, reject) => {
    const reqObj = {
      latitude: newData.latitude,
      longitude: newData.longitude,
    };
    dispatch(
      reqToGetAddressFromLatLong({
        data: reqObj,
        onSuccess: (response) => {
          const location = {
            address: response.data,
            latitude: newData.latitude,
            longitude: newData.longitude,
          };
          resolve(location);
        },
        onFailure: async (error) => {
          await insertEntryInDB("errorLogs", {
            data: reqObj,
            api: apiendpoints.getLocation,
            error: JSON.stringify(error),
            date: new Date(),
            response: JSON.stringify({
              data: error?.response?.data,
              status: error?.response?.status,
              statusText: error?.response?.statusText,
            }),
          });
          reject(error);
        },
      })
    );
  });
};

const handleActivitiesData = async (activities) => {
  const newActivities = [...activities];
  const detailings = newActivities?.filter((item) =>
    modifyText(item?.name).includes("detailing")
  );
  if (detailings && detailings?.length > 0) {
    const newDetailings = detailings[0];
    updatePDetailings(newDetailings)
      .then((res) => {
        db.activities.update(res?.id, res);
      })
      .catch(() => {});
  }
};
const updatePDetailings = async (newDetailings) => {
  if (!newDetailings || newDetailings?.product_detailing.length === 0) {
    return newDetailings;
  }
  const updatedPDetailings = await Promise.all(
    newDetailings?.product_detailing.map(async (item) => {
      return await updateProductDMedia(item);
    })
  );
  return {
    ...newDetailings,
    product_detailing: updatedPDetailings,
  };
};
const updateProductDMedia = async (item) => {
  const updatedItem = { ...item };
  if (item.pdf_url) {
    try {
      if (item && item.pdf_url.includes(".pdf")) {
        updatedItem.pdfBlob = item.pdf_url;
        updatedItem.base64 = item.pdf_url;
        await updateMediaFileInDB(item.pdf_url, "pdf");
      } else {
        updatedItem.pdfBlob = item.pdf_url;
        updatedItem.base64 = item.pdf_url;
        await updateMediaFileInDB(item.pdf_url, "image");
      }
    } catch (error) {
      throw new Error(`Failed to fetch PDF for ${item.pdf_url}`, error);
    }
  }
  if (Array.isArray(item.subProduct) && item.subProduct.length > 0) {
    updatedItem.subProduct = await Promise.all(
      item.subProduct.map(async (subItem) => {
        return await updateProductDMedia(subItem);
      })
    );
  }
  return updatedItem;
};
const concatMdIdsByDate = async (data) => {
  const result = {};
  if (data && data?.length > 0) {
    data.forEach((item) => {
      const { date, md_ids, disable_all } = item;
      const mdIdsArray = md_ids
        ? [...new Set(md_ids.split(",").map((id) => Number(id.trim())))]
        : [];
      if (result[date]) {
        result[date].md_ids = [
          ...new Set(result[date].md_ids.concat(mdIdsArray)),
        ];
      } else {
        result[date] = {
          date: date,
          md_ids: mdIdsArray,
          disable_all: disable_all,
        };
      }
    });
    return Object.values(result);
  } else {
    return [];
  }
};

export const updateMediaFileInDB = async (file, type, blobData) => {
  if (!file || Object.keys(file)?.length === 0) return;
  try {
    const tableName =
      type === "pdf" || type === "image" ? "productImages" : "mediaFiles";
    const findFile = await getEntryByFieldFromDB(tableName, "file", file);
    if (findFile?.length === 0 || !findFile[0]?.base64) {
      const url = file?.includes("https")
        ? file
        : process.env.REACT_APP_IMAGE_URL + file;
      const blob = blobData ? blobData : await fetchMediaData(url);
      const base64 = blob ? await mediaToBase64(blob) : "";
      await updateEntryInDB(tableName, "file", file, {
        file: file,
        type: type,
        blob: "",
        base64: base64,
      });
      return blob;
    }
  } catch (err) {
    console.log("FAILED_TO_UPDATE_MEDIA", err);
    return file;
  }
};
export const getMediaFileFromDB = async (dataList, file, base64, fetch) => {
  try {
    if (
      dataList &&
      dataList[file] &&
      !dataList[base64] &&
      !dataList[file]?.size &&
      !dataList[file]?.includes("base64")
    ) {
      const fileData = await getEntryByFieldFromDB(
        "mediaFiles",
        "file",
        dataList[file]
      );
      if (fetch === "blob") {
        const blob = fileData?.[0]?.base64
          ? dataURLtoBlob(fileData?.[0]?.base64)
          : "";
        return blob;
      } else {
        return fileData?.[0]?.base64 ? fileData?.[0]?.base64 : "";
      }
    } else if (dataList[base64] && dataList[base64]?.includes("base64")) {
      return dataList[base64];
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};
