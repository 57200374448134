import { Navigate } from "react-router-dom";
import { routes } from "./constants";
import DmLoginPage from "../pages/Login/DmLoginPage";
import LoginPage from "../pages/Login/LoginPage";
import Dashboard from "../pages/Dashboard/Dashboard";
import MdList from "../pages/MdList/MdList";
import MonthlyMdList from "../pages/MdList/MonthlyMdList";
import MdBirthDayList from "../pages/MdList/MdBirthDayList";
import MDInfo from "../pages/MdList/MDInfo";
import MissedCallMDList from "../pages/MdList/MissedCallMDList";
import CallActivities from "../pages/CallRecording/CallActivities";
import ViewCallSummary from "../pages/CallRecording/ViewCallSummary";
import QuickSign from "../pages/QuickSign/QuickSign";
import PromoMaterials from "../pages/PromoMaterials/PromoMaterials";
import ReceivePromoMats from "../pages/PromoMaterials/ReceivePromoMats";
import OutOfField from "../pages/OutOfField/OutOfField";
import TimeInOut from "../pages/TimeInOut/TimeInOut";
import Tactical from "../pages/Tactical/Tactical";
import Reports from "../pages/Report/Reports";
import Notifications from "../pages/Notifications/Notifications";
import AdminLoginPage from "../pages/Login/AdminLoginPage";
import AdminItineraries from "../pages/AdminUser/AdminItineraries";
import MissedCalls from "../pages/DmUser/MissedCalls";
import AdminMissedCalls from "../pages/AdminUser/AdminMissedCalls";
import DmApproval from "../pages/DmUser/DmApproval";
import Calls from "../pages/AdminUser/Calls/Calls";
import AuditReport from "../pages/AdminUser/AuditReport/AuditReport";
import Others from "../pages/AdminUser/Others/Others";
import Sync from "../pages/Sync/Sync";
import Itinerary from "../pages/Itinerary/Itinerary";
import UpdateItinerary from "../pages/Itinerary/UpdateItinerary";
import ItineraryPerDay from "../pages/Itinerary/ItineraryPerDay";
import MonthlyItinerary from "../pages/Itinerary/MonthlyItinerary";
import BrowserCompatibility from "../pages/BrowserCompatibility/BrowserCompatibility";

export const compatibilityRoutes = [
  {
    path: routes.homepage,
    element: <Navigate to={routes.browserCompatibility} />,
  },
  { path: routes.browserCompatibility, element: <BrowserCompatibility /> },
];

export const authRoutes = [
  { path: routes.homepage, element: <Navigate to={routes.login} /> },
  { path: routes.login, element: <LoginPage /> },
  // { path: routes.dmLogin, element: <DmLoginPage /> },
  // { path: routes.adminLogin, element: <AdminLoginPage /> },
];

export const dmRoutes = [
  {
    path: routes.homepage,
    element: <Navigate to={routes.approvalItinerary} />,
  },
  { path: routes.approvalItinerary, element: <DmApproval /> },
  { path: routes.missCalls, element: <MissedCalls /> },
];

export const adminRoutes = [
  {
    path: routes.homepage,
    element: <Navigate to={routes.adminApprovalItinerary} />,
  },
  { path: routes.adminApprovalItinerary, element: <AdminItineraries /> },
  { path: routes.adminMissCalls, element: <AdminMissedCalls /> },
  { path: routes.calls, element: <Calls /> },
  { path: routes.auditReport, element: <AuditReport /> },
  { path: routes.others, element: <Others /> },
];

export const mainRoutes = [
  { path: routes.homepage, element: <Navigate to={routes.dashboard} /> },
  { path: routes.dashboard, element: <Dashboard /> },
  { path: routes.mdList, element: <MdList /> },
  { path: routes.monthlyMdList, element: <MonthlyMdList /> },
  { path: routes.missedCallMdList, element: <MissedCallMDList /> },
  { path: routes.itinerary, element: <Itinerary /> },
  { path: routes.updateItinerary, element: <UpdateItinerary /> },
  { path: routes.itineraryPerDay, element: <ItineraryPerDay /> },
  { path: routes.monthlyItinerary, element: <MonthlyItinerary /> },
  { path: routes.callActivities, element: <CallActivities /> },
  { path: routes.quickSign, element: <QuickSign /> },
  { path: routes.viewCallSummary, element: <ViewCallSummary /> },
  { path: routes.promoMaterials, element: <PromoMaterials /> },
  { path: routes.recivePromoMats, element: <ReceivePromoMats /> },
  { path: routes.outOfField, element: <OutOfField /> },
  { path: routes.timeInOut, element: <TimeInOut /> },
  { path: routes.tactical, element: <Tactical /> },
  { path: routes.reports, element: <Reports /> },
  { path: routes.notifications, element: <Notifications /> },
  { path: routes.mdBirthdayList, element: <MdBirthDayList /> },
  { path: routes.mdInfo, element: <MDInfo /> },
  { path: routes.sync, element: <Sync /> },
];

export const timeoutRoutes = [
  { path: routes.homepage, element: <Navigate to={routes.sync} /> },
  { path: routes.sync, element: <Sync /> },
];

export const limitedRoutes = [
  { path: routes.homepage, element: <Navigate to={routes.timeInOut} /> },
  { path: routes.timeInOut, element: <TimeInOut /> },
];

export const invalidRoutes = [
  { path: "*", element: <LoginPage /> },
  { path: routes.login, element: <LoginPage /> },
];
