import React from "react";
import Chart from "react-apexcharts";

const ReportChart = ({
  selectedReportType,
  getBarChartData,
  getPieChartData,
  showChart,
}) => {
  // bar chart options
  const barChartOptions = () => {
    return {
      options: {
        chart: {
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        grid: {
          show:
            selectedReportType.value === "daily_call_rate" ||
            selectedReportType.value === "daily_md_reach" ||
            selectedReportType.value === "daily_incidental_calls" ||
            selectedReportType.value === "monthly_call_rate" ||
            selectedReportType.value === "monthly_md_reach"
              ? true
              : false,
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          style: {
            color: "black",
          },
        },
        dataLabels: {
          enabled:
            selectedReportType.value === "call_concentration" ||
            selectedReportType.value === "incidental_calls"
              ? true
              : false,
          formatter: function (val) {
            return val !== 0 ? val : "";
          },
          offsetY: -20,
          style: {
            fontSize: "16px",
            colors: ["#FFFFFF"],
            fontWeight: "700",
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "70%",
            barHeight: "100%",
            dataLabels: {
              position: "top",
            },
          },
        },
        colors: getBarChartData().colors || ["#FFC000", "#9DC3E6"],
        xaxis: {
          categories: getBarChartData().xAixsLabels || [],
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "#FFFFFF",
              fontSize:
                selectedReportType.value === "daily_call_rate" ||
                selectedReportType.value === "daily_md_reach" ||
                selectedReportType.value === "daily_incidental_calls" ||
                selectedReportType.value === "monthly_call_rate" ||
                selectedReportType.value === "monthly_md_reach" ||
                selectedReportType.value === "call_summary"
                  ? "14px"
                  : "18px",
              fontWeight: 600,
            },
          },
          axisBorder: {
            show: true,
            color: "#FFFFFF",
            height: 1,
            width: "100%",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          tickAmount: 4,
          min: 0,
          max: getBarChartData().maxValue || 0,
          labels: {
            formatter: function (val) {
              return val !== 0 ? val : 0;
            },

            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: "#FFFFFF",
              fontSize: "16px",
              fontWeight: 600,
            },
          },
          axisBorder: {
            show: true,
            color: "#FFFFFF",
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: true,
            borderType: "solid",
            color: "#FFFFFF",
            width: 14,
            offsetX: 7,
            offsetY: 0,
          },
        },
        legend: {
          show: selectedReportType.value === "days_in_field" ? false : true,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "18px",
          itemMargin: {
            horizontal: 10,
            vertical: 0,
          },
          offsetX: 10,
          offsetY: 0,
          fontWeight: 600,
          markers: {
            width: 16,
            height: 16,
            radius: 0,
          },
          labels: {
            colors: "#FFFFFF",
            useSeriesColors: false,
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "none",
              value: 0.15,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0.35,
            },
          },
        },
      },
      series:
        selectedReportType.value === "days_in_field"
          ? [
              {
                name: "Attendance",
                data: getBarChartData().actualCallSeries || [],
              },
            ]
          : selectedReportType.value !== "call_concentration" &&
            selectedReportType.value !== "incidental_calls" &&
            selectedReportType.value !== "daily_incidental_calls"
          ? [
              {
                name: "Target",
                data: getBarChartData().targetCallSeries || [],
              },
              {
                name: "Actual",
                data: getBarChartData().actualCallSeries || [],
              },
            ]
          : [
              {
                name: "Actual",
                data: getBarChartData().actualCallSeries || [],
              },
            ],
    };
  };

  const pieChartOptions = (isPromomatsSample) => {
    return {
      series: isPromomatsSample
        ? getPieChartData(true).series
        : getPieChartData().series || [],
      options: {
        chart: {
          width: 380,
          type: "pie",
        },
        dataLabels: {
          enabled: false,
        },
        colors: isPromomatsSample
          ? getPieChartData(true).colors
          : getPieChartData().colors || [],
        labels: isPromomatsSample
          ? getPieChartData(true).labels
          : getPieChartData().labels || [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
            },
          },
        ],
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontWeight: 600,
          itemMargin: {
            horizontal:
              selectedReportType.value === "promo_mats_inventory" ? 5 : 10,
            vertical: 0,
          },
          markers: {
            width: 16,
            height: 16,
            radius: 0,
          },
          labels: {
            colors: "#FFFFFF",
            useSeriesColors: false,
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
            },
          },
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
    };
  };

  return (
    <div className="tc-chart-content">
      {selectedReportType &&
        (selectedReportType.value === "call_summary" ||
          selectedReportType.value === "md_coverage" ||
          selectedReportType.value === "call_concentration" ||
          selectedReportType.value === "daily_call_rate" ||
          selectedReportType.value === "daily_md_reach" ||
          selectedReportType.value === "monthly_call_rate" ||
          selectedReportType.value === "monthly_md_reach" ||
          selectedReportType.value === "incidental_calls" ||
          selectedReportType.value === "daily_incidental_calls" ||
          selectedReportType.value === "days_in_field") &&
        showChart(selectedReportType.value) && (
          <div className="tc-chart-container">
            <Chart
              options={barChartOptions().options}
              series={barChartOptions().series}
              type="bar"
              className="tc-chart"
              height={200}
              width={
                selectedReportType.value === "daily_call_rate" ||
                selectedReportType.value === "daily_md_reach" ||
                selectedReportType.value === "daily_incidental_calls"
                  ? 550
                  : 450
              }
            />
          </div>
        )}
      {selectedReportType &&
        (selectedReportType.value === "samples_issuance" ||
          selectedReportType.value === "promo_mats_issuance" ||
          selectedReportType.value === "md_excuse") &&
        showChart(selectedReportType.value) && (
          <div className="tc-chart-container">
            <Chart
              options={pieChartOptions().options}
              series={pieChartOptions().series}
              type="pie"
              className={`tc-chart ${
                selectedReportType.value === "call_summary" && "mt-4"
              }`}
              height={selectedReportType.value === "call_summary" ? 325 : 300}
              width={
                selectedReportType.value === "call_summary"
                  ? 190
                  : selectedReportType.value === "days_in_field" ||
                    selectedReportType.value === "md_excuse"
                  ? 620
                  : 500
              }
            />
          </div>
        )}
      {selectedReportType &&
        selectedReportType.value === "promo_mats_inventory" &&
        showChart(selectedReportType.value) && (
          <div className="tc-charts-two-pie-chart tc-chart-container tc-promo-mats-chart-container">
            <Chart
              options={pieChartOptions(true).options}
              series={pieChartOptions(true).series}
              type="pie"
              className="tc-chart"
              height={200}
              width={250}
            />
            <Chart
              options={pieChartOptions().options}
              series={pieChartOptions().series}
              type="pie"
              className="tc-chart"
              height={200}
              width={250}
            />
          </div>
        )}
    </div>
  );
};

export default ReportChart;
