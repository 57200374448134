import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout } from "./userSlice";

// Request to upload master excel
export const reqToUploadMasterExcel = createAsyncThunk(
  "reqToUploadMasterExcel",
  async ({ data, onSuccess, onFailure, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.masterExcel,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      onFailure(error);
      return error;
    }
  }
);

// Request to upload itinerary excel
export const reqToUploadItineraryExcel = createAsyncThunk(
  "reqToUploadItineraryExcel",
  async ({ data, onSuccess, onFailure, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.itineraryExcel,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      onFailure(error);
      return error;
    }
  }
);

// Request to upload promoMats excel
export const reqToUploadPromoMatsExcel = createAsyncThunk(
  "reqToUploadPromoMatsExcel",
  async ({ data, onSuccess, onFailure, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.promoMatsExcel,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      onFailure(error);
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  fileUpload: "",
};

// Create file Upload slice
const fileUploadSlice = createSlice({
  name: "fileUpload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Upload Master Excel
    builder.addCase(reqToUploadMasterExcel.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUploadMasterExcel.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.fileUpload = action?.payload?.data;
        // notify(messages.toast.login, "success");
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToUploadMasterExcel.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Upload Itinerary Excel
    builder.addCase(reqToUploadItineraryExcel.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUploadItineraryExcel.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.fileUpload = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToUploadItineraryExcel.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Upload PromoMats Excel
    builder.addCase(reqToUploadPromoMatsExcel.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUploadPromoMatsExcel.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.fileUpload = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToUploadPromoMatsExcel.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default fileUploadSlice.reducer;
