import React, { useEffect, useState } from "react";
import { PDFIcon, backBtnIcon } from "../../icon/icon";
import { Button, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { nameFormatting } from "../../helpers/commonFunction";
import { DataNotFound, CallActivitiesMDDetails } from "../../components";
import { ProductDetailsModal } from "../../components/Modals";
import { reqToUpdateProductObj } from "../../store/slice/callRecordingSlice";
import { DoctorIcon } from "../../images";
import { getAllEntriesFromDB } from "../../helpers/db";

const ProductDetailing = (props) => {
  const {
    selectedMD,
    showSidebar,
    handleCallActivitiesSidebar,
    updateCallActivitieData,
    savedCallData,
  } = props;
  const dispatch = useDispatch();
  const savedCall = savedCallData && savedCallData?.data?.products;
  const { activities, productDObj, productDetailingData } = useSelector(
    (state) => state.callRecordingReducer
  );

  const [modalShow, setModalShow] = useState(false);
  const [productImages, setProductImages] = useState([]);
  const [productOption, setProductOption] = useState([]);
  const [productDetailing, setProductDetailing] = useState([]);
  const [productDetailingObj, setProductDetailingObj] = useState([]);
  const [uniqueProductDObj, setUniqueProductDObj] = useState([]);
  const [allSelect, setAllSelect] = useState(false);

  useEffect(() => {
    if (
      productDetailingData &&
      productDetailingData?.length > 0 &&
      productDObj?.length === 0 &&
      showSidebar
    ) {
      const sortedProducts = [...productDetailingData].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const newDetailingData = sortedProducts.map((item) => {
        return {
          ...item,
          rank: 0,
          checked: false,
        };
      });
      setProductDetailing(newDetailingData);
    } else {
      if (
        productDetailingData &&
        productDetailingData?.length > 0 &&
        productDObj?.length > 0 &&
        showSidebar
      ) {
        const sortedProducts = [...productDetailingData].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        let newData = [];
        let newUniqueData = [];
        sortedProducts &&
          sortedProducts.forEach((item) => {
            let i = 0;
            productDObj &&
              productDObj.forEach((sItem) => {
                if (item.product_id === sItem.product_id) {
                  i = 1;
                  newData.push({
                    ...sItem,
                    checked: true,
                    base64: item?.base64,
                    pdfBlob: item?.pdfBlob,
                  });
                  newUniqueData.push({
                    ...sItem,
                    checked: true,
                    base64: item?.base64,
                    pdfBlob: item?.pdfBlob,
                  });
                }
              });
            if (i === 0) {
              newData.push({
                ...item,
                checked: false,
                base64: item?.base64,
                pdfBlob: item?.pdfBlob,
              });
            }
          });
        setProductDetailing(newData);
        setUniqueProductDObj(newUniqueData);
        setProductDetailingObj(newUniqueData);
      }
    }
    const handleGetProductImage = async () => {
      const [productImages] = await Promise.all([
        getAllEntriesFromDB("productImages"),
      ]);
      setProductImages(productImages);
    };
    if (showSidebar) handleGetProductImage();
  }, [productDetailingData, showSidebar]);
  useEffect(() => {
    if (productDObj && productDObj.length > 0) {
      handleCallActivitiesSidebar();
      updateCallActivitieData(activities.productD);
    } else {
      updateCallActivitieData(activities.productD);
    }
  }, [productDObj]);

  const handleChangePRank = (data, type) => {
    let newData = [...productDetailingObj];
    let index =
      productDetailingObj &&
      productDetailingObj?.findIndex(
        (product) => product.product_id === data.product_id
      );
    if (index === -1) {
      let getRank = uniqueProductDObj && uniqueProductDObj.length;
      newData.push({
        ...data,
        rank: getRank + 1,
        checked: true,
      });
      setProductDetailing((prev) => {
        let newState = prev.map((item) => {
          if (item.product_id === data.product_id) {
            return {
              ...item,
              rank: getRank + 1,
              checked: true,
            };
          } else {
            return item;
          }
        });
        return newState;
      });
      setUniqueProductDObj(newData);
      setProductDetailingObj(newData);
    } else {
      if (type === "check") {
        newData.splice(index, 1);
        let newData2 = [...productDetailing];
        let index2 =
          newData2 &&
          newData2.findIndex((item) => item.product_id === data.product_id);
        let removeRank = newData2[index2].rank;
        newData2[index2] = { ...newData2[index2], rank: 0, checked: false };
        let newData3 = newData2.map((item) => {
          return {
            ...item,
            rank: item.rank > removeRank ? item.rank - 1 : item.rank,
          };
        });
        setProductDetailing(newData3);
        setUniqueProductDObj(newData);
        setProductDetailingObj(newData);
      }
    }
    setModalShow(false);
  };

  useEffect(() => {
    if (
      productDetailing &&
      productDetailing.every((item) => item.checked === true)
    ) {
      setAllSelect(true);
    } else {
      setAllSelect(false);
    }
  }, [productDetailing]);

  const handleCheckAllProduct = () => {
    const list = [...productDetailing];
    let currRank = uniqueProductDObj && uniqueProductDObj.length;
    let getRank = currRank;
    setAllSelect(!allSelect);
    const updatedProduct = list.map((product, i) => {
      if (!allSelect) {
        if (currRank > 0 && product.rank === 0) {
          getRank = getRank + 1;
        }
        return {
          ...product,
          rank:
            product.rank > 0 ? product.rank : currRank === 0 ? i + 1 : getRank,
          isChecked: !allSelect,
          checked: true,
        };
      } else {
        return {
          ...product,
          rank: 0,
          isChecked: !allSelect,
          checked: false,
        };
      }
    });
    if (!allSelect) {
      setProductDetailingObj(updatedProduct);
      setUniqueProductDObj(updatedProduct);
    } else {
      setProductDetailingObj([]);
      setUniqueProductDObj([]);
    }
    setProductDetailing(updatedProduct);
  };

  const handleViewProductDetails = async () => {
    setProductDetailingObj((prev) => {
      const newState = prev.map((item) => {
        return {
          ...item,
          product_start_time: new Date(),
        };
      });
      return newState;
    });
    const newData = await Promise.all(
      productDetailingObj.map(async (item) => {
        const file = productImages.find((data) => data.file === item?.pdf_url);
        const subProducts = item.subProduct
          ? await Promise.all(
              item.subProduct.map(async (subItem) => {
                const subFile = productImages.find(
                  (data) => data.file === subItem?.pdf_url
                );
                return {
                  ...subItem,
                  base64: subFile ? subFile?.base64 : "",
                };
              })
            )
          : "";
        return {
          ...item,
          base64: file ? file?.base64 : "",
          subProduct: subProducts,
        };
      })
    );
    setProductOption(newData);
    setModalShow(true);
  };

  const handleSubmitProductDetailing = (viewDetails) => {
    if (savedCall?.length > 0) {
      setModalShow(false);
      handleCallActivitiesSidebar();
    } else {
      if (
        productDetailingObj &&
        productDetailingObj?.length !== productDObj?.length
      ) {
        if (viewDetails === true) {
          let newData = [];
          productDetailingObj.forEach((item) => {
            newData.push({
              ...item,
              product_end_time: new Date(),
              base64: "",
            });
            return item;
          });
          setModalShow(false);
          dispatch(reqToUpdateProductObj(newData));
        } else {
          let newData = [];
          productDetailingObj.forEach((item) => {
            newData.push({
              ...item,
              base64: "",
            });
            return item;
          });
          dispatch(reqToUpdateProductObj(newData));
          handleCallActivitiesSidebar();
        }
      } else {
        if (viewDetails === true) {
          let newData = [];
          productDetailingObj.forEach((item) => {
            newData.push({
              ...item,
              product_end_time: new Date(),
              base64: "",
            });
            return item;
          });
          setModalShow(false);
          dispatch(reqToUpdateProductObj(newData));
        } else {
          handleCallActivitiesSidebar();
        }
      }
    }
  };

  return (
    <>
      <div className={`tc-sidebar-section ${showSidebar && "zl-sidebar-open"}`}>
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => handleCallActivitiesSidebar()}
          >
            {backBtnIcon}
          </button>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body-wrapper">
          <div className="tc-sidebar-body">
            <div className="tc-profile-wrapper">
              <img src={DoctorIcon} alt="doctor" />
              <span className="tc-profile-name">
                {selectedMD && nameFormatting(selectedMD)}
              </span>
            </div>
            <div className="h-100 pb-2">
              <div className="tc-content-wrapper h-100">
                <CallActivitiesMDDetails selectedMD={selectedMD} />
                <div className="tc-content-wrapper-title d-flex justify-content-between">
                  <h2 className="m-0">PRODUCT DETAILING</h2>
                  {productDetailing && productDetailing?.length > 0 && (
                    <div className="d-flex justify-content-center gap-4">
                      {productDetailingObj &&
                        productDetailingObj?.length > 0 && (
                          <Button
                            variant="primary"
                            type="button"
                            className="btn-min"
                            onClick={() => handleViewProductDetails()}
                          >
                            Open
                          </Button>
                        )}
                      <Button
                        variant="primary"
                        type="button"
                        className="btn-min"
                        onClick={() => handleSubmitProductDetailing(false)}
                      >
                        Done
                      </Button>
                    </div>
                  )}
                </div>
                <div className="min-height-390 overflow-auto border-radius-12 mb-1">
                  <div
                    className={`tc-table-wrapper mb-0 ${
                      savedCall &&
                      savedCall?.length > 0 &&
                      "tc-call-edit-disabled"
                    }`}
                  >
                    <Table
                      className="tc-table tc-call-activity-table tc-product-detailing-table"
                      responsive
                    >
                      <thead>
                        <tr>
                          <Form.Check
                            className="tc-md-reliever-check mb-2"
                            type={"checkbox"}
                            id="product-detailing"
                            checked={allSelect}
                            onChange={() => handleCheckAllProduct()}
                            disabled={
                              savedCall && savedCall?.length > 0 ? true : false
                            }
                          />
                          <th>Product</th>
                          <th>View Detailing Aid</th>
                          <th>Rank</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productDetailingData &&
                        productDetailingData?.product?.length === 0 ? (
                          <tr>
                            <td colSpan={3}>
                              <DataNotFound />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {productDetailing &&
                              productDetailing?.length > 0 &&
                              productDetailing.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className="tc-md-list-product-detailing-tr"
                                  >
                                    <td>
                                      <Form.Check
                                        className="tc-md-reliever-check mb-2"
                                        type={"checkbox"}
                                        id={`product${item.product_id}`}
                                        checked={item.checked}
                                        onChange={() =>
                                          handleChangePRank(item, "check")
                                        }
                                        disabled={
                                          savedCall && savedCall?.length > 0
                                            ? true
                                            : false
                                        }
                                      />
                                    </td>
                                    <td className="text-start min-width-300">
                                      {item?.name}
                                    </td>
                                    <td>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleChangePRank(item, "pdf")
                                        }
                                      >
                                        {PDFIcon}
                                      </button>
                                    </td>
                                    <td>
                                      <p className="tc-md-list-table-pdf-open-number-text">
                                        {item?.rank ? item?.rank : "-"}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              })}
                          </>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalShow === true && (
        <ProductDetailsModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          productOption={productOption}
          handleSubmitProductDetailing={handleSubmitProductDetailing}
        />
      )}
    </>
  );
};

export default ProductDetailing;
