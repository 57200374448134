import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import TacticalModal from "./TacticalModal";
import { useDispatch, useSelector } from "react-redux";
import {
  tacticalProductList,
  tacticalActivityList,
  mdNameTacticalList,
  addTacticalObjects,
} from "../../store/slice/tacticalSlice";
import { Loader } from "../../components";
import moment from "moment";
import { nameFormatting } from "../../helpers/commonFunction";

const Tactical = () => {
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    md_id: "",
    product_id: "",
    note_id: "",
    budget: "",
  });

  const [errors, setErrors] = useState({});

  const isLoading = useSelector((state) => state?.tactical?.loader);
  const productList = useSelector((state) => state?.tactical?.product);
  const activityList = useSelector((state) => state?.tactical?.activity);
  const mdNameList = useSelector((state) => state?.tactical?.mdName);

  useEffect(() => {
    handleRefreshPage();
  }, []);

  const handleRefreshPage = () => {
    dispatch(tacticalProductList({ dispatch }));
    dispatch(tacticalActivityList({ dispatch }));
    dispatch(mdNameTacticalList({ dispatch }));
  };

  const mdNameArray =
    mdNameList &&
    mdNameList?.length > 0 &&
    mdNameList?.map((item) => ({
      value: item.id,
      label: nameFormatting(item),
    }));

  const productArray =
    productList &&
    productList?.length > 0 &&
    productList?.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const activityArray =
    activityList &&
    activityList?.length > 0 &&
    activityList?.map((item) => ({
      value: item.id,
      label: item.name,
    }));

  const [tactModal, setTactModal] = useState(false);

  const handleInputChange = (selectedOption, type) => {
    // Clear the specific error when the input changes
    setErrors((prevErrors) => ({
      ...prevErrors,
      [type]: "",
    }));

    switch (type) {
      case "md_id":
        setValues((prevValues) => ({
          ...prevValues,
          md_id: selectedOption.value,
        }));
        break;
      case "product_id":
        setValues((prevValues) => ({
          ...prevValues,
          product_id: selectedOption.value,
        }));
        break;
      case "note_id":
        setValues((prevValues) => ({
          ...prevValues,
          note_id: selectedOption.value,
        }));
        break;
      case "budget":
        setValues((prevValues) => ({
          ...prevValues,
          budget: selectedOption.target.value,
        }));
        break;
      default:
        break;
    }
  };

  const validate = (fieldValues) => {
    let temp = {};

    if (!fieldValues.md_id) {
      temp.md_id = "Please select an MD Name!";
    }
    if (!fieldValues.product_id) {
      temp.product_id = "Please select a Product!";
    }
    if (!fieldValues.note_id) {
      temp.note_id = "Please select an Activity!";
    }
    if (!fieldValues.budget) {
      temp.budget = "Please enter a Budget!";
    }
    return temp;
  };

  const handleTacticalSubmit = () => {
    const validationErrors = validate(values);
    if (Object.keys(validationErrors).length === 0) {
      setTactModal(true);
    } else {
      setErrors(validationErrors);
    }
  };

  const handleFinalTacticalSave = () => {
    const data = {
      md_id: values.md_id,
      product_id: values.product_id,
      note_id: values.note_id,
      budget: values.budget,
    };
    dispatch(
      addTacticalObjects({
        data,
        toast: "Tacticals Added Successfully",
      })
    ).then(() => {
      setValues({
        md_id: "",
        product_id: "",
        note_id: "",
        budget: "",
      });
      setTactModal(false);
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="tc-body">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="tc-profile-wrapper mb-0">
            <span className="tc-profile-name">TACTICAL ACTIVITY</span>
          </div>
          <div className="text-end fs-18">
            <p className="mb-0 fw-semibold">
              {moment().format("MMMM DD, YYYY")}
            </p>
          </div>
        </div>
        <div className="tactical-body">
          <div className="tactical-body-content">
            <div className="mb-30">
              <h5 className="mb-3">MD NAME</h5>
              <div>
                <Select
                  classNamePrefix="select"
                  className="tc-custom-select"
                  isSearchable={true}
                  id="md_id"
                  name="md_id"
                  value={
                    (mdNameArray &&
                      mdNameArray?.length > 0 &&
                      mdNameArray?.find(
                        (option) => option.value === values.md_id
                      )) ||
                    null
                  }
                  onChange={(selectedOption) =>
                    handleInputChange(selectedOption, "md_id")
                  }
                  placeholder="Select MD Name"
                  options={mdNameArray}
                />
                {errors && errors.md_id && (
                  <span className="tc-error">{errors.md_id}</span>
                )}
              </div>
            </div>
            <div className="mb-30">
              <h5 className="mb-3">PRODUCT</h5>
              <div>
                <Select
                  isSearchable={true}
                  classNamePrefix="select"
                  className="tc-custom-select"
                  id="product_id"
                  name="product_id"
                  value={
                    (productArray &&
                      productArray?.length > 0 &&
                      productArray.find(
                        (option) => option.value === values.product_id
                      )) ||
                    null
                  }
                  onChange={(selectedOption) =>
                    handleInputChange(selectedOption, "product_id")
                  }
                  placeholder="Select Product"
                  options={productArray}
                />
                {errors && errors.product_id && (
                  <span className="tc-error">{errors.product_id}</span>
                )}
              </div>
            </div>
            <div className="mb-30">
              <h5 className="mb-3">ACTIVITY</h5>
              <div>
                <Select
                  isSearchable={true}
                  classNamePrefix="select"
                  className="tc-custom-select"
                  options={activityArray}
                  id="note_id"
                  name="note_id"
                  value={
                    (activityArray &&
                      activityArray?.length > 0 &&
                      activityArray.find(
                        (option) => option.value === values.note_id
                      )) ||
                    null
                  }
                  onChange={(selectedOption) =>
                    handleInputChange(selectedOption, "note_id")
                  }
                  placeholder="Select Activity"
                />
                {errors && errors.note_id && (
                  <span className="tc-error">{errors.note_id}</span>
                )}
              </div>
            </div>
            <div className="">
              <h5 className="mb-3">BUDGET</h5>
              <Form.Control
                type="number"
                id="budget"
                name="budget"
                placeholder="Enter Budget"
                value={values.budget}
                onChange={(e) => handleInputChange(e, "budget")}
              />
              {errors && errors.budget && (
                <span className="tc-error">{errors?.budget}</span>
              )}
            </div>
          </div>
          <div className="text-center mt-5">
            <Button
              variant="primary"
              className="btn-min"
              onClick={handleTacticalSubmit}
            >
              SUBMIT
            </Button>
          </div>
        </div>
        {tactModal && (
          <TacticalModal
            show={tactModal}
            onHide={() => setTactModal(false)}
            handleFinalTacticalSave={handleFinalTacticalSave}
          />
        )}
      </div>
    </>
  );
};

export default Tactical;
