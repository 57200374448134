import React from "react";
import { routes } from "../../helpers/constants";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const Page404 = () => {
  const navigate = useNavigate();
  const localSUser = JSON.parse(localStorage.getItem("user"));
  const handleGoBackPage = () => {
    if (localSUser && localSUser?.user_role === "TM" && !localSUser?.time_in) {
      navigate(routes.timeInOut);
    } else {
      navigate(routes.homepage);
    }
  };
  return (
    <div>
      <div style={{ color: "grey", textAlign: "center", marginTop: "4rem" }}>
        <h1>Page 404 not found</h1>
        {localSUser && localSUser.user_role === "TM" ? (
          <Button className="mt-3" onClick={() => handleGoBackPage()}>
            {`Go To ${
              localSUser && !localSUser?.time_in ? "Time In/Out" : "Dashboard"
            }`}
          </Button>
        ) : (
          <Button className="mt-3" onClick={() => handleGoBackPage()}>
            {`Go To Dashboard`}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Page404;
