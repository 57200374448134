export default function swDev() {
  if ("serviceWorker" in navigator) {
    const checkForUpdates = async () => {
      let swURL = `${process.env.PUBLIC_URL}/sw.js`;
      try {
        const registration = await navigator.serviceWorker.register(swURL);

        // Check for updates
        registration.addEventListener("updatefound", () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.addEventListener("statechange", () => {
              if (
                installingWorker.state === "installed" &&
                navigator.serviceWorker.controller
              ) {
                window.location.reload();
              } else if (installingWorker.state === "installed") {
              }
            });
          }
        });

        if (registration.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
          window.location.reload();
        }

        let refreshing;
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          if (refreshing) return;
          refreshing = true;
          window.location.reload();
        });
      } catch (error) {
        console.error("Error during service worker registration:", error);
      }
    };
    checkForUpdates();
  }
}
