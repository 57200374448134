import React, { useCallback, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import CustomWebCam from "../CustomWebCam";

const ImageCaptureModal = (props) => {
  const { to, handleCaptureImage, ...rest } = props;

  const webcamRef = useRef(null);
  const [zoom, setZoom] = useState(1.5);

  const capture = useCallback(() => {
    const video = webcamRef.current.video;
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const scaledWidth = video.videoWidth / zoom;
    const scaledHeight = video.videoHeight / zoom;
    const offsetX = (video.videoWidth - scaledWidth) / 2;
    const offsetY = (video.videoHeight - scaledHeight) / 2;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(
      video,
      offsetX,
      offsetY,
      scaledWidth,
      scaledHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );

    const imageSrc = canvas.toDataURL("image/jpeg", 0.3);
    if (imageSrc) {
      handleCaptureImage(imageSrc);
    }
  }, [webcamRef, zoom]);

  return (
    <Modal
      className="tc-main-modal tc-sign-modal cm_modal_xl"
      {...rest}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>SELFIE</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <CustomWebCam zoom={zoom} setZoom={setZoom} webcamRef={webcamRef} />
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex justify-content-center">
        <Button variant="primary" onClick={capture}>
          Capture
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageCaptureModal;
