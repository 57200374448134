import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { routes } from "../../helpers/constants";
import { getAllEntriesFromDB } from "../../helpers/db";

const Itinerary = () => {
  const navigate = useNavigate();
  const currentDate = moment().format("dddd, MMMM DD");

  const itineraryMethods = [
    { type: "ADD", value: "ADD", label: "Add to Itinerary", disabled: false },
    {
      type: "ITINERARYPERDAY",
      value: "ITINERARYPERDAY",
      label: "ITINERARY PER DAY",
      disabled: false,
    },
    { type: "EDIT", value: "EDIT", label: "Edit Itinerary", disabled: false },
    {
      type: "MDCLASS",
      value: "MD CLASS TOTALS",
      label: "MD Class Totals",
      disabled: false,
    },
    {
      type: "REMOVE",
      value: "REMOVE",
      label: "Remove from Itinerary",
      disabled: false,
    },
    {
      type: "PERWPERD",
      value: "PER WEEK PER DAY TOTALS",
      label: "PER WEEK PER DAY TOTALS",
      disabled: false,
    },
    { type: "VIEW", value: "VIEW", label: "View Itinerary", disabled: false },

    { type: "SUMMARY", value: "SUMMARY", label: "Summary", disabled: false },
    // {
    //   type: "WSUMMARY",
    //   value: "SUMMARY",
    //   label: "Summary (Weekly Totals)",
    //   disabled: false,
    // },
  ];

  const handleSelectItineraryMethod = async (method) => {
    if (method && method?.type === "ITINERARYPERDAY") {
      navigate(routes.itineraryPerDay);
    } else {
      const currDate = new Date();
      const currMonth = currDate.getMonth() + 1;
      const currYear = currDate.getFullYear();
      const currentMonth = {
        value: moment().format("M"),
        label: moment().format("MMMM"),
      };
      const nextMonth = {
        value: moment().add(1, "months").format("M"),
        label: moment().add(1, "months").format("MMMM"),
      };
      const selectedMonth = Number(nextMonth.value);
      const reqObj = {
        month: nextMonth.label,
        year: currMonth > selectedMonth ? currYear + 1 : currYear,
      };
      const reqObj2 = {
        currMName: currentMonth.label,
        currMonth: Number(currentMonth.value),
        nextMName: nextMonth.label,
        nextMonth: Number(nextMonth.value),
        currMYear: currYear,
        nextMYear: currMonth > selectedMonth ? currYear + 1 : currYear,
      };
      const [
        frequency,
        holidays,
        itinerary,
        itineraryMDs,
        itineraryRequest,
        addedItinerary,
        mdList,
        universalNMonthMD,
      ] = await Promise.all([
        getAllEntriesFromDB("frequency"),
        getAllEntriesFromDB("holidays"),
        getAllEntriesFromDB("itinerary"),
        getAllEntriesFromDB("itineraryMDs"),
        getAllEntriesFromDB("itineraryRequest"),
        getAllEntriesFromDB("addedItinerary"),
        getAllEntriesFromDB("mdList"),
        getAllEntriesFromDB("universalNMonthMD"),
      ]);
      navigate(routes.updateItinerary, {
        state: {
          method: method,
          itineraryMonth: reqObj?.month + " " + reqObj?.year,
          monthInfo: reqObj2,
          currDate: currDate,
          currentMonth: currentMonth,
          nextMonth: nextMonth,
          holidays: holidays,
          frequency: frequency,
          itinerary: itinerary,
          itineraryMDs: itineraryMDs,
          itineraryRequest: itineraryRequest,
          addedItinerary: addedItinerary,
          mdList: mdList,
          universalMDList: universalNMonthMD,
        },
      });
    }
  };

  return (
    <div className="tc-body">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="tc-profile-wrapper mb-0">
          <span className="tc-profile-name">UPDATES</span>
        </div>
        <div className="text-end fs-18">
          <p className="mb-0 fw-semibold">{moment().format("MMMM YYYY")}</p>
          <p className="mb-0">As of {currentDate}</p>
        </div>
      </div>
      <div className="add-scroll">
        <div className="tc-content-wrapper justify-content-center tc-report-container h-100">
          <div className="tc-update-list mt-4">
            {itineraryMethods.map((method, i) => (
              <div key={i}>
                <Button
                  variant="primary"
                  className="w-100"
                  role="button"
                  onClick={() => handleSelectItineraryMethod(method)}
                  disabled={method.disabled}
                  key={i}
                >
                  {method.label}
                </Button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Itinerary;
