import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

const ReturnpromoMats = (props) => {
  const {
    to,
    materialObj,
    values,
    errors,
    handleInputChange,
    saveMatsHandler,
    handleChangeLotNumber,
    ...rest
  } = props;

  const promoMatsReasons = useSelector(
    (state) => state.promoMaterialReducer.promoMatsReasons
  );

  return (
    <>
      <Modal
        className="tc-main-modal"
        {...rest}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-black"
          >
            Return Promo Materials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <strong className="fw-semibold pe-2 d-inline-block mb-1 text-uppercase">
              Name:{" "}
            </strong>
            <span>
              {materialObj && materialObj.product_name
                ? materialObj.product_name
                : "-"}
            </span>
          </div>
          <div>
            <strong className="fw-semibold pe-2 d-inline-block mb-1 text-uppercase">
              Balance Quantity:{" "}
            </strong>
            <span>
              {materialObj && materialObj.quantity ? materialObj.quantity : "-"}
            </span>
          </div>
          {materialObj &&
            materialObj.product_type_name !== "Giveaways" &&
            materialObj.product_type_name !== "Literature" && (
              <div>
                <strong className="fw-semibold pe-2 d-inline-block mb-1 text-uppercase">
                  Lot #:{" "}
                </strong>
                <span>
                  {materialObj && materialObj.lot_number
                    ? materialObj.lot_number
                    : "-"}
                </span>
              </div>
            )}
          <div className="my-3">
            <strong className="fw-semibold pe-2 d-inline-block mb-1">
              QUANTITY TO RETURN
            </strong>
            <Form.Control
              type="text"
              id="return_qty"
              name="return_qty"
              style={{ width: "18%" }}
              placeholder="Qty"
              value={values.return_qty}
              onChange={handleInputChange}
            />
            <span className="tc-error">{errors.return_qty || ""}</span>
          </div>
          <div>
            <strong className="fw-semibold pe-2 d-inline-block mb-1">
              REASON
            </strong>
            <Form.Select
              aria-label="Default select example"
              name="reason"
              id="reason"
              defaultValue="Select"
              onChange={(e) => handleInputChange(e, "reason")}
            >
              <option disabled>Select</option>
              {promoMatsReasons?.length === 0 ? (
                <option disabled>No reasons found</option>
              ) : (
                promoMatsReasons?.map((reason, i) => {
                  return (
                    <option key={i} value={reason.id}>
                      {reason.name}
                    </option>
                  );
                })
              )}
            </Form.Select>
            <span className="tc-error">{errors.reason || ""}</span>
          </div>

          <div className="text-center mt-4">
            <Button
              variant="primary"
              className="btn-min"
              onClick={() => saveMatsHandler()}
            >
              SAVE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReturnpromoMats;
