import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { apiendpoints } from "../../helpers/constants";
import { messages } from "../../helpers/messages";

export const reqToGetNotificationCount = createAsyncThunk(
  apiendpoints.getNotificationCount,
  async ({ onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.get(
        apiendpoints.getNotificationCount,
        header || authHeaders()
      );
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      onFailure && onFailure(error);
      return error;
    }
  }
);

export const reqToGetNotificationType = createAsyncThunk(
  apiendpoints.getNotificationType,
  async ({ onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.get(
        apiendpoints.getNotificationType,
        header || authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToGetNotification = createAsyncThunk(
  apiendpoints.getNotifications,
  async ({ data, onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.get(
        `${apiendpoints.getNotifications}?start=${data.start}&limit=${data.limit}&type=${data.type}`,
        header || authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToGetBirthDayData = createAsyncThunk(
  apiendpoints.getBirthDayData,
  async ({ data, onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getBirthDayData,
        data,
        header || authHeaders()
      );
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      onFailure && onFailure(error);
      return error;
    }
  }
);

export const reqToReadNotification = createAsyncThunk(
  apiendpoints.readNotifications,
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.readNotifications,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

const initialState = {
  loader: false,
  error: "",
  apiMessage: "",
  notificationCount: 0,
  notificationType: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    reqToResetNotiApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET NOTIFICATION COUNT
    builder
      .addCase(reqToGetNotificationCount.pending, (state) => {
        state.apiMessage = messages.apiMessage.notificationCount;
      })
      .addCase(reqToGetNotificationCount.fulfilled, (state, action) => {})
      .addCase(reqToGetNotificationCount.rejected, (state, action) => {});

    // GET NOTIFICATION TYPE
    builder
      .addCase(reqToGetNotificationType.pending, (state) => {
        state.apiMessage = messages.apiMessage.notification;
      })
      .addCase(reqToGetNotificationType.fulfilled, (state, action) => {})
      .addCase(reqToGetNotificationType.rejected, (state, action) => {});

    // GET BIRTHDAY LIST
    builder
      .addCase(reqToGetBirthDayData.pending, (state) => {
        state.apiMessage = messages.apiMessage.mdBirthday;
      })
      .addCase(reqToGetBirthDayData.fulfilled, (state, action) => {})
      .addCase(reqToGetBirthDayData.rejected, (state, action) => {});

    // GET NOTIFICATION BY TYPE
    builder
      .addCase(reqToGetNotification.pending, (state) => {})
      .addCase(reqToGetNotification.fulfilled, (state, action) => {})
      .addCase(reqToGetNotification.rejected, (state, action) => {});

    // READ NOTIFICATION
    builder
      .addCase(reqToReadNotification.pending, (state) => {})
      .addCase(reqToReadNotification.fulfilled, (state, action) => {})
      .addCase(reqToReadNotification.rejected, (state, action) => {});
  },
});

export const { reqToResetNotiApiMessage } = notificationSlice.actions;
export default notificationSlice.reducer;
