import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { deleteIcon, fileUploadIcon } from "../../../icon/icon";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components";
import { FileUploadSuccess } from "../../../components/Modals";
import {
  reqToUploadMasterExcel,
  reqToUploadItineraryExcel,
  reqToUploadPromoMatsExcel,
} from "../../../store/slice/fileUploadSlice";

const FileUpload = () => {
  const dispatch = useDispatch();

  // Get reducer data
  const fileUploadReducer = useSelector((state) => state.fileUploadReducer);

  const [files, setFiles] = useState({
    dataFile: {},
    itineraryFile: {},
    promoMatsFile: {},
  });
  const [fileStatus, setFileStatus] = useState("");
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle modal
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const hideModal = () => {
    setModalShow(false);
    setTimeout(() => {
      setModalType("");
      setFileStatus("");
    }, 200);
  };

  // Select file
  const handleFileChange = (e, type) => {
    const file = e?.target?.files[0];
    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();
      if (fileExtension === "xlsx" && type === "DATACONVERSION") {
        setFiles({
          ...files,
          dataFile: { file: file, type: type },
        });
        setErrors("");
      } else if (fileExtension === "xlsx" && type === "ITINERARY") {
        setFiles({
          ...files,
          itineraryFile: { file: file, type: type },
        });
        setErrors("");
      } else if (fileExtension === "xlsx" && type === "PROMOMATS") {
        setFiles({
          ...files,
          promoMatsFile: { file: file, type: type },
        });
        setErrors("");
      } else {
        removeSelectFile(type);
        setErrors("Unsupported file format. Only XLSX files are allowed.");
      }
    }
  };

  // Remove file
  const removeSelectFile = (type) => {
    if (type === "DATACONVERSION") {
      files.dataFile = {};
      document.getElementById("file1").value = "";
    } else if (type === "ITINERARY") {
      files.itineraryFile = {};
      document.getElementById("file2").value = "";
    } else if (type === "PROMOMATS") {
      files.promoMatsFile = {};
      document.getElementById("file3").value = "";
    }
  };

  // Upload file
  const handleFileUpload = () => {
    setModalShow(false);
    if (
      Object.keys(files?.dataFile)?.length > 0 ||
      Object.keys(files?.itineraryFile)?.length > 0 ||
      Object.keys(files?.promoMatsFile)?.length > 0
    ) {
      setErrors("");
      setIsLoading(true);
      const promises = [];
      promises.push(
        new Promise((resolve, reject) => {
          if (files && Object.keys(files?.dataFile)?.length > 0) {
            const formData = new FormData();
            formData.append("file", files.dataFile.file);
            dispatch(
              reqToUploadMasterExcel({
                data: formData,
                onSuccess: (res) => {
                  removeSelectFile("DATACONVERSION");
                  resolve(res);
                },
                onFailure: (err) => {
                  reject(err);
                },
                dispatch,
              })
            );
          } else {
            resolve();
          }
        })
      );
      promises.push(
        new Promise((resolve, reject) => {
          if (files && Object.keys(files?.itineraryFile)?.length > 0) {
            const formData = new FormData();
            formData.append("file", files.itineraryFile.file);
            dispatch(
              reqToUploadItineraryExcel({
                data: formData,
                onSuccess: (res) => {
                  removeSelectFile("ITINERARY");
                  resolve(res);
                },
                onFailure: (err) => {
                  reject(err);
                },
                dispatch,
              })
            );
          } else {
            resolve();
          }
        })
      );
      promises.push(
        new Promise((resolve, reject) => {
          if (files && Object.keys(files?.promoMatsFile)?.length > 0) {
            const formData = new FormData();
            formData.append("file", files.promoMatsFile.file);
            dispatch(
              reqToUploadPromoMatsExcel({
                data: formData,
                onSuccess: (res) => {
                  removeSelectFile("PROMOMATS");
                  resolve(res);
                },
                onFailure: (err) => {
                  reject(err);
                },
                dispatch,
              })
            );
          } else {
            resolve();
          }
        })
      );
      Promise.all(promises)
        .then((res) => {
          setModalType("success");
          setFileStatus(res[0]?.message || res[1]?.message || res[2]?.message);
          setTimeout(() => {
            setIsLoading(false);
            setModalShow(true);
          }, 1000);
        })
        .catch((err) => {
          setModalType("failure");
          setFileStatus(err?.response ? err?.response?.data : err?.message);
          setTimeout(() => {
            setIsLoading(false);
            setModalShow(true);
          }, 1000);
        });
    } else {
      setErrors("Please select a file to upload!");
    }
  };

  return (
    <>
      {((fileUploadReducer && fileUploadReducer.loader) || isLoading) && (
        <Loader />
      )}
      <div className="tc-body">
        <Row className="tc-others-row">
          <Col className="col-xs-12 col-lg-4">
            <p className="tc-navbar-title text-dark text-uppercase">
              Data Conversion
            </p>
            <div className="tc-file-upload-input">
              <input
                type="file"
                name="file1"
                id="file1"
                onChange={(e) => handleFileChange(e, "DATACONVERSION")}
              />
              <label htmlFor="file1" className="tc-file-upload-label">
                <div className="tc-choose-content">
                  {fileUploadIcon}
                  <span className="tc-choose-text">
                    Choose XLXS File to upload
                  </span>
                </div>
              </label>
            </div>
            <div className="tc-selected-file-container p-2">
              {files && Object.keys(files?.dataFile).length > 0 ? (
                <div className="tc-selected-file">
                  <span>{files?.dataFile?.file?.name}</span>
                  <span onClick={() => removeSelectFile("DATACONVERSION")}>
                    {deleteIcon}
                  </span>
                </div>
              ) : null}
            </div>
          </Col>
          <Col className="col-xs-12 col-lg-4">
            <p className="tc-navbar-title text-dark text-uppercase">
              Itinerary Creation
            </p>
            <div className="tc-file-upload-input">
              <input
                type="file"
                name="file2"
                id="file2"
                onChange={(e) => handleFileChange(e, "ITINERARY")}
              />
              <label htmlFor="file2" className="tc-file-upload-label">
                <div className="tc-choose-content">
                  {fileUploadIcon}
                  <span className="tc-choose-text">
                    Choose XLXS File to upload
                  </span>
                </div>
              </label>
            </div>
            <div className="tc-selected-file-container p-2">
              {files && Object.keys(files?.itineraryFile).length > 0 ? (
                <div className="tc-selected-file">
                  <span>{files?.itineraryFile?.file?.name}</span>
                  <span onClick={() => removeSelectFile("ITINERARY")}>
                    {deleteIcon}
                  </span>
                </div>
              ) : null}
            </div>
          </Col>
          <Col className="col-xs-12 col-lg-4">
            <p className="tc-navbar-title text-dark text-uppercase">
              Promo Mat Issuance
            </p>
            <div className="tc-file-upload-input">
              <input
                type="file"
                name="file3"
                id="file3"
                onChange={(e) => handleFileChange(e, "PROMOMATS")}
              />
              <label htmlFor="file3" className="tc-file-upload-label">
                <div className="tc-choose-content">
                  {fileUploadIcon}
                  <span className="tc-choose-text">
                    Choose XLXS File to upload
                  </span>
                </div>
              </label>
            </div>
            <div className="tc-selected-file-container p-2">
              {files && Object.keys(files?.promoMatsFile).length > 0 ? (
                <div className="tc-selected-file">
                  <span>{files?.promoMatsFile?.file?.name}</span>
                  <span onClick={() => removeSelectFile("PROMOMATS")}>
                    {deleteIcon}
                  </span>
                </div>
              ) : null}
            </div>
          </Col>
        </Row>
        <div>
          <span className="tc-error-text mt-2 mb-2 justify-content-start">
            {errors}
          </span>
        </div>
        <div className="mt-4 text-center">
          <Button
            variant="primary"
            type="button"
            className="btn-min"
            onClick={() => handleFileUpload()}
          >
            Upload
          </Button>
        </div>
      </div>
      <FileUploadSuccess
        show={modalShow}
        type={modalType}
        status={fileStatus}
        onHide={() => hideModal()}
        handleFileUpload={handleFileUpload}
      />
    </>
  );
};

export default FileUpload;
