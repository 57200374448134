import React, { useRef, useState } from "react";
import {
  backBtnIcon,
  calenderInputIcon,
  cameraIcon2,
  fileUploadIcon,
} from "../../icon/icon";
import Select from "react-select";
import { Button } from "react-bootstrap";
import { CustomImageCapture } from "../../components";
import { ImageCaptureModal } from "../../components/Modals";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import {
  checkSystemWebcam,
  compressMediaFiles,
} from "../../helpers/commonFunction";
import { isDesktop } from "react-device-detect";

const UpdateItineraryForm = ({
  month,
  reason,
  weekName,
  weekDays,
  frequency,
  selectedMD,
  attachment,
  isBlueColor,
  weekDaysErr,
  createMDsId,
  weekOptions,
  mdListOptions,
  returnBackBtn,
  monthYearValue,
  itineraryMethod,
  frequencyOptions,
  universeMDOptions,
  displayDaysHours,
  handleChangeValues,
  handleItineraryDates,
  handleConfirmationModal,
}) => {
  const inputRef = useRef();
  const [showWebcam, setShowWebcam] = useState(false);
  const handleShowWebcam = () => {
    setShowWebcam(!showWebcam);
  };

  const handleSystemWebcam = () => {
    checkSystemWebcam()
      .then(() => {
        if (isDesktop) {
          handleShowWebcam();
        } else {
          inputRef.current.click();
        }
      })
      .catch(() => {});
  };
  const handleCaptureImage = (event) => {
    if (isDesktop) {
      const blobData = dataURLtoBlob(event);
      handleChangeValues(blobData, "file", "attachment");
      handleShowWebcam();
    } else {
      const file =
        event?.target?.files &&
        event?.target?.files?.length > 0 &&
        event?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          // const blobData = await compressMediaFiles(file);
          const blobData = dataURLtoBlob(reader.result);
          handleChangeValues(blobData, "file", "attachment");
          setShowWebcam(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <>
      <div className="tc-body tc-receive-pro-matr-body-section">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={returnBackBtn}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title">{itineraryMethod.value} ITINERARY</p>
          <div className="tc-navbar-right-btn"></div>
        </div>
        <div className="add-scroll">
          <div className="tc-content-wrapper h-100">
            <div className="tc-calender-input-content  d-flex">
              <span className="text-select-option">For:</span>
              <div className="month-static-disabled">{month.label}</div>
            </div>
            <div className="tc-card-itinerary-content">
              <div className="tc-card-itinerary-container">
                <div className="tc-card-input-container">
                  <div className="tc-card-input-label">
                    MD Code <span>:</span>
                  </div>
                  <div className="tc-card-static-input color-gray">
                    {selectedMD
                      ? selectedMD.code && selectedMD.code !== "0"
                        ? selectedMD.code
                        : createMDsId(selectedMD.id)
                      : "-"}
                  </div>
                </div>
                <div className="tc-card-input-container">
                  <div className="tc-card-input-label">
                    MD Name<span>:</span>
                  </div>
                  <Select
                    name="option"
                    value={selectedMD}
                    onChange={(e) =>
                      handleChangeValues(e, "option", "selectedMD")
                    }
                    options={
                      itineraryMethod.value === "ADD"
                        ? universeMDOptions
                        : mdListOptions
                    }
                    className={`tc-custom-select-2 tc-custom-select-mx mb-2`}
                    classNamePrefix="select"
                    placeholder="Select..."
                  />
                </div>
                <div className="tc-card-input-container">
                  <div className="tc-card-input-label">
                    Specialty<span>:</span>
                  </div>
                  <div className="tc-card-static-input color-gray">
                    {selectedMD && selectedMD.s_name
                      ? selectedMD.s_name
                      : selectedMD?.specialty_name
                      ? selectedMD?.specialty_name
                      : "-"}
                  </div>
                </div>
                <div className="tc-card-input-container">
                  <div className="tc-card-input-label">
                    Hospital<span>:</span>
                  </div>
                  <div className="tc-card-static-input color-gray">
                    {selectedMD && selectedMD.clinic_name
                      ? selectedMD.clinic_name
                      : "-"}
                  </div>
                </div>
                <div className="tc-card-input-container">
                  <div className="tc-card-input-label">
                    Clinic Day(s)/ Hours <span>:</span>
                  </div>
                  <div className="tc-card-static-input color-gray d-flex flex-column">
                    {selectedMD && selectedMD.days_hours
                      ? displayDaysHours(selectedMD.days_hours)?.map(
                          (item, i) => <span key={i}>{item}</span>
                        )
                      : "-"}
                  </div>
                </div>
                <div className="tc-card-input-container">
                  <div className="tc-card-input-label">
                    Class/Frequency<span>:</span>
                  </div>
                  <div className="w-100 mb-2">
                    {itineraryMethod.value === "VIEW" ||
                    itineraryMethod.value === "REMOVE" ||
                    (itineraryMethod.value === "EDIT" && !selectedMD) ? (
                      <>
                        <div
                          className="tc-card-static-input color-green w-50"
                          style={{ width: "100px" }}
                        >
                          {frequency ? frequency.label : "-"}
                        </div>
                      </>
                    ) : (
                      <Select
                        name="option"
                        value={frequency}
                        onChange={(e) =>
                          handleChangeValues(e, "option", "frequency")
                        }
                        options={frequencyOptions}
                        className={`tc-custom-select-2 tc-custom-select-sm `}
                        classNamePrefix="select"
                        placeholder="Select..."
                      />
                    )}
                  </div>
                </div>

                <div className="tc-card-input-container">
                  <div className="tc-card-input-label">
                    Month<span>:</span>
                  </div>
                  <div className="w-100">
                    <div className="tc-card-static-input w-50 color-gray">
                      {monthYearValue ? monthYearValue : "Select Month..."}
                    </div>
                  </div>
                </div>
                <div className="tc-card-input-container mb-2">
                  <div className="tc-card-input-label">
                    Day<span>:</span>
                  </div>
                  <div className="w-100">
                    {itineraryMethod.value === "VIEW" ||
                    itineraryMethod.value === "REMOVE" ||
                    (itineraryMethod.value === "EDIT" && !selectedMD) ||
                    (itineraryMethod.value === "EDIT" &&
                      selectedMD &&
                      !frequency) ? (
                      <div
                        className="tc-card-static-input color-green text-white"
                        style={{ width: "100px" }}
                      >
                        {weekName ? weekName.label : "-"}
                      </div>
                    ) : (
                      <Select
                        name="option"
                        value={weekName}
                        onChange={(e) =>
                          handleChangeValues(e, "option", "weekName")
                        }
                        options={weekOptions}
                        className={`tc-custom-select-2 tc-custom-select-sm `}
                        classNamePrefix="select"
                        placeholder="Day..."
                      />
                    )}
                  </div>
                </div>
                <div className="tc-card-input-container">
                  <div className="tc-card-input-label"></div>
                  <div className="tc-card-input-weekday w-100">
                    {weekDays.map((day, i) => (
                      <div
                        key={i}
                        className={`tc-card-weekday-input ${
                          day.isHoliday && itineraryMethod.value === "VIEW"
                            ? "color-red"
                            : day.isGreen
                            ? "color-green"
                            : "color-pink"
                        } ${
                          isBlueColor &&
                          itineraryMethod.value === "REMOVE" &&
                          "color-blue"
                        } mb-0`}
                        onClick={() => handleItineraryDates(i)}
                      >
                        {day.date} <div>{calenderInputIcon}</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tc-card-input-container">
                  <div className="tc-card-input-label"></div>
                  <span className="tc-error w-100" style={{ fontSize: "16px" }}>
                    {weekDaysErr}
                  </span>
                </div>
              </div>
              {itineraryMethod.value !== "VIEW" &&
                frequency &&
                weekDays.length !== 0 &&
                weekName &&
                selectedMD && (
                  <div className="tc-edit-itinerary-wrap">
                    <div className="tc-edit-itinerary-reason">
                      <span className="fw-semibold">Reason</span>
                      <textarea
                        name="remarks"
                        className="form-control"
                        placeholder="Write reason..."
                        rows={5}
                        value={reason}
                        onChange={(e) =>
                          handleChangeValues(e, "text", "reason")
                        }
                      />
                    </div>
                    <div className="tc-edit-itinerary-attachment mt-3">
                      <span className="fw-semibold d-flex justify-content-between align-content-center">
                        Attachment
                        <span
                          role="button"
                          className="selfi-icon"
                          onClick={() => handleSystemWebcam()}
                        >
                          {cameraIcon2}
                        </span>
                      </span>
                      <div className="w-100 tc-file-upload-input">
                        <input
                          type="file"
                          name="file"
                          id="file"
                          accept="image/*,.xls,.xlsx"
                          onChange={(e) =>
                            handleChangeValues(e, "file", "attachment")
                          }
                        />
                        <label htmlFor="file" className="tc-file-upload-label">
                          {attachment ? (
                            <div
                              className={`tc-selected-file tc-choose-content ${
                                attachment?.type?.includes("image") &&
                                "border-0"
                              }`}
                            >
                              {attachment?.type?.includes("image") ? (
                                <img
                                  src={URL.createObjectURL(attachment)}
                                  height={220}
                                  alt="attachment"
                                />
                              ) : (
                                <span>{attachment?.name}</span>
                              )}
                            </div>
                          ) : (
                            <div className="tc-choose-content">
                              {fileUploadIcon}
                              <span className="tc-choose-text">
                                Choose File to upload
                              </span>
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                    {itineraryMethod.value !== "VIEW" &&
                      frequency &&
                      weekDays.length !== 0 &&
                      weekName &&
                      selectedMD && (
                        <div className="text-center mt-4">
                          <Button
                            className="mt-3"
                            onClick={() => handleConfirmationModal()}
                          >
                            {itineraryMethod.value === "REMOVE" && !isBlueColor
                              ? "REMOVE"
                              : "SUBMIT"}
                          </Button>
                        </div>
                      )}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {isDesktop ? (
        <>
          {showWebcam && (
            <ImageCaptureModal
              show={showWebcam}
              onHide={handleShowWebcam}
              handleCaptureImage={handleCaptureImage}
            />
          )}
        </>
      ) : (
        <CustomImageCapture
          inputRef={inputRef}
          handleFileChange={handleCaptureImage}
        />
      )}
    </>
  );
};

export default UpdateItineraryForm;
