import React, { useEffect, useState } from "react";
import { SearchIcon, backBtnIcon, callIcon } from "../../icon/icon";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { DataNotFound } from "../../components";
import { MonthlyCallDates } from "../../components/Modals";
import { messages } from "../../helpers/messages";
import { routes } from "../../helpers/constants";
import {
  handleVisits,
  nameFormatting,
  getNewMDListByRecordDate,
} from "../../helpers/commonFunction";
import { useLiveQuery } from "dexie-react-hooks";
import { db, getAllEntriesFromDB } from "../../helpers/db";
import { UserDefaultImage } from "../../images";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { getMediaFileFromDB } from "../../helpers/offlineAPIs";

const MonthlyMdList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getMDListFromDB = useLiveQuery(() => db.mdList.toArray(), []);
  const getHolidaysListFromDB = useLiveQuery(() => db.holidays.toArray(), []);
  const userReducer = useSelector((state) => state.userReducer.user);

  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [newMdListData, setNewMdListData] = useState([]);
  const [newMdListData2, setNewMdListData2] = useState([]);
  const [disableMDs, setDisableMDs] = useState({
    md_ids: [],
    disable_all: 0,
  });
  const [selectedMD, setSelectedMD] = useState("");
  const [selectedMDCallDates, setSelectedMDCallDates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (newMdListData && newMdListData?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [newMdListData]);

  useEffect(() => {
    handleSearchMDList();
  }, []);


  // Search MD list by date and firstName, lastName
  const handleSearchMDList = async (searchBy, value) => {
    if (searchBy === "name") {
      const newList =
        newMdListData2 &&
        newMdListData2?.filter(
          (item) =>
            `${item.lastname.trim()} ${item.firstname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            `${item.firstname.trim()} ${item.lastname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase())
        );
      const finalList =
        newList?.length > 0 ? newList : !value ? getMDListFromDB : [];
      setNewMdListData(finalList);
    } else {
      await db
        .table("mdList")
        .toArray()
        .then(async (entries) => {
          const mdIdMap = new Map();
          const updatedMDList = await getNewMDListByRecordDate(entries);
          updatedMDList.forEach((entry) => {
            const {
              id,
              date,
              md_id,
              is_summary,
              is_visited,
              is_week_call,
              is_consecutive,
              ...rest
            } = entry;
            const formattedDate = {
              date,
              is_summary,
              is_visited,
              is_week_call,
              is_consecutive,
              mdData: entry,
            };
            if (mdIdMap.has(md_id)) {
              const existingEntry = mdIdMap.get(md_id);
              if (is_summary === 1) {
                existingEntry.actual_visits++;
              }
              existingEntry.dates.push(formattedDate);
            } else {
              mdIdMap.set(md_id, {
                ...rest,
                id,
                md_id,
                dates: [formattedDate],
                actual_visits: is_summary === 1 ? 1 : 0,
              });
            }
          });
          const uniqueEntries = Array.from(mdIdMap.values());
          setNewMdListData(uniqueEntries);
          setNewMdListData2(uniqueEntries);
        });
      const [disabled_mds] = await Promise.all([
        getAllEntriesFromDB("disabled_mds"),
      ]);
      setDisableMDs(disabled_mds);
    }
  };

  const handleOnChange = (event) => {
    if (event.target.value === "") {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    } else {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    }
  };

  const handleScheduleModal = (data) => {
    setShowModal(true);
    setSelectedMD(data);
    setSelectedMDCallDates(data.dates);
  };

  const handleCallActivitiesOfMD = async (item) => {
    if (item && item?.mdData && item?.mdData?.date && item?.mdData?.md_id) {
      let mdData = item?.mdData;
      let saveData = "";
      let editableData = "";
      let quickSignObj = "";
      if (mdData?.is_save === 1) {
        saveData = mdData?.save_data ? JSON.parse(mdData?.save_data) : "";
        let quickSignSData = saveData ? saveData?.quickSignObj : "";
        if (quickSignSData?.signature_url) {
          delete quickSignSData.signPadData
          const base64 = await getMediaFileFromDB(
            quickSignSData,
            "signature_url",
            "signPadData"
          );
          quickSignObj = { ...quickSignSData, signPadData: base64 };
        } else if (quickSignSData?.signPadData) {
          const blobData = dataURLtoBlob(quickSignSData?.signPadData);
          quickSignObj = { ...quickSignSData, signature_url: blobData };
        } else {
          quickSignObj = quickSignSData;
        }
        editableData = {
          data: saveData,
          save_id: mdData?.save_id,
          s_record_time: new Date(mdData?.save_record_date),
          s_target_date: mdData?.save_target_date,
          s_visited_date: mdData?.save_visited_date,
        };
      }
      navigate(routes.callActivities, {
        state: {
          selectedMD: mdData,
          reqObj: { md_id: mdData.md_id },
          prevPath: location.pathname,
          quickSignObj: quickSignObj,
          selectedDate: mdData.date,
          start_time:
            saveData && saveData?.start_time
              ? new Date(saveData?.start_time)
              : new Date(),
          editableData: editableData ? editableData : "",
        },
      });
    }
  };

  const columns = [
    {
      name: "Class",
      sortable: true,
      selector: (row) => row?.name,
      cell: (row) => (
        <p className={`tc-data-table-class-text text-center w-100`}>
          {row?.name}
        </p>
      ),
    },
    {
      name: "Md Name",
      sortable: true,
      selector: (row) => row?.firstname.concat(" ", row?.lastname),
      cell: (row) => (
        <p
          role="button"
          className={`tc-data-table-md-name-text text-start`}
          onClick={() => handleScheduleModal(row)}
        >
          {nameFormatting(row)}
        </p>
      ),
    },
    {
      name: "Visits",
      sortable: true,
      selector: (row) => handleVisits(row?.actual_visits, row?.target),
      cell: (row) => (
        <p className={`tc-data-table-visits-text tc-data-table-green-text`}>
          {handleVisits(row?.actual_visits, row?.target)}
        </p>
      ),
    },
    {
      name: "SKED",
      sortable: true,
      selector: (row) => row.call,
      cell: (row) => (
        <button
          className="tc-data-table-button tc-data-table-icon"
          onClick={() => handleScheduleModal(row)}
        >
          {callIcon}
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="tc-body">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => navigate(routes.mdList)}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title">ALPHABETICAL LIST OF MDs</p>
          <div className="tc-navbar-right-btn"></div>
        </div>
        <div className="tc-profile-wrapper">
          <img src={UserDefaultImage} alt="profile" />
          <span className="tc-profile-name">
            {userReducer && nameFormatting(userReducer, "LF")}
          </span>
        </div>
        <div className="add-scroll">
          <div className="tc-content-wrapper">
            <h2>ALPHABETICAL LIST OF MDs</h2>
            <div className="tc-calender-search-input-row">
              <div className="tc-search-input-content d-flex">
                <input
                  id="text"
                  type="text"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => handleOnChange(e)}
                />
                <button
                  type="button"
                  className="tc-search-input-btn"
                  onClick={() => handleSearchMDList("name", searchValue)}
                  disabled={searchValue === "" && true}
                >
                  {SearchIcon}
                </button>
              </div>
            </div>
            <h6 className="d-flex justify-content-end">
              Total MDs:{" "}
              {newMdListData && newMdListData?.length > 0
                ? newMdListData?.length
                : 0}
            </h6>
            {isLoading ? null : newMdListData && newMdListData?.length === 0 ? (
              <DataNotFound name={messages.toast.nodateData} />
            ) : (
              <DataTable
                columns={columns}
                data={newMdListData && newMdListData}
                className={`tc-data-table flex-grow-1 overflow-y-auto ${newMdListData &&
                  newMdListData?.length === 0 &&
                  "d-flex align-items-center"
                  }`}
              />
            )}
          </div>
        </div>
      </div>
      <MonthlyCallDates
        show={showModal}
        onHide={() => setShowModal(false)}
        selectedMD={selectedMD}
        disableMDs={disableMDs}
        holidays={getHolidaysListFromDB}
        selectedMDCallDates={selectedMDCallDates}
        handleCallActivitiesOfMD={handleCallActivitiesOfMD}
      />
    </>
  );
};

export default MonthlyMdList;
