import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import Itineraries from "./Itineraries";
import MissedCalls from "./MissedCalls";
import { Loader } from "../../components";

const DmApproval = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currReqType, setCurrReqType] = useState("ITINERARY");
  useEffect(() => {
    localStorage.setItem("approval_type", currReqType);
  }, [currReqType]);

  const handleApprovalType = (type) => {
    if (type !== currReqType) {
      setIsLoading(true);
      setCurrReqType(type);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  return (
    <>
      {/* {isLoading && <Loader/>} */}
      <div className="tc-body tc-itinerary-body-section">
        <Tab.Container id="tc-itinerary" defaultActiveKey="itinerary">
          <div className="tc-calender-search-input-row tc-tabs-btn">
            <div className="tc-calender-search-input-row ms-auto mb-0">
              <Nav variant="pills" className="gap-2">
                <Nav.Item>
                  <Nav.Link
                    eventKey="itinerary"
                    active={currReqType === "ITINERARY"}
                    onClick={() => handleApprovalType("ITINERARY")}
                  >
                    ITINERARY
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="missedCalls"
                    active={currReqType === "MISSEDCALLS"}
                    onClick={() => handleApprovalType("MISSEDCALLS")}
                  >
                    MISSED CALLS
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <Tab.Content>
            <Tab.Pane eventKey="itinerary">
              <Itineraries />
            </Tab.Pane>
            <Tab.Pane eventKey="missedCalls">
              <MissedCalls />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
};

export default DmApproval;
