import { messages } from "./messages";

export const formValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors
) => {
  if ("username" in fieldValues) {
    temp.username = fieldValues.username ? "" : messages.errors.user_req;
  }
  if ("password" in fieldValues) {
    temp.password = fieldValues.password ? "" : messages.errors.pass_req;
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const mdExcuseValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors
) => {
  if ("reason" in fieldValues) {
    temp.reason = fieldValues.reason ? "" : messages.errors.reason_err;
  }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const returnPromoMatsValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors,
  isSampleLotPresent
) => {
  if ("return_qty" in fieldValues) {
    temp.return_qty =
      fieldValues.return_qty > 0
        ? values.quantity < fieldValues.return_qty
          ? `Return quantity cannot be greater than ${values.quantity}!`
          : ""
        : messages.errors.quantity_err;
  }
  if ("reason" in fieldValues) {
    temp.reason = fieldValues.reason ? "" : messages.errors.reason_err;
  }

  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const adjustPromoMatsValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors
) => {
  if ("received_qty" in fieldValues) {
    temp.received_qty =
      fieldValues.received_qty > 0
        ? values.issued_qty < fieldValues.received_qty
          ? `Acutal quantity cannot be greater than ${values.issued_qty}!`
          : ""
        : messages.errors.quantity_err;
  }
  if ("reason" in fieldValues) {
    temp.reason = fieldValues.reason ? "" : messages.errors.reason_err;
  }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const outOfFieldValidations = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors
) => {
  if ("notes_id" in fieldValues) {
    temp.notes_id = fieldValues.notes_id ? "" : "Please select a Reason!";
  }
  if ("remark" in fieldValues) {
    temp.remark = fieldValues.remark ? "" : "Please enter remark!";
  }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const relieverModalValidation = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors,
  match
) => {
  if (match === "oldMD" && "oldMD" in fieldValues) {
    temp.oldMD = fieldValues.oldMD ? "" : "Please Select Reliever!";
  }
  if (match === "newMD" && "firstName" in fieldValues) {
    temp.firstName = fieldValues.firstName ? "" : "Please Enter MD Firstname!";
  }
  if (match === "newMD" && "lastName" in fieldValues) {
    temp.lastName = fieldValues.lastName ? "" : "Please Enter MD Lastname!";
  }
  setErrors({
    ...temp,
    oldMD: match === "newMD" ? "" : temp.oldMD,
    firstName: match === "oldMD" ? "" : temp.firstName,
    lastName: match === "oldMD" ? "" : temp.lastName,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};

export const newMDInfoValidation = (
  fieldValues,
  temp,
  values,
  setValues,
  setErrors,
  type
) => {
  if (type === "INSERT") {
    if ("firstname" in fieldValues) {
      temp.firstname = fieldValues.firstname ? "" : "Please enter firstname!";
    }
    if ("lastname" in fieldValues) {
      temp.lastname = fieldValues.lastname ? "" : "Please enter lastname!";
    }
  } else {
    if ("selectedMD" in fieldValues) {
      temp.selectedMD = fieldValues.selectedMD ? "" : "Please Select MD!";
    }
  }
  if (type !== "DELETE") {
    if ("specialty_id" in fieldValues) {
      temp.specialty_id = fieldValues.specialty_id
        ? ""
        : "Please select specialty!";
    }
    if ("clinic" in fieldValues) {
      temp.clinic = fieldValues.clinic ? "" : "Please select institution!";
    }
    if ("frequency_id" in fieldValues) {
      temp.frequency_id = fieldValues.frequency_id
        ? ""
        : "Please select frequency!";
    }
  }
  setErrors({
    ...temp,
  });
  if (fieldValues === values) return Object.values(temp).every((x) => x === "");
};
