import React from "react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { Col, Row, Table } from "react-bootstrap";
import { backBtnIcon } from "../../icon/icon";
import { defaultDateFormat } from "../../helpers/commonFunction";
import { CustomDateInput } from "../../components";

const Summary = (props) => {
  const {
    minDate,
    maxDate,
    isToggle,
    monthInfo,
    tableHeader,
    selectedDate,
    calenderData,
    returnBackBtn,
    summaryReports,
    summaryReports2,
    itineraryMethod,
    itinerarySummaryData,
    getSummaryReportData,
    toggleChangeHandler,
  } = props;

  let total = 0;
  let totalMDs = 0;
  let totalMDs2 = 0;
  let totalCalls = 0;
  let totalCalls2 = 0;
  let totalMon = 0;
  let totalTue = 0;
  let totalWed = 0;
  let totalThu = 0;
  let totalFri = 0;

  const calculateTotals = (data, type) => {
    let total1 = itinerarySummaryData?.length;
    let total2 = itinerarySummaryData?.length;
    data &&
      data?.length > 0 &&
      data.forEach((item, i) => {
        if (i === 0) {
          total1 = itinerarySummaryData?.length;
          total2 = itinerarySummaryData?.length;
        }
        if (i === 1) {
          total1 = total1 + item.count;
          total2 = total2 + item.count;
        }
        if (i === 2) {
          total1 = total1 - item.count;
          total2 = total2 - item.count;
        }
        if (!isToggle && i === 3) {
          total1 = total1 + item.count;
          total2 = total2 + item.count;
        }
        if (i === 6) {
          total2 = total2 + item.count;
        }
        if (i === 7) {
          total2 = total2 - item.count;
        }
        if (!isToggle && i === 8) {
          total2 = total2 + item.count;
        }
      });
    if (type === "Approved") {
      return total2;
    } else {
      return total1;
    }
  };

  const isClickable = (dataList) => {
    if (dataList && dataList?.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="tc-body tc-receive-pro-matr-body-section">
      <div className="tc-navbar-back-text">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => returnBackBtn()}
        >
          {backBtnIcon}
        </button>
        <p className="tc-navbar-title">
          {itineraryMethod.type === "WSUMMARY"
            ? itineraryMethod.value + " (Weekly Totals)"
            : itineraryMethod.value}
        </p>
        <div className="tc-navbar-right-btn"></div>
      </div>
      <div className="add-scroll itinerary-inner-pages">
        <div className="tc-content-wrapper h-100">
          <Row className="h-100">
            {itineraryMethod.type === "PERWPERD" && (
              <Col md={6}>
                <h5
                  className="mb-4 text-uppercase"
                  style={{ lineHeight: "25px" }}
                >
                  {monthInfo?.nextMName?.concat(" ", monthInfo?.nextMYear)}
                </h5>
                {calenderData && calenderData?.length > 0 && (
                  <div className="tc-table-wrapper my-2 calender">
                    <Table responsive className="tc-table">
                      <thead>
                        <tr>
                          {calenderData &&
                            calenderData[0]?.map((item, i) => {
                              return (
                                <th key={i}>
                                  {item?.day ? item?.day?.slice(0, 3) : ""}
                                </th>
                              );
                            })}
                        </tr>
                      </thead>
                      <tbody>
                        {calenderData?.map((item) => {
                          return (
                            <tr>
                              {item?.map((day) => {
                                return (
                                  <td
                                    className={`${
                                      !day?.is_month && "text-success"
                                    }`}
                                  >
                                    {day.date || ""}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Col>
            )}

            {/* MAIN TABLE */}
            {(itineraryMethod.type === "SUMMARY" ||
              itineraryMethod?.type === "WSUMMARY") && (
              <Col md={12}>
                <div className="mb-2 tc-calender-search-input-row justify-content-between">
                  <div>
                    {itineraryMethod.type === "WSUMMARY" && (
                      <>
                        <h6>DATE</h6>
                        <div className="tc-calender-input-content calendar-icon-md">
                          <div className="position-relative">
                            <ReactDatePicker
                              selected={moment(selectedDate)._d}
                              onSelect={(e) =>
                                getSummaryReportData({
                                  ...itineraryMethod,
                                  date: moment(e).format("YYYY-MM-DD"),
                                })
                              }
                              id="date-picker-md"
                              dateFormat="yyyy-MM-dd"
                              value={defaultDateFormat(selectedDate)}
                              minDate={minDate}
                              maxDate={maxDate}
                              onKeyDown={(e) => e.preventDefault()}
                              customInput={<CustomDateInput />}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-between mt-3 gap-2">
                    <h4>
                      {monthInfo?.currMName?.concat(" ", monthInfo?.currMYear)}:
                    </h4>
                    <h4
                      className={`${
                        isClickable(itinerarySummaryData) &&
                        "text-decoration-underline"
                      }`}
                      role="button"
                      onClick={() =>
                        isClickable(itinerarySummaryData) &&
                        getSummaryReportData({
                          type: "SUMMARY",
                          name: "Itinerary",
                          value: "Itinerary",
                        })
                      }
                    >
                      {isClickable(itinerarySummaryData)
                        ? itinerarySummaryData?.length
                        : 0}
                    </h4>
                  </div>
                  <div className="d-flex align-items-center">
                    <h6 className="mb-0 pe-2">
                      {isToggle ? "Total MDs" : "Total Calls"}
                    </h6>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="toggle-btn"
                        value={isToggle}
                        checked={isToggle ? true : false}
                        onChange={() => toggleChangeHandler()}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            )}
            {/* {itineraryMethod.type === "SUMMARY" && (
              <Col md={12}>
                <div className="d-flex justify-content-between mt-3">
                  <h4>
                    {monthInfo?.currMName?.concat(" ", monthInfo?.currMYear)}:
                  </h4>
                  <h4
                    className="text-decoration-underline"
                    role="button"
                    onClick={() =>
                      getSummaryReportData({
                        type: "SUMMARY",
                        name: "Itinerary",
                        value: "Itinerary",
                      })
                    }
                  >
                    {itinerarySummaryData && itinerarySummaryData?.length > 0
                      ? itinerarySummaryData?.length
                      : 0}
                  </h4>
                </div>
              </Col>
            )} */}

            {itineraryMethod?.type === "MDCLASS" && (
              <Col md={12}>
                <div className="d-flex align-items-center mt-2 justify-content-end">
                  <h6 className="mb-0 pe-2">
                    {isToggle
                      ? "If all For Approvals are approved"
                      : "If all For Approvals are not approved"}
                  </h6>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="toggle-btn"
                      value={isToggle}
                      checked={isToggle ? true : false}
                      onChange={() => toggleChangeHandler()}
                    />
                  </div>
                </div>
              </Col>
            )}

            {itineraryMethod.type !== "SUMMARY" && (
              <Col md={6}>
                {itineraryMethod.type === "MDCLASS" && (
                  <h5 className="mb-0 text-uppercase">
                    {monthInfo?.currMName?.concat(" ", monthInfo?.currMYear)}
                  </h5>
                )}
                {itineraryMethod.type === "PERWPERD" && (
                  <div className="d-flex align-items-center justify-content-end mb-4">
                    <h5 className="mb-0 pe-2">
                      Total Number of Calls
                      {/* {isToggle
                        ? "Total Number of Calls"
                        : "Total Number of MDs"} */}
                    </h5>
                    {/* <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="toggle-btn"
                        value={isToggle}
                        checked={isToggle ? true : false}
                        onChange={() => toggleChangeHandler()}
                      />
                    </div> */}
                  </div>
                )}
                <div
                  className={`tc-table-wrapper ${
                    itineraryMethod.type === "SUMMARY" ||
                    itineraryMethod.type === "MDCLASS" ||
                    itineraryMethod.type === "PERWPERD"
                      ? "mt-2"
                      : "mt-5"
                  }`}
                >
                  <Table
                    responsive
                    className="tc-table  dm-class celander-table"
                  >
                    <thead>
                      <tr>
                        {tableHeader(itineraryMethod.type).map((item, i) => (
                          <th key={i} className="fs-6">
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {summaryReports &&
                        summaryReports.map((item, i) => {
                          if (itineraryMethod.type === "WSUMMARY") {
                            total = total + item.count;
                            return (
                              <tr key={i}>
                                <td
                                  className={`${
                                    item.count > 0 &&
                                    "text-decoration-underline"
                                  }`}
                                  role={`${item.count > 0 ? "button" : ""}`}
                                  onClick={() =>
                                    item.count > 0 && getSummaryReportData(item)
                                  }
                                >
                                  {item.name}
                                </td>
                                <td>{item.count}</td>
                              </tr>
                            );
                          } else if (itineraryMethod.type === "MDCLASS") {
                            totalCalls = totalCalls + item?.target_call;
                            totalMDs = totalMDs + item?.target_md;
                            return (
                              <tr key={i}>
                                <td
                                  className={`${"text-decoration-underline"}`}
                                  role="button"
                                  onClick={() =>
                                    getSummaryReportData(item, "approved")
                                  }
                                >
                                  {item?.class}
                                </td>
                                <td>{item?.target_call}</td>
                                <td>{item?.target_md}</td>
                              </tr>
                            );
                          } else if (itineraryMethod.type === "PERWPERD") {
                            totalMon = totalMon + item?.mon;
                            totalTue = totalTue + item?.tue;
                            totalWed = totalWed + item?.wed;
                            totalThu = totalThu + item?.thu;
                            totalFri = totalFri + item?.fri;
                            total =
                              totalMon +
                              totalTue +
                              totalWed +
                              totalThu +
                              totalFri;
                            return (
                              <tr key={i}>
                                <td>W{item?.week}</td>
                                <td>{item?.mon}</td>
                                <td>{item?.tue}</td>
                                <td>{item?.wed}</td>
                                <td>{item?.thu}</td>
                                <td>{item?.fri}</td>
                                <td>{isToggle ? item?.total : item?.wTotal}</td>
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                    <tfoot>
                      {itineraryMethod.type === "WSUMMARY" ? (
                        <tr>
                          <td className="fs-4 text text-center">Totals:</td>
                          <td className="fs-4 text text-center">
                            {total ? total : 0}
                          </td>
                        </tr>
                      ) : itineraryMethod.type === "MDCLASS" ? (
                        <tr>
                          <td className="fs-5 text text-center">Total</td>
                          <td className="fs-5 text text-center">
                            {totalCalls ? totalCalls : 0}
                          </td>
                          <td className="fs-5 text text-center">
                            {totalMDs ? totalMDs : 0}
                          </td>
                        </tr>
                      ) : itineraryMethod.type === "PERWPERD" ? (
                        <tr>
                          <td className="fs-5 text text-center">Total</td>
                          <td className="fs-5 text text-center">
                            {totalMon ? totalMon : 0}
                          </td>
                          <td className="fs-5 text text-center">
                            {totalTue ? totalTue : 0}
                          </td>
                          <td className="fs-5 text text-center">
                            {totalWed ? totalWed : 0}
                          </td>
                          <td className="fs-5 text text-center">
                            {totalThu ? totalThu : 0}
                          </td>
                          <td className="fs-5 text text-center">
                            {totalFri ? totalFri : 0}
                          </td>
                          <td className="fs-5 text text-center">
                            {total ? total : 0}
                          </td>
                        </tr>
                      ) : null}
                    </tfoot>
                  </Table>
                </div>
              </Col>
            )}

            {/* OTHER TABLE */}
            {itineraryMethod.type === "MDCLASS" ? (
              <Col md={6}>
                <div className="d-flex align-items-center mb-2">
                  <h5 className="mb-0 text-uppercase">
                    {monthInfo?.nextMName?.concat(" ", monthInfo?.nextMYear)}
                  </h5>
                </div>
                <div className="tc-table-wrapper">
                  <Table
                    responsive
                    className="tc-table  dm-class celander-table"
                  >
                    <thead>
                      <tr>
                        {tableHeader(itineraryMethod.type).map((item, i) => (
                          <th key={i} className="fs-6">
                            {item}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {summaryReports2 &&
                        summaryReports2.map((item, i) => {
                          totalCalls2 = totalCalls2 + item?.target_call;
                          totalMDs2 = totalMDs2 + item?.target_md;
                          return (
                            <tr key={i}>
                              <td
                                className={`${"text-decoration-underline"}`}
                                role="button"
                                onClick={() =>
                                  getSummaryReportData(item, "approval")
                                }
                              >
                                {item?.class}
                              </td>
                              <td>{item?.target_call}</td>
                              <td>{item?.target_md}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td className="fs-5 text text-center">Total</td>
                        <td className="fs-5 text text-center">
                          {totalCalls2 ? totalCalls2 : 0}
                        </td>
                        <td className="fs-5 text text-center">
                          {totalMDs2 ? totalMDs2 : 0}
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </Col>
            ) : null}

            {itineraryMethod?.type === "SUMMARY" && (
              <>
                <Col md={6} style={{ borderRight: "1px solid #ccc" }}>
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="w-100">
                      <h5 className="text-center">Already Approved:</h5>
                      <div className="tc-table-wrapper">
                        <Table responsive className="tc-table  dm-class">
                          <thead>
                            <tr>
                              <th className="text-start fs-5">
                                Updates Itinerary
                              </th>
                              <th>#</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryReports.map((item, i) => {
                              if (i > 0 && i < 4) {
                                return (
                                  <tr key={i}>
                                    <td
                                      className={`text-start ${
                                        (item?.count > 0 || item?.is_call) &&
                                        "text-decoration-underline"
                                      }`}
                                      role={`${
                                        item?.count > 0 || item?.is_call
                                          ? "button"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        (item?.count > 0 || item?.is_call) &&
                                        getSummaryReportData(item)
                                      }
                                    >
                                      {item?.name}
                                    </td>
                                    <td>{item?.count}</td>
                                  </tr>
                                );
                              } else if (i === 4) {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="text-start fs-5 text-green "
                                      role=""
                                    >
                                      {monthInfo?.currMName?.concat(
                                        " ",
                                        monthInfo?.currMYear
                                      )}{" "}
                                      Totals
                                    </td>
                                    <td className="fs-5 text-green ">
                                      {calculateTotals(
                                        summaryReports,
                                        "Approval"
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex justify-content-center h-100">
                    <div className="w-100">
                      <h5 className="text-center">For Approval:</h5>
                      <div className="tc-table-wrapper">
                        <Table responsive className="tc-table  dm-class">
                          <thead>
                            <tr>
                              <th className="text-start fs-5">
                                Updates Itinerary
                              </th>
                              <th>#</th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryReports.map((item, i) => {
                              if (i > 5 && i < 9) {
                                return (
                                  <tr key={i}>
                                    <td
                                      className={`text-start ${
                                        (item?.count > 0 || item?.is_call) &&
                                        "text-decoration-underline"
                                      }`}
                                      role={`${
                                        item?.count === 0 || item?.is_call
                                          ? "button"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        (item?.count > 0 || item?.is_call) &&
                                        getSummaryReportData(item)
                                      }
                                    >
                                      {item?.name}
                                    </td>
                                    <td>{item?.count}</td>
                                  </tr>
                                );
                              } else if (i === 9) {
                                return (
                                  <tr key={i}>
                                    <td
                                      className="text-start fs-5 text-red "
                                      role=""
                                    >
                                      Total if all are Approved
                                    </td>
                                    <td className="fs-5 text-red ">
                                      {calculateTotals(
                                        summaryReports,
                                        "Approved"
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Summary;
