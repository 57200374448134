import React, { forwardRef } from "react";
import { dateIcon } from "../icon/icon";

const CustomDateInput = forwardRef((props, ref) => {
  const { icon, className, value, onChange, onClick } = props;
  return (
    <div className={`tc-custom-date-picker-input ${className}`}>
      <input
        ref={ref}
        readOnly
        type="text"
        value={value}
        onClick={onClick}
        onChange={onChange}
      />
      <span className="date-icon ms-0" onClick={onClick}>
        {icon ? icon : dateIcon}
      </span>
    </div>
  );
});

export default CustomDateInput;
