import React from "react";
import { nameFormatting } from "../helpers/commonFunction";
import { useSelector } from "react-redux";

const TimeInOutTMDetails = () => {
  const userDetails = useSelector((state) => state.userReducer.user);
  return (
    <div>
      <tr className="mb-1 fw-bold">
        <td className="tc-activitie-md-detail-label">TM Name </td>
        <td className="px-2">
          <b>:</b>
        </td>
        <td className="text-primary">
          {userDetails ? nameFormatting(userDetails, "LF") : "-"}
        </td>
      </tr>
      <tr className="mb-1 fw-bold">
        <td className="tc-activitie-md-detail-label">Territory</td>
        <td className="px-2">
          <b>:</b>
        </td>
        <td>
          {userDetails && userDetails?.territory_name
            ? userDetails?.territory_name
            : "-"}
        </td>
      </tr>
      <tr className="mb-1 fw-bold">
        <td className="tc-activitie-md-detail-label">Territory Code</td>
        <td className="px-2">
          <b>:</b>
        </td>
        <td>
          {userDetails && userDetails?.terr_code ? userDetails?.terr_code : "-"}
        </td>
      </tr>
      <tr className="mb-1 fw-bold">
        <td className="tc-activitie-md-detail-label">Team</td>
        <td className="px-2">
          <b>:</b>
        </td>
        <td>
          {userDetails && userDetails?.team_name ? userDetails?.team_name : "-"}
        </td>
      </tr>
    </div>
  );
};

export default TimeInOutTMDetails;
