import React from "react";
import { Outlet } from "react-router-dom";

const BrowserLayout = () => {
  return (
    <main className="tc-main-section">
      <div className="tc-container">
        <Outlet />
      </div>
    </main>
  );
};

export default BrowserLayout;
