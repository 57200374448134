import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout } from "./userSlice";

// Request to get dashboard data
export const reqToGetDashboardData = createAsyncThunk(
  "reqToGetDashboardData",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.dashboard,
        data.data,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Request to get MD excuse
export const reqToGetMDExcuse = createAsyncThunk(
  "reqToGetMDExcuse",
  async ({ onSuccess }) => {
    try {
      const response = await Axios.get(apiendpoints.getMDExcuse, authHeaders());
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get mdCoverageToday data
export const mdCoverageToday = createAsyncThunk(
  "mdCoverageToday",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.mdCoverageToday,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get sample issuance data
export const samplesIssuance = createAsyncThunk(
  "samplesIssuance",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.samplesIssuance,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get sample issuance data
export const promoMatsIssuance = createAsyncThunk(
  "promoMatsIssuance",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.promoMatsIssuance,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get call Concentration data
export const callConcentration = createAsyncThunk(
  "callConcentration",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.callConcentration,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
// Request to get days-field data
export const reqToGetDaysFieldReport = createAsyncThunk(
  "reqToGetDaysFieldReport",
  async ({ data, onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.post(
        apiendpoints.daysField,
        data,
        header || authHeaders()
      );
      onSuccess && onSuccess(response);
      return response;
    } catch (error) {
      onFailure && onFailure(error);
      return error;
    }
  }
);

// Request to get mdExcuse data
export const mdExcuse = createAsyncThunk("mdExcuse", async (data) => {
  try {
    const response = await Axios.post(
      apiendpoints.mdExcuse,
      data,
      authHeaders()
    );
    return response;
  } catch (error) {
    return error;
  }
});

// Request to get call summary data
export const callSummaryData = createAsyncThunk(
  "callSummaryData",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.callSummaryData,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get promo mats inventory
export const reqToPromoMatsInventory = createAsyncThunk(
  "reqToPromoMatsInventory",
  async ({ data, onSuccess, header }) => {
    try {
      const response = await Axios.post(
        apiendpoints.promoMatsInventory,
        data,
        header || authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get incidental calls
export const reqToIncidentalCalls = createAsyncThunk(
  "reqToIncidentalCalls",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.incidentalCalls,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get daily call rate
export const reqToDailyCallRate = createAsyncThunk(
  "reqToDailyCallRate",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.dailyCallRate,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get daily md reach
export const reqToDailyMdReach = createAsyncThunk(
  "reqToDailyMdReach",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.dailyMdReach,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get monthly call rate
export const reqToMonthlyCallRate = createAsyncThunk(
  "reqToMonthlyCallRate",
  async ({ data, onSuccess, header }) => {
    try {
      const response = await Axios.post(
        apiendpoints.monthlyCallRate,
        data,
        header || authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get monthly md reach
export const reqToMonthlyMdReach = createAsyncThunk(
  "reqToMonthlyMdReach",
  async ({ data, onSuccess, header }) => {
    try {
      const response = await Axios.post(
        apiendpoints.monthlyMdReach,
        data,
        header || authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  dashboardData: [],
  mdExcuseList: [],
  callConcentrationData: [],
  daysFieldData: [],
  mdExcuseData: [],
  incidentalCalls: [],
  incidentalCallsChart: [],
  callSummaryData: [],
  promoMatsInventoryData: [],
  mdCoverageData: [],
  mdCoverageChartData: [],
  samplesIssuanceData: [],
  promoMatsIssuanceData: [],
  dailyCallRateData: [],
  dailyMdReachData: [],
  monthlyCallRateData: [],
  monthlyMdReachData: [],
  monthlyTotalCalls: [],
  monthlyTotalMDs: [],
  notSubmittedSavedCalls: [],
  notSignedJointCalls: [],
  vmcRequestsData: [],
  notYetCalledMissedCalls: [],
};

// Create dashboard slice
const dashboardSlice = createSlice({
  name: "mdList",
  initialState,
  reducers: {
    reqToGetCallSummaryData: (state, action) => {
      state.callSummaryData = action?.payload;
    },
    reqToGetCallConcentrationData: (state, action) => {
      state.callConcentrationData = action?.payload;
    },
    reqToGetPromoMatsInventoryData: (state, action) => {
      state.promoMatsInventoryData = action?.payload;
    },
    reqToGetMDCoverageData: (state, action) => {
      state.mdCoverageData = action?.payload?.list;
      state.mdCoverageChartData = action?.payload?.chart;
    },
    reqToGetSamplesIssuanceData: (state, action) => {
      state.samplesIssuanceData = action?.payload;
    },
    reqToGetPromoMatsIssuanceData: (state, action) => {
      state.promoMatsIssuanceData = action?.payload;
    },
    reqToGetIncidentalCalls: (state, action) => {
      state.incidentalCalls = action?.payload?.list;
      state.incidentalCallsChart = action?.payload?.chart;
    },
    reqToGetDailyCallRateData: (state, action) => {
      state.dailyCallRateData = action?.payload;
    },
    reqToGetDailyMdReachData: (state, action) => {
      state.dailyMdReachData = action?.payload;
    },
    reqToGetMonthlyCallRateData: (state, action) => {
      state.monthlyCallRateData = action?.payload;
    },
    reqToGetMonthlyMdReachData: (state, action) => {
      state.monthlyMdReachData = action?.payload;
    },
    reqToGetMonthlyTotalCalls: (state, action) => {
      state.monthlyTotalCalls = action?.payload;
    },
    reqToGetMonthlyTotalMDs: (state, action) => {
      state.monthlyTotalMDs = action?.payload;
    },
    reqToUpdateDaysFieldReport: (state, action) => {
      state.daysFieldData = action?.payload;
    },
    reqToGetNotSubmittedSavedCalls: (state, action) => {
      state.notSubmittedSavedCalls = action.payload;
    },
    reqToGetNotSignedJointCalls: (state, action) => {
      state.notSignedJointCalls = action.payload;
    },
    reqToGetVMCRequestsData: (state, action) => {
      state.vmcRequestsData = action.payload;
    },
    reqToGetNotYetCalledMissedCalls: (state, action) => {
      state.notYetCalledMissedCalls = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get dashboard Data
    builder.addCase(reqToGetDashboardData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDashboardData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dashboardData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDashboardData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get MD excuse
    builder.addCase(reqToGetMDExcuse.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMDExcuse.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.mdExcuseList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMDExcuse.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get dashboard Data
    builder.addCase(mdCoverageToday.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(mdCoverageToday.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.mdCoverageData = action?.payload?.data?.list;
        state.mdCoverageChartData = action?.payload?.data?.chart;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(mdCoverageToday.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get samples Issuance Data
    builder.addCase(samplesIssuance.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(samplesIssuance.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.samplesIssuanceData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(samplesIssuance.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get promoMats Issuance Data
    builder.addCase(promoMatsIssuance.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(promoMatsIssuance.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.promoMatsIssuanceData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(promoMatsIssuance.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Get callConcentration  Data
    builder.addCase(callConcentration.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(callConcentration.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.callConcentrationData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(callConcentration.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // GET DAYSFIELD REPORT
    builder.addCase(reqToGetDaysFieldReport.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDaysFieldReport.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.daysFieldData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDaysFieldReport.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get mdExcuseData  Data
    builder.addCase(mdExcuse.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(mdExcuse.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.mdExcuseData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(mdExcuse.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // Get mdExcuseData  Data
    builder.addCase(callSummaryData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(callSummaryData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.callSummaryData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(callSummaryData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get promoMatsInventory data
    builder.addCase(reqToPromoMatsInventory.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToPromoMatsInventory.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.promoMatsInventoryData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToPromoMatsInventory.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get incidentalCalls data
    builder.addCase(reqToIncidentalCalls.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToIncidentalCalls.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.incidentalCalls = action?.payload?.data?.list;
        state.incidentalCallsChart = action?.payload?.data?.chart;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToIncidentalCalls.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get dailyCallRate data
    builder.addCase(reqToDailyCallRate.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToDailyCallRate.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyCallRateData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToDailyCallRate.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get dailyMdReach data
    builder.addCase(reqToDailyMdReach.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToDailyMdReach.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dailyMdReachData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToDailyMdReach.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get monthlyCallRate data
    builder.addCase(reqToMonthlyCallRate.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToMonthlyCallRate.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyCallRateData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToMonthlyCallRate.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get monthlyMdReach data
    builder.addCase(reqToMonthlyMdReach.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToMonthlyMdReach.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.monthlyMdReachData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToMonthlyMdReach.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export const {
  reqToGetCallSummaryData,
  reqToGetCallConcentrationData,
  reqToGetPromoMatsInventoryData,
  reqToGetMDCoverageData,
  reqToGetSamplesIssuanceData,
  reqToGetPromoMatsIssuanceData,
  reqToGetIncidentalCalls,
  reqToGetDailyCallRateData,
  reqToGetDailyMdReachData,
  reqToGetMonthlyCallRateData,
  reqToGetMonthlyMdReachData,
  reqToGetMonthlyTotalCalls,
  reqToGetMonthlyTotalMDs,
  reqToUpdateDaysFieldReport,
  reqToGetNotSubmittedSavedCalls,
  reqToGetNotSignedJointCalls,
  reqToGetVMCRequestsData,
  reqToGetNotYetCalledMissedCalls,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
