import React from "react";
import Webcam from "react-webcam";

const CustomWebCam = (props) => {
  const { zoom, setZoom, webcamRef } = props;

  const videoConstraints = {
    width: 800,
    height: 800,
    facingMode: "user",
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Webcam
          audio={false}
          ref={webcamRef}
          className={`tc-selfie-sign-modal-click-photo ${
            zoom === 1.5 ? "zoom-in" : "zoom-out"
          }`}
          screenshotFormat="image/png"
          videoConstraints={videoConstraints}
        />
      </div>
      {zoom && (
        <div className="tc-webcam-zoominout-wrap">
          <button
            className="tc-webcam-zoominout-btn mx-1"
            onClick={() => setZoom(1.5)}
          >
            1x
          </button>
          <button
            className="tc-webcam-zoominout-btn mx-1"
            onClick={() => setZoom(1)}
          >
            0.5x
          </button>
        </div>
      )}
    </>
  );
};

export default CustomWebCam;
