import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
  const { apiMessage: offline, commonMessage } = useSelector(
    (state) => state.offlineReducer
  );
  const { apiMessage: timeInOut } = useSelector(
    (state) => state.timeInOutReducer
  );
  const { apiMessage: mdList } = useSelector((state) => state.mdListReducer);
  const { apiMessage: notification } = useSelector(
    (state) => state.notificationReducer
  );
  const { apiMessage: outOfField } = useSelector(
    (state) => state.outOfFieldReducer
  );
  return (
    <div className="tc-custom-loader">
      <div className="loader"></div>
      <span className="tc-">
        {commonMessage ||
          offline ||
          timeInOut ||
          mdList ||
          notification ||
          outOfField}
      </span>
    </div>
  );
};

export default Loader;
