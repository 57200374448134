import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components";
import { useForm, useOnlineStatus, useFetchAndSyncData } from "../../hooks";
import { formValidations } from "../../helpers/formValidations";
import {
  forceLogout,
  reqToUserLogin,
  reqToUserLoginInLocal,
} from "../../store/slice/userSlice";
import { CDCILogo, CYDCLogo, CYDCSLogo, DMLogo, AdminLogo } from "../../images";
import { notify, dateFormatWithTime } from "../../helpers/commonFunction";
import { getLatestDataFromDB } from "../../helpers/offlineAPIs";
import { OfflineModal, Confirmation } from "../../components/Modals";
import { messages } from "../../helpers/messages";
import { getAllEntriesFromDB } from "../../helpers/db";
import { routes } from "../../helpers/constants";
import { useLocation, useNavigate } from "react-router-dom";

const LoginPage = ({ dmUser, adminUser }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isOnline } = useOnlineStatus();
  const { loader } = useSelector((state) => state.userReducer);

  const [isLoading, setIsLoading] = useState(false);
  const [alreadyLoginModal, setAlreadyLoginModal] = useState(false);
  const handleAlreadyLoginModal = () => {
    setAlreadyLoginModal(!alreadyLoginModal);
  };

  const initialFValues = {
    username: "",
    password: "",
    type: dmUser ? "DM" : adminUser ? "ADMIN" : "TM",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return formValidations(fieldValues, temp, values, setValues, setErrors);
  };
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const {
    syncType,
    syncMessage,
    offlineModal,
    confirmationModal,
    handleOfflineModal,
    handleConfirmModal,
    uploadAndFetchData,
  } = useFetchAndSyncData();

  const handleOfflineOnlineLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (validate() && location.pathname === routes.login) {
      try {
        const [userData] = await Promise.all([getAllEntriesFromDB("userData")]);
        if (isOnline && userData && userData?.length === 0) {
          dispatch(forceLogout());
          handleUserLogin("TM");
        } else if (isOnline && userData && userData?.length > 0) {
          await uploadAndFetchData("upload").then((res) => {
            if (res) {
              dispatch(forceLogout());
              handleUserLogin("TM", userData);
            }
          });
        } else if (!isOnline && userData && userData?.length > 0) {
          setIsLoading(false);
          let oldData = userData[0];
          if (values.username === oldData.username) {
            dispatch(reqToUserLoginInLocal(oldData));
            navigate(routes.dashboard);
          } else {
            handleOfflineModal();
          }
        } else if (!isOnline && userData && userData?.length === 0) {
          setIsLoading(false);
          handleOfflineModal();
        }
      } catch (error) {
        setIsLoading(false);
        notify("Failed to login!", "error");
      }
    } else if (validate() && location.pathname !== routes.login) {
      dispatch(forceLogout());
      handleUserLogin();
    }
  };
  const handleUserLogin = (user, userData) => {
    dispatch(
      reqToUserLogin({
        data: { ...values, date: dateFormatWithTime(new Date()) },
        onSuccess: async () => {
          if (user === "TM") {
            getLatestDataFromDB("login", dispatch, userData);
          }
          setIsLoading(false);
        },
        onFailure: (err) => {
          setIsLoading(false);
          if (err && err.response.data.includes("currently signed")) {
            handleAlreadyLoginModal();
          }
        },
      })
    );
  };

  return (
    <>
      {loader && <Loader />}
      <div className="tc-login-section">
        <div className="tc-navbar"></div>
        <div className="tc-login-content">
          <Form className="tc-login-form">
            <div className="tc-login-top-space"></div>
            <div className="tc-login-form-box">
              <div className="tc-login-logo">
                <div
                  className={`tc-logo ${(dmUser || adminUser) && "mb-4"} ${
                    !dmUser &&
                    !adminUser &&
                    process.env.REACT_APP_TYPE !== "CYDC" &&
                    "tc-logo2"
                  }`}
                >
                  <img
                    src={
                      dmUser
                        ? DMLogo
                        : adminUser
                        ? AdminLogo
                        : process.env.REACT_APP_TYPE === "CYDC"
                        ? CYDCLogo
                        : process.env.REACT_APP_TYPE === "CDCI"
                        ? CDCILogo
                        : CYDCSLogo
                    }
                    alt="ETMS LOGO"
                  />
                </div>
                <div className="logo-title">
                  <h2 className="text-center">
                    TERRITORY <span>MANAGER </span>
                  </h2>
                </div>
                <div className="version tc_version_content">
                  <p> Version {process.env.REACT_APP_VERSION}</p>
                </div>
              </div>
              <Form.Group className="tc-login-form-list">
                <div className="tc-login-form-list-input-row mb-2">
                  <Form.Control
                    type="text"
                    name="username"
                    value={values.username}
                    onChange={handleInputChange}
                    placeholder="Username"
                    autoComplete="false"
                    className="text-center"
                  />
                </div>
                <span className="tc-error-text">
                  {errors && errors.username}
                </span>
              </Form.Group>
              <Form.Group className="tc-login-form-list">
                <div className="tc-login-form-list-input-row">
                  <Form.Control
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    autoComplete="false"
                    className="text-center"
                  />
                </div>
                <span className="tc-error-text">
                  {errors && errors.password}
                </span>
              </Form.Group>
              <div className="text-center">
                <Button
                  variant="primary"
                  className="login-button mt-5"
                  type="submit"
                  onClick={(e) => handleOfflineOnlineLogin(e)}
                  disabled={isLoading}
                >
                  Login
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="tc-footer-blank d-flex justify-content-center"></div>
      </div>
      {offlineModal && (
        <OfflineModal show={offlineModal} onHide={handleOfflineModal} />
      )}
      {confirmationModal && (
        <Confirmation
          show={confirmationModal}
          onHide={() => {
            setIsLoading(false);
            handleConfirmModal();
          }}
          title="Syncing Data"
          subTitle="Failed to sync data!"
          description="Please resync the data!"
          cancel="Cancel"
          save="Syncing"
          errorMessage={syncMessage}
          onSubmit={() => {
            setIsLoading(false);
            uploadAndFetchData(syncType);
          }}
        />
      )}
      {alreadyLoginModal && (
        <Confirmation
          show={alreadyLoginModal}
          onHide={handleAlreadyLoginModal}
          title="Login"
          size="md"
          description={messages.toast.alreadyLogin}
          save="Ok"
          radius={true}
          onSubmit={handleAlreadyLoginModal}
        />
      )}
    </>
  );
};

export default LoginPage;
