import React from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../helpers/constants";
import {
  DashboardIcon,
  MDInfoIcon,
  MdListIcon,
  OutOfFieldIcon,
  QuickSignIcon,
  editCalenderIcon,
} from "../icon/icon";

const Footer = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== routes.timeInOut &&
        location.pathname !== routes.approvalItinerary &&
        location.pathname !== routes.mdBirthdayList &&
        location.pathname !== routes.reports &&
        location.pathname !== routes.sync && (
          <div className="tc-footer">
            <div className="tc-footer-content">
              <Link
                to={routes.dashboard}
                className={`tc-footer-tab ${
                  location.pathname === routes.dashboard && "active"
                }`}
              >
                {DashboardIcon}
                Dashboard
              </Link>
              <Link
                to={routes.mdList}
                className={`tc-footer-tab ${
                  (location.pathname === routes.mdList ||
                    location.pathname === routes.callActivities ||
                    location.pathname === routes.monthlyMdList ||
                    location.pathname === routes.missedCallMdList ||
                    location.pathname === routes.viewCallSummary) &&
                  "active"
                }`}
              >
                {MdListIcon}
                Itinerary
              </Link>
              <Link
                to={routes.quickSign}
                className={`tc-footer-tab ${
                  location.pathname === routes.quickSign && "active"
                }`}
              >
                {QuickSignIcon}
                Quick Call
              </Link>
              <Link
                to={routes.outOfField}
                className={`tc-footer-tab ${
                  location.pathname === routes.outOfField && "active"
                }`}
              >
                {OutOfFieldIcon}
                VMC
              </Link>
              <Link
                to={routes.itinerary}
                className={`tc-footer-tab ${
                  (location.pathname === routes.itinerary ||
                    location.pathname === routes.updateItinerary ||
                    location.pathname === routes.itineraryPerDay ||
                    location.pathname === routes.monthlyItinerary) &&
                  "active"
                }`}
              >
                {editCalenderIcon}
                Updates
              </Link>
              <Link
                to={routes.mdInfo}
                className={`tc-footer-tab ${
                  location.pathname === routes.mdInfo && "active"
                }`}
              >
                {MDInfoIcon}
                MDs
              </Link>
            </div>
          </div>
        )}
    </>
  );
};
export default Footer;
