import React, { useEffect, useState } from "react";
import { backBtnIcon } from "../../icon/icon";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { handleVisits } from "../../helpers/commonFunction";
import { DataNotFound } from "../../components";
import { messages } from "../../helpers/messages";
import { reqToUpdateMarketRObj } from "../../store/slice/callRecordingSlice";

const MarketResearch = (props) => {
  const {
    selectedMD,
    showSidebar,
    handleCallActivitiesSidebar,
    updateCallActivitieData,
  } = props;
  const dispatch = useDispatch();

  // Get call recording reducer data
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );
  const marketResearchData =
    callRecordingReducer && callRecordingReducer.marketResearchData;
  const activities = callRecordingReducer && callRecordingReducer.activities;
  const marketRObj = callRecordingReducer && callRecordingReducer.marketRObj;

  // States
  const [errors, setErrors] = useState("");
  const [marketResearch, setMarketResearch] = useState([]);
  const [marketResearchObj, setMarketResearchObj] = useState([]);
  const [changeStatus, setChangeStatus] = useState(false);

  // Customize marketResearch data array
  useEffect(() => {
    if (
      marketResearchData &&
      marketResearchData?.length > 0 &&
      marketRObj?.length === 0
    ) {
      const newResearchData = marketResearchData.map((item) => {
        if (item.question_type === 1) {
          return {
            ...item,
            value: "Select",
          };
        } else {
          return {
            ...item,
            value: "",
          };
        }
      });
      setMarketResearch(newResearchData);
      setErrors("");
    }
  }, [callRecordingReducer]);

  // Update call activitie data and Close page
  useEffect(() => {
    if (marketRObj && marketRObj.length > 0) {
      handleCallActivitiesSidebar();
      updateCallActivitieData(activities.marketR);
    }
  }, [marketRObj]);

  // Check validation
  const checkValidation = () => {
    if (marketResearchObj && marketResearchObj.length === 0) {
      setErrors(messages.errors.answer_req);
      return false;
    } else {
      setErrors("");
      return true;
    }
  };

  // Handle onChange
  const handleChangeQueAnswer = (event, data) => {
    const { name, value } = event.target;
    setChangeStatus(true);
    let newData = [...marketResearchObj];
    if (name === "option") {
      const currValue =
        data?.options &&
        data?.options.find((item) => item.id === Number(value));
      let filterData =
        marketResearchObj &&
        marketResearchObj?.find(
          (option) => option.question_id === currValue.question_id
        );
      if (filterData) {
        let index =
          newData &&
          newData.findIndex(
            (item) => item.question_id === filterData.question_id
          );
        newData[index] = {
          answer_id: currValue.id,
          question_id: currValue.question_id,
          name: currValue.name,
          question: data.name,
        };
        setMarketResearchObj(newData);
        updateMarketResearchData(currValue);
      } else {
        newData.push({
          answer_id: currValue.id,
          question_id: currValue.question_id,
          name: currValue.name,
          question: data.name,
        });
        setMarketResearchObj(newData);
        updateMarketResearchData(currValue);
      }
    } else {
      let filterData =
        marketResearchObj &&
        marketResearchObj?.find(
          (option) => option.question_id === data.question_id
        );
      if (filterData) {
        let index =
          newData &&
          newData.findIndex(
            (item) => item.question_id === filterData.question_id
          );
        newData[index] = {
          answer_text: value,
          question_id: data.question_id,
          question: data.name,
        };
        setMarketResearchObj(newData);
        updateMarketResearchData({
          question_id: data.question_id,
          data: value,
        });
      } else {
        newData.push({
          answer_text: value,
          question_id: data.question_id,
          question: data.name,
        });
        setMarketResearchObj(newData);
        updateMarketResearchData({
          question_id: data.question_id,
          data: value,
        });
      }
    }
  };

  // Update marketResearch data
  const updateMarketResearchData = (value) => {
    const newMarketResearchData =
      marketResearch &&
      marketResearch.map((item) => {
        if (item.question_id === value.question_id) {
          return {
            ...item,
            value: value,
          };
        } else {
          return item;
        }
      });
    setMarketResearch(newMarketResearchData);
  };

  // Update marketResearch data object
  const handleMarketResearchDataObj = () => {
    if (checkValidation()) {
      if (
        (marketResearchObj &&
          marketResearchObj?.length !== marketRObj?.length) ||
        changeStatus
      ) {
        dispatch(reqToUpdateMarketRObj(marketResearchObj));
        setChangeStatus(false);
      } else {
        handleCallActivitiesSidebar();
      }
    }
  };

  return (
    <>
      <div className={`tc-sidebar-section ${showSidebar && "zl-sidebar-open"}`}>
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-sidebar-back-btn"
            onClick={() => handleCallActivitiesSidebar()}
          >
            {backBtnIcon}
          </button>
          <p className="tc-sidebar-title">Market Research</p>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body">
          <div className="tc-md-list-common-detail">
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">MD Name:</span>
              <p className="tc-md-list-common-detail-text tc-md-list-common-detail-name">
                {selectedMD &&
                  selectedMD?.firstname + " " + selectedMD?.lastname}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Specialty:</span>
              <p className="tc-md-list-common-detail-text">
                {selectedMD && selectedMD?.specialty_name
                  ? selectedMD?.specialty_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Location:</span>
              <p className="tc-md-list-common-detail-text">
                {selectedMD && selectedMD?.location_name
                  ? selectedMD?.location_name
                  : "N/A"}
              </p>
            </div>
            <div className="tc-md-list-common-detail-row">
              <span className="tc-md-list-common-detail-label">Visits:</span>
              <p className="tc-md-list-common-detail-text">
                {selectedMD && selectedMD?.target
                  ? handleVisits(selectedMD?.actual_visits, selectedMD?.target)
                  : "N/A"}
              </p>
            </div>
          </div>
          <Table className="tc-table tc-call-activity-table">
            <thead>
              <tr>
                <th className="text-start border-left-side">Question</th>
                <th className="text-start border-right-side">Answer</th>
              </tr>
            </thead>
            <tbody>
              {marketResearch && marketResearch?.length === 0 ? (
                <tr>
                  <td colSpan={2}>
                    <DataNotFound />
                  </td>
                </tr>
              ) : (
                <>
                  {marketResearch &&
                    marketResearch?.length > 0 &&
                    marketResearch?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          className="tc-md-list-product-detailing-tr"
                        >
                          <td className="tc-md-list-table-paragraph">
                            {item?.name}
                          </td>
                          <td>
                            {item?.question_type === 1 ? (
                              <select
                                name="option"
                                className="tc-md-list-table-question-answer-select"
                                value={
                                  item?.value.id ? item.value.id : "Select"
                                }
                                onChange={(e) => handleChangeQueAnswer(e, item)}
                              >
                                <option defaultValue="Select" disabled>
                                  Select...
                                </option>
                                {item?.options?.length > 0 &&
                                  item?.options.map((option, i) => {
                                    return (
                                      <option key={i} value={option?.id}>
                                        {option?.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            ) : (
                              <textarea
                                type="text"
                                name="text"
                                className="tc-md-list-table-question-answer-textarea"
                                defaultValue={item?.answerValue}
                                value={item?.value.data}
                                maxLength={200}
                                onChange={(e) => handleChangeQueAnswer(e, item)}
                                spellCheck="false"
                              />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </>
              )}
            </tbody>
          </Table>
          {marketResearch && marketResearch?.length > 0 && (
            <>
              <span className="tc-error">{errors && errors}</span>
              <div className="tc-md-list-detail-bottom-btn-content">
                <button
                  type="button"
                  className="tc-md-list-detail-bottom-btn"
                  onClick={() => handleMarketResearchDataObj()}
                >
                  Done
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MarketResearch;
