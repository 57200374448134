import React, { useEffect, useState } from "react";
import { Button, Nav, Tab, Table } from "react-bootstrap";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchIcon, refreshIcon2 } from "../../icon/icon";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { AdjustQuantity } from "../../components/Modals";
import { useDispatch, useSelector } from "react-redux";
import {
  reqToGetAdjustReasons,
  reqToGetReciveMatList,
  submitReceiveMats,
} from "../../store/slice/promoMaterialSlice";
import { adjustPromoMatsValidations } from "../../helpers/formValidations";
import { useForm } from "../../hooks";
import moment from "moment";
import { numberFormatting } from "../../helpers/commonFunction";
import { routes } from "../../helpers/constants";

const ReceivePromoMats = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // Get reducer data
  const returnMatsList = useSelector(
    (state) => state.promoMaterialReducer.receivedMatsList
  );
  const is_received = useSelector(
    (state) => state.promoMaterialReducer.is_received
  );
  const adjustReasons = useSelector(
    (state) => state.promoMaterialReducer.adjustReasons
  );
  const isLoading = useSelector((state) => state.promoMaterialReducer.loader);

  const [adjustQuantityModal, setAdjustQuantityModal] = useState(false);
  const [materialObj, setMaterialObj] = useState({});
  const [newReceivedmatsList, setNewReceivedmatsList] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [updatedList, setUpdatedList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [currProductType, setCurrProductType] = useState(
    location?.state?.currPType ? location?.state?.currPType : "SAMPLES"
  );

  // Handle show adjust quantity modal
  const showAdjustQuantityModal = (item) => {
    setMaterialObj(item);
    setAdjustQuantityModal(true);
  };

  // Handle hide adjust quantity modal
  const hideAdjustQuantityModal = () => {
    setAdjustQuantityModal(false);
    setMaterialObj({});
    setValues({
      issued_qty: "",
      received_qty: "",
      reason: "",
    });
    setErrors({});
  };

  useEffect(() => {
    handleRefreshPage();
  }, []);

  const handleRefreshPage = () => {
    dispatch(reqToGetReciveMatList({ dispatch }));
    dispatch(reqToGetAdjustReasons({ dispatch }));
  };

  useEffect(() => {
    setNewReceivedmatsList(
      returnMatsList &&
        returnMatsList?.map((item) => {
          return {
            ...item,
          };
        })
    );
    dividePromoMaterials();
  }, [returnMatsList, is_received]);

  // Filter product by type
  const dividePromoMaterials = () => {
    const updatedArrList = {
      samplesArr: [],
      giveawaysArr: [],
      literaturesArr: [],
      otherArr: [],
    };

    for (let i = 0; i < returnMatsList?.length; i++) {
      if (returnMatsList[i].product_type_name.includes("Samples")) {
        updatedArrList.samplesArr.push(returnMatsList[i]);
      } else if (returnMatsList[i].product_type_name.includes("Giveaways")) {
        updatedArrList.giveawaysArr.push(returnMatsList[i]);
      } else if (returnMatsList[i].product_type_name.includes("Literature")) {
        updatedArrList.literaturesArr.push(returnMatsList[i]);
      } else {
        updatedArrList.otherArr.push(returnMatsList[i]);
      }
    }

    setUpdatedList(updatedArrList);
    setFilteredList(updatedArrList);
  };

  const handleOnChange = (e) => {
    if (e.target.value === "") {
      handleSearch("");
    }
    setSearchInput(e.target.value);
  };
  // Filter prodyct by search
  const handleSearch = (searchValue) => {
    if (searchValue) {
      // Filter samples product
      const filteredSamples = updatedList.samplesArr.filter((item) =>
        item?.name.toLowerCase().includes(searchValue.trim().toLowerCase())
      );

      // Filter giveaways product
      const filteredGiveaways = updatedList.giveawaysArr.filter((item) =>
        item?.name.toLowerCase().includes(searchValue.trim().toLowerCase())
      );

      // Filter literatures product
      const filteredLiteratures = updatedList.literaturesArr.filter((item) =>
        item?.name.toLowerCase().includes(searchValue.trim().toLowerCase())
      );

      // Filter other product array
      const otherArr = updatedList.otherArr.filter((item) =>
        item?.name.toLowerCase().includes(searchValue.trim().toLowerCase())
      );

      const allFilteredItems = {
        samplesArr: filteredSamples,
        giveawaysArr: filteredGiveaways,
        literaturesArr: filteredLiteratures,
        otherArr: otherArr,
      };

      setFilteredList(allFilteredItems);
    } else {
      setFilteredList(updatedList);
    }
  };

  const getReasonNameById = (notes_id) => {
    const foundReason = adjustReasons?.find((reason) => reason.id === notes_id);
    return foundReason ? foundReason.name : "";
  };

  // Initialvalues
  const initialFValues = {
    issued_qty: "",
    received_qty: "",
    reason: "",
  };

  useEffect(() => {
    setValues({
      ...values,
      issued_qty:
        materialObj && materialObj.issued_qty ? materialObj.issued_qty : "",
      received_qty:
        materialObj && materialObj.actual_qty ? materialObj.actual_qty : "",
      notes_id: "",
    });
  }, [materialObj]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    return adjustPromoMatsValidations(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors
    );
  };

  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true,
    validate
  );

  const updateActualNumber = (
    listData,
    productData,
    newActualNumber,
    reasonId
  ) => {
    if (productData?.product_type_name.includes("Samples")) {
      const updatedArray = listData?.samplesArr?.map((obj) => {
        if (obj.promomat_id === productData.promomat_id) {
          return { ...obj, actual_qty: +newActualNumber, notes_id: +reasonId };
        }
        return obj;
      });
      return { ...listData, samplesArr: updatedArray };
    } else if (productData?.product_type_name.includes("Giveaways")) {
      const updatedArray = listData?.giveawaysArr?.map((obj) => {
        if (obj.promomat_id === productData.promomat_id) {
          return { ...obj, actual_qty: +newActualNumber, notes_id: +reasonId };
        }
        return obj;
      });
      return { ...listData, giveawaysArr: updatedArray };
    } else if (productData?.product_type_name.includes("Literature")) {
      const updatedArray = listData?.literaturesArr?.map((obj) => {
        if (obj.promomat_id === productData.promomat_id) {
          return { ...obj, actual_qty: +newActualNumber, notes_id: +reasonId };
        }
        return obj;
      });
      return { ...listData, literaturesArr: updatedArray };
    } else {
      return { ...listData };
    }
  };

  // Handle save modal changes
  const saveAdjustMatsHandler = () => {
    if (validate()) {
      setFilteredList(
        updateActualNumber(
          filteredList,
          materialObj,
          values.received_qty,
          values.reason
        )
      );
      setAdjustQuantityModal(false);
    }
  };

  const removeNameFromMats = (arr) => {
    const newArray = [];
    arr?.samplesArr?.length > 0 &&
      arr?.samplesArr.map((obj) => {
        const { name, actual_qty, ...rest } = obj;
        newArray.push({ ...rest, received_qty: actual_qty });
      });

    arr?.giveawaysArr?.length > 0 &&
      arr?.giveawaysArr.map((obj) => {
        const { name, actual_qty, ...rest } = obj;
        newArray.push({ ...rest, received_qty: actual_qty });
      });

    arr?.literaturesArr?.length > 0 &&
      arr?.literaturesArr.map((obj) => {
        const { name, actual_qty, ...rest } = obj;
        newArray.push({ ...rest, received_qty: actual_qty });
      });
    return newArray;
  };

  // Handle submit received product
  const onSubmitHandler = () => {
    const data = {
      materials: removeNameFromMats(filteredList),
    };
    dispatch(
      submitReceiveMats({
        data,
        cb: (err, res) => {
          if (err) {
          } else {
            dispatch(reqToGetReciveMatList());
            setValues({
              issued_qty: "",
              received_qty: "",
              reason: "",
            });
            navigate("/promo-meterials");
          }
        },
      })
    );
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="tc-body">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="tc-profile-wrapper mb-0">
            <span className="tc-profile-name">PROMO MATERIALS</span>
            {/* <span
              className="tc-header-refresh"
              role="button"
              onClick={() => handleRefreshPage()}
            >
              {refreshIcon2}
            </span> */}
          </div>
          <div className="text-end fs-18">
            <p className="mb-0 fw-semibold">{moment().format("MMMM YYYY")}</p>
            <p className="mb-0">As of {moment().format("dddd, MMMM DD")}</p>
          </div>
        </div>
        <div className="add-scroll">
          <div className="tc-content-wrapper">
            <div className="tc-promo-content tc-promo-materials-content">
              <Tab.Container id="tc-promo-material" defaultActiveKey="samples">
                <div className="tc-calender-search-input-row">
                  <Nav variant="pills" className="gap-2">
                    <Nav.Item>
                      <Nav.Link
                        active={currProductType === "SAMPLES"}
                        eventKey="samples"
                        onClick={() => setCurrProductType("SAMPLES")}
                      >
                        SAMPLES
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        active={currProductType === "GIVEAWAYS"}
                        eventKey="giveaways"
                        onClick={() => setCurrProductType("GIVEAWAYS")}
                      >
                        GIVEAWAYS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        active={currProductType === "LITERATURE"}
                        eventKey="literature"
                        onClick={() => setCurrProductType("LITERATURE")}
                      >
                        LITERATURE
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <div className="tc-search-input-content d-flex">
                    <input
                      id="text"
                      type="text"
                      placeholder="Search..."
                      value={searchInput}
                      onChange={(e) => handleOnChange(e)}
                    />
                    <button
                      type="button"
                      className="tc-search-input-btn"
                      onClick={() => handleSearch(searchInput)}
                    >
                      {SearchIcon}
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <h5 className="mb-0">{currProductType} RECEIVED</h5>
                  <div className="ms-auto">
                    <Button
                      variant="link"
                      size="sm"
                      type="button"
                      className="p-0"
                      onClick={() =>
                        navigate(routes.promoMaterials, {
                          state: {
                            currPType: currProductType,
                          },
                        })
                      }
                    >
                      ON-HAND
                    </Button>{" "}
                    |{" "}
                    <Button
                      variant="link"
                      size="sm"
                      type="button"
                      className="p-0 active"
                    >
                      RECEIVED
                    </Button>
                  </div>
                </div>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="samples"
                    active={currProductType === "SAMPLES"}
                  >
                    <div className="tc-table-wrapper">
                      <Table
                        responsive
                        className="tc-table tc-promo-mats-table"
                      >
                        <thead>
                          <tr>
                            <th>PRODUCT</th>
                            <th>TYPE</th>
                            <th>LOT #</th>
                            <th>EXPIRY DATE</th>
                            <th>SENT</th>
                            <th>ACTUAL</th>
                            <th>REASON</th>
                          </tr>
                        </thead>
                        {filteredList?.samplesArr?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={7}>No data found</td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {filteredList?.samplesArr?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className={`${
                                      item.name && "min-width-350"
                                    }`}
                                  >
                                    {item.name ? item.name : ""}
                                  </td>
                                  <td>
                                    {item.product_type_name
                                      ? item.product_type_name.charAt(0)
                                      : "-"}
                                  </td>
                                  <td className="min-width-150">
                                    {item.lot_number ? item.lot_number : "-"}
                                  </td>
                                  <td>
                                    {item.expiry_date
                                      ? moment(item.expiry_date).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "-"}
                                  </td>
                                  <td>
                                    {item.issued_qty
                                      ? numberFormatting(+item.issued_qty)
                                      : "-"}
                                  </td>
                                  <td>
                                    {item.actual_qty
                                      ? numberFormatting(+item.actual_qty)
                                      : "-"}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                      {item.notes_id
                                        ? getReasonNameById(item.notes_id)
                                        : ""}
                                      <button
                                        className="tc-cursor-pointer fs-5"
                                        onClick={() =>
                                          showAdjustQuantityModal(item)
                                        }
                                        disabled={is_received === 1}
                                      >
                                        <FontAwesomeIcon icon={faPen} />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="giveaways"
                    active={currProductType === "GIVEAWAYS"}
                  >
                    <div className="tc-table-wrapper">
                      <Table
                        responsive
                        className="tc-table tc-promo-mats-table"
                      >
                        <thead>
                          <tr>
                            <th>PRODUCT</th>
                            <th>SENT</th>
                            <th>ACTUAL</th>
                            <th>REASON</th>
                          </tr>
                        </thead>
                        {filteredList?.giveawaysArr?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={4}>No data found</td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {filteredList?.giveawaysArr?.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td
                                    className={`${
                                      item.name && "min-width-300"
                                    }`}
                                  >
                                    {item.name}
                                  </td>
                                  <td>
                                    {item.issued_qty
                                      ? numberFormatting(+item.issued_qty)
                                      : "-"}
                                  </td>
                                  <td>
                                    {item.actual_qty
                                      ? numberFormatting(+item.actual_qty)
                                      : "-"}
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center justify-content-center">
                                      {item.notes_id
                                        ? getReasonNameById(item.notes_id)
                                        : ""}
                                      <button
                                        className="tc-cursor-pointer fs-5"
                                        onClick={() =>
                                          showAdjustQuantityModal(item)
                                        }
                                        disabled={is_received === 1}
                                      >
                                        <FontAwesomeIcon icon={faPen} />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="literature"
                    active={currProductType === "LITERATURE"}
                  >
                    <div className="tc-table-wrapper">
                      <Table
                        responsive
                        className="tc-table tc-promo-mats-table"
                      >
                        <thead>
                          <tr>
                            <th>PRODUCT</th>
                            <th>SENT</th>
                            <th>ACTUAL</th>
                            <th>REASON</th>
                          </tr>
                        </thead>
                        {filteredList?.literaturesArr?.length === 0 ? (
                          <tbody>
                            <tr>
                              <td colSpan={4}>No data found</td>
                            </tr>
                          </tbody>
                        ) : (
                          <tbody>
                            {filteredList?.literaturesArr?.map(
                              (item, index) => {
                                return (
                                  <tr key={index}>
                                    <td
                                      className={`${
                                        item.name && "min-width-300"
                                      }`}
                                    >
                                      {item.name}
                                    </td>
                                    <td>
                                      {item.issued_qty
                                        ? numberFormatting(+item.issued_qty)
                                        : "-"}
                                    </td>
                                    <td>
                                      {item.actual_qty
                                        ? numberFormatting(+item.actual_qty)
                                        : "-"}
                                    </td>
                                    <td>
                                      <div className="d-flex align-items-center justify-content-center">
                                        {item.notes_id
                                          ? getReasonNameById(item.notes_id)
                                          : ""}
                                        <button
                                          className="tc-cursor-pointer fs-5"
                                          onClick={() =>
                                            showAdjustQuantityModal(item)
                                          }
                                          disabled={is_received === 1}
                                        >
                                          <FontAwesomeIcon icon={faPen} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <div className="text-center mt-5">
              <Button
                variant="primary"
                type="button"
                onClick={() => onSubmitHandler()}
                disabled={is_received === 1}
              >
                SUBMIT
              </Button>
            </div>

            <AdjustQuantity
              show={adjustQuantityModal}
              onHide={hideAdjustQuantityModal}
              values={values}
              errors={errors}
              materialObj={materialObj}
              handleInputChange={handleInputChange}
              saveAdjustMatsHandler={saveAdjustMatsHandler}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceivePromoMats;
