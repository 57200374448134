import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchIcon, aToZIcon, backBtnIcon, callIcon } from "../../icon/icon";
import {
  defaultDateFormat,
  getMonthStartEndDate,
  handleVisits,
  nameFormatting,
} from "../../helpers/commonFunction";
import { DataNotFound } from "../../components";
import { messages } from "../../helpers/messages";
import DataTable from "react-data-table-component";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../helpers/db";
import { routes } from "../../helpers/constants";
import { CustomDateInput } from "../../components";

const ItineraryPerDay = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Get indexedDB data
  const getItineraryFromDB = useLiveQuery(() => db.itineraryMDs.toArray(), []);

  const currentDate = moment().format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(
    location?.state?.selectedDate && location?.state?.selectedDate
  );
  const [isLoading, setIsLoading] = useState(true);
  const [mdListData, setMdListData] = useState([]);
  const [totCallWithMD, setTotCallWithMD] = useState(0);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (mdListData && mdListData?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [mdListData]);

  useEffect(() => {
    let newDates = getMonthStartEndDate(1);
    setMinDate(newDates?.start);
    setMaxDate(newDates?.end);
    setSelectedDate(newDates.start);
    if (getItineraryFromDB && getItineraryFromDB?.length > 0) {
      const currData = moment(newDates?.start).format("YYYY-MM-DD");
      handleSearchMDList("date", currData, getItineraryFromDB);
    }
  }, [getItineraryFromDB]);

  const handleSearchMDList = (searchBy, value, itinerary) => {
    try {
      let updatedMDList = itinerary ? itinerary : getItineraryFromDB;
      if (searchBy === "date") {
        const newList = updatedMDList.filter((item) => item.date === value);
        setMdListData(newList);
        const getVisitedMD =
          newList &&
          newList.filter((item) => item.is_summary === 1 || item.is_save === 1);
        setTotCallWithMD(getVisitedMD?.length);
        let sDate = value ? value : currentDate;
        setSelectedDate(sDate);
      } else {
        const previousNewList = updatedMDList.filter(
          (item) => item.date === selectedDate
        );
        const newList = previousNewList.filter(
          (item) =>
            `${item.lastname.trim()} ${item.firstname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            `${item.firstname.trim()} ${item.lastname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase())
        );
        const finalList =
          newList.length > 0 ? newList : !value ? previousNewList : [];
        setMdListData(finalList);
        const getVisitedMD =
          finalList &&
          finalList.filter(
            (item) => item.is_summary === 1 || item.is_save === 1
          );
        setTotCallWithMD(getVisitedMD?.length);
      }
    } catch (error) {
      console.error("Error searching MD by date:", error);
    }
  };

  const handleOnChange = (event) => {
    if (event.target.value === "") {
      setSearchValue(event.target.value);
      handleSearchMDList("date", selectedDate);
    } else {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    }
  };

  // Data table
  const columns = [
    {
      name: "Class",
      sortable: true,
      selector: (row) => row?.name,
      cell: (row) => (
        <p className={`tc-data-table-class-text text-center w-100`}>
          {row?.name}
        </p>
      ),
    },
    {
      name: "Md Name",
      sortable: true,
      selector: (row) => row?.firstname.concat(" ", row?.lastname),
      cell: (row) => (
        <p role="button" className={`tc-data-table-md-name-text text-start`}>
          {nameFormatting(row)}
        </p>
      ),
    },
    {
      name: "Visits",
      sortable: true,
      selector: (row) => handleVisits(row?.actual_visits, row?.target),
      cell: (row) => (
        <p className={`tc-data-table-visits-text tc-data-table-green-text`}>
          {handleVisits(row?.actual_visits, row?.target)}
        </p>
      ),
    },
    {
      name: "Call Status",
      sortable: true,
      selector: (row) => row.call,
      cell: (row) => (
        <button className={`tc-data-table-button tc-data-table-icon`} disabled>
          {callIcon}
        </button>
      ),
    },
  ];

  return (
    <div className="tc-body">
      <div className="tc-navbar-back-text">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => navigate(routes.itinerary)}
        >
          {backBtnIcon}
        </button>
        <p className="tc-navbar-title"></p>
        <div className="tc-navbar-right-btn"></div>
      </div>
      <div className="tc-profile-wrapper">
        <span className="tc-profile-name">ITINERARY PER DAY</span>
      </div>
      <div className="add-scroll">
        <div className="tc-content-wrapper h-100">
          <h2>MDs TO VISIT</h2>
          <div className="tc-calender-search-input-row justify-content-between">
            <div>
              <h6>DATE</h6>
              <div className="tc-calender-input-content calendar-icon-md">
                <div className="position-relative">
                  <ReactDatePicker
                    selected={moment(selectedDate)._d}
                    onChange={(e) =>
                      handleSearchMDList("date", moment(e).format("YYYY-MM-DD"))
                    }
                    id="date-picker-md"
                    dateFormat="yyyy-MM-dd"
                    value={defaultDateFormat(selectedDate)}
                    minDate={minDate}
                    maxDate={maxDate}
                    onKeyDown={(e) => e.preventDefault()}
                    disabled={searchValue !== ""}
                    customInput={<CustomDateInput />}
                  />
                </div>
                <label
                  className="tc-atoz-icon"
                  onClick={() => navigate(routes.monthlyItinerary)}
                >
                  {aToZIcon}
                </label>
              </div>
            </div>
            <div className="d-flex flex-column align-items-end">
              <div className="ms-auto d-flex align-items-center">
                <div className="tc-search-input-content d-flex">
                  <input
                    id="text"
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <button
                    type="button"
                    className="tc-search-input-btn"
                    onClick={() => handleSearchMDList("name", searchValue)}
                    disabled={searchValue === "" && true}
                  >
                    {SearchIcon}
                  </button>
                </div>
              </div>
              <h6 className="mt-3 mb-0">
                Total MDs:{" "}
                {mdListData && mdListData?.length > 0
                  ? `${totCallWithMD}/${mdListData?.length}`
                  : 0}
              </h6>
            </div>
          </div>
          {isLoading ? null : mdListData && mdListData?.length === 0 ? (
            <DataNotFound name={messages.toast.nodateData} />
          ) : (
            <DataTable
              columns={columns}
              data={mdListData && mdListData}
              className={`tc-data-table flex-grow-1 overflow-y-auto ${
                mdListData &&
                mdListData?.length === 0 &&
                "d-flex align-items-center"
              }`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ItineraryPerDay;
