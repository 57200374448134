import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { messages } from "../../helpers/messages";
import MonthlyCallDates from "./MonthlyCallDates";
import { getAllEntriesFromDB } from "../../helpers/db";
import {
  getSortData,
  nameFormatting,
  getNewMDListByRecordDate,
} from "../../helpers/commonFunction";

const MatchSignature = (props) => {
  const { selectedsignature, match, setMatch, ...rest } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedMD, setSelectedMD] = useState("");
  const [disableMDs, setDisableMDs] = useState([]);
  const [holidayList, setHolidayList] = useState([]);
  const [mdListOptions, setMDListOptions] = useState([]);
  const [universalMDOptions, setUniversalMDOptions] = useState([]);
  const [selectedMDCallDates, setSelectedMDCallDates] = useState([]);
  const [selectedIncidentalMd, setSelectedIncidentalMd] = useState("");
  const [newMDData, setNewMDData] = useState({ selectedIncidentalMd: "" });
  const [errors, setErrors] = useState({ mdselect: "" });
  const [showModal, setShowModal] = useState(false);

  const handleSelect = (event) => {
    setMatch(event.target.value);
    setErrors({ mdselect: "" });
    setNewMDData({ selectedIncidentalMd: "" });
  };

  useEffect(() => {
    handleGetInitialData();
  }, []);

  const handleGetInitialData = async () => {
    const [mdList, holidays, disabled_mds, universalMDList] = await Promise.all(
      [
        getAllEntriesFromDB("mdList"),
        getAllEntriesFromDB("holidays"),
        getAllEntriesFromDB("disabled_mds"),
        getAllEntriesFromDB("universalMDList"),
      ]
    );
    setHolidayList(holidays);
    setDisableMDs(disabled_mds);
    const sortData = getSortData(universalMDList, "lastname");
    const universalOption =
      (sortData &&
        sortData?.length > 0 &&
        sortData.map((item) => {
          return {
            ...item,
            value: nameFormatting(item),
            label: nameFormatting(item),
          };
        })) ||
      [];
    setUniversalMDOptions(universalOption);
    const mdIdMap = new Map();
    const updatedMDList = await getNewMDListByRecordDate(mdList);
    updatedMDList.forEach((entry) => {
      const {
        id,
        date,
        md_id,
        is_save,
        is_summary,
        is_visited,
        is_week_call,
        is_consecutive,
        ...rest
      } = entry;
      const formattedDate = {
        date,
        is_save,
        is_summary,
        is_visited,
        is_week_call,
        is_consecutive,
        mdData: entry,
      };
      if (mdIdMap.has(md_id)) {
        const existingEntry = mdIdMap.get(md_id);
        if (is_summary === 1) {
          existingEntry.actual_visits++;
        }
        existingEntry.dates.push(formattedDate);
      } else {
        mdIdMap.set(md_id, {
          ...rest,
          id,
          md_id,
          dates: [formattedDate],
          actual_visits: is_summary === 1 ? 1 : 0,
        });
      }
    });
    const uniqueEntries = Array.from(mdIdMap.values());
    const uniqueEntries2 = getSortData(uniqueEntries, "lastname");
    const mdListOption =
      (uniqueEntries2 &&
        uniqueEntries2.map((item) => {
          return {
            ...item,
            value: nameFormatting(item),
            label: nameFormatting(item),
          };
        })) ||
      [];
    setMDListOptions(mdListOption);
  };

  const checkValidation = () => {
    if (match === "existing") {
      if (selectedMD === "") {
        setErrors({
          ...errors,
          mdselect: messages.errors.md_select_req,
        });
      } else {
        return true;
      }
    }
  };

  const checkValidationForIncidental = () => {
    let errors = {};
    if (!selectedIncidentalMd) {
      errors = { ...errors, selectedIncidentalMd: "Please select MD!" };
    }
    setErrors(errors);
    return Object.values(errors).every((x) => x === "");
  };

  const handleChangeValues = (event, type, optionName) => {
    if (type === "option") {
      if (!optionName) {
        setSelectedMD(event);
        setSelectedMDCallDates(event.dates);
        setErrors({
          ...errors,
          mdselect: "",
        });
      } else {
        setSelectedIncidentalMd(event);
        setErrors({
          ...errors,
          selectedIncidentalMd: "",
        });
      }
    } else {
      if (optionName) {
        setNewMDData({ ...newMDData, [optionName]: event });
        setErrors({
          ...errors,
          [optionName]: "",
        });
      } else {
        setNewMDData({ ...newMDData, [event.target.name]: event.target.value });
        setErrors({
          ...errors,
          [event.target.name]: "",
        });
      }
    }
  };

  const checkSelectedMd = () => {
    if (checkValidation() && selectedMDCallDates?.length > 0) {
      setShowModal(true);
    }
  };

  const handleMatchMDSignature = () => {
    const tempObj = {
      ...selectedsignature,
      id: selectedsignature?.id,
      signature_id: selectedsignature?.signature_id,
      signature_url: selectedsignature?.signature_url,
      signature_time: selectedsignature?.signature_time,
      signature_erase: selectedsignature?.signature_erase,
    };
    if (match === "existing") {
      if (checkValidation()) {
        props.onHide();
        navigate(routes.callActivities, {
          state: {
            selectedMD: selectedMD,
            reqObj: { md_id: selectedMD?.md_id },
            prevPath: location.pathname,
            quickSignObj: { ...tempObj, isQuick: true },
            selectedDate: selectedMD.date,
            start_time: new Date(),
          },
        });
      }
    } else {
      if (checkValidationForIncidental()) {
        navigate(routes.callActivities, {
          state: {
            selectedMD: selectedIncidentalMd,
            reqObj: { md_id: selectedIncidentalMd.md_id },
            prevPath: location.pathname,
            quickSignObj: tempObj,
            is_incidental: true,
            start_time: new Date(),
          },
        });
      }
    }
  };

  const handleCallActivitiesOfMD = (item) => {
    const tempObj = {
      ...selectedsignature,
      id: selectedsignature?.id,
      signature_id: selectedsignature?.signature_id,
      signature_url: selectedsignature?.signature_url,
      signature_time: selectedsignature?.signature_time,
      signature_erase: selectedsignature?.signature_erase,
    };
    if (checkValidation()) {
      props.onHide();
      let saveData = "";
      let editableData = "";
      if (selectedMD?.is_save === 1) {
        saveData = selectedMD?.save_data
          ? JSON.parse(selectedMD?.save_data)
          : "";
        editableData = {
          data: saveData,
          save_id: selectedMD?.save_id,
          s_record_time: new Date(selectedMD?.save_record_date),
          s_target_date: selectedMD?.save_target_date,
          s_visited_date: selectedMD?.save_visited_date,
        };
      }
      navigate(routes.callActivities, {
        state: {
          selectedMD: selectedMD,
          reqObj: { md_id: selectedMD?.md_id },
          prevPath: location.pathname,
          quickSignObj: { ...tempObj, isQuick: true },
          selectedDate: item.date,
          start_time:
            saveData && saveData?.start_time
              ? new Date(saveData?.start_time)
              : new Date(),
          editableData: editableData ? editableData : "",
        },
      });
      setShowModal(false);
    }
  };

  return (
    <>
      <Modal
        {...rest}
        className={`tc-main-modal tc-quicksign-modal tc-matchsign-modal ${
          showModal && "d-none"
        }`}
        centered
      >
        <Modal.Header closeButton className="p-4">
          <Modal.Title className="">Match Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 pt-0">
          <div className="space-y-2">
            <div className="tc-quicklist-canvas mb-4">
              {selectedsignature && (
                <img
                  src={
                    selectedsignature?.signature_url?.size
                      ? URL.createObjectURL(selectedsignature.signature_url)
                      : selectedsignature?.signPadData?.includes("base64")
                      ? selectedsignature?.signPadData
                      : process.env.REACT_APP_IMAGE_URL +
                        selectedsignature.signature_url
                  }
                  alt="Signature"
                  draggable={false}
                />
              )}
            </div>
            <div className="tc-quicksign-radio row">
              <p className="col-3">MATCH TO: </p>
              <Form className="col-9">
                <div className="mb-3 flex">
                  <Form.Check
                    inline
                    id="existing"
                    name="countries"
                    value="existing"
                    label="Itinerary MDs"
                    type={"radio"}
                    onChange={handleSelect}
                    defaultChecked
                  />
                  <Form.Check
                    inline
                    id="incidental"
                    name="countries"
                    value="incidental"
                    label="Incidental MDs"
                    type={"radio"}
                    checked={match === "incidental"}
                    onChange={handleSelect}
                  />
                </div>
              </Form>
            </div>
            {match === "existing" ? (
              <div className="row mb-4">
                <div className="mb-2 d-flex justify-content-between">
                  <p>MD NAME</p>
                  <p>
                    Total Itinerary MDs:{" "}
                    {mdListOptions && mdListOptions?.length > 0
                      ? mdListOptions?.length
                      : 0}
                  </p>
                </div>
                <div className="col-12">
                  <Select
                    name="option"
                    defaultValue={selectedMD}
                    onChange={(e) => handleChangeValues(e, "option")}
                    options={mdListOptions}
                    className={`tc-custom-select 
                    ${errors && errors.mdselect ? "tc-custom-select-error" : ""}
                    `}
                    classNamePrefix="select"
                    placeholder="Select"
                  />
                  <span className="tc-error">{errors && errors.mdselect}</span>
                </div>
              </div>
            ) : (
              <div>
                <div className="row mb-4">
                  <div className="mb-2 d-flex justify-content-between">
                    <p>MD NAME</p>
                    <p>
                      Total Incidental MDs:{" "}
                      {universalMDOptions && universalMDOptions?.length > 0
                        ? universalMDOptions?.length
                        : 0}
                    </p>
                  </div>
                  <div className="col-12">
                    <Select
                      name="selectedIncidentalMd"
                      defaultValue={selectedIncidentalMd}
                      onChange={(e) =>
                        handleChangeValues(e, "option", "selectedIncidentalMd")
                      }
                      options={universalMDOptions}
                      className={`tc-custom-select 
                      ${
                        errors && errors.selectedIncidentalMd
                          ? "tc-custom-select-error"
                          : ""
                      }
                      `}
                      classNamePrefix="select"
                      placeholder="Select"
                    />
                    <span className="tc-error">
                      {errors && errors.selectedIncidentalMd}
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div className="text-center">
              <Button
                variant="primary"
                onClick={() =>
                  match === "existing"
                    ? checkSelectedMd()
                    : handleMatchMDSignature()
                }
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {selectedMDCallDates?.length > 0 && showModal && (
        <MonthlyCallDates
          show={showModal}
          onHide={() => setShowModal(false)}
          selectedMD={selectedMD}
          disableMDs={disableMDs}
          holidays={holidayList}
          selectedMDCallDates={selectedMDCallDates}
          handleCallActivitiesOfMD={handleCallActivitiesOfMD}
        />
      )}
    </>
  );
};

export default MatchSignature;
