import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DashboardIcon } from "../../icon/icon";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { Button, Table } from "react-bootstrap";
import TimeInOutModal from "./TimeInOutModal";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";
import {
  Loader,
  TimeInOutTMDetails,
  CustomImageCapture,
} from "../../components";
import {
  OfflineModal,
  Confirmation,
  SignSelfieModal,
} from "../../components/Modals";
import {
  checkSystemWebcam,
  dateFormatWithTime,
  defaultTimeFormat,
  dateFormatting2,
  getLocationCoordinates,
  getTimeDifference,
  mediaToBase64,
  compressMediaFiles,
} from "../../helpers/commonFunction";
import { submitTimeInOutDataWhenOnline } from "../../helpers/offlineAPIs";
import { reqToDeleteTimeInOut } from "../../store/slice/timeInOutSlice";
import { updateUserState } from "../../store/slice/userSlice";
import { routes } from "../../helpers/constants";
import { insertEntryInDB, getAllEntriesFromDB } from "../../helpers/db";
import { isDesktop } from "react-device-detect";
import { useFetchAndSyncData } from "../../hooks";

const TimeInOut = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { fetchLoader } = useSelector((state) => state.offlineReducer);

  const inputRef = useRef();
  const signPadRef = useRef();
  const webcamRef = useRef(null);
  const [timeInOutData, setTimeInOutData] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentType, setCurrentType] = useState("");
  const [isDisabledIBOBBtn, setIsDisabledIBOBBtn] = useState(false);
  const [timeOutBtnDisable, setTimeOutBtnDisable] = useState(false);
  const [timeDeleteModal, setTimeDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState("");
  const [selectedDayOpt, setSelectedDayOpt] = useState({
    value: "Whole Day",
    label: "Whole Day",
  });
  const [selectedOption, setSelectedOption] = useState("IB");
  const [signSelfieModal, setSignSelfieModal] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [signatureData, setSignatureData] = useState("");
  const [signTimeCount, setSignTimeCount] = useState({
    eraseCount: 0,
    signStart: "",
    signEnd: "",
    signDrawTime: 0,
  });
  const [selfieUrl, setSelfieUrl] = useState();
  const [selfieData, setSelfieData] = useState("");
  const [signUrl, setSignUrl] = useState();
  const [errors, setErrors] = useState({});
  const dayOptions = [
    { value: "Whole Day", label: "Whole Day" },
    { value: "Half Day", label: "Half Day" },
  ];
  const [locationError, setLocationError] = useState("");
  const [locationModal, setLocationModal] = useState(false);
  const handleLocationModal = (item) => {
    if (item) {
      setLocationError(item);
    } else {
      setLocationError("");
    }
    setLocationModal(!locationModal);
  };

  useEffect(() => {
    let timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(now);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const handleGetInitialData = async () => {
      const [timeInOut] = await Promise.all([getAllEntriesFromDB("timeInOut")]);
      setTimeInOutData(timeInOut);
      if (timeInOut && timeInOut?.length >= 2) {
        setIsDisabledIBOBBtn(true);
        setTimeOutBtnDisable(true);
        setSelectedOption(timeInOut[0]?.time_type === "IB" ? "IB" : "OB");
      } else if (timeInOut && timeInOut?.length >= 1) {
        setIsDisabledIBOBBtn(true);
        setTimeOutBtnDisable(false);
        setSelectedOption(timeInOut[0]?.time_type === "IB" ? "IB" : "OB");
      } else {
        setIsDisabledIBOBBtn(false);
        setTimeOutBtnDisable(false);
      }
    };
    handleGetInitialData();
  }, [user]);

  const {
    syncType,
    setSyncType,
    syncMessage,
    offlineModal,
    confirmationModal,
    setConfirmationModal,
    handleOfflineModal,
    handleConfirmModal,
    uploadAndFetchData,
  } = useFetchAndSyncData();

  const handleShowDeleteModal = (type) => {
    if (type === "IN" && timeInOutData?.length === 2) {
      toast.warning("Please remove first Time Out Details");
    } else {
      setDeleteType(type);
      setTimeDeleteModal(true);
    }
  };
  const removeTimeFromTable = (type) => {
    const data = {
      type: type,
      date: dateFormatWithTime(new Date()),
    };
    dispatch(
      reqToDeleteTimeInOut({
        data,
        toast: `Time ${type} Removed Successfully`,
        cb: (err, res) => {
          if (err) {
          } else {
            setTimeDeleteModal(false);
            if (type === "IN") {
              dispatch(
                updateUserState({
                  ...user,
                  time_in: false,
                  date: dateFormatWithTime(new Date()),
                })
              );
            }
          }
        },
      })
    );
  };

  const clear = () => {
    if (!signPadRef?.current?.isEmpty()) {
      setSignTimeCount({
        ...signTimeCount,
        eraseCount: signTimeCount.eraseCount + 1,
        signDrawTime: 0,
        signStart: "",
        signEnd: "",
      });
    }
    signPadRef?.current?.clear();
    setSignatureData("");
  };
  const handleSignBegin = () => {
    setSignTimeCount({
      ...signTimeCount,
      signStart: new Date(),
    });
  };
  const handleSignEnd = () => {
    if (!signPadRef?.current?.isEmpty()) {
      setErrors({
        ...errors,
        signErr: "",
      });
    }
    setSignatureData(signPadRef?.current?.toDataURL());

    // Get sign draw timeing
    const timeDiff = getTimeDifference(signTimeCount.signStart, new Date());
    setSignTimeCount({
      ...signTimeCount,
      signEnd: new Date(),
      signDrawTime: signTimeCount.signDrawTime + timeDiff,
    });
  };

  // Handle selfie and Sign
  const handleResetData = (typeSign) => {
    if (typeSign) {
      setSignUrl("");
      setSignatureData("");
    } else {
      if (isDesktop) {
        setSelfieUrl("");
        setSelfieData("");
      } else {
        inputRef.current.click();
        setSignSelfieModal(false);
        setIsSign(false);
        setSelfieUrl("");
        setSelfieData("");
      }
    }
  };
  const handleUserTimeInOut = () => {
    setSyncType("");
    setConfirmationModal(false);
    if (
      timeInOutData &&
      timeInOutData?.length > 0 &&
      timeInOutData[0]?.type === "IN"
    ) {
      openSignSelfieModal();
      setCurrentType("OUT");
    } else {
      openSignSelfieModal();
      setCurrentType("IN");
    }
  };
  const openSignSelfieModal = async () => {
    await checkSystemWebcam()
      .then(() => {
        if (isDesktop) {
          setSignSelfieModal(true);
          setIsSign(false);
        } else {
          inputRef.current.click();
        }
      })
      .catch(() => {});
  };
  const closeSignSelfieModal = () => {
    setSignSelfieModal(false);
    setIsSign(false);
    setErrors({
      ...errors,
      signErr: "",
    });
    signPadRef?.current?.clear();
    setSignUrl("");
    setSignatureData("");
    setSelfieUrl("");
    setSelfieData("");
  };
  const checkValidation = () => {
    if (!signatureData) {
      setErrors({
        ...errors,
        signErr: "Signature is required!",
      });
      return false;
    } else {
      setErrors({
        ...errors,
        signErr: "",
      });
      return true;
    }
  };
  const handleSaveSignSelfie = (signature) => {
    if (signature) {
      if (checkValidation()) {
        const canvas = signPadRef.current.toDataURL("image/png");
        const blobData = dataURLtoBlob(canvas);
        setSignUrl(blobData && URL.createObjectURL(blobData));
        setSignSelfieModal(false);
        handleSubmitUserTimeInOut(currentType, blobData);
      }
    } else {
      handleGetSelfie();
    }
  };
  const handleGetSelfie = (event) => {
    if (isDesktop) {
      const imageSrc = webcamRef?.current?.getScreenshot();
      if (imageSrc && imageSrc) {
        const blobData = dataURLtoBlob(imageSrc);
        setSelfieUrl(blobData && URL.createObjectURL(blobData));
        setSelfieData(blobData);
      }
    } else {
      const file =
        event?.target?.files &&
        event?.target?.files?.length > 0 &&
        event?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          // const blobData = await compressMediaFiles(file);
          const blobData = dataURLtoBlob(reader.result);
          setSelfieUrl(blobData && URL.createObjectURL(blobData));
          setSelfieData(blobData);
          setSignSelfieModal(true);
          setIsSign(false);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // Handle submit data
  const checkIsTimeInOb = () => {
    if (
      timeInOutData &&
      timeInOutData?.length === 1 &&
      timeInOutData[0]?.time_type === "OB"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleSubmitUserTimeInOut = async (type, blob) => {
    await getLocationCoordinates()
      .then((res) => {
        handleUserTimeInOutOBJ(res, type, blob);
      })
      .catch(() => {
        handleUserTimeInOutOBJ("", type, blob);
      });
  };
  const handleUserTimeInOutOBJ = async (location, type, blob) => {
    const selfie = selfieData?.size
      ? await mediaToBase64(selfieData)
      : selfieData;
    const sing = blob?.size ? await mediaToBase64(blob) : blob;
    const currDate = dateFormatWithTime(new Date());
    const data = {
      location: null,
      latitude: location && location?.latitude ? location?.latitude : null,
      longitude: location && location?.longitude ? location?.longitude : null,
      type: type,
      time_type: checkIsTimeInOb() ? "OB" : selectedOption,
      day_type: checkIsTimeInOb()
        ? timeInOutData[0]?.day_type
        : selectedOption === "OB"
        ? selectedDayOpt.value
        : null,
      signature_image: sing,
      selfie_image: selfie,
      timein_id: "",
      users_id: user?.id,
      date: currDate,
      created_at: currDate,
      firstname: user?.firstname,
      lastname: user?.lastname,
      territory_name: user?.territory_name,
      terr_code: user?.terr_code,
      timein_date: type === "IN" ? currDate : user.timein_date,
      timeout_date: type === "OUT" ? currDate : "",
    };
    const addedDataID = await insertEntryInDB("timeInOut", data);
    await insertEntryInDB("addedTimeInOut", {
      ...data,
      id: addedDataID,
    });
    setSignatureData("");
    setSignUrl("");
    setSelfieUrl("");
    setSelfieData("");
    signPadRef?.current?.clear();
    if (type === "IN") {
      dispatch(
        updateUserState({
          ...user,
          time_in: true,
          timein_date: currDate,
        })
      );
      navigate(routes.dashboard);
    } else if (type === "OUT") {
      dispatch(
        updateUserState({
          ...user,
          time_in: true,
          timeout_date: currDate,
        })
      );
      navigate(routes.sync);
    }
    if (window.navigator.onLine) {
      const [addedTimeInOut] = await Promise.all([
        getAllEntriesFromDB("addedTimeInOut"),
      ]);
      await submitTimeInOutDataWhenOnline(
        dispatch,
        addedTimeInOut,
        "addTimeInOut"
      )
        .then(() => {})
        .catch(() => {});
    }
  };

  return (
    <>
      {fetchLoader && <Loader />}
      <div className="tc-body tc-body-without-ft">
        <div className="tc-navbar-back-text">
          <div className="tc-navbar-right-btn"></div>
          <p className="tc-navbar-title">Time In/Out</p>
          <div
            className="tc-navbar-right-side-btn logout-btn"
            role="button"
            onClick={() => uploadAndFetchData("logout")}
          >
            Logout
          </div>
        </div>
        <div className="mb-3">
          <TimeInOutTMDetails />
        </div>
        <div className="add-scroll p-0">
          <div className="tc-content-wrapper h-100">
            <div className="d-flex gap-3 h-100 align-items-center">
              <div className="">
                <div className="time-in-time-out-input-container mb-3">
                  <div className="time-main-container">
                    <div
                      className={`time-container time-container-options ${
                        isDisabledIBOBBtn && "disable-time-options"
                      }`}
                    >
                      <div className="time-options">
                        <label>
                          <input
                            type="radio"
                            className="time-option-radio form-check-input off"
                            name="time-option"
                            checked={selectedOption === "IB"}
                            onChange={() => setSelectedOption("IB")}
                          />
                          IB
                        </label>
                        <label>
                          <input
                            type="radio"
                            className="time-option-radio form-check-input on"
                            name="time-option"
                            checked={selectedOption === "OB"}
                            onChange={() => setSelectedOption("OB")}
                          />
                          OB
                        </label>
                      </div>
                      {selectedOption === "OB" ? (
                        <Select
                          name="option"
                          value={selectedDayOpt}
                          onChange={(e) => setSelectedDayOpt(e)}
                          options={dayOptions}
                          classNamePrefix="select"
                          className="tc-custom-select"
                          placeholder="Select..."
                        />
                      ) : (
                        <div style={{ width: "150px", height: "38px" }}></div>
                      )}
                    </div>
                  </div>
                  <div className="time-in-time-out-input-content">
                    <div className="time-main-container">
                      <div className="time-container">
                        <h1 className="live-time">
                          {user && user?.timeout_date
                            ? moment
                                .utc(user?.timeout_date)
                                .format("hh:mm:ss A")
                            : defaultTimeFormat(currentTime)}
                        </h1>
                        <strong className="fw-semibold fs-5 live-date">
                          {dateFormatting2(currentTime)}
                        </strong>
                      </div>
                    </div>
                  </div>
                  {timeInOutData && timeInOutData?.length > 0 && (
                    <div className="tc-timeinout-bottom-btn">
                      <Button
                        variant="primary"
                        type="button"
                        className="btn-min"
                        onClick={() => navigate(routes.dashboard)}
                      >
                        {DashboardIcon}
                        Dashboard
                      </Button>
                    </div>
                  )}
                </div>

                {!isDesktop && (
                  <CustomImageCapture
                    inputRef={inputRef}
                    handleFileChange={handleGetSelfie}
                  />
                )}
              </div>
              <div className="w-100">
                <div className="w-100 p-3">
                  <div className="tc-table-wrapper mb-4">
                    <Table responsive className="tc-table tc-time-table">
                      <thead>
                        <tr>
                          <th>DATE</th>
                          <th>TIME IN</th>
                          <th>LOCATION</th>
                          {/* {timeInOutData &&
                          timeInOutData[0] &&
                          timeInOutData[0]?.type === "IN" ? (
                            <th>
                              <button
                                onClick={() => handleShowDeleteModal("IN")}
                              >
                                <img
                                  src={""}
                                  alt="cross"
                                  style={{ height: "30px", width: "50px" }}
                                  className="object-fit-contain"
                                />
                              </button>
                            </th>
                          ) : (
                            ""
                          )} */}
                        </tr>
                      </thead>
                      <tbody>
                        {timeInOutData && timeInOutData?.length > 0 ? (
                          timeInOutData
                            .filter((item) => item?.type === "IN")
                            .map((item, index) => {
                              if (index === 0 && item?.type === "IN") {
                                return (
                                  <tr key={index}>
                                    <td className="min-width-150">
                                      {item?.date
                                        ? moment
                                            .utc(item?.date)
                                            .format("MM-DD-YYYY")
                                        : ""}
                                    </td>
                                    <td className="min-width-150">
                                      {item?.date
                                        ? moment
                                            .utc(item?.date)
                                            .format("hh:mm:ss A")
                                        : ""}
                                    </td>
                                    <td
                                      className={`${
                                        item?.location && "min-width-400"
                                      }`}
                                    >
                                      {item?.location
                                        ? item?.location
                                        : item?.latitude &&
                                          Number(item?.latitude) > 0
                                        ? "Not yet available"
                                        : "No Location Found"}
                                    </td>
                                  </tr>
                                );
                              }
                            })
                        ) : (
                          <tr>
                            <td className="min-width-150"></td>
                            <td className="min-width-150"></td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <div className="tc-table-wrapper">
                    <Table responsive className="tc-table tc-time-table">
                      <thead>
                        <tr>
                          <th>DATE</th>
                          <th>TIME OUT</th>
                          <th>LOCATION</th>
                          {/* {timeInOutData &&
                          timeInOutData[1] &&
                          timeInOutData[1]?.type === "OUT" ? (
                            <th>
                              <button
                                onClick={() => handleShowDeleteModal("OUT")}
                              >
                                <img
                                  src={""}
                                  alt="cross"
                                  style={{ height: "30px", width: "50px" }}
                                  className="object-fit-contain"
                                />
                              </button>
                            </th>
                          ) : (
                            ""
                          )} */}
                        </tr>
                      </thead>
                      <tbody>
                        {timeInOutData && timeInOutData?.length > 1 ? (
                          timeInOutData
                            .filter((item) => item?.type === "OUT")
                            .map((item, index) => {
                              if (index === 0 && item?.type === "OUT") {
                                return (
                                  <tr key={index}>
                                    <td className="min-width-150">
                                      {item?.date
                                        ? moment
                                            .utc(item?.date)
                                            .format("MM-DD-YYYY")
                                        : ""}
                                    </td>
                                    <td className="min-width-150">
                                      {item?.date
                                        ? moment
                                            .utc(item?.date)
                                            .format("hh:mm:ss A")
                                        : ""}
                                    </td>
                                    <td
                                      className={`${
                                        item?.location && "min-width-400"
                                      }`}
                                    >
                                      {item?.location
                                        ? item?.location
                                        : item?.latitude &&
                                          Number(item?.latitude) > 0
                                        ? "Not yet available"
                                        : "No Location Found"}
                                    </td>
                                  </tr>
                                );
                              }
                            })
                        ) : (
                          <tr>
                            <td className="min-width-150"></td>
                            <td className="min-width-150"></td>
                            <td></td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="btn-container mx-3">
                  <Button
                    variant="primary"
                    onClick={() => handleUserTimeInOut()}
                    disabled={timeOutBtnDisable}
                  >
                    SELFIE/SIGNATURE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tc-footer-blank"></div>
      </div>
      {signSelfieModal && (
        <SignSelfieModal
          show={signSelfieModal}
          clear={clear}
          isSign={isSign}
          setIsSign={setIsSign}
          errors={errors}
          signUrl={signUrl}
          selfieUrl={selfieUrl}
          webcamRef={webcamRef}
          signPadRef={signPadRef}
          signatureData={signatureData}
          handleSignEnd={handleSignEnd}
          handleSignBegin={handleSignBegin}
          handleResetData={handleResetData}
          closeSignSelfieModal={closeSignSelfieModal}
          handleSaveSignSelfie={handleSaveSignSelfie}
        />
      )}
      {timeDeleteModal && (
        <TimeInOutModal
          show={timeDeleteModal}
          onHide={() => setTimeDeleteModal(false)}
          deleteType={deleteType}
          removeTimeFromTable={removeTimeFromTable}
        />
      )}
      {offlineModal && (
        <OfflineModal show={offlineModal} onHide={() => handleOfflineModal()} />
      )}
      {confirmationModal && (
        <Confirmation
          show={confirmationModal}
          onHide={handleConfirmModal}
          title="Syncing Data"
          subTitle="Failed to sync data!"
          description="Please resync the data!"
          cancel="Cancel"
          save="Syncing"
          errorMessage={syncMessage}
          onSubmit={
            syncType ? uploadAndFetchData("logout") : handleUserTimeInOut
          }
        />
      )}
      {locationModal && (
        <Confirmation
          show={locationModal}
          onHide={handleLocationModal}
          type="location"
          save="Close"
          errorMessage={locationError}
          onSubmit={handleLocationModal}
        />
      )}
    </>
  );
};

export default TimeInOut;
