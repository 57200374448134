import React from "react";
import { Navbar2, Footer2 } from "./";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { RotatePhone } from "../components";

const MainLayout2 = () => {
  const user = useSelector((state) => state.userReducer.user);
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  return (
    <main className="tc-main-section">
      {user && user.user_role === "ADMIN" && (
        <RotatePhone show={!isLandscape} />
      )}
      <div
        className={`tc-container ${
          user && user.user_role === "ADMIN"
            ? "tc-admin-container"
            : "tc-dm-container"
        }`}
      >
        <Navbar2 />
        <div className="tc-body-wrapper">
          <Outlet />
        </div>
        <Footer2 />
      </div>
    </main>
  );
};

export default MainLayout2;
