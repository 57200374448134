import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { apiendpoints } from "../../helpers/constants";
import { messages } from "../../helpers/messages";

export const reqToGetTimeInOutData = createAsyncThunk(
  apiendpoints.getTimeInOut,
  async ({ data, onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getTimeInOut,
        data,
        header || authHeaders()
      );
      onSuccess(response);
      return response.data;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToAddTimeInOutData = createAsyncThunk(
  apiendpoints.addTimeInOut,
  async ({ data, onSuccess, onFailure }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addTimeInOut,
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToDeleteTimeInOut = createAsyncThunk(
  apiendpoints.deleteTimeInOut,
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.deleteTimeInOut,
        data.data,
        authHeaders()
      );
      if (response.status === 201 || (response.status === 200 && !data.cb)) {
        toast.success(data.toast, { autoClose: 3000 });
        return response.data;
      }
      if (data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const timeInOutSlice = createSlice({
  name: "timeInOut",
  initialState: {
    loader: false,
    timeInOut: [],
    addTime: "",
    deleteTime: "",
    currAddress: "",
    apiMessage: "",
  },
  reducers: {
    reqToResetTimeApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET TIMEINOUT
    builder
      .addCase(reqToGetTimeInOutData.pending, (state) => {
        state.apiMessage = messages.apiMessage.timeInOut;
      })
      .addCase(reqToGetTimeInOutData.fulfilled, (state, action) => {})
      .addCase(reqToGetTimeInOutData.rejected, (state, action) => {});

    // ADD TIMEINOUT
    builder
      .addCase(reqToAddTimeInOutData.pending, (state) => {})
      .addCase(reqToAddTimeInOutData.fulfilled, (state, action) => {})
      .addCase(reqToAddTimeInOutData.rejected, (state, action) => {});

    // DELETE TIMEINOUT
    builder
      .addCase(reqToDeleteTimeInOut.pending, (state) => {})
      .addCase(reqToDeleteTimeInOut.fulfilled, (state, action) => {})
      .addCase(reqToDeleteTimeInOut.rejected, (state, action) => {});
  },
});

export const { reqToResetTimeApiMessage } = timeInOutSlice.actions;
export default timeInOutSlice.reducer;
