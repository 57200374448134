import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllEntriesFromDB, updateEntryInDB } from "../../helpers/db";
import ViewNotifications from "./ViewNotifications";
import {
  AnnouncementsIcon,
  backBtnIcon,
  cakeIcon2,
  userIcon,
} from "../../icon/icon";
import { timeWithHoursFormat } from "../../helpers/commonFunction";
import { reqToReadNotification } from "../../store/slice/notificationSlice";
import { NotificationDetails } from "../../components";

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const [selectedView, setSelectedView] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [notificationList, setNotificationList] = useState({
    admin: [],
    dm: [],
    birthDay: [],
    announcements: [],
  });
  const [notificationCount, setNotificationCount] = useState({
    count: 0,
    ADMIN: 0,
    DM: 0,
  });
  const [notificationTypes, setNotificationTypes] = useState({
    admin: "",
    dm: "",
    birthDay: "",
    announcements: "",
  });
  const [selectedNotifiType, setSelectedNotifiType] = useState("");
  const [showViewNotification, setShowViewNotification] = useState(false);
  const handleShowViewNotification = () => {
    setShowViewNotification(!showViewNotification);
    if (showViewNotification === true) {
      setNotifications([]);
    }
  };

  useEffect(() => {
    const handleGetNotification = async () => {
      const [
        notificationCount,
        adminNotification,
        dmNotification,
        mdBirthNotification,
      ] = await Promise.all([
        getAllEntriesFromDB("notificationCount"),
        getAllEntriesFromDB("adminNotification"),
        getAllEntriesFromDB("dmNotification"),
        getAllEntriesFromDB("mdBirthNotification"),
      ]);
      setNotificationList({
        ...notificationList,
        admin: adminNotification,
        dm: dmNotification,
        birthDay: mdBirthNotification,
      });
      setNotificationCount({
        ...notificationCount,
        count: notificationCount,
        ADMIN:
          notificationCount && notificationCount?.length > 0
            ? notificationCount[0]?.ADMIN
            : 0,
        DM:
          notificationCount && notificationCount?.length > 0
            ? notificationCount[0]?.DM
            : 0,
      });
      setNotificationTypes({
        ...notificationTypes,
        admin:
          adminNotification && adminNotification?.length > 0
            ? adminNotification[0]
            : "",
        dm:
          dmNotification && dmNotification?.length > 0 ? dmNotification[0] : "",
        birthDay:
          mdBirthNotification && mdBirthNotification?.length > 0
            ? mdBirthNotification[0]?.month_birthday &&
              mdBirthNotification[0]?.month_birthday?.length > 0
              ? mdBirthNotification[0]?.month_birthday[0]
              : mdBirthNotification[0]?.weekly_birthday &&
                mdBirthNotification[0]?.weekly_birthday?.length > 0
              ? mdBirthNotification[0]?.weekly_birthday[0]
              : ""
            : "",
      });
      // if (adminNotification && adminNotification?.length > 0) {
      //   handleViewNotification("ADMIN", notificationCount, adminNotification);
      // }
      // if (location?.state?.type === "DM" && dmNotification?.length > 0) {
      //   handleViewNotification(
      //     location?.state?.type,
      //     notificationCount,
      //     dmNotification
      //   );
      // } else if (
      //   location?.state?.type === "ADMIN" &&
      //   adminNotification?.length > 0
      // ) {
      //   handleViewNotification(
      //     location?.state?.type,
      //     notificationCount,
      //     adminNotification
      //   );
      // }
    };
    handleGetNotification();
  }, []);

  const handleViewNotification = async (type, count, data) => {
    setSelectedView(type);
    setSelectedNotifiType(type);
    const newData =
      type === "ADMIN"
        ? data
          ? data
          : notificationList.admin
        : type === "DM"
        ? data
          ? data
          : notificationList.dm
        : type === "BIRTHDAY"
        ? data
          ? data
          : notificationList.birthDay
        : data
        ? data
        : notificationList.announcements;
    const newCount1 = count ? count : notificationCount.count;
    const newCount2 = newCount1 && newCount1?.length > 0 ? newCount1[0] : "";

    if (type === "BIRTHDAY") {
      setNotifications({
        month_birthday:
          newData && newData?.length > 0
            ? newData[0]?.month_birthday &&
              newData[0]?.month_birthday?.length > 0
              ? newData[0]?.month_birthday
              : []
            : [],
        weekly_birthday:
          newData && newData?.length > 0
            ? newData[0]?.weekly_birthday &&
              newData[0]?.weekly_birthday?.length > 0
              ? newData[0]?.weekly_birthday
              : []
            : [],
      });
    } else if (type === "ANNOUNCEMENTS") {
      setNotifications(newData);
    } else {
      setNotifications(newData);
      if (type === "ADMIN" && newCount2 && newCount2?.ADMIN > 0) {
        dispatch(reqToReadNotification({ data: { type: type } }));
        await updateEntryInDB("notificationCount", "id", newCount2.id, {
          ...newCount2,
          ADMIN: 0,
          DM: newCount2.DM,
        });
        setNotificationCount({
          ...notificationCount,
          ADMIN: 0,
          count: [{ ...newCount2, ADMIN: 0, DM: newCount2.DM }],
          DM: newCount2?.DM,
        });
      } else if (type === "DM" && newCount2 && newCount2?.DM > 0) {
        dispatch(reqToReadNotification({ data: { type: type } }));
        await updateEntryInDB("notificationCount", "id", newCount2.id, {
          ...newCount2,
          DM: 0,
          ADMIN: newCount2.ADMIN,
        });
        setNotificationCount({
          ...notificationCount,
          DM: 0,
          count: [{ ...newCount2, ADMIN: newCount2.ADMIN, DM: 0 }],
          ADMIN: newCount2?.ADMIN,
        });
      }
    }
  };

  return (
    <>
      <div className="tc-body" id="scrollableDiv">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => navigate(-1)}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title">NOTIFICATIONS</p>
          <div className="tc-navbar-right-btn"></div>
        </div>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="tabs-contain-mainbox">
            <Row>
              <Col sm={4}>
                <div className="left-side-tabs">
                  <h2>NOTIFICATIONS</h2>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="ADMIN"
                        active={selectedView === "ADMIN"}
                        onClick={() => handleViewNotification("ADMIN")}
                      >
                        <div className="tc-notification-type-container">
                          <div className="tc-notification-content">
                            <div>{userIcon}</div>
                            <div className="tc-notification-left-content">
                              <div className="tc-notification-title">
                                <h4 className="mb-0">Admin</h4>
                              </div>
                              <div className="">
                                <span className="tc-notification-date">
                                  {notificationTypes.admin.created_at
                                    ? timeWithHoursFormat(
                                        notificationTypes.admin.created_at
                                      )
                                    : ""}
                                </span>
                              </div>
                            </div>
                            {notificationCount &&
                              notificationCount?.ADMIN > 0 && (
                                <div className="tc-n-notification">
                                  {notificationCount?.ADMIN}
                                </div>
                              )}
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="DM"
                        active={selectedView === "DM"}
                        onClick={() => handleViewNotification("DM")}
                      >
                        <div className="tc-notification-type-container">
                          <div className="tc-notification-content">
                            <div>{userIcon}</div>
                            <div className="tc-notification-left-content">
                              <div className="tc-notification-title">
                                <h4 className="mb-0">District Manager</h4>
                              </div>
                              <div className="">
                                {" "}
                                <span className="tc-notification-date">
                                  {notificationTypes.dm.created_at
                                    ? timeWithHoursFormat(
                                        notificationTypes.dm.created_at
                                      )
                                    : ""}
                                </span>
                              </div>
                            </div>
                            {notificationCount && notificationCount?.DM > 0 && (
                              <div className="tc-n-notification">
                                {notificationCount?.DM}
                              </div>
                            )}
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="BIRTHDAY"
                        active={selectedView === "BIRTHDAY"}
                        onClick={() => handleViewNotification("BIRTHDAY")}
                      >
                        <div className="tc-notification-type-container">
                          <div className="tc-notification-content">
                            <div>{cakeIcon2}</div>
                            <div className="tc-notification-left-content">
                              <div className="tc-notification-title">
                                <h4 className="mb-0">MD Birthdays</h4>
                              </div>
                              <div className="">
                                <span className="tc-notification-date">
                                  {notificationTypes.birthDay.created_at
                                    ? timeWithHoursFormat(
                                        notificationTypes.birthDay.created_at
                                      )
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="ANNOUNCEMENTS"
                        active={selectedView === "ANNOUNCEMENTS"}
                        onClick={() => handleViewNotification("ANNOUNCEMENTS")}
                      >
                        <div className="tc-notification-type-container">
                          <div className="tc-notification-content">
                            <div>{AnnouncementsIcon}</div>
                            <div className="tc-notification-left-content">
                              <div className="tc-notification-title">
                                <h4 className="mb-0">Announcements</h4>
                              </div>
                              <div className="">
                                <span className="tc-notification-date">
                                  {notificationTypes.announcements?.created_at
                                    ? timeWithHoursFormat(
                                        notificationTypes.announcements
                                          ?.created_at
                                      )
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Col>
              <Col sm={8}>
                <div className="right-side-tabs">
                  <Tab.Content>
                    <Tab.Pane
                      eventKey="ADMIN"
                      active={selectedView === "ADMIN"}
                    >
                      <NotificationDetails
                        user={user}
                        notifications={notifications}
                        selectedNotifiType={selectedNotifiType}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="DM" active={selectedView === "DM"}>
                      <NotificationDetails
                        user={user}
                        notifications={notifications}
                        selectedNotifiType={selectedNotifiType}
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="BIRTHDAY"
                      active={selectedView === "BIRTHDAY"}
                    >
                      <NotificationDetails
                        user={user}
                        notifications={notifications}
                        selectedNotifiType={selectedNotifiType}
                      />
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="ANNOUNCEMENTS"
                      active={selectedView === "ANNOUNCEMENTS"}
                    >
                      <NotificationDetails
                        user={user}
                        notifications={notifications}
                        selectedNotifiType={selectedNotifiType}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </div>
        </Tab.Container>
      </div>
      {showViewNotification && (
        <ViewNotifications
          user={user}
          show={showViewNotification}
          onHide={handleShowViewNotification}
          notifications={notifications}
          selectedNotifiType={selectedNotifiType}
        />
      )}
    </>
  );
};

export default Notifications;
