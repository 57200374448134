import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { notify } from "../../helpers/commonFunction";

const ImportDataModal = (props) => {
  const { onSubmit, ...rest } = props;
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };
  const handleSubmit = () => {
    if (file) {
      onSubmit("import", file);
    } else {
      notify("No file selected!", "error");
    }
  };
  return (
    <Modal
      {...rest}
      size="lg"
      className="tc-main-modal tc-sign-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-dark">
          Import Data
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-detail-content">
          <div className="modal-detail-main-text">
            <input type="file" accept=".json" onChange={handleFileChange} />
          </div>
        </div>
        <div className="modal-btn-row">
          <Button variant="danger" type="button" onClick={() => props.onHide()}>
            CANCEL
          </Button>
          <Button
            variant="primary"
            type="button"
            className="ms-2"
            onClick={() => handleSubmit()}
          >
            Import
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImportDataModal;
