import React, { useEffect, useState, lazy, Suspense } from "react";
import { Modal, Button } from "react-bootstrap";
import { Slide } from "react-slideshow-image";
import Loader from "../Loader";
import { PDFIcon } from "../../images";
const PDFViewer = lazy(() => import("../PDFViewer"));

const ProductDetailsModal = (props) => {
  const { productOption, handleSubmitProductDetailing, ...rest } = props;

  const [pdfList, setPdfList] = useState([]);
  const [sProduct, setSProduct] = useState("");
  const [currentPDF, setCurrentPDF] = useState(0);
  const [sSubProduct, setSSubProduct] = useState("");

  useEffect(() => {
    setSProduct(productOption[0]);
  }, []);

  const handleSlideValue = (prev, curr) => {
    setSProduct(productOption[curr]);
    setSSubProduct("");
    setCurrentPDF(curr);
  };

  const properties = {
    indicators: true,
    onChange: handleSlideValue,
  };

  const handleSelectSubProduct = async (item) => {
    setSSubProduct(item);
  };

  return (
    <Modal
      {...rest}
      centered
      className="tc-main-modal tc-product-pdf-modal"
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="text-dark">
          <h3>{sProduct && sProduct?.name}</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0 pb-0">
        <Slide
          {...properties}
          cssClass="h-100"
          autoplay={false}
          transitionDuration={200}
          arrows={productOption && productOption?.length > 1}
          indicators={productOption && productOption?.length > 1}
        >
          {productOption.map((slideImage, index) => {
            return (
              <div key={index}>
                {((slideImage.pdf_url.endsWith(".pdf") &&
                  (currentPDF === index ||
                    currentPDF === index - 1 ||
                    pdfList?.includes(index))) ||
                  !slideImage?.pdf_url.endsWith(".pdf")) && (
                  <>
                    {sSubProduct ? (
                      <>
                        {sSubProduct &&
                        sSubProduct?.pdf_url.includes(".pdf") ? (
                          <div className="tc-product-modal-img-wrapper">
                            <Suspense fallback={<Loader />}>
                              <PDFViewer
                                index={index}
                                pdfList={pdfList}
                                setPdfList={setPdfList}
                                url={
                                  sSubProduct?.base64
                                    ? sSubProduct?.base64
                                    : sSubProduct?.pdf_url
                                }
                              />
                            </Suspense>
                          </div>
                        ) : (
                          <div className="tc-product-modal-img-wrapper">
                            <img
                              src={
                                sSubProduct?.base64
                                  ? sSubProduct?.base64
                                  : sSubProduct?.pdf_url
                              }
                              className="img-fluid tc-product-modal-img"
                              alt="product details image"
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {slideImage && slideImage?.pdf_url.includes(".pdf") ? (
                          <div
                            className={`tc-product-modal-img-wrapper ${
                              (!sProduct ||
                                (sProduct && !sProduct?.subProduct)) &&
                              "tc-full-img-height"
                            }`}
                          >
                            <Suspense fallback={<Loader />}>
                              <PDFViewer
                                index={index}
                                pdfList={pdfList}
                                setPdfList={setPdfList}
                                url={
                                  slideImage?.base64
                                    ? slideImage?.base64
                                    : slideImage?.pdf_url
                                }
                              />
                            </Suspense>
                          </div>
                        ) : (
                          <div
                            className={`tc-product-modal-img-wrapper ${
                              (!sProduct ||
                                (sProduct && !sProduct?.subProduct)) &&
                              "tc-full-img-height"
                            }`}
                          >
                            <img
                              src={
                                slideImage?.base64?.includes("base64")
                                  ? slideImage?.base64
                                  : slideImage?.pdf_url
                              }
                              className="img-fluid tc-product-modal-img"
                              alt="product details image"
                            />
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </Slide>
        {sProduct && sProduct.subProduct?.length > 0 ? (
          <div className="tc-sub-product-img">
            {sProduct &&
              sProduct.subProduct?.length > 0 &&
              sProduct.subProduct.map((subProduct, subPIndex) => {
                let imageName =
                  subProduct.pdf_url &&
                  subProduct.pdf_url.split("product-images/");
                return (
                  <div
                    key={subPIndex}
                    role="button"
                    onClick={() => handleSelectSubProduct(subProduct)}
                  >
                    {subProduct && subProduct.pdf_url.includes(".pdf") ? (
                      <>
                        <img src={PDFIcon} alt="sub product details image" />
                        <p>
                          {imageName && imageName.length > 0 && imageName[1]}
                        </p>
                      </>
                    ) : (
                      <>
                        <img
                          src={
                            subProduct?.base64?.includes("base64")
                              ? subProduct?.base64
                              : subProduct?.pdf_url
                          }
                          alt="sub product details image"
                        />
                        <p>
                          {imageName && imageName.length > 0 && imageName[1]}
                        </p>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center pt-2">
        <Button
          variant="primary"
          type="button"
          size="lg"
          onClick={() => handleSubmitProductDetailing(true)}
        >
          Detailing Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductDetailsModal;
