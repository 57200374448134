import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { reqToUserLogout } from "../store/slice/userSlice";
import { useLocation } from "react-router-dom";
import { routes } from "../helpers/constants";

const Navbar2 = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userReducer =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const [currReqType, setCurrReqType] = useState("");
  useEffect(() => {
    setInterval(() => {
      const type = localStorage.getItem("approval_type");
      setCurrReqType(type);
    }, 100);
  }, [currReqType]);

  const handleUserLogout = () => {
    dispatch(reqToUserLogout({ data: "", onSuccess: () => {} }));
  };

  const getTitle = () => {
    if (
      location?.pathname === routes.approvalItinerary ||
      location?.pathname === routes.adminApprovalItinerary
    ) {
      if (
        userReducer &&
        userReducer?.user_role === "DM" &&
        currReqType === "ITINERARY"
      ) {
        return "APPROVAL OF ITINERARY CHANGES FOR NEXT MONTH";
      } else if (
        userReducer &&
        userReducer?.user_role === "DM" &&
        currReqType === "MISSEDCALLS"
      ) {
        return "APPROVAL OF MISSED CALLS";
      } else {
        return "APPROVAL OF ITINERARY CHANGES FOR NEXT MONTH";
      }
    } else if (location?.pathname === routes.calls) {
      return "Calls";
    } else if (location?.pathname === routes.auditReport) {
      return "AUDIT REPORT";
    } else if (location?.pathname === routes.others) {
      return "Data Conversion and Itinerary Creation";
    } else {
      return "APPROVAL OF MISSED CALLS";
    }
  };

  return (
    <div className="tc-navbar-back-text">
      <div className="tc-navbar-back-btn"></div>
      <p className="tc-navbar-title text-uppercase">{getTitle()}</p>
      <div
        className="tc-navbar-right-side-btn logout-btn"
        role="button"
        onClick={() => handleUserLogout()}
      >
        Logout
      </div>
    </div>
  );
};

export default Navbar2;
