import React from "react";
import { Modal } from "react-bootstrap";
import { BirthdayIcon } from "../../images";
import { nameFormatting } from "../../helpers/commonFunction";

const BirthDayModal = (props) => {
  const { selectedMD, compareDates, ...rest } = props;
  const handleGetMDBirthYear = () => {
    return (
      selectedMD && `Happy Birthday, Dr. ${nameFormatting(selectedMD, "FL")}!`
    );
  };

  return (
    <Modal
      className="tc-main-modal tc-md-birthday-modal"
      {...rest}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton style={{ borderRadius: "30px" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          MD Birthday
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="modal-detail-content">
          <img src={BirthdayIcon} alt="MD Birthday" />
          <span className="mt-3 mb-4">{handleGetMDBirthYear()}</span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BirthDayModal;
