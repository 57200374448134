import React from "react";
import { Button, Modal } from "react-bootstrap";

const TimeInOutModal = (props) => {
  const { to, removeTimeFromTable, deleteType, ...rest } = props;
  return (
    <>
      <Modal
        className="tc-main-modal tc-sign-modal"
        {...rest}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Time In/Out, Location Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <p className="col-12 modal-title-p">
              Are you sure you want to empty the Date,time & location?
            </p>
          </div>
          <div className="text-center">
            <Button
              variant="primary"
              type="button"
              onClick={() => removeTimeFromTable(deleteType)}
            >
              REMOVE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TimeInOutModal;
