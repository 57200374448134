import React from "react";
import { routes } from "../helpers/constants";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  dataManagementIcon,
  endOfDayIcon,
  endOfMonthIcon,
  itineraryIcon,
  missedCallIcon,
  otherIcon,
  DashboardIcon,
  assessmentIcon,
  devPlanIcon,
  reviewIcon,
  approvalIcon,
  attendanceIcon,
  auditReport,
} from "../icon/icon";

const Footer2 = () => {
  const location = useLocation();
  const userReducer = useSelector((state) => state.userReducer.user);
  const userRole = userReducer && userReducer.user_role;

  const getFooterLinks = () => {
    if (userRole === "ADMIN") {
      return [
        {
          name: "ITINERARY",
          icon: itineraryIcon,
          link: routes.adminApprovalItinerary,
          disabled: false,
        },
        {
          name: "MISSED CALLS",
          icon: missedCallIcon,
          link: routes.adminMissCalls,
          disabled: false,
        },
        {
          name: "DATA MGT",
          icon: dataManagementIcon,
          link: "",
          disabled: true,
        },
        {
          name: "PORTAL",
          icon: endOfDayIcon,
          link: routes.calls,
          disabled: false,
        },
        {
          name: "EOM",
          icon: endOfMonthIcon,
          link: "",
          disabled: true,
        },
        {
          name: "AUDIT REPORT",
          icon: auditReport,
          link: routes.auditReport,
          disabled: false,
        },
        {
          name: "OTHERS",
          icon: otherIcon,
          link: routes.others,
          disabled: false,
        },
      ];
    } else {
      return [
        {
          name: "DASHBOARD",
          icon: DashboardIcon,
          link: "",
          disabled: true,
        },
        {
          name: "ASSESSMENT",
          icon: assessmentIcon,
          link: "",
          disabled: true,
        },
        {
          name: "DEV PLAN",
          icon: devPlanIcon,
          link: "",
          disabled: true,
        },
        {
          name: "REVIEW",
          icon: reviewIcon,
          link: "",
          disabled: true,
        },
        {
          name: "FOR APPROVAL",
          icon: approvalIcon,
          link: routes.approvalItinerary,
          disabled: false,
        },
        {
          name: "ATTENDANCE",
          icon: attendanceIcon,
          link: "",
          disabled: true,
        },
      ];
    }
  };

  return (
    <div
      className={`tc-footer ${
        userRole === "ADMIN" ? "tc-admin-footer" : "tc-dm-footer"
      }`}
    >
      <div className="tc-footer-content">
        {getFooterLinks().map((item, i) => {
          return (
            <Link
              to={item.link}
              className={`tc-footer-tab ${
                location.pathname === item.link && "active"
              } ${item.disabled && "pe-none"}`}
              key={i}
            >
              {item.icon}
              {item.name}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Footer2;
