import { useState, useEffect } from "react";

const useKeyboardDetection = () => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [initialViewportHeight, setInitialViewportHeight] = useState(
    window.innerHeight
  );

  useEffect(() => {
    setInitialViewportHeight(window.innerHeight);
    const checkKeyboardStatus = () => {
      const currentViewportHeight = window.innerHeight;
      if (currentViewportHeight >= initialViewportHeight) {
        setIsKeyboardVisible(false);
        document.body.style.height = `${initialViewportHeight}px`;
        window.scrollTo(0, 0);
      } else {
        setIsKeyboardVisible(true);
      }
    };
    const keyboardCheckInterval = setInterval(checkKeyboardStatus, 100);
    return () => clearInterval(keyboardCheckInterval);
  }, [initialViewportHeight]);

  return isKeyboardVisible;
};

export default useKeyboardDetection;
