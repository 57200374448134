import React, { useEffect, useState } from "react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import { messages } from "../../helpers/messages";
import { SearchIcon, backBtnIcon } from "../../icon/icon";
import {
  defaultDateFormat,
  getSortData,
  handleVisits,
  nameFormatting,
} from "../../helpers/commonFunction";
import { MonthlyCallDates } from "../../components/Modals";
import { DataNotFound, CustomDateInput } from "../../components";

const SummaryItineraryList = (props) => {
  const {
    minDate,
    maxDate,
    selectedDate,
    returnBackBtn,
    selectedSummary,
    setSelectedDate,
    itineraryMethod,
    summaryReportData,
    getItineraryMDsFromDB,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [selectedMD, setSelectedMD] = useState("");
  const [selectedMDCallDates, setSelectedMDCallDates] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [newMdListData, setNewMdListData] = useState([]);
  const [newMdListData2, setNewMdListData2] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (newMdListData && newMdListData?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [newMdListData]);

  useEffect(() => {
    handleSearchMDList();
  }, []);

  const handleSearchMDList = async (searchBy, value) => {
    if (searchBy === "name") {
      const newList =
        newMdListData2 &&
        newMdListData2?.filter(
          (item) =>
            `${item.lastname.trim()} ${item.firstname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            `${item.firstname.trim()} ${item.lastname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase())
        );
      const finalList =
        newList?.length > 0 ? newList : !value ? summaryReportData : [];
      const sortData = getSortData(finalList, "lastname");
      setNewMdListData(sortData);
    } else if (searchBy === "date") {
      setSelectedDate(value);
      const newList = getItineraryMDsFromDB.filter(
        (call) => call.date === value
      );
      const sortData = getSortData(newList, "lastname");
      setNewMdListData(sortData);
    } else {
      const sortData = getSortData(summaryReportData, "lastname");
      setNewMdListData(sortData);
      setNewMdListData2(sortData);
    }
  };

  const handleOnChange = (event) => {
    if (event.target.value === "") {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    } else {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    }
  };

  const handleScheduleModal = (data) => {
    setShowModal(true);
    setSelectedMD(data);
    setSelectedMDCallDates(data.dates);
  };

  const getColumns = () => {
    const columns = [
      {
        name: "Class",
        sortable: false,
        selector: (row) => (row?.class_name ? row?.class_name : row?.name),
        cell: (row) => (
          <p className={`tc-data-table-class-text text-center w-100`}>
            {row?.class_name ? row.class_name : row?.name ? row?.name : "-"}
          </p>
        ),
      },
      {
        name: "Md Name",
        sortable: false,
        selector: (row) => row?.firstname.concat(" ", row?.lastname),
        cell: (row) => (
          <p
            role="button"
            className={`tc-data-table-md-name-text text-start min-width-300`}
            onClick={() => row?.dates && handleScheduleModal(row)}
          >
            {nameFormatting(row)}
          </p>
        ),
      },
      {
        name: "Frequency",
        sortable: false,
        selector: (row) =>
          row?.frequency_name ? row?.frequency_name : row?.target,
        cell: (row) => (
          <p className={`tc-data-table-class-text text-center w-100`}>
            {row?.frequency_name
              ? row?.frequency_name
              : row?.target
              ? `${row?.target}X`
              : "-"}
          </p>
        ),
      },
    ];
    if (selectedSummary && selectedSummary?.date) {
      let newData = columns.slice(0, 2);
      newData.push({
        name: "Visits",
        sortable: true,
        selector: (row) => handleVisits(row?.actual_visits, row?.target),
        cell: (row) => (
          <p className={`tc-data-table-visits-text tc-data-table-green-text`}>
            {handleVisits(row?.actual_visits, row?.target)}
          </p>
        ),
      });
      return newData;
    } else {
      return columns;
    }
  };

  return (
    <>
      <div className="tc-body pb-5">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => returnBackBtn()}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title text-uppercase">
            {selectedSummary.name}
          </p>
          <div className="tc-navbar-right-btn"></div>
        </div>
        <div className="add-scroll">
          <div className="tc-content-wrapper">
            {selectedSummary?.date && <h2>MDs TO VISIT</h2>}
            <div className="tc-calender-search-input-row justify-content-between">
              <div>
                {selectedSummary?.date && (
                  <>
                    <h6>DATE</h6>
                    <div className="tc-calender-input-content calendar-icon-md">
                      <div className="position-relative">
                        <ReactDatePicker
                          selected={moment(selectedDate)._d}
                          onChange={(e) =>
                            handleSearchMDList(
                              "date",
                              moment(e).format("YYYY-MM-DD")
                            )
                          }
                          id="date-picker-md"
                          dateFormat="yyyy-MM-dd"
                          value={defaultDateFormat(selectedDate)}
                          minDate={minDate}
                          maxDate={maxDate}
                          onKeyDown={(e) => e.preventDefault()}
                          customInput={<CustomDateInput />}
                        />
                      </div>
                    </div>
                  </>
                )}
                {itineraryMethod?.type === "MDCLASS" && (
                  <h5 className="m-0">
                    Total Number of MDs:{" "}
                    {newMdListData && newMdListData?.length > 0
                      ? newMdListData?.length
                      : "00"}
                  </h5>
                )}
              </div>
              <div className="d-flex flex-column align-items-end">
                <div className="ms-auto d-flex align-items-center">
                  <div className="tc-search-input-content d-flex">
                    <input
                      id="text"
                      type="text"
                      placeholder="Search..."
                      value={searchValue}
                      onChange={(e) => handleOnChange(e)}
                    />
                    <button
                      type="button"
                      className="tc-search-input-btn"
                      onClick={() => handleSearchMDList("name", searchValue)}
                      disabled={searchValue === "" && true}
                    >
                      {SearchIcon}
                    </button>
                  </div>
                </div>
                {selectedSummary?.date && (
                  <h6 className="mt-3 mb-0">
                    Total MDs:{" "}
                    {newMdListData && newMdListData?.length > 0
                      ? newMdListData?.length
                      : 0}
                  </h6>
                )}
              </div>
            </div>
            {isLoading ? null : newMdListData && newMdListData?.length === 0 ? (
              <DataNotFound name={messages.toast.noitinerary} />
            ) : (
              <DataTable
                columns={getColumns()}
                data={newMdListData && newMdListData}
                className="tc-data-table"
              />
            )}
          </div>
        </div>
      </div>

      <MonthlyCallDates
        show={showModal}
        onHide={() => setShowModal(false)}
        selectedMD={selectedMD}
        disableMDs={[]}
        holidays={[]}
        selectedMDCallDates={selectedMDCallDates}
        handleCallActivitiesOfMD={() => setShowModal(false)}
      />
    </>
  );
};

export default SummaryItineraryList;
