import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { db } from "../../helpers/db";
import { useLiveQuery } from "dexie-react-hooks";
import { nameFormatting } from "../../helpers/commonFunction";
import { relieverModalValidation } from "../../helpers/formValidations";
import { useForm } from "../../hooks";

const RelieverModal = (props) => {
  const {
    match,
    setMatch,
    selectedReliever,
    setSelectedReliever,
    handleRelieverMD,
    ...rest
  } = props;

  const getMDMasterListFromDB = useLiveQuery(
    () => db.mdMasterList.toArray(),
    []
  );
  const mdMasterListOptions =
    (getMDMasterListFromDB &&
      getMDMasterListFromDB?.length > 0 &&
      getMDMasterListFromDB.map((item) => {
        return {
          ...item,
          value: nameFormatting(item),
          label: nameFormatting(item),
        };
      })) ||
    [];

  const handleChangeMDType = (event) => {
    setMatch(event.target.value);
  };

  // Handle reliever MD
  const initialFValues = {
    oldMD: "",
    firstName: "",
    lastName: "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return relieverModalValidation(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors,
      match
    );
  };
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );
  useEffect(() => {
    if (match) {
      setErrors({
        ...errors,
        oldMD: "",
        firstName: "",
        lastName: "",
      });
    }
  }, [match]);

  const handleSaveRelieverMD = () => {
    if (validate()) {
      let newObj = "";
      if (match === "oldMD") {
        newObj = values.oldMD;
      } else {
        newObj = {
          md_id: "",
          lastname: values.lastName,
          firstname: values.firstName,
          id: "",
          value: values.lastName.concat(", ", values.firstName),
          label: values.lastName.concat(", ", values.firstName),
        };
      }
      setSelectedReliever(newObj);
      handleRelieverMD(true);
    }
  };

  return (
    <>
      <Modal
        className="tc-main-modal tc-quicksign-modal"
        {...rest}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="p-4">
          <Modal.Title id="contained-modal-title-vcenter">
            SIGNED BY:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 pt-0">
          <div className="space-y-2 mb-4">
            <div className="mb-3 d-flex justify-content-between">
              <p>MD NAME</p>
              <Form className="d-flex">
                <Form.Check
                  inline
                  id="oldMD"
                  name="oldMD"
                  value="oldMD"
                  label="MD List"
                  type={"radio"}
                  onChange={handleChangeMDType}
                  checked={match === "oldMD"}
                />
                <Form.Check
                  inline
                  id="newMD"
                  name="newMD"
                  value="newMD"
                  label="New MD"
                  type={"radio"}
                  checked={match === "newMD"}
                  onChange={handleChangeMDType}
                />
              </Form>
            </div>
            {match === "oldMD" ? (
              <div className="col-12">
                <Select
                  id="oldMD"
                  name="oldMD"
                  value={values.oldMD}
                  onChange={(e) => {
                    handleInputChange(e, "oldMD");
                    setErrors({ ...errors, oldMD: "" });
                  }}
                  options={mdMasterListOptions}
                  className="tc-custom-select"
                  classNamePrefix="select"
                  placeholder="Select"
                />
                <span className="tc-error">{errors && errors?.oldMD}</span>
              </div>
            ) : (
              <>
                <div className="mb-2">
                  <p className="fw-medium d-inline-block mb-1">FIRST NAME:</p>
                  <Form.Control
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter Firstname"
                    value={values?.firstName}
                    onChange={(e) => {
                      handleInputChange(e);
                      setErrors({ ...errors, firstName: "" });
                    }}
                  />
                  <span className="tc-error">
                    {errors && errors?.firstName}
                  </span>
                </div>
                <div className="mb-2">
                  <p className="fw-medium d-inline-block mb-1">LAST NAME:</p>
                  <Form.Control
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Enter Lastname"
                    value={values?.lastName}
                    onChange={(e) => {
                      handleInputChange(e);
                      setErrors({ ...errors, lastName: "" });
                    }}
                  />
                  <span className="tc-error">{errors && errors?.lastName}</span>
                </div>
              </>
            )}
          </div>
          <div className="text-center">
            <Button className="primary" onClick={() => handleSaveRelieverMD()}>
              Done
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RelieverModal;
