import React, { useEffect, useRef, useState } from "react";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import moment from "moment/moment";
import { Button, Card, Col, Row } from "react-bootstrap";
import {
  db,
  getEntryFromDB,
  insertEntryInDB,
  getAllEntriesFromDB,
} from "../../helpers/db";
import { deleteIcon2, cameraIcon2, refreshIcon3 } from "../../icon/icon";
import { messages } from "../../helpers/messages";
import {
  notify,
  getTimeDifference,
  mediaToBase64,
  checkSystemWebcam,
  compressMediaFiles,
} from "../../helpers/commonFunction";
import { getMediaFileFromDB } from "../../helpers/offlineAPIs";
import {
  Confirmation,
  SignZoomModal,
  MatchSignature,
  ImageCaptureModal,
} from "../../components/Modals";
import { CustomImageCapture } from "../../components";
import { isDesktop } from "react-device-detect";
import { useSelector } from "react-redux";

const QuickSign = () => {
  const { user } = useSelector((state) => state.userReducer);
  const inputRef = useRef();
  const signPadRef = useRef();
  const signPadRef2 = useRef();
  const [isUpdateSign, setIsUpdateSign] = useState(false);
  const [signatureData, setSignatureData] = useState("");
  const [errors, setErrors] = useState({
    mdSign: "",
  });
  const [signatureModal, setSignatureModal] = useState(false);
  const [quickSignList, setQuickSignList] = useState([]);
  const [newQuickSignList, setNewQuickSignList] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState("");
  const [showListView, setShowListView] = useState(true);
  const [showWebcam, setShowWebcam] = useState(false);
  const [screenShort, setScreenShort] = useState("");
  const [viewSignModal, setViewSignModal] = useState(false);
  const [match, setMatch] = useState("existing");
  const [signTimeCount, setSignTimeCount] = useState({
    eraseCount: 0,
    signStart: "",
    signEnd: "",
    signDrawTime: 0,
  });
  const [showModal, setModalShow] = useState(false);
  const handleShowModal = (item) => {
    setModalShow(!showModal);
    if (!showModal) {
      setSelectedSignature(item);
    } else {
      setSelectedSignature("");
    }
  };

  useEffect(() => {
    const handleGetInitialData = async () => {
      const quickSign = await getAllEntriesFromDB("quickSign");
      const data1 = [...quickSign];
      const data2 = await Promise.all(
        data1.map(async (item) => {
          if (!item?.signPadData?.includes("base64")) {
            const base64 = await getMediaFileFromDB(
              item,
              "signature_url",
              "signPadData"
            );
            return {
              ...item,
              rotateAngle: 0,
              signPadData: base64,
            };
          } else {
            return {
              ...item,
              rotateAngle: 0,
            };
          }
        })
      );
      while (data2?.length < 12) {
        data2.push({});
      }
      setQuickSignList(quickSign);
      setNewQuickSignList(data2);
    };
    setIsUpdateSign(false);
    handleGetInitialData();
  }, [user, isUpdateSign]);

  const handleShowSignList = () => {
    setShowListView(!showListView);
  };
  const handleShowWebcam = () => {
    setShowWebcam(!showWebcam);
  };
  const handleSystemWebcam = () => {
    checkSystemWebcam()
      .then(() => {
        if (isDesktop) {
          handleShowWebcam();
        } else {
          inputRef.current.click();
        }
      })
      .catch(() => {});
  };
  const handleCaptureImage = async (event) => {
    const quickSign = await getAllEntriesFromDB("quickSign");
    if (isDesktop) {
      setScreenShort(event);
      if (quickSign && quickSign?.length <= 12) {
        const fileObject = {
          date: new Date(),
          signature_id: "",
          signature_url: event,
          signature_type: "SELFIE",
          signPadData: event,
          signature_time: 0,
          signature_erase: 0,
        };
        handleFileUpload(fileObject);
        closeZoomModalHandler();
      }
    } else {
      const file =
        event?.target?.files &&
        event?.target?.files?.length > 0 &&
        event?.target?.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          setScreenShort(reader.result);
          if (quickSign && quickSign?.length <= 12) {
            const fileObject = {
              date: new Date(),
              signature_id: "",
              signature_url: reader.result,
              signature_type: "SELFIE",
              signPadData: reader.result,
              signature_time: 0,
              signature_erase: 0,
            };
            handleFileUpload(fileObject);
            closeZoomModalHandler();
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const clear = (secondSignPad) => {
    if (screenShort && screenShort) {
      setScreenShort("");
    } else {
      if (secondSignPad) {
        if (!signPadRef2?.current?.isEmpty()) {
          setSignTimeCount({
            ...signTimeCount,
            eraseCount: signTimeCount.eraseCount + 1,
            signDrawTime: 0,
            signStart: "",
            signEnd: "",
          });
        }
        signPadRef2?.current?.clear();
      } else {
        signPadRef?.current?.clear();
      }
      setSignatureData("");
    }
  };
  const handleSignBegin = () => {
    setSignTimeCount({
      ...signTimeCount,
      signStart: new Date(),
    });
  };
  const handleSignEnd2 = () => {
    if (!signPadRef2?.current?.isEmpty()) {
      setErrors({
        ...errors,
        mdSign: "",
      });
    }
    setSignatureData(signPadRef2?.current?.toDataURL());
    const timeDiff = getTimeDifference(signTimeCount.signStart, new Date());
    setSignTimeCount({
      ...signTimeCount,
      signEnd: new Date(),
      signDrawTime: signTimeCount.signDrawTime + timeDiff,
    });
  };

  const checkValidation = () => {
    if (!signatureData) {
      setErrors({
        ...errors,
        mdSign: messages.errors.sign_req,
      });
      return false;
    } else {
      setErrors({
        ...errors,
        mdSign: "",
      });
      return true;
    }
  };
  const handleSaveSignature = (btnType, bigPad) => {
    if (quickSignList && quickSignList?.length >= 12) {
      notify(messages.errors.sign_count);
    } else {
      if (screenShort && screenShort) {
        const fileObject = {
          date: new Date(),
          signature_id: "",
          signature_url: screenShort,
          signature_type: "SELFIE",
          signature_time: signTimeCount.signDrawTime,
          signature_erase: signTimeCount.eraseCount,
        };
        handleFileUpload(fileObject);
      } else {
        if (checkValidation()) {
          const actualPad = bigPad ? signPadRef2 : signPadRef;
          const canvas = actualPad.current.toDataURL("image/png");
          const fileObject = {
            date: new Date(),
            signature_id: "",
            signature_url: canvas,
            signature_type: "SIGNATURE",
            signPadData: canvas,
            signature_time: signTimeCount.signDrawTime,
            signature_erase: signTimeCount.eraseCount,
          };
          handleFileUpload(fileObject);
          bigPad && closeZoomModalHandler();
        }
      }
    }
  };

  const handleFileUpload = async (formData) => {
    const signID = await insertEntryInDB("quickSign", formData);
    newQuickSignList[quickSignList?.length] = { ...formData, id: signID };
    setQuickSignList([...quickSignList, { ...formData, id: signID }]);
    await insertEntryInDB("addedQuickSign", { ...formData, id: signID });
    handleShowSignList();
    setScreenShort("");
    setSignatureData("");
    setShowWebcam(false);
    setIsUpdateSign(true);
    setSignTimeCount({
      eraseCount: 0,
      signStart: "",
      signEnd: "",
      signDrawTime: 0,
    });
  };

  const handleMatchSignature = (item) => {
    setSelectedSignature(item);
    setSignatureModal(true);
  };

  const handleDeleteSignature = async () => {
    const [quickSign, addedQuickSign] = await Promise.all([
      getAllEntriesFromDB("quickSign"),
      getAllEntriesFromDB("addedQuickSign"),
    ]);
    const isExist1 =
      quickSign && quickSign.find((item) => item.id === selectedSignature?.id);
    const isExist2 =
      addedQuickSign &&
      addedQuickSign.find((item) => item.id === selectedSignature?.id);
    const index = newQuickSignList.findIndex(
      (item) => item?.id === selectedSignature?.id
    );
    quickSignList.splice(index, 1);
    newQuickSignList.splice(index, 1);
    newQuickSignList.push({});
    if (isExist1?.signature_id && isExist2?.signature_id) {
      await db.quickSign.delete(selectedSignature?.id);
      await db.addedQuickSign.delete(selectedSignature?.id);
      await insertEntryInDB("deletedQuickSign", selectedSignature);
    } else if (isExist2) {
      await db.quickSign.delete(selectedSignature?.id);
      await db.addedQuickSign.delete(selectedSignature?.id);
    } else {
      await db.quickSign.delete(selectedSignature?.id);
      await insertEntryInDB("deletedQuickSign", selectedSignature);
    }
    handleShowModal();
  };

  const openZoomModalHandler = () => {
    setViewSignModal(true);
    setSignatureData("");
    setErrors({
      ...errors,
      mdSign: "",
    });
  };

  const closeZoomModalHandler = () => {
    setViewSignModal(false);
    setSignTimeCount({
      eraseCount: 0,
      signStart: "",
      signEnd: "",
      signDrawTime: 0,
    });
    signPadRef2?.current?.clear();
    setSignatureData("");
    setErrors({
      ...errors,
      mdSign: "",
    });
  };

  const handleRotate = async (signData) => {
    let index =
      newQuickSignList &&
      newQuickSignList.findIndex((item) => item.id === signData.id);
    let newSignList = [...newQuickSignList];
    if (index !== -1) {
      const imageData = newSignList[index]?.signPadData;
      if (!imageData?.includes("base64")) return;
      const angle = (0 + 90) % 360;
      rotateImage(imageData, angle)
        .then(async (res) => {
          const base64 = await mediaToBase64(res);
          newSignList[index].signPadData = base64;
          newSignList[index].signature_url = res;
          const isExist = await getEntryFromDB("addedQuickSign", signData.id);
          await db.quickSign.update(signData.id, newSignList[index]);
          if (isExist) {
            await db.addedQuickSign.update(signData.id, newSignList[index]);
          } else {
            await insertEntryInDB("addedQuickSign", newSignList[index]);
          }
          setNewQuickSignList(newSignList);
        })
        .catch((err) => {
          setNewQuickSignList(newSignList);
        });
    }
  };

  const rotateImage = (media, angle) => {
    return new Promise(async (resolve, reject) => {
      const newBlob = dataURLtoBlob(media);
      const reader = new FileReader();
      reader.onload = (event) => {
        const imageData = event.target.result;
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (angle === 90 || angle === 270) {
            canvas.width = img.height;
            canvas.height = img.width;
          } else {
            canvas.width = img.width;
            canvas.height = img.height;
          }
          ctx.save();
          ctx.translate(canvas.width / 2, canvas.height / 2);
          ctx.rotate((angle * Math.PI) / 180);
          ctx.drawImage(img, -img.width / 2, -img.height / 2);
          ctx.restore();
          canvas.toBlob((rotatedBlob) => {
            resolve(rotatedBlob);
          }, "image/png");
        };
        img.src = imageData;
      };
      reader.onerror = (err) => {
        reject(err);
      };
      reader.onabort = (err) => {
        reject(err);
      };
      reader.readAsDataURL(newBlob);
    });
  };

  return (
    <>
      <div className="tc-body">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="tc-profile-wrapper mb-0">
            <span className="tc-profile-name d-flex align-items-center">
              QUICK CALL{" "}
              <h4 className="m-0 ms-2">{`(${
                quickSignList && quickSignList?.length
              }/${12})`}</h4>
            </span>
          </div>
          <div className="text-end fs-18">
            <p className="mb-0 fw-semibold">{moment().format("MMMM YYYY")}</p>
            <p className="mb-0">As of {moment().format("dddd, MMMM DD")}</p>
          </div>
        </div>
        <div className="add-scroll bg-transparent">
          <div className="tc-quicksign-content">
            <Row className="tc-quicksign-list-section">
              {newQuickSignList &&
                newQuickSignList?.length > 0 &&
                newQuickSignList.map((item, i) => {
                  return (
                    <Col xs={4} className="tc-quicksign-list-box" key={i}>
                      {item?.signature_url ? (
                        <Card>
                          <Card.Body className="h-100 pb-1">
                            <div className="tc-quicklist-canvas">
                              <div className="d-flex">
                                <span
                                  className="tc-sign-rotate"
                                  onClick={() => handleRotate(item)}
                                >
                                  {refreshIcon3}
                                </span>
                                <span
                                  className="tc-sign-edit"
                                  onClick={() => handleShowModal(item)}
                                >
                                  {deleteIcon2}
                                </span>
                              </div>
                              <img
                                src={
                                  item?.signature_url?.size
                                    ? URL.createObjectURL(item.signature_url)
                                    : item?.signPadData?.includes("base64")
                                    ? item?.signPadData
                                    : process.env.REACT_APP_IMAGE_URL +
                                      item.signature_url
                                }
                                alt="Signature"
                                draggable={false}
                              />
                            </div>
                          </Card.Body>
                          <Card.Footer className="d-flex align-items-center">
                            {item.date && (
                              <small>
                                {moment(item?.date).format(
                                  "MM/DD/YY, hh:mm:ss A"
                                )}
                              </small>
                            )}
                            <Button
                              variant="primary"
                              size="sm"
                              className="ms-auto"
                              onClick={() => handleMatchSignature(item)}
                            >
                              MATCH
                            </Button>
                          </Card.Footer>
                        </Card>
                      ) : i === 0 || newQuickSignList[i - 1].signature_url ? (
                        <Card>
                          <Card.Body className="h-100 pb-1">
                            <div className="tc-quicklist-canvas">
                              <span
                                className="tc-sign-edit tc-sign-camera"
                                onClick={() => handleSystemWebcam()}
                              >
                                {cameraIcon2}
                              </span>
                            </div>
                          </Card.Body>
                          <Card.Footer className="d-flex align-items-center">
                            <Button
                              variant="danger"
                              size="sm"
                              className="ms-auto"
                              onClick={() => openZoomModalHandler()}
                            >
                              SIGN
                            </Button>
                          </Card.Footer>
                        </Card>
                      ) : (
                        <Card className="h-100">
                          <Card.Body className="pb-1"></Card.Body>
                          <Card.Footer className="d-flex align-items-center">
                            <Button
                              variant="secondary"
                              disabled
                              size="sm"
                              className="ms-auto"
                              onClick={() => openZoomModalHandler()}
                            >
                              SIGN
                            </Button>
                          </Card.Footer>
                        </Card>
                      )}
                    </Col>
                  );
                })}
            </Row>
            {signatureModal && (
              <MatchSignature
                show={signatureModal}
                match={match}
                setMatch={setMatch}
                onHide={() => {
                  setSignatureModal(false);
                  setMatch("existing");
                }}
                selectedsignature={selectedSignature}
              />
            )}
          </div>
        </div>
      </div>
      {isDesktop ? (
        <>
          {showWebcam && (
            <ImageCaptureModal
              show={showWebcam}
              onHide={handleShowWebcam}
              handleCaptureImage={handleCaptureImage}
            />
          )}
        </>
      ) : (
        <CustomImageCapture
          inputRef={inputRef}
          handleFileChange={handleCaptureImage}
        />
      )}
      <Confirmation
        show={showModal}
        onHide={handleShowModal}
        title="Signature"
        subTitle="Are you sure?"
        description="You want to delete signature?"
        cancel="Cancel"
        save="Confirm"
        onSubmit={handleDeleteSignature}
      />
      <SignZoomModal
        type="QC"
        show={viewSignModal}
        closeZoomModalHandler={closeZoomModalHandler}
        clear={clear}
        signPadRef={signPadRef2}
        signatureData={signatureData}
        handleSignEnd={handleSignEnd2}
        handleSignBegin={handleSignBegin}
        handleCaptureImage={handleCaptureImage}
        handleSaveSignature={handleSaveSignature}
        error={errors.mdSign ? errors.mdSign : ""}
      />
    </>
  );
};

export default QuickSign;
