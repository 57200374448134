import React from "react";
import { handleVisits } from "../helpers/commonFunction";

const CallActivitiesMDDetails = (props) => {
  const { selectedMD } = props;
  return (
    <div className="tc-md-list-common-detail tc-activitie-md-detail mb-0">
      <tr className="tc-activitie-md-detail-row">
        <td className="tc-activitie-md-detail-label">Specialty</td>
        <td>
          <b>:</b>
        </td>
        <td className="tc-activitie-md-detail-text">
          {selectedMD && selectedMD?.specialty_name
            ? selectedMD?.specialty_name
            : "N/A"}
        </td>
      </tr>
      <tr className="tc-activitie-md-detail-row">
        <td className="tc-activitie-md-detail-label">Location</td>
        <td>
          <b>:</b>
        </td>
        <td className="tc-activitie-md-detail-text">
          {selectedMD && selectedMD?.location_name
            ? selectedMD?.location_name
            : "N/A"}
        </td>
      </tr>
      <tr className="tc-activitie-md-detail-row">
        <td className="tc-activitie-md-detail-label">Visits</td>
        <td>
          <b>:</b>
        </td>
        <td className="tc-activitie-md-detail-text">
          {selectedMD && selectedMD?.target
            ? handleVisits(selectedMD?.actual_visits, selectedMD?.target)
            : "N/A"}
        </td>
      </tr>
    </div>
  );
};

export default CallActivitiesMDDetails;
