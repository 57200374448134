import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { useDispatch } from "react-redux";
import moment from "moment";
import { db } from "../../helpers/db";
import { backBtnIcon } from "../../icon/icon";
import ReportsDetail from "./ReportsDetail";
import {
  getCallSummaryPerMDClassData,
  getCallConcentrationData,
  getMDCoverageData,
  getSamplesPromoIssuanceData,
  getDailyCallRateMdReachData,
  getMonthlyCallRateMdReachData,
  getMonthlyTotalCallsMDsData,
  getIncidentalCalls,
  getNotSubmittedSavedCalls,
  getNotSignedJointCalls,
  getVMCRequestsData,
  getNotYetCalledMissedCalls,
  convertDefaultTimeZone,
} from "../../helpers/commonFunction";
import {
  mdExcuse,
  reqToGetCallSummaryData,
  reqToGetCallConcentrationData,
  reqToGetPromoMatsInventoryData,
  reqToGetMDCoverageData,
  reqToGetSamplesIssuanceData,
  reqToGetPromoMatsIssuanceData,
  reqToGetIncidentalCalls,
  reqToGetDailyCallRateData,
  reqToGetDailyMdReachData,
  reqToGetMonthlyCallRateData,
  reqToGetMonthlyMdReachData,
  reqToGetMonthlyTotalCalls,
  reqToGetMonthlyTotalMDs,
  reqToUpdateDaysFieldReport,
  reqToGetDaysFieldReport,
  reqToGetNotSubmittedSavedCalls,
  reqToGetNotSignedJointCalls,
  reqToGetVMCRequestsData,
  reqToGetNotYetCalledMissedCalls,
} from "../../store/slice/dashboardSlice";

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentDate = moment().format("YYYY-MM-DD");

  // Get indexedDB data
  const getMDListFromDB = useLiveQuery(() => db.mdList.toArray(), []);
  const getCumulativeCallsFromDB = useLiveQuery(
    () => db.cumulativeCalls.toArray(),
    []
  );
  const getOOFMDList = useLiveQuery(() => db.disabled_mds.toArray(), []);
  const getHolidays = useLiveQuery(() => db.holidays.toArray(), []);
  const getDaysFieldFromDB = useLiveQuery(() => db.daysField.toArray(), []);
  const getIncidentalDataFromDB = useLiveQuery(
    () => db.incidentalCalls.toArray(),
    []
  );
  const monthlyCallRateData = useLiveQuery(
    () => db.monthlyCallRate.toArray(),
    []
  );
  const monthlyMdReachData = useLiveQuery(
    () => db.monthlyMDReach.toArray(),
    []
  );
  const promoMatsInventoryData = useLiveQuery(
    () => db.promoMatsInventory.toArray(),
    []
  );
  const getOOFListFromDB = useLiveQuery(() => db.oofListData.toArray(), []);

  const reportTypes = [
    {
      title: "Today’s Reports",
      type: "today",
      name: "Today's",
      list: [
        {
          value: "md_coverage",
          label: "Itinerary Calls",
          active: true,
        },
        {
          value: "samples_issuance",
          label: "Samples Issuance",
          active: false,
        },
        {
          value: "promo_mats_issuance",
          label: "Promo Mats Issuance",
          active: false,
        },
        {
          value: "incidental_calls",
          label: "Incidental Calls",
          active: true,
        },
        {
          value: "today_not_submitted_saved_calls",
          label: "Calls Saved, Not Submitted",
          active: true,
        },
        {
          value: "today_not_signed_joint_calls",
          label: "Joint Calls",
          active: true,
        },
        {
          value: "today_vmc_request",
          label: "VMC Requests",
          active: true,
        },
      ],
    },
    {
      title: "Cumulative Reports",
      type: "cumulative",
      name: "Cumulative",
      list: [
        {
          value: "total_calls",
          label: "Itinerary Calls for the Month",
          active: true,
        },
        {
          value: "total_mds",
          label: "Total MDs",
          active: false,
        },
        {
          value: "call_summary",
          label: "Call Summary per MD Class",
          active: false,
        },
        {
          value: "call_concentration",
          label: "Call Concentration",
          active: false,
        },
        {
          value: "days_in_field",
          label: "Days in Field",
          active: false,
        },
        {
          value: "promo_mats_inventory",
          label: "Promo Mats Inventory",
          active: false,
        },
        {
          value: "monthly_incidental_calls",
          label: "Incidental Calls for the Month",
          active: true,
        },
        {
          value: "monthly_not_submitted_saved_calls",
          label: "Calls Saved, Not Submitted for the Month",
          active: true,
        },
        {
          value: "monthly_not_signed_joint_calls",
          label: "Joint Calls for the Month",
          active: true,
        },
        {
          value: "monthly_vmc_request",
          label: "VMC Requests for the Month",
          active: true,
        },
        {
          value: "monthly_missed_calls",
          label: "Missed Calls for the Month",
          active: false,
        },
      ],
    },
    {
      title: "Periodic Reports",
      type: "periodic",
      name: "Periodic",
      list: [
        {
          value: "daily_call_rate",
          label: "Daily Totals – Itinerary Calls",
          active: true,
        },
        {
          value: "daily_md_reach",
          label: "Daily MD Totals",
          active: false,
        },
        {
          value: "monthly_call_rate",
          label: "Monthly Call Totals",
          active: false,
        },
        {
          value: "monthly_md_reach",
          label: "Monthly MD Reach",
          active: false,
        },
        {
          value: "daily_incidental_calls",
          label: "Daily Totals - Incidental Calls",
          active: true,
        },
      ],
    },
  ];

  const [isAciveReport, setIsAciveReport] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [isRefreshed, setIsRefreshed] = useState(false);

  const onSelectReportHandler = (report) => {
    if (report.value === "call_summary") {
      const newData = getCallSummaryPerMDClassData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        currentDate
      );
      dispatch(reqToGetCallSummaryData(newData));
    } else if (report.value === "md_coverage") {
      const newData = getMDCoverageData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        currentDate,
        "today",
        "T"
      );
      dispatch(reqToGetMDCoverageData(newData));
    } else if (report.value === "samples_issuance") {
      const { samplesData } = getSamplesPromoIssuanceData(
        getMDListFromDB,
        currentDate,
        "samples_issuance"
      );
      dispatch(reqToGetSamplesIssuanceData(samplesData));
    } else if (report.value === "promo_mats_issuance") {
      const { promoMatsData } = getSamplesPromoIssuanceData(
        getMDListFromDB,
        currentDate,
        "promo_mats_issuance"
      );
      dispatch(reqToGetPromoMatsIssuanceData(promoMatsData));
    } else if (report.value === "call_concentration") {
      const newData = getCallConcentrationData(getMDListFromDB, currentDate);
      dispatch(reqToGetCallConcentrationData(newData));
    } else if (report.value === "days_in_field") {
      dispatch(reqToUpdateDaysFieldReport(getDaysFieldFromDB));
    } else if (report.value === "md_excuse") {
      dispatch(mdExcuse());
    } else if (report.value === "promo_mats_inventory") {
      dispatch(reqToGetPromoMatsInventoryData(promoMatsInventoryData));
    } else if (report.value === "daily_call_rate") {
      const { dailyCallRate } = getDailyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        currentDate,
        "daily_call_rate"
      );
      dispatch(reqToGetDailyCallRateData(dailyCallRate));
    } else if (report.value === "daily_md_reach") {
      const { dailyMdReach } = getDailyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        currentDate,
        "daily_md_reach"
      );
      dispatch(reqToGetDailyMdReachData(dailyMdReach));
    } else if (report.value === "monthly_call_rate") {
      const newData = getMonthlyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        monthlyCallRateData,
        "monthly_call_rate"
      );
      dispatch(reqToGetMonthlyCallRateData(newData));
    } else if (report.value === "monthly_md_reach") {
      const newData = getMonthlyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        monthlyMdReachData,
        "monthly_md_reach"
      );
      dispatch(reqToGetMonthlyMdReachData(newData));
    } else if (report.value === "total_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === currentDate &&
            !item?.is_save &&
            !item?.is_incidental
        );
      if (findData && findData?.length > 0) {
        const newData = getMDCoverageData(
          getCumulativeCallsFromDB,
          [],
          [],
          currentDate,
          "month",
          "C"
        );
        dispatch(reqToGetMDCoverageData({ list: newData, chart: [] }));
      } else {
        const newData = getMDCoverageData(
          getMDListFromDB,
          getOOFMDList,
          getHolidays,
          currentDate,
          "today",
          "C"
        );
        dispatch(reqToGetMDCoverageData(newData));
      }
    } else if (report.value === "total_mds") {
      const newData = getMonthlyTotalCallsMDsData(getMDListFromDB, "", "mds");
      dispatch(reqToGetMonthlyTotalMDs(newData));
    } else if (report.value === "incidental_calls") {
      const newData = getIncidentalCalls(
        getIncidentalDataFromDB,
        currentDate,
        "today"
      );
      dispatch(reqToGetIncidentalCalls(newData));
    } else if (report.value === "monthly_incidental_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === currentDate &&
            item?.is_incidental
        );
      if (findData && findData?.length > 0) {
        const newData = getIncidentalCalls(
          getCumulativeCallsFromDB,
          currentDate,
          "month",
          "cumulative"
        );
        dispatch(reqToGetIncidentalCalls(newData));
      } else {
        const newData = getIncidentalCalls(
          getIncidentalDataFromDB,
          currentDate,
          "month"
        );
        dispatch(reqToGetIncidentalCalls(newData));
      }
    } else if (report.value === "daily_incidental_calls") {
      const newData = getIncidentalCalls(
        getIncidentalDataFromDB,
        currentDate,
        "daily"
      );
      dispatch(reqToGetIncidentalCalls(newData));
    } else if (report.value === "today_not_submitted_saved_calls") {
      const newData = getNotSubmittedSavedCalls(
        getMDListFromDB,
        currentDate,
        "today",
        "T"
      );
      dispatch(reqToGetNotSubmittedSavedCalls(newData));
    } else if (report.value === "monthly_not_submitted_saved_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === currentDate &&
            item?.is_save
        );
      if (findData && findData?.length > 0) {
        const newData = getNotSubmittedSavedCalls(
          getCumulativeCallsFromDB,
          currentDate,
          "month",
          "C"
        );
        dispatch(reqToGetNotSubmittedSavedCalls(newData));
      } else {
        const newData = getNotSubmittedSavedCalls(
          getMDListFromDB,
          currentDate,
          "today",
          "C"
        );
        dispatch(reqToGetNotSubmittedSavedCalls(newData));
      }
    } else if (report.value === "today_not_signed_joint_calls") {
      const newData = getNotSignedJointCalls(
        getMDListFromDB,
        getIncidentalDataFromDB,
        currentDate,
        "today",
        "T"
      );
      dispatch(reqToGetNotSignedJointCalls(newData));
    } else if (report.value === "monthly_not_signed_joint_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) => convertDefaultTimeZone(item?.record_time) === currentDate
        );
      if (findData && findData?.length > 0) {
        const newData = getNotSignedJointCalls(
          getCumulativeCallsFromDB,
          getIncidentalDataFromDB,
          currentDate,
          "month",
          "C"
        );
        dispatch(reqToGetNotSignedJointCalls(newData));
      } else {
        const newData = getNotSignedJointCalls(
          getMDListFromDB,
          getIncidentalDataFromDB,
          currentDate,
          "today",
          "C"
        );
        dispatch(reqToGetNotSignedJointCalls(newData));
      }
    } else if (report.value === "today_vmc_request") {
      const newData = getVMCRequestsData(
        getOOFListFromDB,
        currentDate,
        "today"
      );
      dispatch(reqToGetVMCRequestsData(newData));
    } else if (report.value === "monthly_vmc_request") {
      const newData = getVMCRequestsData(
        getOOFListFromDB,
        currentDate,
        "month"
      );
      dispatch(reqToGetVMCRequestsData(newData));
    } else if (report.value === "monthly_missed_calls") {
      const newData = getNotYetCalledMissedCalls(
        getMDListFromDB,
        getOOFMDList,
        currentDate
      );
      dispatch(reqToGetNotYetCalledMissedCalls(newData));
    }
    setSelectedReportType(report);
    setIsAciveReport(true);
  };

  const refreshToGetCurrentDateData = () => {
    if (selectedReportType.value === "call_summary") {
      const newData = getCallSummaryPerMDClassData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays
      );
      dispatch(reqToGetCallSummaryData(newData));
    } else if (selectedReportType.value === "call_concentration") {
      const newData = getCallConcentrationData(getMDListFromDB);
      dispatch(reqToGetCallConcentrationData(newData));
    } else if (selectedReportType.value === "md_excuse") {
      dispatch(mdExcuse({ refresh: true }));
    } else if (selectedReportType.value === "days_in_field") {
      dispatch(reqToGetDaysFieldReport({ data: { refresh: true } }));
    }
    setIsRefreshed(true);
  };

  const onDateChangehandler = (event) => {
    const selectedD = moment(event).format("YYYY-MM-DD");
    if (selectedReportType.value === "md_coverage") {
      const newData = getMDCoverageData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        selectedD,
        "today",
        "T"
      );
      dispatch(reqToGetMDCoverageData(newData));
    } else if (selectedReportType.value === "samples_issuance") {
      const { samplesData } = getSamplesPromoIssuanceData(
        getMDListFromDB,
        selectedD,
        "samples_issuance"
      );
      dispatch(reqToGetSamplesIssuanceData(samplesData));
    } else if (selectedReportType.value === "promo_mats_issuance") {
      const { promoMatsData } = getSamplesPromoIssuanceData(
        getMDListFromDB,
        selectedD,
        "promo_mats_issuance"
      );
      dispatch(reqToGetPromoMatsIssuanceData(promoMatsData));
    } else if (selectedReportType.value === "daily_call_rate") {
      const { dailyCallRate } = getDailyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        selectedD,
        "daily_call_rate"
      );
      dispatch(reqToGetDailyCallRateData(dailyCallRate));
    } else if (selectedReportType.value === "daily_md_reach") {
      const { dailyMdReach } = getDailyCallRateMdReachData(
        getMDListFromDB,
        getOOFMDList,
        getHolidays,
        selectedD,
        "daily_md_reach"
      );
      dispatch(reqToGetDailyMdReachData(dailyMdReach));
    } else if (selectedReportType.value === "total_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === selectedD &&
            !item?.is_save &&
            !item?.is_incidental
        );
      if (findData && findData?.length > 0) {
        const newData = getMDCoverageData(
          getCumulativeCallsFromDB,
          [],
          [],
          selectedD,
          "month",
          "C"
        );
        dispatch(reqToGetMDCoverageData({ list: newData, chart: [] }));
      } else {
        const newData = getMDCoverageData(
          getMDListFromDB,
          getOOFMDList,
          getHolidays,
          selectedD,
          "today",
          "C"
        );
        dispatch(reqToGetMDCoverageData(newData));
      }
    } else if (selectedReportType.value === "incidental_calls") {
      const newData = getIncidentalCalls(
        getIncidentalDataFromDB,
        selectedD,
        "today"
      );
      dispatch(reqToGetIncidentalCalls(newData));
    } else if (selectedReportType.value === "monthly_incidental_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === selectedD &&
            item?.is_incidental
        );
      if (findData && findData?.length > 0) {
        const newData = getIncidentalCalls(
          getCumulativeCallsFromDB,
          selectedD,
          "month",
          "cumulative"
        );
        dispatch(reqToGetIncidentalCalls(newData));
      } else {
        const newData = getIncidentalCalls(
          getIncidentalDataFromDB,
          selectedD,
          "month"
        );
        dispatch(reqToGetIncidentalCalls(newData));
      }
    } else if (selectedReportType.value === "daily_incidental_calls") {
      const newData = getIncidentalCalls(
        getIncidentalDataFromDB,
        selectedD,
        "daily"
      );
      dispatch(reqToGetIncidentalCalls(newData));
    } else if (selectedReportType.value === "today_not_submitted_saved_calls") {
      const newData = getNotSubmittedSavedCalls(
        getMDListFromDB,
        selectedD,
        "today",
        "T"
      );
      dispatch(reqToGetNotSubmittedSavedCalls(newData));
    } else if (
      selectedReportType.value === "monthly_not_submitted_saved_calls"
    ) {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) =>
            convertDefaultTimeZone(item?.record_time) === selectedD &&
            item?.is_save
        );
      if (findData && findData?.length > 0) {
        const newData = getNotSubmittedSavedCalls(
          getCumulativeCallsFromDB,
          selectedD,
          "month",
          "C"
        );
        dispatch(reqToGetNotSubmittedSavedCalls(newData));
      } else {
        const newData = getNotSubmittedSavedCalls(
          getMDListFromDB,
          selectedD,
          "today",
          "C"
        );
        dispatch(reqToGetNotSubmittedSavedCalls(newData));
      }
    } else if (selectedReportType.value === "today_not_signed_joint_calls") {
      const newData = getNotSignedJointCalls(
        getMDListFromDB,
        getIncidentalDataFromDB,
        selectedD,
        "today",
        "T"
      );
      dispatch(reqToGetNotSignedJointCalls(newData));
    } else if (selectedReportType.value === "monthly_not_signed_joint_calls") {
      const findData =
        getCumulativeCallsFromDB &&
        getCumulativeCallsFromDB?.filter(
          (item) => convertDefaultTimeZone(item?.record_time) === selectedD
        );
      if (findData && findData?.length > 0) {
        const newData = getNotSignedJointCalls(
          getCumulativeCallsFromDB,
          getIncidentalDataFromDB,
          selectedD,
          "month",
          "C"
        );
        dispatch(reqToGetNotSignedJointCalls(newData));
      } else {
        const newData = getNotSignedJointCalls(
          getMDListFromDB,
          getIncidentalDataFromDB,
          selectedD,
          "today",
          "C"
        );
        dispatch(reqToGetNotSignedJointCalls(newData));
      }
    } else if (selectedReportType.value === "today_vmc_request") {
      const newData = getVMCRequestsData(getOOFListFromDB, selectedD, "today");
      dispatch(reqToGetVMCRequestsData(newData));
    } else if (selectedReportType.value === "monthly_vmc_request") {
      const newData = getVMCRequestsData(getOOFListFromDB, selectedD, "month");
      dispatch(reqToGetVMCRequestsData(newData));
    } else if (selectedReportType.value === "monthly_missed_calls") {
      const newData = getNotYetCalledMissedCalls(
        getMDListFromDB,
        getOOFMDList,
        selectedD
      );
      dispatch(reqToGetNotYetCalledMissedCalls(newData));
    }
    setSelectedDate(selectedD);
  };

  const backToReportsHandler = () => {
    setIsAciveReport(false);
    setSelectedReportType("");
    setSelectedDate();
    setIsRefreshed(false);
  };

  return (
    <>
      <div className="tc-body tc-body-without-ft tc-report-page">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => navigate(-1)}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title text-uppercase">Reports</p>
          <div className="tc-navbar-right-btn"></div>
        </div>
        <div className="tc-content-wrapper">
          <div className="add-scroll">
            <div className="tc-report-contents">
              {reportTypes.map((type, i) => (
                <div className="tc-report-container mb-4" key={i}>
                  <h2 className="tc-report-label">{type.title}</h2>
                  <div className="tc-report-list">
                    {type.list.map((report, j) => {
                      if (report.active) {
                        return (
                          <Button
                            key={j}
                            role="button"
                            variant="primary"
                            className="w-100"
                            onClick={() =>
                              onSelectReportHandler({
                                ...report,
                                type: type?.type,
                                name: type?.name,
                              })
                            }
                          >
                            {report.label}
                          </Button>
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* <div className="tc-footer-blank"></div> */}
      </div>
      <ReportsDetail
        showSidebar={isAciveReport}
        backToReportsHandler={backToReportsHandler}
        selectedReportType={selectedReportType}
        selectedDate={selectedDate}
        onDateChangehandler={onDateChangehandler}
        refreshToGetCurrentDateData={refreshToGetCurrentDateData}
        isRefreshed={isRefreshed}
      />
    </>
  );
};

export default Reports;
