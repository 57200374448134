import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLiveQuery } from "dexie-react-hooks";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { SearchIcon, backBtnIcon, calendarSearch } from "../../icon/icon";
import {
  nameFormatting,
  dateFormatting,
  defaultDateFormat,
} from "../../helpers/commonFunction";
import {
  db,
  updateEntryInDB,
  getEntryByFieldFromDB,
  getAllEntriesFromDB,
} from "../../helpers/db";
import { DataNotFound, CustomDatePicker } from "../../components";
import { Confirmation } from "../../components/Modals";
import { messages } from "../../helpers/messages";
import { useFetchAndSyncData } from "../../hooks";

const MdBirthDayList = () => {
  const navigate = useNavigate();
  const getMDListFromDB = useLiveQuery(() => db.mdList.toArray(), []);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [newMdListData, setNewMdListData] = useState([]);
  const [newMdListData2, setNewMdListData2] = useState([]);
  const [callRecordingData, setCallRecordingData] = useState([]);
  const [editableCallsData, setEditableCallsData] = useState([]);

  const {
    syncMessage,
    confirmationModal,
    handleConfirmModal,
    uploadAndFetchData,
  } = useFetchAndSyncData();

  useEffect(() => {
    if (newMdListData && newMdListData?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [newMdListData]);

  useEffect(() => {
    const handleGetInitialData = async () => {
      handleSearchMDList();
      const [callRecording, editableCalls] = await Promise.all([
        getAllEntriesFromDB("callRecording"),
        getAllEntriesFromDB("editableCalls"),
      ]);
      setCallRecordingData(callRecording);
      setEditableCallsData(editableCalls);
    };
    handleGetInitialData();
  }, []);

  const handleSearchMDList = async (searchBy, value) => {
    if (searchBy === "name") {
      const newList =
        newMdListData2 &&
        newMdListData2?.filter(
          (item) =>
            `${item.lastname.trim()} ${item.firstname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            `${item.firstname.trim()} ${item.lastname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase())
        );
      const finalList =
        newList?.length > 0 ? newList : !value ? getMDListFromDB : [];
      setNewMdListData(finalList);
    } else {
      await db
        .table("mdList")
        .toArray()
        .then((entries) => {
          const mdIdMap = new Map();
          entries.forEach((entry) => {
            const {
              id,
              date,
              md_id,
              is_summary,
              is_visited,
              is_consecutive,
              ...rest
            } = entry;
            const formattedDate = {
              date,
              is_summary,
              is_visited,
              is_consecutive,
            };
            if (mdIdMap.has(md_id)) {
              const existingEntry = mdIdMap.get(md_id);
              if (is_summary === 1) {
                existingEntry.actual_visits++;
              }
              existingEntry.dates.push(formattedDate);
            } else {
              mdIdMap.set(md_id, {
                ...rest,
                id,
                md_id,
                dates: [formattedDate],
                actual_visits: is_summary === 1 ? 1 : 0,
              });
            }
          });
          const uniqueEntries = Array.from(mdIdMap.values());
          setNewMdListData(uniqueEntries);
          setNewMdListData2(uniqueEntries);
        });
    }
  };

  const handleOnChange = (event) => {
    if (event.target.value === "") {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    } else {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    }
  };

  const handleEditMDBirthdate = async (date, mdData) => {
    if (date && mdData.birthdate !== dateFormatting(date)) {
      const tempObj = {
        birth_date: date,
        md_id: mdData.md_id,
      };
      const getMatchMD = await getEntryByFieldFromDB(
        "mdList",
        "md_id",
        mdData.md_id
      );
      if (getMatchMD.length === 0) return;
      for (let i = 0; i < getMatchMD?.length; i++) {
        const md = getMatchMD[i];
        await db.mdList.update(md.id, {
          ...md,
          birthdate: dateFormatting(date),
        });
      }
      await updateEntryInDB("addedMDBirthday", "md_id", mdData.md_id, tempObj);
      setNewMdListData((prev) => {
        const newState = prev.map((item) => {
          if (item.id === mdData.id) {
            return { ...item, birthdate: dateFormatting(date) };
          } else {
            return item;
          }
        });
        return newState;
      });
    }
  };

  const columns = [
    {
      name: "Class",
      sortable: true,
      selector: (row) => row?.name,
      cell: (row) => (
        <p className={`tc-data-table-class-text text-center w-100`}>
          {row?.name}
        </p>
      ),
    },
    {
      name: "Md Name",
      sortable: true,
      selector: (row) => row?.firstname.concat(" ", row?.lastname),
      cell: (row) => (
        <p role="button" className={`tc-data-table-md-name-text text-start`}>
          {nameFormatting(row)}
        </p>
      ),
    },
    {
      name: "MD Birthday",
      sortable: true,
      selector: (row) => row?.birthdate,
      cell: (row) => (
        <p className={`tc-data-table-class-text text-center w-100`}>
          {row?.birthdate ? defaultDateFormat(row?.birthdate) : "-"}
        </p>
      ),
    },
    {
      name: "",
      sortable: true,
      selector: (row) => row.call,
      cell: (row) => (
        <span className="tc-data-table-date">
          <label htmlFor={row.id} role="button" className="tc-data-table-icon2">
            {calendarSearch}
          </label>
          <CustomDatePicker
            id={row.id}
            data={row}
            type="custom"
            name="selectedDate"
            dateFormat="yyyy-MM-dd"
            value={new Date(row?.birthdate)}
            maxDate={moment()._d}
            onChange={(e) => handleEditMDBirthdate(e, row)}
            selected={row?.birthdate ? new Date(row?.birthdate) : new Date()}
            showMonthDropdown={true}
            showYearDropdown={true}
            yearDropdownItemNumber={70}
          />
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="tc-body pb-5">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => navigate(-1)}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title">Birthday</p>
          {callRecordingData?.length === 0 &&
          editableCallsData?.length === 0 ? (
            <div
              className="tc-navbar-right-side-btn logout-btn"
              role="button"
              onClick={() => uploadAndFetchData("logout")}
            >
              Logout
            </div>
          ) : (
            <div className="tc-navbar-right-btn"></div>
          )}
        </div>
        <div className="tc-profile-wrapper">
          <span className="tc-profile-name">LIST OF MDs</span>
        </div>
        <div className="tc-content-wrapper" style={{ height: "95%" }}>
          <div className="tc-calender-search-input-row">
            <div className="tc-search-input-content d-flex">
              <input
                id="text"
                type="text"
                placeholder="Search..."
                value={searchValue}
                onChange={(e) => handleOnChange(e)}
              />
              <button
                type="button"
                className="tc-search-input-btn"
                onClick={() => handleSearchMDList("name", searchValue)}
                disabled={searchValue === "" && true}
              >
                {SearchIcon}
              </button>
            </div>
          </div>

          {isLoading ? null : newMdListData && newMdListData?.length === 0 ? (
            <DataNotFound name={messages.toast.nodateData} />
          ) : (
            <div className="add-scroll pb-0">
              <DataTable
                columns={columns}
                data={newMdListData && newMdListData}
                className="tc-data-table flex-grow-1 overflow-y-auto"
              />
            </div>
          )}
        </div>
        <div className="tc-footer-blank"></div>
      </div>
      {confirmationModal && (
        <Confirmation
          show={confirmationModal}
          onHide={handleConfirmModal}
          title="Syncing Data"
          subTitle="Failed to sync data!"
          description="Please resync the data!"
          cancel="Cancel"
          save="Syncing"
          errorMessage={syncMessage}
          onSubmit={() => uploadAndFetchData("logout")}
        />
      )}
    </>
  );
};

export default MdBirthDayList;
