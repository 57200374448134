import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const AddNewMDModal = (props) => {
  const { save, cancel, values, errors, onSubmit, ...rest } = props;

  const [newClinic, setNewClinic] = useState("");
  useEffect(() => {
    if (!values?.clinic?.id) {
      setNewClinic(values?.clinic?.value);
    } else {
      setNewClinic("");
    }
  }, [values.clinic]);

  return (
    <Modal
      {...rest}
      className={`tc-main-modal tc-modal-white tc-add-clinic-modal`}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="pb-0 opacity-1">
        <Modal.Title id="contained-modal-title-vcenter">
          ADD INSTITUTION
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-4">
        <Form.Group className="d-flex justify-content-center">
          <Form.Control
            type="text"
            name="username"
            value={newClinic}
            onChange={(e) => setNewClinic(e.target.value)}
            placeholder="Enter Institution"
            className={`tc_add_clinic_input ${
              errors?.clinic && "error-active"
            }`}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex justify-content-center">
        <Button
          variant="danger"
          type="button"
          className="min-width-150"
          onClick={() => props.onHide()}
        >
          {cancel}
        </Button>
        <Button
          variant="primary"
          type="button"
          className="min-width-150"
          onClick={() => onSubmit(newClinic)}
        >
          {save}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewMDModal;
