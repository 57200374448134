import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

const ItineraryApproval = (props) => {
  const {
    modalType,
    reasonsOptions,
    selectedResons,
    remarks,
    handleOnChange,
    approveDisapproveHandler,
    errors,
    isMisscall,
    ...rest
  } = props;
  return (
    <>
      <Modal
        className="tc-main-modal tc-sign-modal"
        {...rest}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className="mb-0">
              {modalType === "Approve" ? "Approval" : "Disapproval"}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType !== "Disapprove" ? (
            <div className="tc-itinerary-approval-content">
              <div className="modal-detail-main-text">Are you sure?</div>
              <div className="modal-detail-sub-text">
                You want to Approve{" "}
                {isMisscall ? "Missed call?" : "Itineraries?"}
              </div>
            </div>
          ) : (
            <div className="">
              <div className="mb-2">
                <div className="fw-semibold">REASON</div>
                <Select
                  name="option"
                  value={selectedResons}
                  onChange={(e) => handleOnChange(e, "option", "reason")}
                  options={reasonsOptions}
                  className={`tc-custom-select ${
                    errors && errors.selectedResons && "error-active"
                  }`}
                  classNamePrefix="select"
                  placeholder="Select..."
                />
              </div>
              <div className="">
                <div className="fw-semibold">REMARKS</div>
                <textarea
                  name="remarks"
                  className={`form-control ${
                    errors && errors.remarks && "error-active"
                  }`}
                  value={remarks}
                  onChange={(e) => handleOnChange(e, "text", "remarks")}
                  placeholder="Write Remarks..."
                  rows={5}
                />
              </div>
            </div>
          )}
          <div className="text-center mt-4">
            <Button
              variant="primary"
              onClick={() => approveDisapproveHandler(modalType)}
            >
              {modalType === "Approve" ? "Approve" : "SUBMIT"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItineraryApproval;
