import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import axios from "axios";

// Request to get callActivities Data
export const reqToGetCallActivitieData = createAsyncThunk(
  "reqToGetCallActivitieData",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.callActivities,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get productDetailing Data
export const reqToGetProductDetailingData = createAsyncThunk(
  "reqToGetProductDetailingData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.productDetailing,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get marketResearch Data
export const reqToGetMarketResearchData = createAsyncThunk(
  "reqToGetMarketResearchData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.marketResearch,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get productSamples Data
export const reqToGetProductSamplingData = createAsyncThunk(
  "reqToGetProductSamplingData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.productSamples,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get jointCall Data
export const reqToGetJointCallData = createAsyncThunk(
  "reqToGetJointCallData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.jointCall,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get jointCall Data
export const reqToAddMediaFile = createAsyncThunk(
  "reqToAddMediaFile",
  async ({ data, onSuccess, onFailure }) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_MEDIA_URL + apiendpoints.media,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

// Request to get call-summary Data
export const reqToGetCallSummaryData = createAsyncThunk(
  "reqToGetCallSummaryData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.callSummary,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add-product-detailing
export const reqToAddProductDetailing = createAsyncThunk(
  "reqToAddProductDetailing",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addProductDetailing,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add-market-research
export const reqToAddMarketResearch = createAsyncThunk(
  "reqToAddMarketResearch",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addMarketResearch,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add-product-sampling
export const reqToAddProductSampling = createAsyncThunk(
  "reqToAddProductSampling",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addProductSampling,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add-joint-call
export const reqToAddJointCall = createAsyncThunk(
  "reqToAddJointCall",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addJointCall,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add-md-signature
export const reqToAddMDSignature = createAsyncThunk(
  "reqToAddMDSignature",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.mdSignature,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get md excuse reason
export const reqToGetMDExcuseReason = createAsyncThunk(
  "reqToGetMDExcuseReason",
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.getMDExcuseReason,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add md excuse
export const reqToAddMDExcuse = createAsyncThunk(
  "reqToAddMDExcuse",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.insertMDExcuse,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get call obj options
export const reqToGetCallObjOptions = createAsyncThunk(
  "reqToGetCallObjOptions",
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.getCallObjOptions,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetPromoMatsTypeList = createAsyncThunk(
  "reqToGetPromoMatsTypeList",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.reqToGetPromoMatsTypeList,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  callActivitieData: [],
  productDetailingData: [],
  marketResearchData: [],
  productSamplesData: [],
  allProductSamplesData: [],
  jointCallData: [],
  callSummaryData: [],
  mdExcuseReason: [],
  mdBehavior: "",
  activities: {
    productD: "Product Detailing",
    marketR: "Market Research",
    productS: "Product Sampling",
    jointCall: "Joint Call",
  },
  productDObj: [],
  marketRObj: [],
  productSObj: [],
  uniqueProductDObj: [],
  jointCObj: [],
  mdDetails: [],
  qcDetails: {},
  callObjOptions: [],
  promoMatsTypes: [],
};

// Create callRecording slice
const callRecordingSlice = createSlice({
  name: "callRecordingSlice",
  initialState,
  reducers: {
    getCallActivitieDataFromDB: (state, action) => {
      state.callActivitieData = action.payload;
    },
    getProductDetalingDataFromDB: (state, action) => {
      state.productDetailingData = action.payload;
    },
    getMarketResearchDataFromDB: (state, action) => {
      state.marketResearchData = action.payload;
    },
    getAllProductSamplesDataFromDB: (state, action) => {
      state.allProductSamplesData = action.payload;
    },
    getProductSamplesDataFromDB: (state, action) => {
      const filterData =
        state.allProductSamplesData &&
        state.allProductSamplesData?.length > 0 &&
        state.allProductSamplesData?.filter((prod) => {
          return prod.promomat_type === action.payload.name;
        });
      state.productSamplesData = filterData;
    },
    getPromoTypeDataFromDB: (state, action) => {
      state.promoMatsTypes = action.payload;
    },
    getJointCallDataFromDB: (state, action) => {
      state.jointCallData = action.payload;
    },
    reqToUpdateProductObj: (state, action) => {
      const uniqueList = {};
      const result = [];
      if (action?.payload && action?.payload?.length > 0) {
        action?.payload.forEach((product) => {
          if (!uniqueList[product.product_id]) {
            uniqueList[product.product_id] = true;
            result.push(product);
          }
        });
        state.uniqueProductDObj = result;
        state.productDObj = action.payload;
      } else {
        state.uniqueProductDObj = action.payload;
        state.productDObj = action.payload;
      }
    },
    reqToUpdateMarketRObj: (state, action) => {
      state.marketRObj = action.payload;
    },
    reqToUpdateProductSObj: (state, action) => {
      state.productSObj = action.payload;
    },
    reqToUpdateJointCallObj: (state, action) => {
      state.jointCObj = action.payload;
    },
    reqToUpdateMDDetailsObj: (state, action) => {
      state.mdDetails = action.payload;
    },
    reqToUpdateQCDetailsObj: (state, action) => {
      state.qcDetails = action.payload;
    },
    updateMDBehavior: (state, action) => {
      state.mdBehavior = action.payload;
    },
    resetActivitiyObject: (state) => {
      state.productDObj = [];
      state.uniqueProductDObj = [];
      state.marketRObj = [];
      state.productSObj = [];
      state.jointCObj = [];
      state.mdDetails = [];
      state.mdBehavior = "";
    },
    reqToGetProductSampleData: (state, action) => {
      state.productSamplesData = action.payload;
    },
    clearProductSampleData: (state, action) => {
      state.productSamplesData = [];
    },
  },
  extraReducers: (builder) => {
    // Get callActivities data
    builder.addCase(reqToGetCallActivitieData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetCallActivitieData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.callActivitieData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetCallActivitieData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get productDetailing data
    builder.addCase(reqToGetProductDetailingData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetProductDetailingData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.productDetailingData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetProductDetailingData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get marketResearch data
    builder.addCase(reqToGetMarketResearchData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMarketResearchData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.marketResearchData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMarketResearchData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get productSampling data
    builder.addCase(reqToGetProductSamplingData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetProductSamplingData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        // state.productSamplesData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetProductSamplingData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get jointCall data
    builder.addCase(reqToGetJointCallData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetJointCallData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.jointCallData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetJointCallData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get callSummaryData
    builder.addCase(reqToGetCallSummaryData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetCallSummaryData.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.callSummaryData = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetCallSummaryData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get media file data
    builder.addCase(reqToAddMediaFile.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddMediaFile.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddMediaFile.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add productDetailing
    builder.addCase(reqToAddProductDetailing.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddProductDetailing.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddProductDetailing.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add marketResearch
    builder.addCase(reqToAddMarketResearch.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddMarketResearch.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddMarketResearch.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add productSampling
    builder.addCase(reqToAddProductSampling.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddProductSampling.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddProductSampling.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add jointCall
    builder.addCase(reqToAddJointCall.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddJointCall.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddJointCall.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add MD Signature
    builder.addCase(reqToAddMDSignature.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddMDSignature.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddMDSignature.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get MD excuse reason
    builder.addCase(reqToGetMDExcuseReason.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMDExcuseReason.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.mdExcuseReason = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMDExcuseReason.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add MD excuse
    builder.addCase(reqToAddMDExcuse.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddMDExcuse.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddMDExcuse.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get MD call obj options
    builder.addCase(reqToGetCallObjOptions.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetCallObjOptions.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.callObjOptions = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetCallObjOptions.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export const {
  getCallActivitieDataFromDB,
  getProductDetalingDataFromDB,
  getMarketResearchDataFromDB,
  getAllProductSamplesDataFromDB,
  getProductSamplesDataFromDB,
  getPromoTypeDataFromDB,
  getJointCallDataFromDB,
  reqToUpdateProductObj,
  reqToUpdateMarketRObj,
  reqToUpdateProductSObj,
  reqToUpdateJointCallObj,
  reqToUpdateMDDetailsObj,
  reqToUpdateQCDetailsObj,
  updateMDBehavior,
  resetActivitiyObject,
  reqToGetProductSampleData,
  clearProductSampleData,
} = callRecordingSlice.actions;
export default callRecordingSlice.reducer;
