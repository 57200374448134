import { useEffect, useMemo, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";

const PDFViewer = (props) => {
  const { url, index, pdfList, setPdfList } = props;
  const [numPages, setNumPages] = useState();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPdfList([...pdfList, index]);
  };
  const onDocumentLoadError = (error) => {
    console.error("Failed to load PDF document", error);
  };
  const onDocumentSourceError = (error) => {
    console.error("onDocumentSourceError", error);
  };

  useEffect(() => {
    const originalGetContext = HTMLCanvasElement.prototype.getContext;

    HTMLCanvasElement.prototype.getContext = function (
      contextType,
      contextAttributes
    ) {
      if (contextType === "2d") {
        contextAttributes = {
          ...contextAttributes,
          willReadFrequently: true,
        };
      }
      return originalGetContext.call(this, contextType, contextAttributes);
    };

    return () => {
      HTMLCanvasElement.prototype.getContext = originalGetContext;
    };
  }, []);

  const options = useMemo(
    () => ({
      cMapUrl: "cmaps/",
      cMapPacked: true,
    }),
    []
  );

  return (
    <div>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        onSourceError={onDocumentSourceError}
        options={options}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </div>
  );
};

export default PDFViewer;
