import React from "react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import ReportChart from "./ReportChart";
import ReportTable from "./ReportTable";
import {
  dashDateFormat,
  dateWithTimeDashFormat,
  dateWithTimeFormat,
  getPrevious7DaysArr,
  handleVisits,
  manageStatus,
  nameFormatting,
  timeWithHoursFormat,
} from "../../helpers/commonFunction";
import { OutOfFieldIcon, backBtnIcon, refreshIcon } from "../../icon/icon";
import { messages } from "../../helpers/messages";

const ReportsDetail = ({
  showSidebar,
  backToReportsHandler,
  selectedReportType,
  selectedDate,
  onDateChangehandler,
  refreshToGetCurrentDateData,
  isRefreshed,
}) => {
  const yesterday = moment().subtract(1, "day");
  const formattedYesterday = yesterday.format("dddd, MMMM DD");
  const formattedCurrentDay = moment().format("dddd, MMMM DD");
  const {
    callSummaryData,
    mdCoverageData,
    mdCoverageChartData,
    samplesIssuanceData,
    promoMatsIssuanceData,
    callConcentrationData,
    daysFieldData,
    mdExcuseData,
    promoMatsInventoryData,
    incidentalCalls,
    incidentalCallsChart,
    dailyCallRateData,
    dailyMdReachData,
    monthlyCallRateData,
    monthlyMdReachData,
    monthlyTotalCalls,
    monthlyTotalMDs,
    notSubmittedSavedCalls,
    notSignedJointCalls,
    vmcRequestsData,
    notYetCalledMissedCalls,
  } = useSelector((state) => state.dashboardReducer);

  const modifyArr = (arrData) => {
    const array2 = [
      {
        class_name: "A",
        zero: 0,
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        greaterFour: 0,
        total: 0,
      },
      {
        class_name: "B",
        zero: 0,
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        greaterFour: 0,
        total: 0,
      },
      {
        class_name: "C",
        zero: 0,
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        greaterFour: 0,
        total: 0,
      },
      {
        class_name: "D",
        zero: 0,
        one: 0,
        two: 0,
        three: 0,
        four: 0,
        greaterFour: 0,
        total: 0,
      },
    ];

    arrData.forEach((item) => {
      const matchingItem = array2.find(
        (el) => el.class_name === item.class_name
      );

      if (matchingItem) {
        if (item.total_calls >= 5) {
          matchingItem.greaterFour = item.total_mds;
        }
        if (item.total_calls === 4) {
          matchingItem.four = item.total_mds;
        }
        if (item.total_calls === 3) {
          matchingItem.three = item.total_mds;
        }
        if (item.total_calls === 2) {
          matchingItem.two = item.total_mds;
        }
        if (item.total_calls === 1) {
          matchingItem.one = item.total_mds;
        }
        if (item.total_calls === 0) {
          matchingItem.zero = item.total_mds;
        }

        matchingItem.total =
          Object.values(matchingItem)
            .slice(1)
            .reduce((acc, val) => acc + val, 0) -
          matchingItem.total -
          matchingItem.zero;
      }
    });

    return array2;
  };
  const getTotalTypes = (propertyName, dataArr, typeCheck) => {
    const filteredArr = dataArr.filter(
      (item) => item[propertyName] === typeCheck
    );
    return filteredArr.length;
  };

  const getSeriesForCallConcentration = (arrData) => {
    let seriesArr = [0, 0, 0, 0, 0, 0];
    arrData.forEach((item) => {
      seriesArr[0] += item.greaterFour;
      seriesArr[1] += item.four;
      seriesArr[2] += item.three;
      seriesArr[3] += item.two;
      seriesArr[4] += item.one;
      seriesArr[5] += item.zero;
    });
    return seriesArr;
  };

  const getMaxValueForCallConcentration = (arrData) => {
    var maxNumber = Math.max(...arrData);
    var nearestDivisibleBy4 = Math.ceil(maxNumber / 4) * 4;
    return nearestDivisibleBy4;
  };

  const getGraphDataForPromoMatsInventory = (arrData, isSample, isLabels) => {
    const filteredArray = arrData.filter((item) => {
      return isSample
        ? item.type_name.includes("Samples")
        : !item.type_name.includes("Samples");
    });

    const resultArray = filteredArray.reduce((result, item) => {
      const index = result.findIndex(
        (elem) => elem.type_name === item.type_name
      );

      if (index !== -1) {
        result[index].totalEnding += parseInt(item.ending);
      } else {
        result.push({
          type_name: item.type_name,
          totalEnding: parseInt(item.ending),
        });
      }

      return result;
    }, []);

    const typeNamesArray = resultArray.map((item) => item.type_name);
    const totalEndingArray = resultArray.map((item) => item.totalEnding);

    if (isLabels) {
      return typeNamesArray;
    } else {
      return totalEndingArray;
    }
  };

  const updateCallRatesArr = (dataArr) => {
    const dateArr = getPrevious7DaysArr(selectedDate);
    const updatedArray = dateArr.map((date) => {
      const data = dataArr.find((item) => item.date === date);
      if (data) {
        return {
          date: data.date,
          target_calls: +data.target_calls,
          actual_call: +data.actual_call,
        };
      } else {
        return {
          date: date,
          target_calls: 0,
          actual_call: 0,
        };
      }
    });
    return updatedArray;
  };

  const updateMdReachArr = (dataArr) => {
    const dateArr = getPrevious7DaysArr(selectedDate);
    const updatedArray = dateArr.map((date) => {
      const data = dataArr.find((item) => item.date === date);
      if (data) {
        return {
          date: data.date,
          target_mds: +data.target_mds,
          actual_mds: +data.actual_mds,
        };
      } else {
        return {
          date: date,
          target_mds: 0,
          actual_mds: 0,
        };
      }
    });
    return updatedArray;
  };

  const updatedMonthlyCallRateArr = (dataArr, isCallRate) => {
    const currentDate = moment();
    const previousMonths = [];
    for (let i = 0; i < 6; i++) {
      const previousMonth = currentDate
        .clone()
        .subtract(i, "months")
        .format("YYYY-MM");
      previousMonths.push(previousMonth);
    }
    const updatedArr = previousMonths.map((month) => {
      const data = dataArr.find((item) => item.month === month);
      if (isCallRate) {
        if (data) {
          return {
            month: data.month,
            target_calls: +data.target_calls,
            actual_call: +data.actual_call,
          };
        } else {
          return {
            month: month,
            target_calls: 0,
            actual_call: 0,
          };
        }
      } else {
        if (data) {
          return {
            month: data.month,
            target_mds: +data.target_mds,
            actual_mds: +data.actual_mds,
          };
        } else {
          return {
            month: month,
            target_mds: 0,
            actual_mds: 0,
          };
        }
      }
    });

    return updatedArr;
  };

  // Handle tables
  const tableHeaders = (reportType) => {
    let headList = [];
    if (reportType === "call_summary") {
      headList = ["Class", "Target Call", "Actual Call", "%Call"];
    } else if (reportType === "md_coverage") {
      headList = [
        "DATE/TIME SUBMITTED",
        "DATE/TIME OF SIGNATURE",
        "CALL PLANDATE",
        "LAST NAME",
        "FIRST NAME",
        "CLASS",
        "SPECIALTY",
        "RELIEVER",
        "LOCATION",
        "CALL TYPE",
        "REMARKS",
      ];
    } else if (reportType === "samples_issuance") {
      headList = [
        "Time",
        "Type",
        "Promo Mat SKU",
        "Lot Number",
        "Last Name",
        "First Name",
        "Quantity",
      ];
    } else if (reportType === "promo_mats_issuance") {
      headList = [
        "Time",
        "Type",
        "Promo Mat SKU",
        "Last Name",
        "First Name",
        "Quantity",
      ];
    } else if (reportType === "call_concentration") {
      headList = ["Class", ">4x", "4x", "3x", "2x", "1x", "0x ", "Total"];
    } else if (reportType === "days_in_field") {
      headList = ["Attendance"];
    } else if (reportType === "md_excuse") {
      headList = ["Date", "Last Name", "First Name", "Reason"];
    } else if (reportType === "promo_mats_inventory") {
      headList = [
        "Type",
        "Promo Mats SKU",
        "Beginning",
        "Received",
        "Returns",
        "Issuances",
        "Ending",
      ];
    } else if (reportType === "daily_call_rate") {
      headList = ["Day", "Target Calls", "Actual Calls", "%"];
    } else if (reportType === "daily_md_reach") {
      headList = ["Day", "Actual Count"];
    } else if (reportType === "monthly_call_rate") {
      headList = ["Month", "Target Calls", "Actual Calls", "%"];
    } else if (reportType === "monthly_md_reach") {
      headList = ["Month", "Target Count", "Actual Count", "%"];
    } else if (reportType === "total_calls") {
      headList = [
        "DATE/TIME SUBMITTED",
        "DATE/TIME OF SIGNATURE",
        "CALL PLANDATE",
        "LAST NAME",
        "FIRST NAME",
        "CLASS",
        "SPECIALTY",
        "RELIEVER",
        "LOCATION",
        "CALL TYPE",
        "REMARKS",
      ];
    } else if (reportType === "total_mds") {
      headList = [
        "LAST NAME",
        "FIRST NAME",
        "VISITS",
        "CALL PLANDATES",
        "ACTUAL DATE",
      ];
    } else if (
      reportType === "incidental_calls" ||
      reportType === "monthly_incidental_calls"
    ) {
      // headList = [
      //   "ACTUAL DATE/TIME",
      //   "LAST NAME",
      //   "FIRST NAME",
      //   "PRODUCT",
      //   "RANK",
      //   "SPECIALITY",
      //   "JOINT CALL NOTES",
      //   "POST CALL ANALYSIS",
      // ];
      headList = [
        "Date and Time",
        "Last Name",
        "First Name",
        "Class",
        "Speciality",
        "Location",
      ];
    } else if (reportType === "daily_incidental_calls") {
      headList = ["DAY", "ACTUAL INCIDENTAL"];
    } else if (
      reportType === "today_not_submitted_saved_calls" ||
      reportType === "monthly_not_submitted_saved_calls"
    ) {
      headList = [
        "DATE/TIME SUBMITTED",
        "DATE/TIME OF SIGNATURE",
        "CALL PLANDATE",
        "LAST NAME",
        "FIRST NAME",
        "CLASS",
        "SPECIALTY",
        "RELIEVER",
        "LOCATION",
        "CALL TYPE",
        "REMARKS",
      ];
    } else if (
      reportType === "today_not_signed_joint_calls" ||
      reportType === "monthly_not_signed_joint_calls"
    ) {
      headList = [
        "DATE/TIME SUBMITTED",
        "DATE/TIME OF SIGNATURE",
        "CALL PLANDATE",
        "LAST NAME",
        "FIRST NAME",
        "CLASS",
        "SPECIALTY",
        "RELIEVER",
        "LOCATION",
        "CALL TYPE",
        "CALL STATUS",
        "DM SIGNATURE",
        "REMARKS",
      ];
    } else if (
      reportType === "today_vmc_request" ||
      reportType === "monthly_vmc_request"
    ) {
      headList = [
        "DATE SUBMITTED",
        "ACTIVITY TYPE",
        "REASON",
        "ACTIVITY DATE",
        "MDs AFFECTED",
        "REMARKS",
        "STATUS",
      ];
    } else if (reportType === "monthly_missed_calls") {
      headList = ["CALL PLANDATE", "LAST NAME", "FIRST NAME"];
    }
    return headList;
  };
  const headerStyle = (reportType, index) => {
    let style = {};
    if (reportType === "samples_issuance") {
      style =
        index === 2
          ? "min-width-180"
          : index === 3 || index === 4 || index === 5
          ? "min-width-150"
          : "";
    } else if (reportType === "promo_mats_issuance") {
      style =
        index === 2
          ? "min-width-180"
          : index === 3 || index === 4
          ? "min-width-150"
          : "";
    } else if (reportType === "incidental_calls") {
      style =
        index === 0
          ? "min-width-180"
          : index === 1 || index === 2
          ? "min-width-150"
          : "";
    } else if (reportType === "promo_mats_inventory") {
      style = index === 1 ? "min-width-180" : "";
    }
    return style;
  };
  const tableBody = (reportType) => {
    if (reportType === "call_summary") {
      if (callSummaryData && callSummaryData.length > 0) {
        return callSummaryData.map((item, i) => (
          <tr key={i}>
            <td className="min-width-100">{item.class}</td>
            <td className="min-width-150">{item.target_call}</td>
            <td className="min-width-150">{item.actual_call}</td>
            <td className="min-width-100">
              {getPercentage(item.actual_call, item.target_call) + "%"}
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={4}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "md_coverage") {
      if (mdCoverageData && mdCoverageData.length > 0) {
        return mdCoverageData.map((item, i) => (
          <tr key={i}>
            <td className="min-width-250">
              {item?.record_date ? dateWithTimeFormat(item?.record_date) : ""}
            </td>
            <td>
              {item?.signature_md_time
                ? dateWithTimeFormat(item?.signature_md_time)
                : "-"}
            </td>
            <td>{item?.date}</td>
            <td className="min-width-200">{item?.lastname}</td>
            <td className="min-width-200">{item?.firstname}</td>
            <td>{item?.name}</td>
            <td className="min-width-300">{item?.specialty_name}</td>
            <td className="min-width-250">
              {item?.reliever_md
                ? nameFormatting(item?.reliever_md, "LF")
                : "-"}
            </td>
            <td className="min-width-400">{item?.address}</td>
            <td className="min-width-150 text-uppercase">{item?.call_type}</td>
            <td className="min-width-150 text-uppercase">{item?.remark}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={11}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "samples_issuance") {
      if (samplesIssuanceData && samplesIssuanceData.length > 0) {
        return samplesIssuanceData.map((item, i) => (
          <tr key={i}>
            <td className="min-width-150">
              {item.record_time ? timeWithHoursFormat(item.record_time) : ""}
            </td>
            <td>{item.type}</td>
            <td className="text-start min-width-350">{item.sku}</td>
            <td className="min-width-200">{item.lot_number}</td>
            <td className="min-width-200">{item.lastname}</td>
            <td className="min-width-200">{item.firstname}</td>
            <td>{item.quantity}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={7}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "promo_mats_issuance") {
      if (promoMatsIssuanceData && promoMatsIssuanceData.length > 0) {
        return promoMatsIssuanceData.map((item, i) => (
          <tr key={i}>
            <td className="min-width-150">
              {item.record_time ? timeWithHoursFormat(item.record_time) : ""}
            </td>
            <td>{item.type}</td>
            <td className="text-start min-width-350">{item.sku}</td>
            <td className="min-width-200">{item.lastname}</td>
            <td className="min-width-200">{item.firstname}</td>
            <td>{item.quantity}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={6}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "call_concentration") {
      if (callConcentrationData && callConcentrationData.length > 0) {
        return modifyArr(callConcentrationData).map((item, i) => (
          <tr key={i}>
            <td>{item.class_name}</td>
            <td>{item.greaterFour}</td>
            <td>{item.four}</td>
            <td>{item.three}</td>
            <td>{item.two}</td>
            <td>{item.one}</td>
            <td>{item.zero}</td>
            <td>{item.total}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={8}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "days_in_field") {
      if (daysFieldData && daysFieldData.length > 0) {
        return daysFieldData.map((item, i) => (
          <tr key={i}>
            <td className="w-50">{item.reason}</td>
            <td>
              {Number.isInteger(Number(item.days))
                ? Math.floor(Number(item.days))
                : item.days}
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={2}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "md_excuse") {
      if (mdExcuseData && mdExcuseData.length > 0) {
        return mdExcuseData.map((item, i) => (
          <tr key={i}>
            <td>{item.date}</td>
            <td>{item.lastname}</td>
            <td>{item.firstname}</td>
            <td>{item.reason}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={4}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "promo_mats_inventory") {
      if (promoMatsInventoryData && promoMatsInventoryData.length > 0) {
        return promoMatsInventoryData.map((item, i) => (
          <tr key={i}>
            <td>{item.type}</td>
            <td className="text-start min-width-350">{item.name}</td>
            <td>{item.beginning}</td>
            <td>{item.received}</td>
            <td>{item.returns}</td>
            <td>{item.issuances}</td>
            <td>{item.ending}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={7}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "daily_call_rate") {
      if (dailyCallRateData) {
        return updateCallRatesArr(dailyCallRateData).map((item, i) => (
          <tr key={i}>
            <td style={{ textTransform: "none" }}>
              {moment(item.date).format("DD-MMM")}
            </td>
            <td>{item.target_calls}</td>
            <td>{item.actual_call}</td>
            <td>{getPercentage(item.actual_call, item.target_calls) + "%"}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={4}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "daily_md_reach") {
      if (dailyMdReachData) {
        return updateMdReachArr(dailyMdReachData).map((item, i) => (
          <tr key={i}>
            <td style={{ textTransform: "none" }}>
              {moment(item.date).format("DD-MMM")}
            </td>
            <td>{item.actual_mds}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={2}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "monthly_call_rate") {
      if (monthlyCallRateData) {
        return updatedMonthlyCallRateArr(monthlyCallRateData, true).map(
          (item, i) => (
            <tr key={i}>
              <td style={{ textTransform: "none" }}>
                {moment(item.month).format("MMM")}
              </td>
              <td>{item.target_calls}</td>
              <td>{item.actual_call}</td>
              <td>
                {getPercentage(item.actual_call, item.target_calls) + "%"}
              </td>
            </tr>
          )
        );
      } else {
        return (
          <tr>
            <td colSpan={4}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "monthly_md_reach") {
      if (monthlyMdReachData) {
        return updatedMonthlyCallRateArr(monthlyMdReachData).map((item, i) => (
          <tr key={i}>
            <td style={{ textTransform: "none" }}>
              {moment(item.month).format("MMM")}
            </td>
            <td>{item.target_mds}</td>
            <td>{item.actual_mds}</td>
            <td>{getPercentage(item.actual_mds, item.target_mds) + "%"}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={4}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "total_calls") {
      if (mdCoverageData && mdCoverageData?.length > 0) {
        return mdCoverageData.map((item, i) => (
          <tr key={i}>
            <td className="min-width-250">
              {item?.record_date ? dateWithTimeFormat(item?.record_date) : ""}
            </td>
            <td>
              {item?.signature_md_time
                ? dateWithTimeFormat(item?.signature_md_time)
                : "-"}
            </td>
            <td>{item?.date}</td>
            <td className="min-width-200">{item?.lastname}</td>
            <td className="min-width-200">{item?.firstname}</td>
            <td>{item?.name}</td>
            <td className="min-width-300">{item?.specialty_name}</td>
            <td className="min-width-250">
              {item?.reliever_md
                ? nameFormatting(item?.reliever_md, "LF")
                : "-"}
            </td>
            <td className="min-width-400">{item?.address}</td>
            <td className="min-width-150 text-uppercase">{item?.call_type}</td>
            <td className="min-width-150 text-uppercase">{item?.remark}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={11}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "total_mds") {
      if (monthlyTotalMDs && monthlyTotalMDs?.length > 0) {
        return monthlyTotalMDs.map((item, i) => (
          <tr key={i}>
            <td className="min-width-200">
              {item.lastname ? item.lastname : ""}
            </td>
            <td className="min-width-200">
              {item.firstname ? item.firstname : ""}
            </td>
            <td className="min-width-100">
              {handleVisits(item?.actual_visits, item?.target)}
            </td>
            <td className="min-width-100">
              {item.date ? dashDateFormat(item.date) : ""}
            </td>
            <td className="min-width-100">
              {item.record_date ? dashDateFormat(item.record_date) : ""}
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={5}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (
      reportType === "incidental_calls" ||
      reportType === "monthly_incidental_calls"
    ) {
      // if (incidentalCalls && incidentalCalls?.length > 0) {
      //   return incidentalCalls.map((item, i) => {
      //     if (item?.product?.length > 0) {
      //       return item.product.map((item2, j) => {
      //         if (j === 0) {
      //           return (
      //             <tr key={j}>
      //               <td className="min-width-200">
      //                 {item?.record_time
      //                   ? dateWithTimeDashFormat(item.record_time)
      //                   : ""}
      //               </td>
      //               <td className="min-width-200">
      //                 {item.lastname ? item.lastname : ""}
      //               </td>
      //               <td className="min-width-200">
      //                 {item.firstname ? item.firstname : ""}
      //               </td>
      //               <td className="min-width-300">{item2?.name}</td>
      //               <td>{item2?.rank}</td>
      //               <td className="min-width-200">
      //                 {item.specialty_name ? item.specialty_name : ""}
      //               </td>
      //               <td className="min-width-250">
      //                 {item?.jointCall ? item?.jointCall : "-"}
      //               </td>
      //               <td className="min-width-250">
      //                 {item?.postCall ? item?.postCall : "-"}
      //               </td>
      //             </tr>
      //           );
      //         } else {
      //           return (
      //             <tr key={j}>
      //               <td></td>
      //               <td></td>
      //               <td></td>
      //               <td>{item2?.name}</td>
      //               <td>{item2?.rank}</td>
      //               <td></td>
      //               <td></td>
      //               <td></td>
      //             </tr>
      //           );
      //         }
      //       });
      //     } else {
      //       return (
      //         <tr key={i}>
      //           <td className="min-width-200">
      //             {item?.record_time
      //               ? dateWithTimeDashFormat(item.record_time)
      //               : ""}
      //           </td>
      //           <td className="min-width-200">
      //             {item.lastname ? item.lastname : ""}
      //           </td>
      //           <td className="min-width-200">
      //             {item.firstname ? item.firstname : ""}
      //           </td>
      //           <td>-</td>
      //           <td>-</td>
      //           <td className="min-width-200">
      //             {item.specialty_name ? item.specialty_name : ""}
      //           </td>
      //           <td className="min-width-250">
      //             {item?.jointCall ? item?.jointCall : "-"}
      //           </td>
      //           <td className="min-width-250">
      //             {item?.postCall ? item?.postCall : "-"}
      //           </td>
      //         </tr>
      //       );
      //     }
      //   });
      // } else {
      //   return (
      //     <tr>
      //       <td colSpan={8}>{messages.toast.nodata}</td>
      //     </tr>
      //   );
      // }
      if (incidentalCalls && incidentalCalls?.length > 0) {
        return incidentalCalls.map((item, i) => (
          <tr key={i}>
            <td className="min-width-200">
              {item?.record_time ? dateWithTimeFormat(item?.record_time) : ""}
            </td>
            <td className="min-width-200">
              {item.lastname ? item.lastname : ""}
            </td>
            <td className="min-width-200">
              {item.firstname ? item.firstname : ""}
            </td>
            <td>{item.class_name ? item.class_name : ""}</td>
            <td className="min-width-200">
              {item.specialty_name ? item.specialty_name : ""}
            </td>
            <td className="min-width-450">
              {item.address ? item.address : ""}
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={6}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "daily_incidental_calls") {
      if (incidentalCalls && incidentalCalls?.length > 0) {
        return incidentalCalls.map((item, i) => (
          <tr key={i}>
            <td>{item?.date ? moment(item?.date).format("DD-MMM") : "-"}</td>
            <td>{item?.actual_call ? item?.actual_call : 0}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={2}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (
      reportType === "today_not_submitted_saved_calls" ||
      reportType === "monthly_not_submitted_saved_calls"
    ) {
      if (notSubmittedSavedCalls && notSubmittedSavedCalls?.length > 0) {
        return notSubmittedSavedCalls.map((item, i) => {
          return (
            <tr key={i}>
              <td className="min-width-250">
                {item?.actual_call_date
                  ? dateWithTimeFormat(item?.actual_call_date)
                  : ""}
              </td>
              <td>
                {item?.signature_md_time
                  ? dateWithTimeFormat(item?.signature_md_time)
                  : "-"}
              </td>
              <td>{item?.date}</td>
              <td className="min-width-200">{item?.lastname}</td>
              <td className="min-width-200">{item?.firstname}</td>
              <td>{item?.name}</td>
              <td className="min-width-300">{item?.specialty_name}</td>
              <td className="min-width-250">
                {item?.reliever_md
                  ? nameFormatting(item?.reliever_md, "LF")
                  : "-"}
              </td>
              <td className="min-width-400">{item?.address}</td>
              <td className="min-width-150 text-uppercase">
                {item?.call_type}
              </td>
              <td className="min-width-150 text-uppercase">{item?.remark}</td>
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td colSpan={11}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (
      reportType === "today_not_signed_joint_calls" ||
      reportType === "monthly_not_signed_joint_calls"
    ) {
      if (notSignedJointCalls && notSignedJointCalls?.length > 0) {
        return notSignedJointCalls.map((item, i) => {
          return (
            <tr key={i}>
              <td className="min-width-250">
                {item?.actual_call_date
                  ? dateWithTimeFormat(item?.actual_call_date)
                  : ""}
              </td>
              <td>
                {item?.signature_md_time
                  ? dateWithTimeFormat(item?.signature_md_time)
                  : "-"}
              </td>
              <td>{item?.date ? item?.date : "-"}</td>
              <td className="min-width-200">{item?.lastname}</td>
              <td className="min-width-200">{item?.firstname}</td>
              <td>{item?.name}</td>
              <td className="min-width-300">{item?.specialty_name}</td>
              <td className="min-width-250">
                {item?.reliever_md
                  ? nameFormatting(item?.reliever_md, "LF")
                  : "-"}
              </td>
              <td className="min-width-400">{item?.address}</td>
              <td className="min-width-150 text-uppercase">
                {item?.call_type}
              </td>
              <td className="text-uppercase">{item?.call_status}</td>
              <td className="min-width-150 text-uppercase">{item?.dm_sign}</td>
              <td className="min-width-150 text-uppercase">{item?.remark}</td>
            </tr>
          );
        });
      } else {
        return (
          <tr>
            <td colSpan={13}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (
      reportType === "today_vmc_request" ||
      reportType === "monthly_vmc_request"
    ) {
      if (vmcRequestsData && vmcRequestsData?.length > 0) {
        return vmcRequestsData.map((item, i) => (
          <tr key={i}>
            <td>
              {item?.created_at
                ? dashDateFormat(item?.created_at)
                : item?.record_time
                ? dashDateFormat(item?.record_time)
                : "-"}
            </td>
            <td>{item?.name ? item?.name : "-"}</td>
            <td className="min-width-250">
              {item?.reason ? item?.reason : "-"}
            </td>
            <td>{item?.date ? dashDateFormat(item?.date) : "-"}</td>
            <td className="min-width-300">
              {item?.md_name && item?.md_name?.length > 0 ? (
                <>
                  {item.md_name.map((md, j) => {
                    return (
                      <div key={j}>
                        {nameFormatting(md, "FLC")}
                        {item.md_name.length - 1 === j ? "" : ","}
                      </div>
                    );
                  })}
                </>
              ) : (
                "-"
              )}
            </td>
            <td className="min-width-250">
              {item?.remark ? item?.remark : "-"}
            </td>
            <td className="min-width-200">
              {item?.status
                ? item?.status === "APPROVED"
                  ? "APPROVED"
                  : item?.status === "DISAPPROVED"
                  ? "DISAPPROVED"
                  : "FOR APPROVAL"
                : "-"}
              <div>
                {item?.status === "APPROVED" || item?.status === "DISAPPROVED"
                  ? item?.record_time
                    ? dateWithTimeDashFormat(item?.record_time)
                    : item?.created_at
                    ? dateWithTimeDashFormat(item?.created_at)
                    : "-"
                  : item?.created_at
                  ? dateWithTimeDashFormat(item?.created_at)
                  : "-"}
              </div>
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={7}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else if (reportType === "monthly_missed_calls") {
      if (notYetCalledMissedCalls && notYetCalledMissedCalls?.length > 0) {
        return notYetCalledMissedCalls.map((item, i) => (
          <tr key={i}>
            <td>{item?.date ? dashDateFormat(item?.date) : "-"}</td>
            <td>{item?.lastname ? item?.lastname : "-"}</td>
            <td>{item?.firstname ? item?.firstname : "-"}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={3}>{messages.toast.nodata}</td>
          </tr>
        );
      }
    } else {
      return (
        <tr>
          <td>{messages.toast.nodata}</td>
        </tr>
      );
    }
  };
  const getTotalValues = (reportType) => {
    if (
      reportType === "call_summary" &&
      callSummaryData &&
      callSummaryData?.length > 0
    ) {
      return (
        <tr className="tc-report-table-footer">
          <td>Total</td>
          <td>{calculateTotal("target_call", callSummaryData)}</td>
          <td>{calculateTotal("actual_call", callSummaryData)}</td>
          <td>
            {getPercentage(
              calculateTotal("actual_call", callSummaryData),
              calculateTotal("target_call", callSummaryData)
            ) + "%"}
          </td>
        </tr>
      );
    } else if (
      reportType === "days_in_field" &&
      daysFieldData &&
      daysFieldData?.length > 0
    ) {
      return (
        <tr className="tc-report-table-footer">
          <td>Total</td>
          <td>{calculateTotal("days", daysFieldData)}</td>
        </tr>
      );
    } else if (reportType === "call_concentration") {
      return (
        <tr className="tc-report-table-footer">
          <td>Total</td>
          <td>
            {calculateTotal("greaterFour", modifyArr(callConcentrationData))}
          </td>
          <td>{calculateTotal("four", modifyArr(callConcentrationData))}</td>
          <td>{calculateTotal("three", modifyArr(callConcentrationData))}</td>
          <td>{calculateTotal("two", modifyArr(callConcentrationData))}</td>
          <td>{calculateTotal("one", modifyArr(callConcentrationData))}</td>
          <td>{calculateTotal("zero", modifyArr(callConcentrationData))}</td>
          <td>{calculateTotal("total", modifyArr(callConcentrationData))}</td>
        </tr>
      );
    } else if (reportType === "daily_call_rate") {
      if (dailyCallRateData && dailyCallRateData?.length > 0) {
        return (
          <tr className="tc-report-table-footer green-color">
            <td>Total</td>
            <td>{calculateTotal("target_calls", dailyCallRateData)}</td>
            <td>{calculateTotal("actual_call", dailyCallRateData)}</td>
            <td>
              {getPercentage(
                calculateTotal("actual_call", dailyCallRateData),
                calculateTotal("target_calls", dailyCallRateData)
              ) + "%"}
            </td>
          </tr>
        );
      }
    } else if (reportType === "daily_md_reach") {
      if (dailyMdReachData && dailyMdReachData?.length > 0) {
        return (
          <tr className="tc-report-table-footer green-color">
            <td>Total</td>
            <td>{calculateTotal("actual_mds", dailyMdReachData)}</td>
          </tr>
        );
      }
    } else if (reportType === "monthly_call_rate") {
      if (monthlyCallRateData && monthlyCallRateData?.length > 0) {
        return (
          <tr className="tc-report-table-footer green-color">
            <td>Total</td>
            <td>{calculateTotal("target_calls", monthlyCallRateData)}</td>
            <td>{calculateTotal("actual_call", monthlyCallRateData)}</td>
            <td>
              {getPercentage(
                calculateTotal("actual_call", monthlyCallRateData),
                calculateTotal("target_calls", monthlyCallRateData)
              ) + "%"}
            </td>
          </tr>
        );
      }
    } else if (reportType === "monthly_md_reach") {
      if (monthlyMdReachData && monthlyMdReachData?.length > 0) {
        return (
          <tr className="tc-report-table-footer green-color">
            <td>Total</td>
            <td>{calculateTotal("target_mds", monthlyMdReachData)}</td>
            <td>{calculateTotal("actual_mds", monthlyMdReachData)}</td>
            <td>
              {getPercentage(
                calculateTotal("actual_mds", monthlyMdReachData),
                calculateTotal("target_mds", monthlyMdReachData)
              ) + "%"}
            </td>
          </tr>
        );
      }
    } else if (reportType === "daily_incidental_calls") {
      if (incidentalCalls && incidentalCalls?.length > 0) {
        return (
          <tr className="tc-report-table-footer green-color">
            <td>Total</td>
            <td>{calculateTotal("actual_call", incidentalCalls)}</td>
          </tr>
        );
      }
    }
  };

  // UHandle chart
  const getBarChartData = () => {
    let chartData = {
      xAixsLabels: [],
      targetCallSeries: [],
      actualCallSeries: [],
      maxValue: 0,
    };
    if (
      selectedReportType.value === "call_summary" &&
      callSummaryData.length > 0
    ) {
      chartData = {
        xAixsLabels: callSummaryData.map((item) => item.class),
        targetCallSeries: callSummaryData.map((item) => item.target_call),
        actualCallSeries: callSummaryData.map((item) => item.actual_call),
        maxValue: getChartMaxValue(callSummaryData, "target_call"),
        colors: ["#F3F3F373", "#c5e0b4"],
      };
    } else if (
      selectedReportType.value === "md_coverage" &&
      mdCoverageChartData.length > 0
    ) {
      chartData = {
        xAixsLabels: mdCoverageChartData.map((item) => item.class),
        targetCallSeries: mdCoverageChartData.map((item) => item.target_md),
        actualCallSeries: mdCoverageChartData.map((item) => item.actual_md),
        maxValue:
          getChartMaxValue(mdCoverageChartData, "target_md") ||
          getChartMaxValue(mdCoverageChartData, "actual_md"),
        colors: ["#F3F3F373", "#c5e0b4"],
      };
    } else if (
      selectedReportType.value === "call_concentration" &&
      modifyArr(callConcentrationData).length > 0
    ) {
      chartData = {
        xAixsLabels: [">4X", "4X", "3X", "2X", "1X", "0X"],
        actualCallSeries: getSeriesForCallConcentration(
          modifyArr(callConcentrationData)
        ),
        maxValue: getMaxValueForCallConcentration(
          getSeriesForCallConcentration(modifyArr(callConcentrationData))
        ),
        colors: ["#F3F3F373"],
      };
    } else if (selectedReportType.value === "daily_call_rate") {
      chartData = {
        xAixsLabels: updateCallRatesArr(dailyCallRateData)
          ?.reverse()
          ?.map((item) => moment(item.date).format("DD-MMM")),
        targetCallSeries: updateCallRatesArr(dailyCallRateData)
          ?.reverse()
          ?.map((item) => item.target_calls),
        actualCallSeries: updateCallRatesArr(dailyCallRateData)
          ?.reverse()
          ?.map((item) => item.actual_call),
        maxValue:
          getChartMaxValue(dailyCallRateData, "target_calls") ||
          getChartMaxValue(dailyCallRateData, "actual_call"),
        colors: ["#F3F3F373", "#c5e0b4"],
      };
    } else if (selectedReportType.value === "daily_md_reach") {
      chartData = {
        xAixsLabels: updateMdReachArr(dailyMdReachData)
          ?.reverse()
          ?.map((item) => moment(item.date).format("DD-MMM")),
        actualCallSeries: updateMdReachArr(dailyMdReachData)
          ?.reverse()
          ?.map((item) => item.actual_mds),
        maxValue:
          getChartMaxValue(dailyMdReachData, "target_mds") ||
          getChartMaxValue(dailyMdReachData, "actual_mds"),
        colors: ["#F3F3F373", "#c5e0b4"],
      };
    } else if (selectedReportType.value === "monthly_call_rate") {
      chartData = {
        xAixsLabels: updatedMonthlyCallRateArr(monthlyCallRateData, true)
          ?.reverse()
          ?.map((item) => moment(item.month).format("MMM")),
        targetCallSeries: updatedMonthlyCallRateArr(monthlyCallRateData, true)
          ?.reverse()
          ?.map((item) => item.target_calls),
        actualCallSeries: updatedMonthlyCallRateArr(monthlyCallRateData, true)
          ?.reverse()
          ?.map((item) => item.actual_call),
        maxValue:
          getChartMaxValue(monthlyCallRateData, "target_calls") ||
          getChartMaxValue(monthlyCallRateData, "actual_call"),
        colors: ["#F3F3F373", "#c5e0b4"],
      };
    } else if (selectedReportType.value === "monthly_md_reach") {
      chartData = {
        xAixsLabels: updatedMonthlyCallRateArr(monthlyMdReachData)
          ?.reverse()
          ?.map((item) => moment(item.month).format("MMM")),
        targetCallSeries: updatedMonthlyCallRateArr(monthlyMdReachData)
          ?.reverse()
          ?.map((item) => item.target_mds),
        actualCallSeries: updatedMonthlyCallRateArr(monthlyMdReachData)
          ?.reverse()
          ?.map((item) => item.actual_mds),
        maxValue:
          getChartMaxValue(monthlyMdReachData, "target_mds") ||
          getChartMaxValue(monthlyMdReachData, "actual_mds"),
        colors: ["#F3F3F373", "#c5e0b4"],
      };
    } else if (
      selectedReportType.value === "incidental_calls" &&
      incidentalCallsChart.length > 0
    ) {
      chartData = {
        xAixsLabels: incidentalCallsChart.map((item) => item.class),
        actualCallSeries: incidentalCallsChart.map((item) => item.actual_calls),
        maxValue: getChartMaxValue(incidentalCallsChart, "actual_calls"),
        colors: ["#F3F3F373"],
      };
    } else if (selectedReportType.value === "daily_incidental_calls") {
      chartData = {
        xAixsLabels: updateCallRatesArr(incidentalCalls)
          ?.reverse()
          ?.map((item) => moment(item.date).format("DD-MMM")),
        targetCallSeries: updateCallRatesArr(incidentalCalls)
          ?.reverse()
          ?.map((item) => item.target_calls),
        actualCallSeries: updateCallRatesArr(incidentalCalls)
          ?.reverse()
          ?.map((item) => item.actual_call),
        maxValue: getChartMaxValue(incidentalCalls, "actual_call"),
        colors: ["#c5e0b4"],
      };
    } else if (
      selectedReportType.value === "days_in_field" &&
      daysFieldData.length > 0
    ) {
      chartData = {
        xAixsLabels: daysFieldData.map((item) => item.reason),
        actualCallSeries: daysFieldData.map((item) => item.days),
        maxValue: getChartMaxValue(daysFieldData, "days"),
        colors: ["#F3F3F373"],
      };
    } else if (selectedReportType.value === "daily_incidental_calls") {
      chartData = {
        xAixsLabels: callSummaryData.map((item) => item.class),
        targetCallSeries: callSummaryData.map((item) => item.target_call),
        actualCallSeries: callSummaryData.map((item) => item.actual_call),
        maxValue: getChartMaxValue(callSummaryData, "target_call"),
        colors: ["#F3F3F373", "#c5e0b4"],
      };
    }
    return chartData;
  };
  const getPieChartData = (samplePromoMats) => {
    let chartData = {
      labels: [],
      series: [],
      colors: [],
    };
    if (
      selectedReportType.value === "call_summary" &&
      callSummaryData.length > 0
    ) {
      chartData = {
        labels: ["F2F"],
        series: [
          // calculateTotal("f2f_call", callSummaryData),
          // calculateTotal("virtual_call", callSummaryData),
        ],
        colors: ["#F3F3F373", "#c5e0b4"],
      };
    } else if (
      selectedReportType.value === "samples_issuance" &&
      samplesIssuanceData.length > 0
    ) {
      chartData = {
        labels: ["Physician Samples", "Commercial Samples"],
        series: [
          getTotalTypes("type", samplesIssuanceData, "P"),
          getTotalTypes("type", samplesIssuanceData, "C"),
        ],
        colors: ["#4472C4", "#ED7D31"],
      };
    } else if (
      selectedReportType.value === "promo_mats_issuance" &&
      promoMatsIssuanceData.length > 0
    ) {
      chartData = {
        labels: ["Giveaways", "Literatures", "Others"],
        series: [
          getTotalTypes("type", promoMatsIssuanceData, "G"),
          getTotalTypes("type", promoMatsIssuanceData, "L"),
          getTotalTypes("type", promoMatsIssuanceData, "O"),
        ],
        colors: ["#4472C4", "#ED7D31", "#A5A5A5"],
      };
    } else if (
      selectedReportType.value === "days_in_field" &&
      daysFieldData.length > 0
    ) {
      chartData = {
        labels: daysFieldData.map((item) => item.reason),
        series: daysFieldData.map((item) => +item.days),
        colors: [
          "#4472C4",
          "#ED7D31",
          "#A5A5A5",
          "#FFC000",
          "#8b008b",
          "#8D33FF",
          "#ff00ff",
          "#008000",
          "#ffff00",
        ],
      };
    } else if (
      selectedReportType.value === "md_excuse" &&
      mdExcuseData.length > 0
    ) {
      chartData = {
        labels: countReasonsForChart(mdExcuseData).labels,
        series: countReasonsForChart(mdExcuseData).series,
        colors: ["#4472C4", "#ED7D31", "#A5A5A5", "#FFC000"],
      };
    } else if (
      selectedReportType.value === "promo_mats_inventory" &&
      promoMatsInventoryData?.length > 0
    ) {
      chartData = {
        labels: samplePromoMats
          ? getGraphDataForPromoMatsInventory(
              promoMatsInventoryData,
              true,
              true
            )
          : getGraphDataForPromoMatsInventory(
              promoMatsInventoryData,
              false,
              true
            ),
        series: samplePromoMats
          ? getGraphDataForPromoMatsInventory(
              promoMatsInventoryData,
              true,
              false
            )
          : getGraphDataForPromoMatsInventory(
              promoMatsInventoryData,
              false,
              false
            ),
        colors: [
          "#4472C4",
          "#ED7D31",
          "#A5A5A5",
          "#FFC000",
          "#8b008b",
          "#8D33FF",
          "#ff00ff",
          "#008000",
          "#ffff00",
        ],
      };
    }
    return chartData;
  };
  const countReasonsForChart = (dataArr) => {
    const reasonCounts = {};
    dataArr.forEach((item) => {
      const reason = item.reason;
      if (!reasonCounts[reason]) {
        reasonCounts[reason] = 1;
      } else {
        reasonCounts[reason]++;
      }
    });
    const labels = Object.keys(reasonCounts);
    const series = labels.map((reason) => reasonCounts[reason]);
    return { labels, series };
  };
  const getChartMaxValue = (dataArr, propertyName) => {
    const maxTargetMd = dataArr.reduce(
      (max, current) =>
        +current[propertyName] > +max ? +current[propertyName] : +max,
      +dataArr[0][propertyName]
    );
    const nearestDivisibleByFour = Math.ceil(maxTargetMd / 4) * 4;
    return nearestDivisibleByFour;
  };
  const getPercentage = (actual, targeted) => {
    const actualNumber = actual
      ? typeof actual === "number"
        ? actual
        : +actual
      : 0;
    const targetedNumber = targeted
      ? typeof targeted === "number"
        ? targeted
        : +targeted
      : 0;
    if (actualNumber === 0 || targetedNumber === 0) {
      return "0.00";
    }
    const finalPrctge = (100 * actualNumber) / targetedNumber;
    return finalPrctge.toFixed(2);
  };
  const calculateTotal = (propertyName, dataArr) => {
    let total = 0;
    for (const data of dataArr) {
      const propertyValue = data[propertyName];
      total +=
        typeof propertyValue === "number" ? propertyValue : +propertyValue;
    }
    return total;
  };
  const showChart = (reportType) => {
    if (
      reportType === "call_summary" &&
      callSummaryData.length > 0 &&
      !callSummaryData?.every(
        (item) => item?.target_call === 0 && item?.actual_call === 0
      )
    ) {
      return true;
    } else if (
      reportType === "md_coverage" &&
      mdCoverageChartData.length > 0 &&
      !mdCoverageChartData?.every(
        (item) => item?.target_md === 0 && item?.actual_md === 0
      )
    ) {
      return true;
    } else if (
      reportType === "samples_issuance" &&
      samplesIssuanceData.length > 0
    ) {
      return true;
    } else if (
      reportType === "promo_mats_issuance" &&
      promoMatsIssuanceData.length > 0
    ) {
      return true;
    } else if (
      reportType === "call_concentration" &&
      callConcentrationData.length > 0
    ) {
      return true;
    } else if (
      reportType === "days_in_field" &&
      daysFieldData.length > 0 &&
      !daysFieldData?.every((item) => Number(item?.days) === 0)
    ) {
      return true;
    } else if (reportType === "md_excuse" && mdExcuseData.length > 0) {
      return true;
    } else if (
      reportType === "promo_mats_inventory" &&
      promoMatsInventoryData?.length > 0
    ) {
      return true;
    } else if (
      reportType === "daily_call_rate" &&
      dailyCallRateData?.length > 0 &&
      !dailyCallRateData?.every(
        (item) => item?.target_calls === 0 && item?.actual_call === 0
      )
    ) {
      return true;
    } else if (
      reportType === "daily_md_reach" &&
      dailyMdReachData?.length > 0 &&
      !dailyMdReachData?.every(
        (item) => item?.target_mds === 0 && item?.actual_mds === 0
      )
    ) {
      return true;
    } else if (
      reportType === "monthly_call_rate" &&
      monthlyCallRateData?.length > 0
    ) {
      return true;
    } else if (
      reportType === "monthly_md_reach" &&
      monthlyMdReachData?.length > 0
    ) {
      return true;
    } else if (
      reportType === "incidental_calls" &&
      incidentalCallsChart?.length > 0 &&
      !incidentalCallsChart?.every((item) => item?.actual_calls === 0)
    ) {
      return true;
    } else if (
      reportType === "daily_incidental_calls" &&
      incidentalCalls?.length > 0 &&
      !incidentalCalls?.every((item) => item?.actual_calls === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getReportData = (reportType) => {
    if (reportType === "md_coverage") {
      return mdCoverageData;
    } else if (reportType === "total_calls") {
      return mdCoverageData;
    } else if (reportType === "total_mds") {
      return monthlyTotalMDs;
    } else if (
      reportType === "incidental_calls" ||
      reportType === "monthly_incidental_calls"
    ) {
      return incidentalCalls;
    } else if (
      reportType === "today_not_submitted_saved_calls" ||
      reportType === "monthly_not_submitted_saved_calls"
    ) {
      return notSubmittedSavedCalls;
    } else if (
      reportType === "today_not_signed_joint_calls" ||
      reportType === "monthly_not_signed_joint_calls"
    ) {
      return notSignedJointCalls;
    } else if (
      reportType === "today_vmc_request" ||
      reportType === "monthly_vmc_request"
    ) {
      return vmcRequestsData;
    } else if (reportType === "monthly_missed_calls") {
      return notYetCalledMissedCalls;
    } else {
      return [];
    }
  };

  return (
    <div
      className={`tc-sidebar-section tc-sidebar-without-ft ${
        showSidebar && "zl-sidebar-open"
      }`}
    >
      <div className="tc-sidebar-navbar">
        <button
          type="button"
          className="tc-navbar-back-btn"
          onClick={() => backToReportsHandler()}
        >
          {backBtnIcon}
        </button>
        <p className="tc-navbar-title text-uppercase">
          {selectedReportType?.name} Reports
        </p>
        {selectedReportType &&
        (selectedReportType.value === "call_summary" ||
          selectedReportType.value === "call_concentration" ||
          selectedReportType.value === "md_excuse" ||
          selectedReportType.value === "days_in_field") ? (
          <div
            className="tc-sidebar-right-btn"
            role="button"
            onClick={() => refreshToGetCurrentDateData()}
          >
            {refreshIcon}
          </div>
        ) : (
          <div className="tc-sidebar-right-btn"></div>
        )}
      </div>
      <div className="tc-sidebar-report-body tc-sidebar-body-wrapper">
        <div className="tc-sidebar-body">
          <div className="tc-report-header">
            <div className="d-flex align-items-baseline justify-content-between mb-2">
              {selectedReportType && (
                <div className="tc-profile-wrapper mb-0">
                  <h2 className="tc-reports-title-name mb-0">
                    {selectedReportType?.value === "total_mds"
                      ? "Total MDs for the Month"
                      : selectedReportType?.label}
                  </h2>
                </div>
              )}
              <div className="text-end fs-18 min-width-300">
                {selectedReportType &&
                (selectedReportType.value === "samples_issuance" ||
                  selectedReportType.value === "promo_mats_issuance" ||
                  selectedReportType.value === "md_coverage" ||
                  selectedReportType.value === "daily_call_rate" ||
                  selectedReportType.value === "daily_md_reach" ||
                  selectedReportType.value === "monthly_call_rate" ||
                  selectedReportType.value === "monthly_md_reach" ||
                  selectedReportType.value === "total_calls" ||
                  selectedReportType.value === "incidental_calls" ||
                  selectedReportType.value === "monthly_incidental_calls" ||
                  selectedReportType.value === "daily_incidental_calls" ||
                  selectedReportType.value ===
                    "today_not_submitted_saved_calls" ||
                  selectedReportType.value ===
                    "monthly_not_submitted_saved_calls" ||
                  selectedReportType.value === "today_not_signed_joint_calls" ||
                  selectedReportType.value ===
                    "monthly_not_signed_joint_calls" ||
                  selectedReportType.value === "today_vmc_request" ||
                  selectedReportType.value === "monthly_vmc_request" ||
                  selectedReportType.value === "monthly_missed_calls" ||
                  selectedReportType.value === "promo_mats_inventory") ? (
                  <>
                    <p className="mb-0 fw-semibold tc-report-header-date">
                      <span>
                        {selectedDate
                          ? selectedDate === moment().format("YYYY-MM-DD")
                            ? "Today: "
                            : "Date: "
                          : "Today: "}
                      </span>
                      {selectedDate
                        ? moment(selectedDate).format("MMM DD, YYYY")
                        : moment().format("MMM DD, YYYY")}

                      {selectedReportType.value !== "monthly_call_rate" &&
                        selectedReportType.value !== "monthly_md_reach" &&
                        selectedReportType.value !== "promo_mats_inventory" && (
                          <>
                            <label htmlFor="selectedDate">
                              {OutOfFieldIcon}
                            </label>
                            <ReactDatePicker
                              onChange={(e) => onDateChangehandler(e)}
                              id="selectedDate"
                              name="selectedDate"
                              dateFormat="yyyy-MM-dd"
                              value={selectedDate}
                              minDate={
                                selectedReportType.value !==
                                  "daily_call_rate" &&
                                selectedReportType.value !== "daily_md_reach"
                                  ? moment().startOf("month")._d
                                  : ""
                              }
                              maxDate={moment()._d}
                              className="d-none"
                              selected={
                                selectedDate
                                  ? new Date(selectedDate)
                                  : new Date()
                              }
                              onKeyDown={(e) => e.preventDefault()}
                            />
                          </>
                        )}
                    </p>
                  </>
                ) : (
                  <p className="mb-0 fw-semibold tc-report-header-date">
                    <span>As of: </span>
                    {isRefreshed ? formattedCurrentDay : formattedYesterday}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="tc-content-wrapper" style={{ height: "90%" }}>
            <div className="h-100 p-0">
              {selectedReportType && (
                <ReportChart
                  selectedReportType={selectedReportType}
                  getBarChartData={getBarChartData}
                  getPieChartData={getPieChartData}
                  showChart={showChart}
                />
              )}
              {selectedReportType && (
                <ReportTable
                  tableHeaders={tableHeaders}
                  headerStyle={headerStyle}
                  tableBody={tableBody}
                  showChart={showChart}
                  getReportData={getReportData}
                  getTotalValues={getTotalValues}
                  selectedReportType={selectedReportType}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="tc-footer-blank"></div>
    </div>
  );
};

export default ReportsDetail;
