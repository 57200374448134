import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

const AdjustQuantity = (props) => {
  const {
    to,
    materialObj,
    values,
    errors,
    handleInputChange,
    saveAdjustMatsHandler,
    ...rest
  } = props;

  const adjustReasons = useSelector(
    (state) => state.promoMaterialReducer.adjustReasons
  );

  return (
    <>
      <Modal
        className="tc-main-modal"
        {...rest}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Adjust Quantity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row mb-3">
              <p className="col-4">Name: </p>
              <div className="col-8">
                <p>{materialObj && materialObj.name ? materialObj.name : ""}</p>
              </div>
            </div>
            <div className="row mb-3">
              <p className="col-4">Quantity:</p>
              <div className="col-8">
                <Form.Control
                  type="text"
                  id="received_qty"
                  name="received_qty"
                  placeholder="Input quantity"
                  value={values.received_qty}
                  onChange={handleInputChange}
                />
                <span className="tc-error-text">
                  {errors.received_qty || ""}
                </span>
              </div>
            </div>
            <div className="row mb-3">
              <p className="col-4">Reason:</p>
              <div className="col-8">
                <Form.Select
                  aria-label="Default select example"
                  name="reason"
                  id="reason"
                  defaultValue="Select"
                  onChange={(e) => handleInputChange(e, "reason")}
                  className="form-select-input"
                >
                  <option disabled>Select</option>
                  {adjustReasons.map((reason, i) => {
                    return (
                      <option key={i} value={reason.id}>
                        {reason.name}
                      </option>
                    );
                  })}
                </Form.Select>
                <span className="tc-error-text">{errors.reason || ""}</span>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button className="primary" onClick={() => saveAdjustMatsHandler()}>
              SAVE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AdjustQuantity;
