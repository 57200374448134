import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout } from "./userSlice";

// Request to get DM list
export const reqToGetDMList = createAsyncThunk(
  "reqToGetDMList",
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.get(
        apiendpoints.dmList,
        // data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Request to get TM list by DM
export const reqToGetTmListByDM = createAsyncThunk(
  "reqToGetTmListByDM",
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.dmByTmList,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Request to get MD list by TM
export const reqToGetMDListByTM = createAsyncThunk(
  "reqToGetMDListByTM",
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.tmByMDList,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Request to get audit report
export const reqToGetAuditReport = createAsyncThunk(
  "reqToGetAuditReport",
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.auditReport,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Request to get call list
export const reqToGetCallList = createAsyncThunk(
  "reqToGetCallList",
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.callList,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  dMList: [],
  tmList: [],
  mdList: [],
  auditReportList: [],
  callList: [],
};

// Create file Upload slice
const auditReportSlice = createSlice({
  name: "auditReportSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get DM list
    builder.addCase(reqToGetDMList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetDMList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.dMList = action?.payload?.data?.data;
        state.tmList = [];
        state.mdList = [];
        state.auditReportList = [];
        state.callList = [];
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetDMList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get TM list
    builder.addCase(reqToGetTmListByDM.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetTmListByDM.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.tmList = action?.payload?.data?.data;
        state.mdList = [];
        state.callList = [];
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetTmListByDM.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get MD list
    builder.addCase(reqToGetMDListByTM.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetMDListByTM.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.mdList = action?.payload?.data;
        state.callList = [];
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetMDListByTM.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get audit report list
    builder.addCase(reqToGetAuditReport.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetAuditReport.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.auditReportList = action?.payload?.data?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetAuditReport.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get call list
    builder.addCase(reqToGetCallList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetCallList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.callList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetCallList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default auditReportSlice.reducer;
