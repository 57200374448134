import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { db, getAllEntriesFromDB } from "../../helpers/db";
import { updateUserState } from "../../store/slice/userSlice";
import { dateFormatting } from "../../helpers/commonFunction";
import { getLatestDataFromDB } from "../../helpers/offlineAPIs";
import { routes } from "../../helpers/constants";
import { useFetchAndSyncData } from "../../hooks";
import { Loader } from "../../components";
import { OfflineModal, Confirmation } from "../../components/Modals";

const Sync = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userReducer);
  const { syncLoader } = useSelector((state) => state.offlineReducer);
  const [isOfflineData, setIsOfflineData] = useState(true);

  useEffect(() => {
    if (user && user?.user_role === "TM") {
      checkDataInLocalDB();
    }
  }, [user]);

  const {
    syncMessage,
    offlineModal,
    confirmationModal,
    handleOfflineModal,
    handleConfirmModal,
    uploadAndFetchData,
  } = useFetchAndSyncData();

  const checkDataInLocalDB = async () => {
    const [
      addedCumulativeCalls,
      callRecordingData,
      addedQuickSign,
      deletedQuickSign,
      editableCalls,
      missedCalls,
      addedTimeInOut,
      addedMDBirthday,
      addedItinerary,
      addedMD,
    ] = await Promise.all([
      getAllEntriesFromDB("addedCumulativeCalls"),
      getAllEntriesFromDB("callRecording"),
      getAllEntriesFromDB("addedQuickSign"),
      getAllEntriesFromDB("deletedQuickSign"),
      getAllEntriesFromDB("editableCalls"),
      getAllEntriesFromDB("oofMissedCalls"),
      getAllEntriesFromDB("addedTimeInOut"),
      getAllEntriesFromDB("addedMDBirthday"),
      getAllEntriesFromDB("addedItinerary"),
      getAllEntriesFromDB("addedMD"),
    ]);
    if (
      addedCumulativeCalls?.length > 0 ||
      callRecordingData?.length > 0 ||
      addedQuickSign?.length > 0 ||
      deletedQuickSign?.length > 0 ||
      editableCalls?.length > 0 ||
      missedCalls?.length > 0 ||
      addedTimeInOut?.length > 0 ||
      addedMDBirthday?.length > 0 ||
      addedItinerary?.length > 0 ||
      addedMD?.length > 0
    ) {
      setIsOfflineData(true);
    } else {
      setIsOfflineData(false);
      checkTimeInOut("autoSync");
    }
  };

  const checkTimeInOut = async (type) => {
    const [userData] = await Promise.all([getAllEntriesFromDB("userData")]);
    const { getOldTime, getNewTime, isNextMonth } = getUserTimeInOutDates(user);
    if (getOldTime !== getNewTime) {
      const newUser = {
        ...user,
        time_in: false,
        timein_date: "",
        timeout_date: "",
      };
      dispatch(updateUserState(newUser));
      if (type === "autoSync") {
        await db.timeInOut.clear();
        await getLatestDataFromDB(
          isNextMonth ? "isNMonth" : "finalUpload",
          dispatch,
          userData
        );
      }
    }
  };

  const handleSyncData = () => {
    const { isNextMonth } = getUserTimeInOutDates();
    uploadAndFetchData(
      isNextMonth ? "isNMonth" : "finalUpload",
      checkTimeInOut
    );
  };

  const getUserTimeInOutDates = () => {
    let getOldTime = "";
    let localDBDate = "";
    const getNewTime = dateFormatting(new Date());
    if (user && user?.timeout_date) {
      if (user?.timeout_date?.trim()?.includes(" ")) {
        localDBDate = user?.timeout_date?.trim()?.split(" ")[0];
      }
      if (localDBDate) {
        getOldTime = localDBDate;
      } else {
        getOldTime = moment.utc(user?.timeout_date).format("YYYY-MM-DD");
      }
    } else if (user && user?.timein_date) {
      if (user?.timein_date?.trim()?.includes(" ")) {
        localDBDate = user?.timein_date?.trim()?.split(" ")[0];
      }
      if (localDBDate) {
        getOldTime = localDBDate;
      } else {
        getOldTime = moment.utc(user?.timein_date).format("YYYY-MM-DD");
      }
    }
    const oldDate = new Date(getOldTime);
    const newDate = new Date(getNewTime);
    const isNextMonth =
      oldDate.getFullYear() < newDate.getFullYear() ||
      (oldDate.getFullYear() === newDate.getFullYear() &&
        oldDate.getMonth() < newDate.getMonth());
    return { getOldTime, getNewTime, isNextMonth };
  };

  return (
    <>
      {syncLoader && <Loader />}
      <div className="tc-body pb-5">
        <Row>
          <div className="tc-navbar-back-text">
            <Col sm={4}>
              <div className="tc-navbar-back-btn me-0"></div>
            </Col>
            <Col sm={4}>
              <p className="tc-navbar-title text-center">FINAL SYNC</p>
            </Col>
            <Col sm={4} className="w-auto ms-auto">
              <div
                role="button"
                className="tc-navbar-right-side-btn logout-btn w-auto px-2"
                onClick={() => uploadAndFetchData("export")}
              >
                Export DB
              </div>
            </Col>
          </div>
        </Row>
        <div className="tc-content-wrapper tc-sync-content-wrapper mt-3">
          <div className="tc-sync-top-content">
            <h5>
              Please tap the <span>SYNC</span> button to make sure all your data
              are uploaded. Thank you!
            </h5>
            <div className="mt-4 text-center">
              <Button
                variant="primary"
                type="button"
                className="btn-min"
                onClick={handleSyncData}
                disabled={!isOfflineData}
              >
                SYNC
              </Button>
            </div>
          </div>
          <span className="tc-sync-center-content"></span>
          <div className="tc-sync-bottom-content">
            <span>STATUS</span>
            <div className="tc-sync-data-status mt-3">
              <span></span>
              <div className="tc-sync-data-status-content">
                {isOfflineData ? (
                  <div className="color-red">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      className="tc-failure-checkmark toggle mb-2"
                    >
                      <circle
                        className="tc-failure-checkmark-circle"
                        cx="32"
                        cy="32"
                        r="30"
                        fill="none"
                      />
                      <line
                        className="tc-failure-left-check"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        x1="19.271"
                        y1="19.521"
                        x2="44.729"
                        y2="44.979"
                      />
                      <line
                        className="tc-failure-right-check"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                        x1="44.729"
                        y1="19.521"
                        x2="19.271"
                        y2="44.979"
                      />
                    </svg>
                    Not all data has been uploaded and downloaded!
                  </div>
                ) : (
                  <div className="color-green">
                    <svg
                      className="tc-success-checkmark mb-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="tc-success-checkmark-circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="tc-success-checkmark-check"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                    All data are successfully uploaded and downloaded!
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4 text-center">
              {!isOfflineData && (
                <Button
                  variant="primary"
                  type="button"
                  className="btn-min"
                  onClick={() => navigate(routes.dashboard)}
                  disabled={isOfflineData}
                >
                  DASHBOARD
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="tc-footer-blank"></div>
      </div>
      {offlineModal && (
        <OfflineModal show={offlineModal} onHide={() => handleOfflineModal()} />
      )}
      {confirmationModal && (
        <Confirmation
          show={confirmationModal}
          onHide={handleConfirmModal}
          title="Syncing Data"
          subTitle="Failed to sync data!"
          description="Please resync the data!"
          cancel="Cancel"
          save="Syncing"
          errorMessage={syncMessage}
          onSubmit={handleSyncData}
        />
      )}
    </>
  );
};

export default Sync;
