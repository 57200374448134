import React from "react";
import { Button, Modal } from "react-bootstrap";

const TacticalModal = (props) => {
  const { to, handleFinalTacticalSave, ...rest } = props;

  return (
    <>
      <Modal
        className="tc-main-modal"
        {...rest}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Tactical Activity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <p className="col-12 modal-title-p">
              Are you sure you want to add Tactical Activities?
            </p>
          </div>

          <div className="text-center">
            <Button className="tc-save" onClick={handleFinalTacticalSave}>
              SAVE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TacticalModal;
