import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import UpdateMDInfo from "./UpdateMDInfo";
import {
  notify,
  mdMethods,
  modifyText,
  getSortData,
  nameFormatting,
  getClassByFreq,
  getMonthStartEndDate,
} from "../../helpers/commonFunction";
import { db, getAllEntriesFromDB, insertEntryInDB } from "../../helpers/db";
import { newMDInfoValidation } from "../../helpers/formValidations";
import { useForm } from "../../hooks";
import { Confirmation } from "../../components/Modals";

const MDInfo = () => {
  const currentDate = moment().format("dddd, MMMM DD");
  const [isLoading, setIsLoading] = useState(false);
  const [mdInfoMethod, setMDInfoMethod] = useState("");
  const [classOptions, setClassOptions] = useState([]);
  const [clinicOptions, setClinicOptions] = useState([]);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [universeMDOptions, setUniverseMDOptions] = useState([]);
  const [monthInfo, setMonthInfo] = useState({
    year: "",
    currMonth: "",
    currMName: "",
    nextMonth: "",
    nextMName: "",
  });
  const [showMDInfoForm, setShowMDInfoForm] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const handleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const initialFValues = {
    selectedMD: "",
    prc: "",
    firstname: "",
    lastname: "",
    specialty_id: "",
    class_id: "",
    clinic: "",
    frequency_id: "",
    type_request: "",
    month: "",
    year: "",
    reason: "",
    attachment: "",
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    return newMDInfoValidation(
      fieldValues,
      temp,
      values,
      setValues,
      setErrors,
      mdInfoMethod?.value
    );
  };
  const { errors, setErrors, values, setValues, handleInputChange } = useForm(
    initialFValues,
    true
  );

  const handleInitialData = async (method) => {
    const [classes, clinic, specialty, frequency, universalMDList] =
      await Promise.all([
        getAllEntriesFromDB("classes"),
        getAllEntriesFromDB("clinic"),
        getAllEntriesFromDB("specialty"),
        getAllEntriesFromDB("frequency"),
        getAllEntriesFromDB("universalMDList"),
      ]);
    const classOption =
      (classes &&
        classes?.length > 0 &&
        classes.map((item) => {
          return {
            ...item,
            id: item?.class_id,
            value: item?.class_id,
            label: item?.name,
          };
        })) ||
      [];
    setClassOptions(classOption);
    const clinicOption =
      (clinic &&
        clinic?.length > 0 &&
        clinic.map((item) => {
          return {
            ...item,
            id: item?.clinic_id,
            value: item?.name,
            label: item?.name,
          };
        })) ||
      [];
    setClinicOptions(clinicOption);
    const uniqueNames = specialty.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t?.name === item?.name)
    );
    const newSpecialty = uniqueNames.filter((item) => item?.group_id === 2);
    const specialtyOption =
      (newSpecialty &&
        newSpecialty?.length > 0 &&
        newSpecialty.map((item) => {
          return {
            ...item,
            id: item?.specialty_id,
            value: item?.specialty_id,
            label: item?.name?.toUpperCase(),
          };
        })) ||
      [];
    setSpecialtyOptions(specialtyOption);
    const freqOptions =
      (frequency &&
        frequency?.length > 0 &&
        frequency.map((item) => {
          const freqN = getClassByFreq(item?.name);
          const currClass = classes.find((item) => item.name === freqN);
          return {
            data: item,
            id: item?.freq_id,
            value: item?.freq_id,
            name: item?.name,
            class: freqN,
            class_id: currClass?.class_id,
            label: freqN.concat(" - ", item?.name),
          };
        })) ||
      [];
    setFrequencyOptions(freqOptions);
    const universalMDList1 = getSortData(universalMDList, "lastname");
    const newMDOptions =
      (universalMDList1 &&
        universalMDList1?.length > 0 &&
        universalMDList1?.map((item) => {
          return {
            ...item,
            id: item.md_id,
            value: nameFormatting(item),
            label: nameFormatting(item),
          };
        })) ||
      [];
    setUniverseMDOptions(newMDOptions);
    const currDate = new Date();
    const currMonth = currDate.getMonth() + 1;
    const currYear = currDate.getFullYear();
    const currentMonth = {
      value: moment().format("M"),
      label: moment().format("MMMM"),
    };
    const nextMonth = {
      value: moment().add(1, "months").format("M"),
      label: moment().add(1, "months").format("MMMM"),
    };
    const selectedMonth = Number(nextMonth.value);
    setMonthInfo({
      ...monthInfo,
      currMonth: currentMonth.value,
      currMName: currentMonth.label,
      nextMonth: nextMonth.value,
      nextMName: nextMonth.label,
      year: currMonth > selectedMonth ? currYear + 1 : currYear,
    });

    if (
      method?.value === "DELETE" ||
      method?.value === "EDIT" ||
      method?.value === "VIEW"
    ) {
      setValues({
        ...values,
        selectedMD: newMDOptions && newMDOptions?.length > 0 && newMDOptions[0],
      });
    } else {
      setValues({
        ...values,
        frequency_id: {
          data: {
            id: 1,
            name: "4X",
            target: 4,
            freq_id: 1,
          },
          id: 1,
          value: 1,
          name: "4X",
          class: "A",
          class_id: 1,
          label: "A - 4X",
        },
      });
    }
    setMDInfoMethod(method);
    setShowMDInfoForm(true);
  };

  const handleBackBtn = (back) => {
    setErrors("");
    setValues(initialFValues);
    setMDInfoMethod("");
    if (back) setShowMDInfoForm(false);
  };

  useEffect(() => {
    if (values?.selectedMD) {
      const sMD = values?.selectedMD;
      const specialty =
        specialtyOptions &&
        specialtyOptions.find(
          (item) => item?.name?.trim() === sMD?.specialty_name?.trim()
        );
      const clinic =
        clinicOptions &&
        clinicOptions.find(
          (item) => item?.name?.trim() === sMD?.location_name?.trim()
        );
      const frequency =
        frequencyOptions &&
        frequencyOptions.find((item) => item.id === sMD?.frequency_id);
      setValues({
        ...values,
        specialty_id: specialty,
        clinic: clinic,
        frequency_id: frequency,
        firstname: sMD?.firstname,
        lastname: sMD?.lastname,
        md_code: sMD?.code,
        prc: sMD?.prc ? sMD?.prc : "",
      });
      setErrors({
        ...errors,
        specialty_id: "",
        clinic: "",
        frequency_id: "",
      });
    }
  }, [values?.selectedMD]);

  const handleSavePrompt = () => {
    if (validate()) {
      handleConfirmationModal();
    }
  };
  const handleSaveMDInfo = async () => {
    setIsLoading(true);
    const newDate = getMonthStartEndDate(1);
    const reqObj = {
      lastname: values?.lastname?.toUpperCase(),
      firstname: values?.firstname?.toUpperCase(),
      specialty_id: values?.specialty_id?.value,
      class_id: values?.frequency_id?.class_id,
      clinic: values?.clinic?.value?.trim(),
      frequency_id: values?.frequency_id?.value,
      approve_request: mdInfoMethod?.value,
      type_request: mdInfoMethod?.value,
      month: monthInfo?.nextMName,
      year: monthInfo?.year,
      md_code: values?.md_code,
      clinic_code: values?.clinic?.code ? values?.clinic?.code : "",
      start: moment(newDate.start).format("YYYY-MM-DD"),
      end: moment(newDate.end).format("YYYY-MM-DD"),
      prc: values?.prc,
      reason: values?.reason,
      file: values?.attachment,
    };
    const [mdRequest, addedMD] = await Promise.all([
      getAllEntriesFromDB("mdRequest"),
      getAllEntriesFromDB("addedMD"),
    ]);

    const isExist1 = filterMDByName();
    const isExist2 = filterMDByStatus(mdRequest);
    const isExist3 = filterMDByStatus(addedMD);
    const isExist4 = filterMDByStatus2(mdRequest);
    if (mdInfoMethod?.value === "INSERT") {
      if (isExist1 && isExist1?.length > 0) {
        notify("MD Already Exist!", "error");
      } else if (isExist2?.length > 0 || isExist4?.length > 0) {
        notify("MD Already Added For Approval!", "error");
      } else {
        const addedID = await insertEntryInDB("mdRequest", reqObj);
        await insertEntryInDB("addedMD", {
          ...reqObj,
          id: addedID,
        });
        handleConfirmationModal();
        handleBackBtn(true);
        notify("MD has been added!", "success");
      }
    } else if (mdInfoMethod?.value === "EDIT") {
      if (isExist3 && isExist3?.length > 0) {
        await db.mdRequest.update(isExist3[0]?.id, reqObj);
        await db.addedMD.update(isExist3[0]?.id, reqObj);
      } else {
        const addedID = await insertEntryInDB("mdRequest", reqObj);
        await insertEntryInDB("addedMD", {
          ...reqObj,
          id: addedID,
        });
      }
      handleConfirmationModal();
      handleBackBtn(true);
      notify("MD has been Updated!", "success");
    } else {
      if (isExist2 && isExist2?.length > 0) {
        notify("MD Already Added For Approval!", "error");
      } else {
        const addedID = await insertEntryInDB("mdRequest", reqObj);
        await insertEntryInDB("addedMD", {
          ...reqObj,
          id: addedID,
        });
        handleConfirmationModal();
        handleBackBtn(true);
        notify("MD has been Removed!", "success");
      }
    }
    setIsLoading(false);
  };

  const filterMDByName = () => {
    return universeMDOptions.filter(
      (item) =>
        modifyText(item?.firstname) === modifyText(values?.firstname) &&
        modifyText(item?.lastname) === modifyText(values?.lastname)
    );
  };
  const filterMDByStatus = (mdInfoList) => {
    return mdInfoList.filter(
      (item) =>
        item?.approve_request === mdInfoMethod?.value &&
        modifyText(item?.firstname) === modifyText(values?.firstname) &&
        modifyText(item?.lastname) === modifyText(values?.lastname)
    );
  };
  const filterMDByStatus2 = (mdInfoList) => {
    return mdInfoList.filter(
      (item) =>
        item?.approve_request === "ADMIN_APPROVAL" &&
        modifyText(item?.firstname) === modifyText(values?.firstname) &&
        modifyText(item?.lastname) === modifyText(values?.lastname)
    );
  };

  return (
    <div className="tc-body">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="tc-profile-wrapper mb-0">
          <span className="tc-profile-name">MDs</span>
        </div>
        <div className="text-end fs-18">
          <p className="mb-0 fw-semibold">{moment().format("MMMM YYYY")}</p>
          <p className="mb-0">As of {currentDate}</p>
        </div>
      </div>
      <div className="add-scroll">
        <div className="tc-content-wrapper justify-content-center h-100">
          <div className="tc-md-contents mt-4">
            {mdMethods.map((method, i) => (
              <div className="tc-report-container mb-4" key={i}>
                <div className="tc-md-list">
                  <Link to={method.url} style={{ textDecoration: "none" }}>
                    <Button
                      variant="primary"
                      className="w-100"
                      role="button"
                      onClick={() => handleInitialData(method)}
                    >
                      {method.label}
                    </Button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <UpdateMDInfo
        show={showMDInfoForm}
        values={values}
        errors={errors}
        validate={validate}
        setErrors={setErrors}
        setValues={setValues}
        isLoading={isLoading}
        monthInfo={monthInfo}
        handleBackBtn={handleBackBtn}
        mdInfoMethod={mdInfoMethod}
        classOptions={classOptions}
        clinicOptions={clinicOptions}
        frequencyOptions={frequencyOptions}
        specialtyOptions={specialtyOptions}
        universeMDOptions={universeMDOptions}
        handleInputChange={handleInputChange}
        handleSavePrompt={handleSavePrompt}
      />
      <Confirmation
        size="md"
        show={confirmationModal}
        onHide={handleConfirmationModal}
        title={mdInfoMethod?.label}
        description={
          mdInfoMethod?.value === "INSERT"
            ? "Are you sure you want to add this to your MD List?"
            : mdInfoMethod?.value === "EDIT"
            ? "Are you sure you want to edit this MD?"
            : "Are you sure you want to remove this MD?"
        }
        cancel="Cancel"
        save="Ok"
        onSubmit={() => handleSaveMDInfo()}
      />
    </div>
  );
};

export default MDInfo;
