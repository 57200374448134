import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { messages } from "../../helpers/messages";
import { apiendpoints } from "../../helpers/constants";
import { db, insertEntryInDB, updateEntryInDB } from "../../helpers/db";

export const reqToUserLogin = createAsyncThunk(
  apiendpoints.login,
  async ({ data, onSuccess, onFailure }) => {
    try {
      const response = await Axios.post(apiendpoints.login, data);
      const reqObj = {
        ...response?.data,
        timein_date: "",
        timeout_date: "",
        user_id: response?.data?.id,
      };
      await db.userData.clear();
      await db.lastAPICallTime.clear();
      await updateEntryInDB("userData", "id", reqObj.id, reqObj);
      await insertEntryInDB("lastAPICallTime", {
        call: 0,
        time: new Date(),
      });
      onSuccess(response);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToUserLogout = createAsyncThunk(
  apiendpoints.logout,
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.logout,
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

const initialUser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

const initialState = {
  loader: false,
  user: initialUser,
  error: "",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserState: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
      if (action?.payload?.id) {
        db.userData.update(action.payload.id, action.payload);
      }
    },
    reqToUserLoginInLocal: (state, action) => {
      state.user = action.payload;
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      localStorage.setItem("user", JSON.stringify(action.payload));
      notify(messages.toast.login, "success");
    },
    reqToUserLogoutInLocal: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      notify(messages.toast.logout, "success");
    },
    forceLogout: (state) => {
      state.user = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
  extraReducers: (builder) => {
    // LOGIN
    builder
      .addCase(reqToUserLogin.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToUserLogin.fulfilled, (state, action) => {
        state.loader = false;
        if (action.payload.status === 200) {
          notify(messages.toast.login, "success");
          const reqObj = {
            ...action.payload.data,
            timein_date: "",
            timeout_date: "",
            user_id: action.payload.data.id,
          };
          state.user = reqObj;
          const authToken = action.payload.headers["authorization"];
          localStorage.setItem("token", JSON.stringify(authToken));
          localStorage.setItem("user", JSON.stringify(reqObj));
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200 &&
          !action.payload.response.data.includes("currently signed")
        ) {
          notify(action.payload.response.data, "error");
        } else if (!action.payload.response) {
          notify(action.payload.message, "error");
        }
      })
      .addCase(reqToUserLogin.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });

    // LOGOUT
    builder
      .addCase(reqToUserLogout.pending, (state) => {
        state.loader = true;
      })
      .addCase(reqToUserLogout.fulfilled, (state, action) => {
        state.loader = false;
        if (action.payload.status === 200) {
          state.user = null;
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          notify(messages.toast.logout, "success");
        } else if (
          action.payload.response &&
          action.payload.response.status !== 200
        ) {
          notify(action.payload.response.data, "error");
        } else {
          notify(action.payload.message, "error");
        }
      })
      .addCase(reqToUserLogout.rejected, (state, action) => {
        state.loader = false;
        state.error = action.payload;
      });
  },
});

export const {
  updateUserState,
  reqToUserLoginInLocal,
  reqToUserLogoutInLocal,
  forceLogout,
} = userSlice.actions;
export default userSlice.reducer;
