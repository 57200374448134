import React from "react";
import { RotatePhoneGIF } from "../images";

const RotatePhone = ({ show }) => {
  return (
    <div
      className={`rotate-phone-content ${show && "rotate-phone-content-show"}`}
    >
      <img src={RotatePhoneGIF} />
      <h3>Please rotate your device!</h3>
    </div>
  );
};

export default RotatePhone;
