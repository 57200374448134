import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout } from "./userSlice";

// Request to get quickSign count Data
export const reqToGetQuickSignCount = createAsyncThunk(
  "reqToGetQuickSignCount",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignCount,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Request to get quickSign list Data
export const reqToGetQuickSignlist = createAsyncThunk(
  "reqToGetQuickSignlist",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignlist,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Request to get quickSign MD list list Data
export const reqToGetQuickSignMDList = createAsyncThunk(
  "reqToGetQuickSignMDList",
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.quickSignmdList,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to add Signature
export const reqToAddSignature = createAsyncThunk(
  "reqToAddSignature",
  async ({ data, onSuccess, onFailure }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addSignature,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

// Request to delete Signature
export const reqToDeleteSignature = createAsyncThunk(
  "reqToDeleteSignature",
  async ({ data, onSuccess, onFailure }) => {
    try {
      const response = await Axios.post(
        apiendpoints.deleteSignature,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

// Request to add MD
export const reqToAddMD = createAsyncThunk(
  "reqToAddMD",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addMD,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to Update quickSign
export const reqToUpdateQuickSign = createAsyncThunk(
  "reqToUpdateQuickSign",
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.quickSignUpdate,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get incidentalOptionss
export const reqToGetIncidentalOptions = createAsyncThunk(
  "reqToGetIncidentalOptions",
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.incidentalOptions,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// request to get location
export const reqToGetAddressFromLatLong = createAsyncThunk(
  "reqToGetAddressFromLatLong",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.getLocation,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        data.onSuccess(response);
      } else {
        notify("something went wrong!");
      }
      return response;
    } catch (error) {
      data.onFailure(error);
      return error;
    }
  }
);

// Update quick-sign
export const reqToUpdateSignature = createAsyncThunk(
  "reqToUpdateSignature",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.updateQuickSign,
        data.data,
        authHeaders()
      );
      if (response.status === 200) {
        data.onSuccess(response);
      } else {
        notify("something went wrong!");
      }
      return response;
    } catch (error) {
      data.onFailure(error);
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  quickSignCount: "",
  quickSignList: [],
  quickSignMDList: [],
  incidentalOptions: {},
};

// Create quickSign slice
const quickSignSlice = createSlice({
  name: "quickSignSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get quickSignCount data
    builder.addCase(reqToGetQuickSignCount.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetQuickSignCount.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.quickSignCount = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetQuickSignCount.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get quickSign list
    builder.addCase(reqToGetQuickSignlist.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetQuickSignlist.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        let data = action?.payload?.data?.list || [];
        while (data?.length < 10) {
          data.push({});
        }
        state.quickSignList = {
          list: data,
          count: action?.payload?.data?.count,
        };
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetQuickSignlist.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get quickSign MD list
    builder.addCase(reqToGetQuickSignMDList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetQuickSignMDList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.quickSignMDList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetQuickSignMDList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add Signature
    builder.addCase(reqToAddSignature.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddSignature.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddSignature.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Delete Signature
    builder.addCase(reqToDeleteSignature.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToDeleteSignature.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToDeleteSignature.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Add MD
    builder.addCase(reqToAddMD.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToAddMD.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToAddMD.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get incidentalOptions data
    builder.addCase(reqToGetIncidentalOptions.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetIncidentalOptions.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.incidentalOptions = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetIncidentalOptions.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // get location
    builder.addCase(reqToGetAddressFromLatLong.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetAddressFromLatLong.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetAddressFromLatLong.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update signature
    builder.addCase(reqToUpdateSignature.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToUpdateSignature.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        // notify(action.payload.response.data, "error");
      } else {
        // notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToUpdateSignature.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default quickSignSlice.reducer;
