import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout } from "./userSlice";
import { messages } from "../../helpers/messages";

export const reqToGetActiveCycle = createAsyncThunk(
  apiendpoints.activeCycle,
  async ({ onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.get(
        apiendpoints.activeCycle,
        header || authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToGetMDList = createAsyncThunk(
  apiendpoints.mdList,
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.mdList,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

export const reqToGetMDBySearch = createAsyncThunk(
  apiendpoints.searchMD,
  async ({ data }) => {
    try {
      const response = await Axios.post(
        apiendpoints.searchMD,
        data,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetFrequencyList = createAsyncThunk(
  apiendpoints.getFrequency,
  async () => {
    try {
      const response = await Axios.get(
        apiendpoints.getFrequency,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetIncidentalMdList = createAsyncThunk(
  apiendpoints.incidentalMdList,
  async ({}) => {
    try {
      const response = await Axios.get(
        apiendpoints.incidentalMdList,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const reqToGetMDListByMonth = createAsyncThunk(
  `${apiendpoints.mdList}/by/month`,
  async ({ data, onSuccess, onFailure, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.mdList,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      onFailure(error);
      return error;
    }
  }
);

const initialState = {
  loader: false,
  error: "",
  apiMessage: "",
};

const mdListSlice = createSlice({
  name: "mdList",
  initialState,
  reducers: {
    reqToResetMDApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET CYCLE
    builder
      .addCase(reqToGetActiveCycle.pending, (state) => {
        state.apiMessage = messages.apiMessage.cycle;
      })
      .addCase(reqToGetActiveCycle.fulfilled, (state, action) => {})
      .addCase(reqToGetActiveCycle.rejected, (state, action) => {});

    // GET MDLIST
    builder
      .addCase(reqToGetMDList.pending, (state) => {})
      .addCase(reqToGetMDList.fulfilled, (state, action) => {})
      .addCase(reqToGetMDList.rejected, (state, action) => {});

    // GET MDLIST BY SEARCH
    builder
      .addCase(reqToGetMDBySearch.pending, (state) => {})
      .addCase(reqToGetMDBySearch.fulfilled, (state, action) => {})
      .addCase(reqToGetMDBySearch.rejected, (state, action) => {});

    // GET PATIENT DETAILS
    builder
      .addCase(reqToGetFrequencyList.pending, (state) => {})
      .addCase(reqToGetFrequencyList.fulfilled, (state, action) => {})
      .addCase(reqToGetFrequencyList.rejected, (state, action) => {});

    // GET INCIDENTAL MDLIST
    builder
      .addCase(reqToGetIncidentalMdList.pending, (state) => {})
      .addCase(reqToGetIncidentalMdList.fulfilled, (state, action) => {})
      .addCase(reqToGetIncidentalMdList.rejected, (state, action) => {});

    // GET MD LIST BY MONTH
    builder
      .addCase(reqToGetMDListByMonth.pending, (state) => {})
      .addCase(reqToGetMDListByMonth.fulfilled, (state, action) => {})
      .addCase(reqToGetMDListByMonth.rejected, (state, action) => {});
  },
});

export const { reqToResetMDApiMessage } = mdListSlice.actions;
export default mdListSlice.reducer;
