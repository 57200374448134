import React from "react";
import { Button, Modal } from "react-bootstrap";

const OfflineModal = (props) => {
  const { to, onHide, ...rest } = props;

  return (
    <>
      <Modal
        className="tc-main-modal tc-sign-modal"
        {...rest}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ color: "red" }}
          >
            Oops!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mb-3">
            <p className="col-12 modal-title-p">You can't take action,</p>
            <p className="col-12 modal-title-p">Because you are offline.</p>
          </div>

          <div className="text-center">
            <Button variant="primary" onClick={onHide}>
              CLOSE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OfflineModal;
