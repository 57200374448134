import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout } from "./userSlice";
import { messages } from "../../helpers/messages";

export const reqToGetOutOfFieldData = createAsyncThunk(
  apiendpoints.outOfField,
  async ({ onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.get(
        apiendpoints.outOfField,
        header || authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToGetVMCModalData = createAsyncThunk(
  apiendpoints.outOfFieldModalData,
  async ({ onSuccess, onFailure, header }) => {
    try {
      const response = await Axios.get(
        apiendpoints.outOfFieldModalData,
        header || authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToAddOutOfFieldData = createAsyncThunk(
  apiendpoints.addOutOfField,
  async ({ data, onSuccess, onFailure }) => {
    try {
      const response = await Axios.post(
        apiendpoints.addOutOfField,
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      onFailure(error);
      return error;
    }
  }
);

export const reqToGetMDListByOof = createAsyncThunk(
  apiendpoints.getMDListByOof,
  async ({ data, onSuccess, dispatch }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getMDListByOof,
        data,
        authHeaders()
      );
      onSuccess(response.data);
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        dispatch(forceLogout());
      }
      return error;
    }
  }
);

const initialState = {
  loader: false,
  error: "",
  apiMessage: "",
  modalData: {},
};

const outOfFieldSlice = createSlice({
  name: "out-of-field",
  initialState,
  reducers: {
    reqToResetVMCApiMessage: (state, action) => {
      state.apiMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET VMC LIST
    builder
      .addCase(reqToGetOutOfFieldData.pending, (state) => {
        state.apiMessage = messages.apiMessage.vmcList;
      })
      .addCase(reqToGetOutOfFieldData.fulfilled, (state, action) => {})
      .addCase(reqToGetOutOfFieldData.rejected, (state, action) => {});

    // GET VMC MODAL DATA
    builder
      .addCase(reqToGetVMCModalData.pending, (state) => {
        state.apiMessage = messages.apiMessage.vmcModalData;
      })
      .addCase(reqToGetVMCModalData.fulfilled, (state, action) => {})
      .addCase(reqToGetVMCModalData.rejected, (state, action) => {});

    // ADD VMC REQUEST
    builder
      .addCase(reqToAddOutOfFieldData.pending, (state) => {})
      .addCase(reqToAddOutOfFieldData.fulfilled, (state, action) => {})
      .addCase(reqToAddOutOfFieldData.rejected, (state, action) => {});

    // GET MDLIST BY VMC
    builder
      .addCase(reqToGetMDListByOof.pending, (state) => {})
      .addCase(reqToGetMDListByOof.fulfilled, (state, action) => {})
      .addCase(reqToGetMDListByOof.rejected, (state, action) => {});
  },
});

export const { reqToResetVMCApiMessage } = outOfFieldSlice.actions;
export default outOfFieldSlice.reducer;
