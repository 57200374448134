import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import moment from "moment";
import { CloseIcon } from "../../images";
import CustomWebCam from "../CustomWebCam";
import { useMediaQuery } from "react-responsive";
import { isDesktop } from "react-device-detect";

const SignSelfieModal = (props) => {
  const {
    clear,
    errors,
    isSign,
    signUrl,
    selfieUrl,
    setIsSign,
    webcamRef,
    signPadRef,
    signatureData,
    handleSignEnd,
    handleSignBegin,
    handleResetData,
    closeSignSelfieModal,
    handleSaveSignSelfie,
    ...rest
  } = props;

  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    setInterval(() => {
      setTimer(moment().format("MMM D, YYYY h:mm:ss A"));
    }, 1000);
  }, []);

  useEffect(() => {
    if (signPadRef.current && signatureData) {
      signPadRef.current.clear();
      signPadRef.current.fromDataURL(signatureData);
      setTimeout(() => {
        signPadRef.current.clear();
        signPadRef.current.fromDataURL(signatureData);
      }, 100);
    }
  }, [isLandscape, isPortrait]);
  useEffect(() => {
    const handleResize = () => {
      if (signPadRef.current && signatureData) {
        signPadRef.current.clear();
        signPadRef.current.fromDataURL(signatureData);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [signatureData, signPadRef]);

  return (
    <Modal
      className="tc-sign-modal tc-sign-selfie-modal"
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="selfie-modal-body">
        <div className="sign-selfie-modal-container">
          <div className="sign-selfie-modal-header">
            <div style={{ width: "40px" }}></div>
            <div className="sign-selfie-modal-header-text">
              {isSign ? "Signature" : "Selfie"}
            </div>
            <button
              className="sign-selfie-modal-header-close-btn"
              onClick={() => closeSignSelfieModal()}
              style={{ top: "20px", right: "20px" }}
            >
              <img src={CloseIcon} alt="close" />
            </button>
          </div>
          {isSign && !signUrl ? (
            <>
              <div className="sign-selfie-modal-current-time">
                Date: {timer}
              </div>
              <SignaturePad
                maxWidth={3}
                minWidth={3}
                canvasProps={{
                  className: "sign-selfie-quicksign-signaturepad",
                  id: "sign-pad",
                }}
                ref={signPadRef}
                onEnd={() => handleSignEnd()}
                onBegin={() => handleSignBegin()}
              />
            </>
          ) : (
            !isSign &&
            !selfieUrl &&
            isDesktop && <CustomWebCam webcamRef={webcamRef} />
          )}
          {((isSign && signUrl) || (!isSign && selfieUrl)) && (
            <div className={`sign-selfie-modal-preview`}>
              <img
                className="img-fluid"
                src={isSign ? signUrl : selfieUrl}
                alt="sign-url"
              />
            </div>
          )}
        </div>
        {errors && errors?.signErr && (
          <span className="tc-error">{errors?.signErr}</span>
        )}
        <div
          className="tc-md-list-detail-bottom-btn-content tc-quick-sign-btn-row"
          style={{ marginTop: 0 }}
        >
          {isSign && !signUrl && (
            <Button type="button" variant="danger" onClick={() => clear()}>
              Erase
            </Button>
          )}
          {((isSign && signUrl) || (!isSign && selfieUrl)) && (
            <>
              <Button
                type="button"
                variant="danger"
                onClick={() => handleResetData(isSign ? true : false)}
              >
                Erase
              </Button>
              {!isSign && (
                <Button type="button" onClick={() => setIsSign(true)}>
                  Next
                </Button>
              )}
            </>
          )}
          {((isSign && !signUrl) || (!isSign && !selfieUrl)) && (
            <Button
              type="button"
              onClick={() => handleSaveSignSelfie(isSign ? true : false)}
            >
              {isSign ? "Save" : "Capture"}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignSelfieModal;
