import React, { useEffect, useState } from "react";
import { OutOfFieldIcon } from "../../../icon/icon";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  reqToGetAuditReport,
  reqToGetDMList,
  reqToGetTmListByDM,
} from "../../../store/slice/auditReportSlice";
import {
  dateFormatting,
  dateWithTimeFormat,
  defaultDateFormat,
  nameFormatting,
  getSeconds,
  timeWithHoursFormat,
} from "../../../helpers/commonFunction";
import { Loader } from "../../../components";

const reports = [
  {
    id: 1,
    type: "DOC",
    name: "Duration of Call",
  },
  {
    id: 2,
    type: "DOMDS",
    name: "Duration of MD Signatures",
  },
  {
    id: 3,
    type: "DOPD",
    name: "Duration of Product Detailing",
  },
];

const AuditReport = () => {
  const dispatch = useDispatch();

  // Get reducer data
  const auditReportReducer = useSelector((state) => state.auditReportReducer);
  const { loader, dMList, tmList, auditReportList } = auditReportReducer;

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedReport, setSelectedReport] = useState({
    ...reports[0],
    value: reports[0].name,
    label: reports[0].name,
  });
  const [selectedDM, setSelectedDM] = useState();
  const [selectedTM, setSelectedTM] = useState();

  useEffect(() => {
    dispatch(reqToGetDMList({ data: "", onSuccess: () => {}, dispatch }));
  }, []);

  const reportOptions =
    (reports &&
      reports?.length > 0 &&
      reports?.map((item) => {
        return {
          ...item,
          value: item?.name,
          label: item?.name,
        };
      })) ||
    [];

  const dmListOptions =
    (dMList &&
      dMList?.length > 0 &&
      dMList?.map((item) => {
        return {
          ...item,
          value: nameFormatting(item),
          label: nameFormatting(item),
        };
      })) ||
    [];

  const tmListOptions =
    (tmList &&
      tmList?.length > 0 &&
      tmList?.map((item) => {
        return {
          ...item,
          value: nameFormatting(item),
          label: item?.terr_code + " - " + nameFormatting(item),
        };
      })) ||
    [];

  const handleChangeValues = (event, type, name) => {
    if (type === "option") {
      if (name === "DM") {
        setSelectedDM(event);
        setSelectedTM("");
        dispatch(
          reqToGetTmListByDM({
            data: { dm_id: event.id },
            onSuccess: () => {},
            dispatch,
          })
        );
      } else if (name === "TM") {
        setSelectedTM(event);
        dispatch(
          reqToGetAuditReport({
            data: { user_id: event.user_id, date: selectedDate },
            onSuccess: () => {},
            dispatch,
          })
        );
      } else if (name === "report") {
        setSelectedReport(event);
      }
    } else {
      setSelectedDate(dateFormatting(event));
      dispatch(
        reqToGetAuditReport({
          data: { user_id: selectedTM.user_id, date: dateFormatting(event) },
          onSuccess: () => {},
          dispatch,
        })
      );
    }
  };

  const tableHeaders = (reportType) => {
    let headList = [];
    if (reportType === "DOC") {
      headList = ["Date", "MD Name", "Start Time", "End Time"];
    } else if (reportType === "DOMDS") {
      headList = [
        "Date and Time",
        "MD Name",
        "# of Erasures",
        "Time Completed",
      ];
    } else if (reportType === "DOPD") {
      headList = ["Date", "MD Name", "Product", "Start Time", "End Time"];
    }
    return headList;
  };

  const tableBody = (reportType) => {
    if (reportType === "DOC") {
      if (auditReportList && auditReportList?.length > 0) {
        return auditReportList.map((item, i) => (
          <tr key={i}>
            <td>
              {item?.record_time ? defaultDateFormat(item?.record_time) : "-"}
            </td>
            <td>{nameFormatting(item)}</td>
            <td>
              {item?.start_time ? timeWithHoursFormat(item?.start_time) : "-"}
            </td>
            <td>
              {item?.record_time ? timeWithHoursFormat(item?.record_time) : "-"}
            </td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={4}>No data found</td>
          </tr>
        );
      }
    } else if (reportType === "DOMDS") {
      if (auditReportList && auditReportList?.length > 0) {
        return auditReportList.map((item, i) => (
          <tr key={i}>
            <td>
              {item?.record_time ? dateWithTimeFormat(item?.record_time) : "-"}
            </td>
            <td>{nameFormatting(item)}</td>
            <td>{item?.signature_erase}</td>
            <td>{getSeconds(item?.signature_time)}</td>
          </tr>
        ));
      } else {
        return (
          <tr>
            <td colSpan={4}>No data found</td>
          </tr>
        );
      }
    } else if (reportType === "DOPD") {
      if (auditReportList && auditReportList?.length > 0) {
        return auditReportList.map(
          (item, i) =>
            item.productDetailing?.length > 0 &&
            item?.productDetailing?.map((product) => (
              <tr key={i}>
                <td>
                  {item?.record_time
                    ? defaultDateFormat(item.record_time)
                    : "-"}
                </td>
                <td>{nameFormatting(item)}</td>
                <td>{product?.name ? product.name : "-"}</td>
                <td>
                  {product?.product_start_time
                    ? timeWithHoursFormat(product.product_start_time)
                    : "-"}
                </td>
                <td>
                  {product?.product_end_time
                    ? timeWithHoursFormat(product.product_end_time)
                    : "-"}
                </td>
              </tr>
            ))
        );
      } else {
        return (
          <tr>
            <td colSpan={5}>No data found</td>
          </tr>
        );
      }
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="tc-body tc-audit-report-container">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="tc-profile-wrapper mb-0">
            <Select
              name="option"
              value={selectedReport}
              onChange={(e) => handleChangeValues(e, "option", "report")}
              options={reportOptions}
              className={`tc-custom-select-2 min-width-350`}
              classNamePrefix="select"
              placeholder="Select..."
            />
          </div>
          <div className="text-end fs-18">
            <p className="mb-0 fw-semibold">
              Today:{" "}
              {selectedDate
                ? moment(selectedDate).format("MMM DD, YYYY")
                : moment().format("MMM DD, YYYY")}
              <label
                htmlFor="selectedDate"
                className={`${
                  (!selectedDM || !selectedTM) && "tc-audit-date-disabled"
                }`}
              >
                {OutOfFieldIcon}
              </label>
              <ReactDatePicker
                onChange={(e) => handleChangeValues(e, "date", "date")}
                id="selectedDate"
                name="selectedDate"
                dateFormat="yyyy-MM-dd"
                value={selectedDate}
                minDate={moment().startOf("month")._d}
                maxDate={moment()._d}
                className="d-none"
                selected={selectedDate ? new Date(selectedDate) : new Date()}
                onKeyDown={(e) => e.preventDefault()}
                disabled={!selectedDM || !selectedTM}
              />
            </p>
          </div>
        </div>
        <div className="add-scroll">
          <div className="tc-content-wrapper">
            <div className="tc-card-input-container">
              <div className="tc-card-input-label">
                <span className="">District:</span>
                <Select
                  name="option"
                  value={selectedDM}
                  onChange={(e) => handleChangeValues(e, "option", "DM")}
                  options={dmListOptions}
                  className={`tc-custom-select-2 tc-audit-report-select`}
                  classNamePrefix="select"
                  placeholder="Select..."
                />
              </div>
              <div className="tc-card-input-label">
                <span>Territory Manager:</span>
                <Select
                  name="option"
                  value={selectedTM}
                  onChange={(e) => handleChangeValues(e, "option", "TM")}
                  options={tmListOptions}
                  className={`tc-custom-select-2 tc-audit-report-select`}
                  classNamePrefix="select"
                  placeholder="Select..."
                />
              </div>
            </div>
            <div className="tc-table-wrapper">
              <Table responsive className="tc-table">
                <thead>
                  <tr>
                    {tableHeaders(selectedReport.type).map((item, i) => (
                      <td
                        key={i}
                        className={`${
                          selectedReport.type === "DOMDS"
                            ? i === 0
                              ? "text-center min-width-250"
                              : i === 1
                              ? "text-center min-width-350"
                              : i === 2 || i === 3
                              ? "text-center"
                              : null
                            : selectedReport.type === "DOC"
                            ? "text-center"
                            : selectedReport.type === "DOPD"
                            ? "text-center"
                            : null
                        }`}
                      >
                        {item}
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>{tableBody(selectedReport.type)}</tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditReport;
