import React from "react";
import { DataNotFoundIcon } from "../images";
import { messages } from "../helpers/messages";

const DataNotFound = (props) => {
  return (
    <div className="tc-datanot-found mt-5">
      <img src={DataNotFoundIcon} alt={messages.toast.nodata} />
      <p>{props?.name ? props.name : messages.toast.nodata}</p>
    </div>
  );
};

export default DataNotFound;
