import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";
import {
  MissedCallIcon,
  SearchIcon,
  aToZIcon,
  cakeIcon2,
  callIcon,
  checkBorderIcon,
  editIcon2,
} from "../../icon/icon";
import { routes } from "../../helpers/constants";
import { DataNotFound, CustomDateInput } from "../../components";
import { BirthDayModal } from "../../components/Modals";
import {
  handleVisits,
  nameFormatting,
  defaultDateFormat,
  getNewMDListByRecordDate,
} from "../../helpers/commonFunction";
import { getMediaFileFromDB } from "../../helpers/offlineAPIs";
import { messages } from "../../helpers/messages";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../helpers/db";
import dataURLtoBlob from "blueimp-canvas-to-blob";

const MdList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getMDListFromDB = useLiveQuery(() => db.mdList.toArray(), []);
  const getHolidaysListFromDB = useLiveQuery(() => db.holidays.toArray(), []);
  const getActiveCycleFromDB = useLiveQuery(() => db.activeCycle.toArray(), []);
  const getDisableMdsListFromDB = useLiveQuery(
    () => db.disabled_mds.toArray(),
    []
  );

  const currentDate = moment().format("YYYY-MM-DD");
  const [selectedDate, setSelectedDate] = useState(
    location?.state?.selectedDate && location?.state?.selectedDate
  );
  const [isLoading, setIsLoading] = useState(true);
  const [mdListData, setMdListData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totCallWithMD, setTotCallWithMD] = useState(0);
  const [disableMDs, setDisableMDs] = useState({
    md_ids: [],
    disable_all: 0,
  });
  const [holidays, setHolidays] = useState({
    date: "",
    holiday_all: 0,
  });
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [selectedMD, setSelectedMD] = useState("");
  const [birthDateModal, setBirthDateModal] = useState(false);
  const handleBirthDayModal = () => {
    setBirthDateModal(!birthDateModal);
  };

  useEffect(() => {
    if (mdListData && mdListData?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [mdListData]);

  useEffect(() => {
    if (getMDListFromDB && getMDListFromDB?.length > 0) {
      handleSearchMDList("date", selectedDate);
    }
  }, [
    selectedDate,
    getMDListFromDB,
    getDisableMdsListFromDB,
    getHolidaysListFromDB,
  ]);

  useEffect(() => {
    if (getActiveCycleFromDB && getActiveCycleFromDB?.length > 0) {
      const cycle = getActiveCycleFromDB && getActiveCycleFromDB[0];
      setMinDate(new Date(cycle.start));
      setMaxDate(new Date(cycle.end));
    }
  }, [getActiveCycleFromDB]);

  const handleOnChange = (event) => {
    if (event.target.value === "") {
      setSearchValue(event.target.value);
      handleSearchMDList("date", selectedDate);
    } else {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    }
  };

  const handleSearchMDList = async (searchBy, value) => {
    try {
      const updatedMDList = await getNewMDListByRecordDate(getMDListFromDB);
      if (searchBy === "date") {
        const newList = updatedMDList.filter((item) => item.date === value);
        // console.log("newList", newList);
        setMdListData(newList);
        const getVisitedMD =
          newList &&
          newList.filter((item) => item.is_summary === 1 || item.is_save === 1);
        setTotCallWithMD(getVisitedMD?.length);
        let sDate = value ? value : currentDate;
        setSelectedDate(sDate);
        const getDisabledMds =
          getDisableMdsListFromDB &&
          getDisableMdsListFromDB?.length > 0 &&
          getDisableMdsListFromDB?.filter((item) => item.date === sDate);
        handleGetMDListByOof(getDisabledMds);
        const getHolidays =
          getHolidaysListFromDB &&
          getHolidaysListFromDB?.length > 0 &&
          getHolidaysListFromDB?.filter((item) => item.date === sDate);
        setHolidays({
          ...getHolidays,
          date: getHolidays?.length > 0 ? getHolidays[0].date : "",
          holiday_all: getHolidays?.length > 0 ? getHolidays[0].holiday_all : 0,
        });
      } else {
        const previousNewList = updatedMDList.filter(
          (item) => item.date === selectedDate
        );
        const newList = previousNewList.filter(
          (item) =>
            `${item.lastname.trim()} ${item.firstname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            `${item.firstname.trim()} ${item.lastname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase())
        );
        const finalList =
          newList.length > 0 ? newList : !value ? previousNewList : [];
        setMdListData(finalList);
        const getVisitedMD =
          finalList &&
          finalList.filter(
            (item) => item.is_summary === 1 || item.is_save === 1
          );
        setTotCallWithMD(getVisitedMD?.length);
      }
    } catch (error) {
      console.error("Error searching MD list by date:", error);
    }
  };

  const handleGetMDListByOof = (data) => {
    if (data && data?.length > 0) {
      setDisableMDs({
        ...disableMDs,
        md_ids: data[0]?.md_ids,
        disable_all: data[0]?.disable_all,
      });
    } else {
      setDisableMDs({
        ...disableMDs,
        md_ids: [],
        disable_all: 0,
      });
    }
  };

  const handleCallActivitiesOfMD = async (item) => {
    if (item && item.md_id) {
      let saveData = "";
      let editableData = "";
      let quickSignObj = "";
      if (item?.is_save === 1) {
        saveData = item?.save_data ? JSON.parse(item?.save_data) : "";
        let quickSignSData = saveData ? saveData?.quickSignObj : "";
        if (quickSignSData?.signature_url) {
          delete quickSignSData.signPadData;
          const base64 = await getMediaFileFromDB(
            quickSignSData,
            "signature_url",
            "signPadData"
          );
          quickSignObj = { ...quickSignSData, signPadData: base64 };
        } else if (quickSignSData?.signPadData) {
          const blobData = dataURLtoBlob(quickSignSData?.signPadData);
          quickSignObj = { ...quickSignSData, signature_url: blobData };
        } else {
          quickSignObj = quickSignSData;
        }
        editableData = {
          data: saveData,
          save_id: item?.save_id,
          s_record_time: new Date(item?.save_record_date),
          s_target_date: item?.save_target_date,
          s_visited_date: item?.save_visited_date,
        };
      }
      navigate(routes.callActivities, {
        state: {
          selectedMD: item,
          reqObj: { md_id: item.md_id },
          prevPath: location.pathname,
          quickSignObj: quickSignObj,
          selectedDate: item.date,
          start_time:
            saveData && saveData?.start_time
              ? new Date(saveData?.start_time)
              : new Date(),
          editableData: editableData ? editableData : "",
        },
      });
    }
  };

  const handleViewCallSummary = async (item) => {
    const newData = { ...item.data };
    if (newData?.signature_url) {
      const base64 = await getMediaFileFromDB(
        newData,
        "signature_url",
        "signPadData"
      );
      newData.signPadData = base64;
    }
    if (newData?.jointCall?.signature_url) {
      const base64 = await getMediaFileFromDB(
        newData?.jointCall,
        "signature_url",
        "signPadData"
      );
      newData.jointCall.signPadData = base64;
    }
    navigate(routes.viewCallSummary, {
      state: { mdData: { ...item, data: newData }, callDate: selectedDate },
    });
  };

  const handleMDBirthDay = (mdData) => {
    setSelectedMD(mdData);
    handleBirthDayModal();
  };

  const compareDates = (birthDate) => {
    const date1 = new Date(birthDate);
    const date2 = new Date(selectedDate);

    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();

    const dayMonth1 = date1.getDate() + "-" + (date1.getMonth() + 1);
    const dayMonth2 = date2.getDate() + "-" + (date2.getMonth() + 1);

    const yearsDifference = Math.abs(year2 - year1);
    const sameDatMonth = dayMonth1 === dayMonth2;
    return {
      sameDatMonth,
      yearsDifference,
    };
  };

  const columns = [
    {
      name: "Class",
      sortable: true,
      selector: (row) => row?.name,
      cell: (row) => (
        <p
          className={`tc-data-table-class-text text-center w-100 ${
            row?.is_summary === 0 &&
            (disableMDs?.disable_all === 1 ||
              holidays?.holiday_all === 1 ||
              row?.is_consecutive === 1 ||
              disableMDs?.md_ids?.includes(row?.md_id) ||
              row?.is_week_call === 1)
              ? "tc-table-row-disabled"
              : ""
          }`}
        >
          {row?.name}
        </p>
      ),
    },
    {
      name: "Md Name",
      sortable: true,
      selector: (row) => row?.firstname.concat(" ", row?.lastname),
      cell: (row) => (
        <>
          {row?.is_summary === 0 ? (
            <p
              role="button"
              className={`tc-data-table-md-name-text text-start ${
                disableMDs?.disable_all === 1 ||
                holidays?.holiday_all === 1 ||
                row?.is_visited === 1 ||
                row?.is_consecutive === 1 ||
                disableMDs?.md_ids?.includes(row?.md_id) ||
                row?.is_week_call === 1
                  ? "tc-table-row-disabled"
                  : ""
              }`}
              onClick={() => handleCallActivitiesOfMD(row)}
            >
              {nameFormatting(row)}
            </p>
          ) : (
            <p
              role="button"
              className="tc-data-table-md-name-text text-start"
              onClick={() => handleViewCallSummary(row)}
            >
              {nameFormatting(row)}
            </p>
          )}
          {row?.birthdate && compareDates(row.birthdate).sameDatMonth ? (
            <span
              className="ms-2 tc-data-table-icon3"
              role="button"
              onClick={() => handleMDBirthDay(row)}
            >
              {cakeIcon2}
            </span>
          ) : (
            <span className="ms-2 tc-data-table-icon3-nodata"></span>
          )}
        </>
      ),
    },
    {
      name: "Visits",
      sortable: true,
      selector: (row) => handleVisits(row?.actual_visits, row?.target),
      cell: (row) => (
        <p
          className={`tc-data-table-visits-text tc-data-table-green-text ${
            row?.is_summary === 0 &&
            (disableMDs?.disable_all === 1 ||
              holidays?.holiday_all === 1 ||
              row?.is_consecutive === 1 ||
              disableMDs?.md_ids?.includes(row?.md_id) ||
              row?.is_week_call === 1)
              ? "tc-table-row-disabled"
              : ""
          }`}
        >
          {handleVisits(row?.actual_visits, row?.target)}
        </p>
      ),
    },
    {
      name: "Call Status",
      sortable: true,
      selector: (row) => row.call,
      cell: (row) =>
        row?.is_summary === 0 ? (
          <button
            className={`tc-data-table-button tc-data-table-icon ${
              row?.is_save === 1 && "tc-table-edit-icon"
            }`}
            onClick={() => handleCallActivitiesOfMD(row)}
            disabled={
              disableMDs?.disable_all === 1 ||
              holidays?.holiday_all === 1 ||
              row.is_visited === 1 ||
              row.is_consecutive === 1 ||
              disableMDs?.md_ids?.includes(row?.md_id) ||
              row?.is_week_call === 1
            }
          >
            {row?.is_save === 1 ? editIcon2 : callIcon}
          </button>
        ) : (
          <div
            className="tc-data-table-button-check-icon tc-data-table-icon"
            onClick={() => handleViewCallSummary(row)}
          >
            {checkBorderIcon}
          </div>
        ),
    },
  ];

  return (
    <>
      <div className="tc-body">
        <div className="tc-profile-wrapper">
          <span className="tc-profile-name">ITINERARY</span>
        </div>
        <div className="tc-content-wrapper">
          <h2>MDs TO VISIT</h2>
          <div className="tc-calender-search-input-row justify-content-between">
            <div>
              <h6>DATE</h6>
              <div className="tc-calender-input-content calendar-icon-md">
                <div className="position-relative">
                  <ReactDatePicker
                    selected={moment(selectedDate)._d}
                    onChange={(e) =>
                      handleSearchMDList("date", moment(e).format("YYYY-MM-DD"))
                    }
                    id="date-picker-md"
                    dateFormat="yyyy-MM-dd"
                    value={defaultDateFormat(selectedDate)}
                    minDate={minDate}
                    maxDate={maxDate}
                    onKeyDown={(e) => e.preventDefault()}
                    disabled={searchValue !== ""}
                    customInput={<CustomDateInput />}
                  />
                </div>
                <label
                  className="tc-atoz-icon"
                  onClick={() => navigate(routes.monthlyMdList)}
                >
                  {aToZIcon}
                </label>
                <label
                  className="tc-atoz-icon"
                  onClick={() => navigate(routes.missedCallMdList)}
                >
                  {MissedCallIcon}
                </label>
              </div>
            </div>
            <div className="d-flex flex-column align-items-end">
              <div className="ms-auto d-flex align-items-center">
                <div className="tc-search-input-content d-flex">
                  <input
                    id="text"
                    type="text"
                    placeholder="Search..."
                    value={searchValue}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <button
                    type="button"
                    className="tc-search-input-btn"
                    onClick={() => handleSearchMDList("name", searchValue)}
                    disabled={searchValue === "" && true}
                  >
                    {SearchIcon}
                  </button>
                </div>
              </div>
              <h6 className="mt-3 mb-0">
                Total MDs:{" "}
                {mdListData && mdListData?.length > 0
                  ? `${totCallWithMD}/${mdListData?.length}`
                  : 0}
              </h6>
            </div>
          </div>
          {isLoading ? null : mdListData && mdListData?.length === 0 ? (
            <DataNotFound name={messages.toast.nodateData} />
          ) : (
            <DataTable
              columns={columns}
              data={mdListData && mdListData}
              className={`tc-data-table flex-grow-1 overflow-y-auto tc-height-340 border-radius-12 ${
                mdListData &&
                mdListData?.length === 0 &&
                "d-flex align-items-center"
              }`}
            />
          )}
        </div>
      </div>

      {birthDateModal && (
        <BirthDayModal
          show={birthDateModal}
          onHide={handleBirthDayModal}
          selectedMD={selectedMD}
          compareDates={compareDates}
        />
      )}
    </>
  );
};

export default MdList;
