import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const ItineraryConfirmation = (props) => {
  const { addItineraryHandler, itineraryMethod, ...rest } = props;
  return (
    <>
      <Modal
        {...rest}
        className="tc-main-modal tc-sign-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-dark">
            {itineraryMethod.value === "ADD"
              ? "Add"
              : itineraryMethod.value === "EDIT"
                ? "Edit"
                : "Remove"}{" "}
            Itinerary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-detail-content">
            <div className="modal-detail-main-text">Are you sure?</div>
            <div className="modal-detail-sub-text">
              You want to{" "}
              {itineraryMethod.value === "ADD"
                ? "Add"
                : itineraryMethod.value === "EDIT"
                  ? "Edit"
                  : "Remove"}{" "}
              Itinerary?
            </div>
          </div>
          <div className="modal-btn-row">
            <Button
              variant="danger"
              type="button"
              onClick={() => props.onHide()}
            >
              CANCEL
            </Button>
            <Button
              variant="primary"
              type="button"
              className="ms-2"
              onClick={() => addItineraryHandler()}
            >
              {itineraryMethod.value === "ADD"
                ? "ADD"
                : itineraryMethod.value === "EDIT"
                  ? "EDIT"
                  : "Remove"}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ItineraryConfirmation;
