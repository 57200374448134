import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { toast } from "react-toastify";
import { forceLogout } from "./userSlice";

export const tacticalProductList = createAsyncThunk(
  "tacticalProductList",
  async (data) => {
    try {
      const response = await Axios.get(`/tactical/product`, authHeaders());
      return response.data;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

export const tacticalActivityList = createAsyncThunk(
  "tacticalActivityList",
  async (data) => {
    try {
      const response = await Axios.get(
        `/tactical/activity-list`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

export const mdNameTacticalList = createAsyncThunk(
  "mdNameTacticalList",
  async (data) => {
    try {
      const response = await Axios.get(
        `/quick-sign/md-list-quick-sign`,
        authHeaders()
      );
      return response.data;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

export const addTacticalObjects = createAsyncThunk(
  "addTacticalObjects",
  async (data) => {
    try {
      const response = await Axios.post(
        `/tactical/add-tactical`,
        data.data,
        authHeaders()
      );
      if (response.status === 201 || response.status === 200) {
        toast.success(data.toast, { autoClose: 3000 });
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: 3000 });
    }
  }
);

const tacticalSlice = createSlice({
  name: "tactical",
  initialState: {
    mdName: [],
    product: [],
    activity: [],
    loader: false,
    error: "",
    addTacticals: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //GET TACTICAL PRODUCT LIST
    builder.addCase(tacticalProductList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(tacticalProductList.fulfilled, (state, action) => {
      state.loader = false;
      state.product = action.payload;
    });
    builder.addCase(tacticalProductList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //GET TACTICAL ACTIVITY LIST
    builder.addCase(tacticalActivityList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(tacticalActivityList.fulfilled, (state, action) => {
      state.loader = false;
      state.activity = action.payload;
    });
    builder.addCase(tacticalActivityList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //GET MD NAME LIST
    builder.addCase(mdNameTacticalList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(mdNameTacticalList.fulfilled, (state, action) => {
      state.loader = false;
      state.mdName = action.payload;
    });
    builder.addCase(mdNameTacticalList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    //ADD TACTICAL OBJECTS
    builder.addCase(addTacticalObjects.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addTacticalObjects.fulfilled, (state, action) => {
      state.loader = false;
      state.addTacticals = action.payload;
    });
    builder.addCase(addTacticalObjects.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default tacticalSlice.reducer;
