import React, { useEffect, useRef, useState } from "react";
import { backBtnIcon, deleteIcon, zoomIn } from "../../icon/icon";
import { Button, Table } from "react-bootstrap";
import SignaturePad from "react-signature-canvas";
import { useDispatch, useSelector } from "react-redux";
import {
  dateWithTimeFormat,
  nameFormatting,
  getTimeDifference,
} from "../../helpers/commonFunction";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { DoctorIcon } from "../../images";
import { DataNotFound, CallActivitiesMDDetails } from "../../components";
import { SignZoomModal } from "../../components/Modals";
import { useMediaQuery } from "react-responsive";
import { reqToUpdateJointCallObj } from "../../store/slice/callRecordingSlice";

const JointCall = (props) => {
  const {
    location,
    isLoading,
    selectedMD,
    showSidebar,
    selectedActivity,
    handleCallActivitiesSidebar,
    updateCallActivitieData,
    savedCallData,
    handleSaveCallRecordingData,
  } = props;
  const dispatch = useDispatch();
  const savedCall = savedCallData && savedCallData?.data?.jointCall;
  const { jointCallData, activities, jointCObj, callActivitieData } =
    useSelector((state) => state.callRecordingReducer);

  const [changeStatus, setChangeStatus] = useState(false);
  const [mdData, setMdData] = useState("Select");
  const [noteData, setNoteData] = useState("");
  const [errors, setErrors] = useState({
    joinCall: "",
    dmSign: "",
  });
  const smallPadRef = useRef(null);
  const bigPadRef = useRef(null);
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });
  const [signatureData, setSignatureData] = useState("");
  const [bigPadSigned, setBigPadSigned] = useState(false);
  const [viewSignModal, setViewSignModal] = useState(false);
  const [signTimeCount, setSignTimeCount] = useState({
    eraseCount: 0,
    signStart: "",
    signEnd: "",
    signDrawTime: 0,
  });

  const clearSingAndFileData = () => {
    if (!smallPadRef?.current?.isEmpty()) {
      setSignTimeCount({
        ...signTimeCount,
        eraseCount: signTimeCount.eraseCount + 1,
        signDrawTime: 0,
        signStart: "",
        signEnd: "",
      });
      smallPadRef?.current?.clear();
      if (!bigPadRef?.current?.isEmpty()) {
        bigPadRef?.current?.clear();
      }
    } else if (!bigPadRef?.current?.isEmpty()) {
      setSignTimeCount({
        ...signTimeCount,
        eraseCount: signTimeCount.eraseCount + 1,
        signDrawTime: 0,
        signStart: "",
        signEnd: "",
      });
      bigPadRef?.current?.clear();
      if (!smallPadRef?.current?.isEmpty()) {
        smallPadRef?.current?.clear();
      }
    }
    setBigPadSigned(false);
    setSignatureData("");
  };

  useEffect(() => {
    if (showSidebar === true) {
      setMdData("Select");
      setNoteData("");
      setErrors({
        ...errors,
        joinCall: "",
        dmSign: "",
      });
      if (Object.keys(jointCObj && jointCObj)?.length === 0) {
        clearSingAndFileData();
        setSignTimeCount({
          eraseCount: 0,
          signStart: "",
          signEnd: "",
          signDrawTime: 0,
        });
      } else {
        reStoreJointCallDetails();
      }
    }
  }, [showSidebar]);

  const reStoreJointCallDetails = () => {
    if (jointCObj && jointCObj?.signPadData) {
      setNoteData(jointCObj?.notes);
      smallPadRef?.current?.clear();
      setSignatureData(jointCObj?.signature_url);
      smallPadRef?.current?.fromDataURL(jointCObj?.signPadData);
      setSignTimeCount({
        ...signTimeCount,
        eraseCount: jointCObj.eraseCount,
        signDrawTime: jointCObj.speed,
      });
    } else if (jointCObj && jointCObj?.notes) {
      setNoteData(jointCObj?.notes);
      smallPadRef?.current?.clear();
      setSignTimeCount({
        ...signTimeCount,
        eraseCount: jointCObj.eraseCount,
        signDrawTime: jointCObj.speed,
      });
    }
  };

  useEffect(() => {
    if (Object.keys(jointCObj)?.length > 0) {
      updateCallActivitieData(activities.jointCall);
    }
  }, [jointCObj]);

  const handleJointCallValues = (event) => {
    const { name, value } = event.target;
    setChangeStatus(true);
    if (name === "option") {
      const newData =
        jointCallData &&
        jointCallData?.find((item) => item.id === Number(value));
      setMdData(newData);
      setErrors({
        ...errors,
        joinCall: "",
      });
      setErrors({
        ...errors,
        joinCall: "",
      });
    } else if (name === "notes") {
      setNoteData(value);
    }
  };

  const handleSignBegin = () => {
    setSignTimeCount({
      ...signTimeCount,
      signStart: new Date(),
    });
  };
  const handleSmallPadEnd = () => {
    if (!smallPadRef?.current?.isEmpty()) {
      setErrors({
        ...errors,
        dmSign: "",
      });
    }
    setChangeStatus(true);
    setSignatureData(smallPadRef?.current?.toDataURL());
    const timeDiff = getTimeDifference(signTimeCount.signStart, new Date());
    setSignTimeCount({
      ...signTimeCount,
      signEnd: new Date(),
      signDrawTime: signTimeCount.signDrawTime + timeDiff,
    });
  };
  const handleBigPadEnd = () => {
    if (!bigPadRef?.current?.isEmpty()) {
      setErrors({
        ...errors,
        dmSign: "",
      });
    }
    setBigPadSigned(true);
    setChangeStatus(true);
    setSignatureData(bigPadRef?.current?.toDataURL());
    const timeDiff = getTimeDifference(signTimeCount.signStart, new Date());
    setSignTimeCount({
      ...signTimeCount,
      signEnd: new Date(),
      signDrawTime: signTimeCount.signDrawTime + timeDiff,
    });
  };
  const openBigSignModal = () => {
    setViewSignModal(true);
    if (smallPadRef.current && bigPadRef.current) {
      bigPadRef.current.clear();
      bigPadRef.current.fromDataURL(smallPadRef.current.toDataURL());
    }
  };
  const closeBigSignModal = () => {
    setViewSignModal(false);
    if (bigPadSigned) {
      setSignatureData(bigPadRef?.current?.toDataURL());
      if (smallPadRef.current) {
        smallPadRef.current.clear();
        smallPadRef.current.fromDataURL(bigPadRef.current.toDataURL());
      }
      setBigPadSigned(false);
    }
  };
  useEffect(() => {
    if (viewSignModal && smallPadRef.current && bigPadRef.current) {
      bigPadRef.current.clear();
      bigPadRef.current.fromDataURL(smallPadRef.current.toDataURL());
    }
  }, [viewSignModal]);
  useEffect(() => {
    if (bigPadRef.current && signatureData) {
      bigPadRef.current.fromDataURL(signatureData);
    }
  }, [signatureData]);
  useEffect(() => {
    if (smallPadRef.current && signatureData) {
      smallPadRef.current.clear();
      smallPadRef.current.fromDataURL(signatureData);
      setTimeout(() => {
        smallPadRef.current.clear();
        smallPadRef.current.fromDataURL(signatureData);
      }, 100);
    }
  }, [isLandscape, isPortrait]);
  useEffect(() => {
    const handleResize = () => {
      if (smallPadRef.current && signatureData) {
        smallPadRef.current.clear();
        smallPadRef.current.fromDataURL(signatureData);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [signatureData, smallPadRef]);

  const handleJointCallDataObj = (btnType, bigPad) => {
    setViewSignModal(false);
    if (changeStatus) {
      let actualPad = bigPad ? bigPadRef : smallPadRef;
      const canvas = !actualPad?.current?.isEmpty()
        ? actualPad.current.toDataURL("image/png")
        : "";
      const blobData = canvas ? dataURLtoBlob(canvas) : "";
      const tempObj = {
        notes_id: jointCallData?.length > 0 ? jointCallData[0]?.id : 1,
        name: jointCallData?.length > 0 && jointCallData[0]?.name,
        notes: noteData,
        signature_url: blobData,
        signature_type: "SIGNATURE",
        dm_name: callActivitieData[3]?.dm_name
          ? callActivitieData[3]?.dm_name
          : "",
        speed: signTimeCount?.signDrawTime,
        eraseCount: signTimeCount?.eraseCount,
        signPadData: canvas || "",
      };
      setTimeout(() => {
        if (!blobData && !noteData) {
          dispatch(reqToUpdateJointCallObj([]));
          handleSaveCallRecordingData("", "", []);
        } else {
          dispatch(reqToUpdateJointCallObj(tempObj));
          setSignTimeCount({
            eraseCount: 0,
            signStart: "",
            signEnd: "",
            signDrawTime: 0,
          });
          if (btnType === "save") {
            handleSaveCallRecordingData("", "", tempObj);
          } else {
            handleCallActivitiesSidebar(selectedActivity, "next");
          }
        }
        setChangeStatus(false);
      }, 200);
    } else {
      if (btnType === "save") {
        handleSaveCallRecordingData();
      } else {
        handleCallActivitiesSidebar(selectedActivity, "next");
      }
    }
  };

  return (
    <>
      <div className={`tc-sidebar-section ${showSidebar && "zl-sidebar-open"}`}>
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => handleCallActivitiesSidebar()}
            disabled={isLoading}
          >
            {backBtnIcon}
          </button>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body-wrapper">
          <div className="tc-sidebar-body">
            <div className="tc-profile-wrapper">
              <img src={DoctorIcon} alt="doctor" />
              <span className="tc-profile-name">
                {selectedMD && nameFormatting(selectedMD)}
              </span>
            </div>
            <div className="h-100">
              <div className="tc-content-wrapper h-100">
                <CallActivitiesMDDetails selectedMD={selectedMD} />
                <div className="tc-content-wrapper-title d-flex justify-content-between">
                  <h2 className="m-0">JOINT CALL</h2>
                  <div className="d-flex gap-3">
                    {!location?.state?.is_incidental && (
                      <Button
                        variant="primary"
                        type="button"
                        className="btn-min"
                        onClick={() => handleJointCallDataObj("save")}
                        disabled={isLoading}
                      >
                        Save
                      </Button>
                    )}
                    <Button
                      variant="primary"
                      type="button"
                      className="btn-min"
                      onClick={() => handleJointCallDataObj()}
                      disabled={
                        isLoading ||
                        ((smallPadRef?.current?.isEmpty() === undefined ||
                          smallPadRef?.current?.isEmpty()) &&
                          !noteData)
                      }
                    >
                      Next
                    </Button>
                  </div>
                </div>

                <div className="tc-table-wrapper h-100 mb-5">
                  <Table
                    className="tc-table tc-join-call-table tc-border-bottom-table"
                    responsive
                  >
                    <thead>
                      <tr>
                        <th
                          className="text-start ps-0"
                          style={{ minWidth: 250 }}
                        >
                          Who joined this call?
                        </th>
                        <th className="text-start">
                          {callActivitieData[3]?.dm_name}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {jointCallData && jointCallData?.length === 0 ? (
                        <tr>
                          <td colSpan={2}>
                            <DataNotFound />
                          </td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td
                              className={`text-start ${
                                isLoading || savedCall?.signPadData
                                  ? "tc-call-edit-disabled"
                                  : ""
                              }`}
                            >
                              <h6 className="text-center">Notes</h6>
                              <div
                                className=""
                                style={{ position: "relative" }}
                              >
                                <textarea
                                  name="notes"
                                  value={noteData}
                                  spellCheck="false"
                                  onChange={(e) => handleJointCallValues(e)}
                                  className="form-control"
                                  style={{ height: "220px" }}
                                  disabled={isLoading}
                                />
                                {/* <div className="tc-signature-pad-icons">
                                  <button
                                    // onClick={() => openBigSignModal()}
                                    disabled={isLoading}
                                  >
                                    {zoomIn}
                                  </button>
                                </div> */}
                              </div>
                            </td>
                            <td
                              className={`${
                                isLoading || savedCall?.signPadData
                                  ? "tc-call-edit-disabled"
                                  : ""
                              }`}
                            >
                              <h6 className="text-center">SIGNATURE</h6>
                              {jointCallData && jointCallData?.length > 0 && (
                                <>
                                  <div className="tc-md-list-behavior-input-box">
                                    <div
                                      className="tc-quick-sign-signature-box"
                                      style={{ position: "relative" }}
                                    >
                                      <div className="tc-signature-pad-icons">
                                        <button
                                          onClick={() => openBigSignModal()}
                                          disabled={isLoading}
                                        >
                                          {zoomIn}
                                        </button>
                                        <button
                                          role="button"
                                          onClick={() => clearSingAndFileData()}
                                          disabled={isLoading}
                                        >
                                          {deleteIcon}
                                        </button>
                                      </div>
                                      <SignaturePad
                                        maxWidth={3}
                                        minWidth={3}
                                        canvasProps={{
                                          className:
                                            "tc-join-call-digi-signaturepad",
                                          id: "sign-pad",
                                        }}
                                        ref={smallPadRef}
                                        onEnd={() => handleSmallPadEnd()}
                                        onBegin={() => handleSignBegin()}
                                      />
                                    </div>
                                    {errors && errors?.dmSign && (
                                      <span className="tc-error">
                                        {errors && errors?.dmSign}
                                      </span>
                                    )}
                                    <p className="tc-signaturepad-time-text">
                                      {dateWithTimeFormat(new Date())}
                                    </p>
                                  </div>
                                </>
                              )}
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SignZoomModal
        type="DM"
        show={viewSignModal}
        signPadRef={bigPadRef}
        clear={clearSingAndFileData}
        signatureData={signatureData}
        handleSignEnd={handleBigPadEnd}
        handleSignBegin={handleSignBegin}
        closeZoomModalHandler={closeBigSignModal}
        handleSaveSignature={handleJointCallDataObj}
        error={errors.dmSign ? errors.dmSign : ""}
      />
    </>
  );
};

export default JointCall;
