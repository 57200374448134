import React from "react";
import { Button, Modal } from "react-bootstrap";

const FileUploadSuccess = (props) => {
  const { type, status, handleFileUpload, ...rest } = props;
  return (
    <>
      <Modal
        className="tc-main-modal tc-sign-modal tc-fileupload-success-modal"
        {...rest}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-dark">
            File Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {type === "success" ? (
            <svg
              className="tc-success-checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="tc-success-checkmark-circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="tc-success-checkmark-check"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              className="tc-failure-checkmark toggle"
            >
              <circle
                className="tc-failure-checkmark-circle"
                cx="32"
                cy="32"
                r="30"
                fill="none"
              />
              <line
                className="tc-failure-left-check"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                x1="19.271"
                y1="19.521"
                x2="44.729"
                y2="44.979"
              />
              <line
                className="tc-failure-right-check"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                x1="44.729"
                y1="19.521"
                x2="19.271"
                y2="44.979"
              />
            </svg>
          )}
          <h6 className="text-center" style={{ marginTop: 30 }}>
            {status}
          </h6>
          <div className="text-center mt-4">
            {type === "success" ? (
              <Button variant="primary" onClick={props.onHide}>
                Done
              </Button>
            ) : (
              <Button variant="primary" onClick={()=> handleFileUpload()}>
                Re-Upload
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FileUploadSuccess;
