import moment from "moment";
import { toast } from "react-toastify";
import { messages } from "./messages";
import { isDesktop } from "react-device-detect";
import { db, insertEntryInDB } from "./db";

const toastList = new Set();
const MAX_TOAST = 2;
export const API_TIME_OUT = 200;
export const notify = (test, message) => {
  if (toastList.size < MAX_TOAST) {
    let id;
    if (message === "success") {
      id = toast.success(test, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else if (message === "warning") {
      id = toast.warning(test, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    } else {
      id = toast.error(test, {
        autoClose: 1500,
        onClose: () => toastList.delete(id),
      });
    }
    toastList.add(id);
  }
};
export const notifyAPIMessages = (res) => {
  if (res.status === 200 || res.status === 201) {
    notify(res.data?.message, "success");
  } else {
    const errorMessage = res.response
      ? res.response.data?.message
      : res.message;
    notify(errorMessage, "error");
  }
};

// DEFAULT DAY AND MONTH NAMES
var dayNames = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const monthNamesOptions = [
  {
    value: "1",
    label: "January",
  },
  {
    value: "2",
    label: "February",
  },
  {
    value: "3",
    label: "March",
  },
  {
    value: "4",
    label: "April",
  },
  {
    value: "5",
    label: "May",
  },
  {
    value: "6",
    label: "June",
  },
  {
    value: "7",
    label: "July",
  },
  {
    value: "8",
    label: "August",
  },
  {
    value: "9",
    label: "September",
  },
  {
    value: "10",
    label: "October",
  },
  {
    value: "11",
    label: "November",
  },
  {
    value: "12",
    label: "December",
  },
];
export const weekNameOptions = [
  { value: "Mon", label: "Mon" },
  { value: "Tue", label: "Tue" },
  { value: "Wed", label: "Wed" },
  { value: "Thu", label: "Thu" },
  { value: "Fri", label: "Fri" },
];
export const itineraryMethods = [
  { id: 1, type: "ADD", value: "ADD", label: "Add Itinerary" },
  { id: 2, type: "EDIT", value: "EDIT", label: "Edit Itinerary" },
  { id: 3, type: "REMOVE", value: "REMOVE", label: "Remove Itinerary" },
  { id: 4, type: "VIEW", value: "VIEW", label: "View Itinerary" },
  { id: 5, type: "SUMMARY", value: "SUMMARY", label: "Summary" },
  {
    id: 6,
    type: "WSUMMARY",
    value: "SUMMARY",
    label: "Summary (Weekly Totals)",
  },
];
export const mdMethods = [
  { type: "ADD", value: "INSERT", label: "Add New MD" },
  { type: "REMOVE", value: "DELETE", label: "Delete MD" },
  { type: "EDIT", value: "EDIT", label: "Edit MD Info" },
  { type: "VIEW", value: "VIEW", label: "View MD Info" },
];
export const summaryReportType = [
  {
    type: "",
    name: "Already Approved:",
    value: "Already Approved:",
    approve_request: "",
    type_request: "",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Add to Itinerary",
    value: "Added",
    approve_request: "APPROVED",
    type_request: "INSERT",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Remove from Itinerary",
    value: "Removed",
    approve_request: "APPROVED",
    type_request: "DELETE",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Edit Itinerary",
    value: "Edited",
    approve_request: "APPROVED",
    type_request: "EDIT",
    count: 0,
  },
  {
    type: "",
    name: "October 2024 Totals",
    value: "October 2024 Totals",
    approve_request: "",
    type_request: "",
    count: 0,
  },
  {
    type: "",
    name: "For Approval:",
    value: "For Approval:",
    approve_request: "",
    type_request: "",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Add to Itinerary",
    value: "approvalAdded",
    approve_request: "INSERT",
    type_request: "INSERT",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Remove from Itinerary",
    value: "approvalRemoved",
    approve_request: "DELETE",
    type_request: "DELETE",
    count: 0,
  },
  {
    type: "SUMMARY",
    name: "Edit Itinerary",
    value: "approvalEdited",
    approve_request: "EDIT",
    type_request: "EDIT",
    count: 0,
  },
  {
    type: "",
    name: "Total if all are Approved",
    value: "Total if all are Approved",
    approve_request: "",
    type_request: "",
    count: 0,
  },
];
export const summaryWReportType = [
  {
    id: 1,
    name: "Week 1",
    value: "Week 1",
    type: "WSUMMARY",
    count: 0,
  },
  {
    id: 2,
    name: "Week 2",
    value: "Week 2",
    type: "WSUMMARY",
    count: 0,
  },
  {
    id: 3,
    name: "Week 3",
    value: "Week 3",
    type: "WSUMMARY",
    count: 0,
  },
  {
    id: 4,
    name: "Week 4",
    value: "Week 4",
    type: "WSUMMARY",
    count: 0,
  },
];

// GET START, END DATE AND MONTH WEEKS
const dateFormatConvertor = (date, month, year) => {
  let dd = date < 10 ? "0" + date : date;
  let mm = month < 10 ? "0" + month : month;
  let yyyy = year;
  return yyyy + "-" + mm + "-" + dd;
};
export const getStartDateAndEndDate = (type, week, month, defDate) => {
  const currDate = new Date();
  const currYear = currDate.getFullYear();
  const currMonth =
    typeof month !== "number" ? monthArray.indexOf(month) : month;
  const startDate = new Date(currYear, currMonth, 1);
  const endDate = new Date(currYear, currMonth + 1, 0);

  if (type === "day") {
    const weeks = [];
    const numDays = endDate.getDate();
    let dayOfWeekCounter = startDate.getDay() - 1;
    for (let i = 1; i <= numDays; i++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(i);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }
    const weekData = weeks
      .filter((w) => w.length !== 0)
      .map((w) => ({
        start: dateFormatConvertor(w[0], currMonth + 1, currYear),
        end: dateFormatConvertor(w[w.length - 1], currMonth + 1, currYear),
        dates: w.map((d, i) => {
          return {
            date: dateFormatConvertor(d, currMonth + 1, currYear),
            day: dayNames[i],
          };
        }),
      }));
    return {
      report: type,
      startDate: weekData[week - 1]?.start,
      endDate: weekData[week - 1]?.end,
      dates: weekData[week - 1].dates,
      week: week,
    };
  } else if (type === "week") {
    const weeks = [];
    const numDays = defDate ? defDate : endDate.getDate();
    let dayOfWeekCounter = startDate.getDay() - 1;

    for (let i = 1; i <= numDays; i++) {
      if (dayOfWeekCounter === 0 || weeks.length === 0) {
        weeks.push([]);
      }
      weeks[weeks.length - 1].push(i);
      dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
    }

    const weekData = weeks
      .filter((w) => w.length !== 0)
      .map((w, i) => ({
        dates: w,
        weekDetails: {
          id: i + 1,
          label: `Week ${i + 1}`,
          value: i + 1,
        },
      }));
    const weekDates = weeks
      .filter((w) => w.length !== 0 && w?.length === 7)
      .map((w) =>
        w.map((d) => {
          return dateFormatConvertor(d, currMonth + 1, currYear);
        })
      );
    return {
      report: type,
      weekData: weekData,
      weekDates: weekDates,
      month: monthArray[month],
      year: currYear,
    };
  } else if (type === "month") {
    const months = [];
    const numMonths = month;
    for (let i = 0; i <= numMonths; i++) {
      months.push(monthArray[i]);
    }
    const monthData = months
      .filter((m) => m.length !== 0)
      .map((m, i) => ({
        monthDetails: { id: i + 1, lable: m, value: m },
      }));
    return {
      report: type,
      month: monthData,
    };
  } else if (type === "monthDates") {
    return { startDate: startDate, endDate: endDate };
  }
};
export const getStartDateOfWeek = (date) => {
  const startOfWeek = date ? new Date(date) : new Date();
  const day = startOfWeek.getDay();
  const diff = startOfWeek.getDate() - day + (day === 0 ? -6 : 1);
  startOfWeek.setDate(diff);
  return startOfWeek;
};

// HANDLE DATE FORMATTING
const moment2 = require("moment-timezone");
export const defaultTimeZone = moment2.tz.guess();
export const convertDefaultTimeZone = (date) => {
  return moment
    .utc(date, "YYYY-MM-DD")
    .clone()
    .tz(defaultTimeZone)
    .format("YYYY-MM-DD");
};
export const dateFormatting = (date) => {
  const newDate = date ? date : new Date();
  return moment(newDate).format("YYYY-MM-DD");
};
export const dateFormatting2 = (date) => {
  return moment(date).format("MM-DD-YYYY");
};
export const dateFormatWithTime = (date) => {
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};
export const defaultDateFormat = (date) => {
  return moment(date).format("MM/DD/YY");
};
export const defaultTimeFormat = (date) => {
  return moment(date).format("hh:mm:ss A");
};
export const dashDateFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MM-DD-YY");
};
export const defaultDateDashFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("YYYY-MM-DD");
};
export const dateWithTimeFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MM/DD/YY hh:mm A");
};
export const dateWithTimeDashFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MM-DD-YY hh:mm A");
};
export const timeWithHoursFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("h:mm A");
};
export const fullTimeFormat = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("hh:mm:ss A");
};
export const getMonthAndDate = (date) => {
  return moment
    .utc(date, "YYYYMMDD HH:mm:ss")
    .clone()
    .tz(defaultTimeZone)
    .format("MMM DD");
};
export const getMonthStartEndDate = (month) => {
  const start = moment()
    .add(month ? month : 0, "months")
    .startOf("month")
    .format();
  const end = moment()
    .add(month ? month : 0, "months")
    .endOf("month")
    .format();
  const year = moment()
    .clone()
    .add(month ? month : 0, "month")
    .format("YYYY");
  const cMonth = moment()
    .clone()
    .add(month ? month : 0, "month")
    .format("MMMM");
  return {
    start: new Date(start),
    end: new Date(end),
    year: Number(year),
    month: cMonth,
  };
};
export const getTimeDifferenceInMin = (date1, date2) => {
  const ndate1 = new Date(date1);
  const ndate2 = new Date(date2);
  const milliseconds = Math.abs(ndate2 - ndate1);
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(milliseconds / 1000 / 60);
  return { seconds, minutes };
};

// GET LOCATION COORDINATES AND CHECK CAMERA PERMISSION
export const getLocationCoordinates = async () => {
  if (!navigator.geolocation) {
    notify(messages?.errors?.location_not_support, "error");
    return null;
  }

  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, (error) => {
        const errorMessages = {
          [error.PERMISSION_DENIED]: {
            title: "Permission Denied",
            message: messages.errors.location_permission_issue,
          },
          [error.POSITION_UNAVAILABLE]: {
            title: "Position Unavailable",
            message: messages.errors.location_position_issue,
          },
          [error.TIMEOUT]: {
            title: "Request Timeout",
            message: messages.errors.location_timeout_issue,
          },
        };

        const errorDetails = errorMessages[error.code] || {
          title: "Location Unknown Issue",
          message: messages.errors.location_unknown_issue,
        };

        const reqObj = {
          date: new Date(),
          code: error.code,
          error: error.message,
          ...errorDetails,
        };

        insertEntryInDB("errorLogs", reqObj);
        reject(reqObj);
      });
    });
    return position.coords;
  } catch (error) {
    return "";
  }
};
export const checkSystemWebcam = () => {
  return new Promise((resolve, reject) => {
    if (isDesktop) {
      navigator.mediaDevices
        .getUserMedia({ video: { width: 300 } })
        .then((stream) => {
          const tracks = stream.getTracks();
          tracks.forEach((track) => track.stop());
          resolve(stream);
        })
        .catch((error) => {
          if (error.name === "NotAllowedError") {
            notify(messages.errors.camera_permission, "error");
          } else {
            notify(messages.errors.camera_not_support, "error");
          }
          reject(error);
        });
    } else {
      resolve();
    }
  });
};
export const getCameraStream = async (webcamRef, onChange) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { width: 800, height: 800, facingMode: "user" },
    });
    webcamRef.current.srcObject = stream;
    onChange(stream);
  } catch (error) {
    notify("Unable to access the front camera. Switching to the back camera.");
    try {
      const fallbackStream = await navigator.mediaDevices.getUserMedia({
        video: { width: 800, height: 800, facingMode: "environment" },
      });
      webcamRef.current.srcObject = fallbackStream;
    } catch (error2) {
      notify("Unable to access any camera.");
    }
  }
};

// GET TIME DIFFERENCE
export const getTimeDifference = (start, end) => {
  let startTime = new Date(start).getTime();
  let endTime = new Date(end).getTime();
  let seconds = Math.round(endTime - startTime);
  return seconds;
};
export const getPrevious7dayDate = (selectedDate) => {
  return moment(selectedDate)
    .subtract(7, "days")
    .add(1, "days")
    .format("YYYY-MM-DD");
};
export const getPrevious7DaysArr = (selectedDate) => {
  let datesArray = [];
  let currentDate = moment(selectedDate);

  for (let i = 0; i < 7; i++) {
    datesArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate.subtract(1, "days");
  }

  return datesArray;
};

// GET REPORT DATA
export const getUniqueMDData = (dataList) => {
  const uniqueData = {};
  dataList &&
    dataList?.length > 0 &&
    dataList?.forEach((entry) => {
      const md_id = entry.md_id;
      if (!uniqueData[md_id]) {
        uniqueData[md_id] = {
          ...entry,
          dates: [
            {
              date: entry.date,
              is_summary: entry.is_summary,
              is_visited: entry.is_visited,
            },
          ],
        };
      } else {
        uniqueData[md_id].dates.push({
          date: entry.date,
          is_summary: entry.is_summary,
          is_visited: entry.is_visited,
        });
      }
    });
  const newUniqueData = Object.values(uniqueData);
  return newUniqueData;
};
export const updateDashboardDataByMDList = (dataList, oofList, holiday) => {
  const dashMData = {
    A: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    B: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    C: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    D: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
  };
  const dashDData = {
    A: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    B: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    C: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
    D: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
    },
  };
  const dailyData = [];
  const monthlyData = [];
  const currentDate = defaultDateDashFormat(new Date());
  const newOOFList = oofList ? oofList : [];
  const newHoliday = holiday ? holiday : [];
  dataList &&
    dataList?.length > 0 &&
    dataList?.forEach((entry) => {
      const { name, is_summary, md_id, date, record_date } = entry;
      const entryDate = defaultDateDashFormat(date);
      const visitDate = record_date ? defaultDateDashFormat(record_date) : null;
      const oofMDList = getOOFMDListByDate(newOOFList, date);
      const holiday = getHolidayByDate(newHoliday, date);

      if (!dashMData[name]) {
        dashMData[name] = {
          targetCalls: [],
          actualCalls: [],
          targetCount: [],
          actualCount: [],
        };
        dashDData[name] = {
          targetCalls: [],
          actualCalls: [],
          targetCount: [],
          actualCount: [],
        };
      }

      // Get dashboard monthly data
      if (!oofMDList.includes(md_id) && holiday?.date !== date) {
        dashMData[name].targetCalls.push(entry);
      }
      if (is_summary === 1) {
        dashMData[name].actualCalls.push(entry);
      }
      if (!dashMData[name].targetCount[md_id]) {
        dashMData[name].targetCount[md_id] = { ...entry };
      }
      if (is_summary === 1 && !dashMData[name].actualCount[md_id]) {
        dashMData[name].actualCount[md_id] = { ...entry };
      }

      // Get dashboard perDay data
      if (entryDate <= currentDate) {
        if (!oofMDList.includes(md_id) && holiday?.date !== date) {
          dashDData[name].targetCalls.push(entry);
        }
        if (!dashDData[name].targetCount[md_id]) {
          dashDData[name].targetCount[md_id] = { ...entry };
        }
        if (is_summary === 1 && !dashDData[name].actualCount[md_id]) {
          dashDData[name].actualCount[md_id] = { ...entry };
        }
      }
      if (visitDate !== null && visitDate <= currentDate && is_summary === 1) {
        dashDData[name].actualCalls.push(entry);
      }
    });

  // Get dashboard monthly data
  Object.keys(dashMData).forEach((className, index) => {
    const { targetCalls, actualCalls, targetCount, actualCount } =
      dashMData[className];
    const newTargetCount = Object.values(targetCount);
    const newActualCount = Object.values(actualCount);

    monthlyData[index] = {
      id: index + 1,
      class: className,
      actual_call: actualCalls.length,
      actual_md: newActualCount.length,
      f2f_call: 0,
      perf_call: 0,
      perf_md: 0,
      virtual_call: 0,
      target_call: targetCalls.length,
      target_md: newTargetCount.length,
    };
  });

  // Get dashboard perDay data
  Object.keys(dashDData).forEach((className, index) => {
    const { targetCalls, actualCalls, targetCount, actualCount } =
      dashDData[className];
    const newTargetCount = Object.values(targetCount);
    const newActualCount = Object.values(actualCount);

    dailyData[index] = {
      id: index + 1,
      class: className,
      actual_call: actualCalls.length,
      actual_md: newActualCount.length,
      f2f_call: 0,
      perf_call: 0,
      perf_md: 0,
      virtual_call: 0,
      target_call: targetCalls.length,
      target_md: newTargetCount.length,
    };
  });

  if (monthlyData && monthlyData?.length > 0) {
    db.dashBoardMonthly.clear();
    monthlyData &&
      monthlyData?.map(async (item) => {
        await insertEntryInDB("dashBoardMonthly", item);
      });
  }
  if (dailyData && dailyData?.length > 0) {
    db.dashBoardPerDay.clear();
    dailyData &&
      dailyData?.map(async (item) => {
        await insertEntryInDB("dashBoardPerDay", item);
      });
  }
  return { dailyData, monthlyData };
};
export const getNewMDListByRecordDate = async (mdList) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const newMDList = await updateMDListByRecordDate(mdList);
  const updatedMDList = newMDList.map((item) => {
    if (
      item.today !== currentDate &&
      item.is_visited === 1 &&
      item.is_summary === 0
    ) {
      return {
        ...item,
        today: currentDate,
        is_visited: 0,
      };
    } else {
      return {
        ...item,
        today: currentDate,
      };
    }
  });
  return updatedMDList?.length > 0 ? updatedMDList : newMDList;
};
export const updateMDListByRecordDate = async (callList) => {
  if (!callList || callList?.length === 0) return [];
  let newCalls = [...callList];

  const groupedCallList = newCalls.reduce((acc, call) => {
    if (!acc[call.md_id]) {
      acc[call.md_id] = [];
    }
    acc[call.md_id].push(call);
    return acc;
  }, {});

  const dayDiffClass = ["A", "B"];
  const weekDiffClass = ["C", "D"];
  const currDate = new Date();

  const filteredCalls = Object.values(groupedCallList).flatMap((calls) => {
    const sortedCalls = calls.sort(
      (a, b) => new Date(b?.record_date) - new Date(a?.record_date)
    );
    const lastCall = sortedCalls.find(
      (call) => call?.record_date && call?.is_summary
    );
    const newCurrDate = currDate ? defaultDateDashFormat(currDate) : null;
    const lastCallDate = lastCall ? new Date(lastCall?.record_date) : null;
    const newLastCallDate = lastCallDate
      ? defaultDateDashFormat(lastCallDate)
      : null;

    const weekStartDate = getStartDateOfWeek(currDate);
    const weekEndDate = new Date(weekStartDate);
    weekEndDate.setDate(weekEndDate.getDate() + 6);

    const newWeekSDate = defaultDateDashFormat(weekStartDate);
    const newWeekEDate = defaultDateDashFormat(weekEndDate);

    const weekCalls = sortedCalls.filter((call) => {
      const callDate =
        call?.record_date && call?.is_summary
          ? defaultDateDashFormat(call?.record_date)
          : null;
      return (
        dayDiffClass.includes(call.name) &&
        callDate >= newWeekSDate &&
        callDate <= newWeekEDate
      );
    });
    const isWeekCall = weekCalls.length >= 2;
    return sortedCalls.map((call) => {
      const dayDifference = Math.abs(
        Math.floor(
          (new Date(newCurrDate) - new Date(newLastCallDate)) /
            (1000 * 60 * 60 * 24)
        )
      );
      if (dayDiffClass.includes(call.name)) {
        call.is_week_call = isWeekCall ? 1 : 0;
        call.is_consecutive = dayDifference >= 2 ? 0 : 1;
      } else if (weekDiffClass.includes(call.name)) {
        call.is_consecutive = dayDifference >= 2 ? 0 : 1;
        call.is_week_call =
          newLastCallDate >= newWeekSDate && newLastCallDate <= newWeekEDate
            ? 1
            : 0;
      }
      return call;
    });
  });
  return filteredCalls?.length > 0 ? filteredCalls : callList;
};
export const getCallSummaryPerMDClassData = (
  dataList,
  oofList,
  holidays,
  currDate
) => {
  const dashDData = {
    A: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
      F2FCalls: [],
      virtualCall: [],
    },
    B: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
      F2FCalls: [],
      virtualCall: [],
    },
    C: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
      F2FCalls: [],
      virtualCall: [],
    },
    D: {
      targetCalls: [],
      actualCalls: [],
      targetCount: [],
      actualCount: [],
      F2FCalls: [],
      virtualCall: [],
    },
  };
  const dailyData = [];
  const date = moment(currDate).subtract(1, "days").format("YYYY-MM-DD");
  const currentDate = currDate
    ? defaultDateDashFormat(date)
    : defaultDateDashFormat(new Date());
  dataList &&
    dataList?.length > 0 &&
    dataList?.forEach((entry) => {
      const { name, is_summary, md_id, date, isVirtual, record_date } = entry;
      const entryDate = defaultDateDashFormat(date);
      const visitDate = record_date ? defaultDateDashFormat(record_date) : null;
      const oofMDList = getOOFMDListByDate(oofList, date);
      const holiday = getHolidayByDate(holidays, date);

      if (!dashDData[name]) {
        dashDData[name] = {
          targetCalls: [],
          actualCalls: [],
          targetCount: [],
          actualCount: [],
          F2FCalls: [],
          virtualCall: [],
        };
      }

      // Get dashboard perDay data
      if (entryDate <= currentDate) {
        if (!oofMDList.includes(md_id) && holiday?.date !== date) {
          dashDData[name].targetCalls.push(entry);
        }
        if (!dashDData[name].targetCount[md_id]) {
          dashDData[name].targetCount[md_id] = { ...entry };
        }
        if (is_summary === 1 && !dashDData[name].actualCount[md_id]) {
          dashDData[name].actualCount[md_id] = { ...entry };
        }
      }
      if (visitDate !== null && visitDate <= currentDate && is_summary === 1) {
        dashDData[name].actualCalls.push(entry);
        if (isVirtual === 1) {
          dashDData[name].virtualCall.push(entry);
        } else {
          dashDData[name].F2FCalls.push(entry);
        }
      }
    });

  Object.keys(dashDData).forEach((className, index) => {
    const {
      targetCalls,
      actualCalls,
      targetCount,
      actualCount,
      F2FCalls,
      virtualCall,
    } = dashDData[className];
    const newTargetCount = Object.values(targetCount);
    const newActualCount = Object.values(actualCount);

    dailyData[index] = {
      id: index + 1,
      class: className,
      actual_call: actualCalls.length,
      actual_md: newActualCount.length,
      f2f_call: F2FCalls.length,
      perf_call: 0,
      perf_md: 0,
      virtual_call: virtualCall.length,
      target_call: targetCalls.length,
      target_md: newTargetCount.length,
    };
  });
  return dailyData;
};
export const getCallConcentrationData = (dataList) => {
  const uniqueData = new Map();
  const newDataList = [];
  dataList &&
    dataList?.length > 0 &&
    dataList?.forEach(({ md_id, name, is_summary }) => {
      const existingEntry = uniqueData.get(md_id);
      uniqueData.set(md_id, {
        class_name: name,
        total_calls:
          (existingEntry ? existingEntry.total_calls : 0) +
          (is_summary === 1 ? 1 : 0),
        total_mds: 0,
      });
    });
  const newUniqueData = Array.from(uniqueData.values());
  newUniqueData &&
    newUniqueData?.length > 0 &&
    newUniqueData?.forEach(({ class_name, total_calls, total_mds }) => {
      const index = newDataList.findIndex(
        (item) =>
          item.class_name === class_name && item.total_calls === total_calls
      );
      if (index !== -1) {
        newDataList[index].total_mds++;
      } else {
        newDataList.push({ class_name, total_calls, total_mds: total_mds + 1 });
      }
    });
  return newDataList;
};
export const getMDCoverageData = (
  dataList,
  oofList,
  holidays,
  sDate,
  type,
  rType
) => {
  if (type === "today") {
    let currDateMDs = [];
    let currDateCalls = [];
    const isCurrDate = sDate === dateFormatting();
    const mdCoverageData = {
      chart: [
        { class: "A", actual_md: 0, target_md: 0, perf_md: "0.00" },
        { class: "B", actual_md: 0, target_md: 0, perf_md: "0.00" },
        { class: "C", actual_md: 0, target_md: 0, perf_md: "0.00" },
        { class: "D", actual_md: 0, target_md: 0, perf_md: "0.00" },
      ],
      list: [],
      date: sDate,
    };

    currDateMDs =
      (dataList &&
        dataList?.length > 0 &&
        dataList.filter((entry) => entry.date === sDate)) ||
      [];
    currDateCalls =
      (dataList &&
        dataList?.length > 0 &&
        dataList
          .filter((entry) =>
            rType === "T"
              ? defaultDateDashFormat(entry?.record_date) === sDate
              : isCurrDate
              ? defaultDateDashFormat(entry?.record_date) < sDate
              : defaultDateDashFormat(entry?.record_date) <= sDate
          )
          .sort(
            (a, b) => new Date(b?.record_date) - new Date(a?.record_date)
          )) ||
      [];
    const oofMDList = getOOFMDListByDate(oofList, sDate);
    const holiday = getHolidayByDate(holidays, sDate);
    currDateMDs?.forEach((entry) => {
      const { md_id, name, date } = entry;
      const classIndex = mdCoverageData.chart.findIndex(
        (c) => c.class === name
      );
      if (
        classIndex !== -1 &&
        !oofMDList.includes(md_id) &&
        holiday?.date !== date
      ) {
        mdCoverageData.chart[classIndex].target_md++;
      }
    });
    currDateCalls?.forEach((entry) => {
      const {
        name,
        date,
        data,
        address,
        isQuicksign,
        is_summary,
        record_date,
      } = entry;
      if (!is_summary) return;
      const classIndex = mdCoverageData.chart.findIndex(
        (c) => c.class === name
      );
      if (classIndex === -1) return;
      const call_date = defaultDateDashFormat(record_date);
      const callType = isQuicksign
        ? "Quick Call"
        : data?.quickSignObj &&
          Object.keys(data?.quickSignObj)?.length > 0 &&
          (data?.quickSignObj?.signature_url || data?.quickSignObj?.signPadData)
        ? "Quick Call"
        : "Itinerary";
      const reliever_md =
        data && data?.reliever_md && Object.keys(data?.reliever_md).length > 0
          ? data?.reliever_md
          : "";
      mdCoverageData.chart[classIndex].actual_md++;
      mdCoverageData.list.push({
        ...entry,
        address: address ? address : "Not yet available",
        reliever_md: reliever_md,
        call_type: callType,
        signature_md_time: data?.signature_time,
        remark:
          call_date < date
            ? "Advanced"
            : call_date > date
            ? "Make-Up"
            : "On-Time",
      });
    });
    return mdCoverageData;
  } else {
    const list = [];
    const isCurrDate = sDate === dateFormatting();
    dataList &&
      dataList?.length > 0 &&
      dataList?.forEach((entry) => {
        const {
          data,
          is_save,
          target_date,
          isQuicksign,
          is_incidental,
          record_time,
        } = entry;
        if (is_save || is_incidental) return;
        const checkCallDate = isCurrDate
          ? convertDefaultTimeZone(record_time) < sDate
          : convertDefaultTimeZone(record_time) <= sDate;
        if (!checkCallDate) return;
        const sMD = data && data?.selectedMD;
        const newData = data && data?.summary_data;
        const call_date = defaultDateDashFormat(record_time);
        const callType = isQuicksign
          ? "Quick Call"
          : newData?.quickSignObj &&
            Object.keys(newData?.quickSignObj)?.length > 0 &&
            (newData?.quickSignObj?.signature_url ||
              newData?.quickSignObj?.signPadData)
          ? "Quick Call"
          : "Itinerary";
        const reliever_md =
          newData &&
          newData?.reliever_md &&
          Object.keys(newData?.reliever_md).length > 0
            ? newData?.reliever_md
            : "";
        list.push({
          ...sMD,
          address: sMD?.address ? sMD?.address : "No Location Found",
          record_date: record_time,
          date: target_date,
          reliever_md: reliever_md,
          call_type: callType,
          signature_md_time: newData?.signature_time,
          remark:
            call_date < target_date
              ? "Advanced"
              : call_date > target_date
              ? "Make-Up"
              : "On-Time",
        });
      });
    const newList =
      (list?.length > 0 &&
        list.sort(
          (a, b) => new Date(b?.record_date) - new Date(a?.record_date)
        )) ||
      list;
    return newList;
  }
};
export const getSamplesPromoIssuanceData = (dataList, sDate, type) => {
  let currDateArray = [];
  const samplesData = [];
  const promoMatsData = [];

  currDateArray =
    (dataList &&
      dataList?.length > 0 &&
      dataList?.filter(
        (entry) => defaultDateDashFormat(entry?.record_date) === sDate
      )) ||
    [];
  currDateArray.forEach((entry) => {
    const { is_summary, data } = entry;
    if (is_summary === 1 && data) {
      data &&
        data?.ProductSampling?.length > 0 &&
        data?.ProductSampling?.map((item) => {
          if (
            type === "samples_issuance" &&
            (item.promomat_type === "P" || item.promomat_type === "C")
          ) {
            samplesData.push({
              ...item,
              class_id: entry?.class_id,
              firstname: entry?.firstname,
              id: entry?.call_id,
              lastname: entry?.lastname,
              lot_number: item?.lot_number,
              md_id: entry?.md_id,
              record_time: entry?.record_date,
              sku: item?.name,
              quantity: item?.quantity,
              type: item?.promomat_type,
            });
          } else if (
            type === "promo_mats_issuance" &&
            item.promomat_type !== "P" &&
            item.promomat_type !== "C"
          ) {
            promoMatsData.push({
              ...item,
              class_id: entry?.class_id,
              firstname: entry?.firstname,
              id: entry?.call_id,
              lastname: entry?.lastname,
              lot_number: item?.lot_number,
              md_id: entry?.md_id,
              record_time: entry?.record_date,
              sku: item?.name,
              quantity: item?.quantity,
              type: item?.promomat_type,
            });
          }
          return item;
        });
    }
  });
  return { samplesData, promoMatsData };
};
export const getDailyCallRateMdReachData = (
  dataList,
  oofList,
  holidays,
  sDate,
  type
) => {
  let dailyCallRate = [];
  let dailyMdReach = [];
  if (type === "daily_call_rate") {
    dailyCallRate = getPrevious7DaysArr(sDate).map((item) => {
      return {
        date: item,
        target_calls: 0,
        actual_call: 0,
      };
    });

    dataList &&
      dataList?.length > 0 &&
      dataList?.forEach((entry) => {
        const { md_id, date, is_summary, record_date } = entry;
        const oofMDList = getOOFMDListByDate(oofList, date);
        const holiday = getHolidayByDate(holidays, date);
        const classIndex1 = dailyCallRate.findIndex(
          (item) => item.date === date
        );
        const classIndex2 = dailyCallRate.findIndex(
          (item) => item.date === defaultDateDashFormat(record_date)
        );
        if (
          classIndex1 !== -1 &&
          !oofMDList.includes(md_id) &&
          holiday?.date !== date
        ) {
          dailyCallRate[classIndex1].target_calls++;
        }
        if (classIndex2 !== -1 && is_summary === 1) {
          dailyCallRate[classIndex2].actual_call++;
        }
      });
  } else {
    dailyMdReach = getPrevious7DaysArr(sDate).map((item) => {
      return {
        date: item,
        target_mds: 0,
        actual_mds: 0,
      };
    });

    dataList &&
      dataList?.length > 0 &&
      dataList?.forEach((entry) => {
        const { md_id, date, is_summary, record_date } = entry;
        const oofMDList = getOOFMDListByDate(oofList, date);
        const holiday = getHolidayByDate(holidays, date);
        const classIndex1 = dailyMdReach.findIndex(
          (item) => item.date === date
        );
        const classIndex2 = dailyMdReach.findIndex(
          (item) => item.date === defaultDateDashFormat(record_date)
        );
        if (
          classIndex1 !== -1 &&
          !oofMDList.includes(md_id) &&
          holiday?.date !== date
        ) {
          dailyMdReach[classIndex1].target_mds++;
        }
        if (classIndex2 !== -1 && is_summary === 1) {
          dailyMdReach[classIndex2].actual_mds++;
        }
      });
  }
  return { dailyCallRate, dailyMdReach };
};
export const getMonthlyCallRateMdReachData = (
  mdList,
  oofList,
  holidays,
  dataList,
  type
) => {
  let calCallRate = 0;
  let newDataList = [];
  let currentMonth = new Date().toISOString().slice(0, 7);
  const { monthlyData } = updateDashboardDataByMDList(mdList);
  const oofMDList = getAllOOFMDList(oofList);
  const isHoliday = (date) => {
    if (holidays && holidays?.length > 0) {
      return holidays.some((h) => h.date === date);
    } else {
      return false;
    }
  };
  let getMDWithHoliday =
    (mdList &&
      mdList?.length > 0 &&
      mdList.filter((md) => isHoliday(md.date))) ||
    [];
  if (type === "monthly_call_rate") {
    monthlyData &&
      monthlyData?.length > 0 &&
      monthlyData?.forEach((item) => {
        return (calCallRate += item.actual_call);
      });
    newDataList =
      dataList &&
      dataList?.length > 0 &&
      dataList?.map((item) => {
        if (item.month === currentMonth) {
          return {
            ...item,
            actual_call: calCallRate,
            target_calls:
              item.target_calls - oofMDList?.length - getMDWithHoliday?.length,
          };
        } else {
          return {
            ...item,
          };
        }
      });
    return newDataList;
  } else {
    monthlyData &&
      monthlyData?.length > 0 &&
      monthlyData?.forEach((item) => {
        return (calCallRate += item.actual_md);
      });
    newDataList =
      dataList &&
      dataList?.length > 0 &&
      dataList?.map((item) => {
        if (item.month === currentMonth) {
          return {
            ...item,
            actual_mds: calCallRate,
          };
        } else {
          return {
            ...item,
          };
        }
      });
    return newDataList;
  }
};
export const getMonthlyTotalCallsMDsData = (mdList, sDate, type) => {
  if (type === "calls" && mdList?.length > 0) {
    let newCalls = mdList
      .filter(
        (call) =>
          call.is_summary === 1 &&
          defaultDateDashFormat(call?.record_date) === sDate
      )
      .sort((a, b) => new Date(b?.record_date) - new Date(a?.record_date));
    let uniqueCallsMap = new Map();
    newCalls &&
      newCalls?.length > 0 &&
      newCalls.forEach((call) => {
        const existingCall = uniqueCallsMap.get(call.md_id);
        if (!existingCall || call.actual_visits > existingCall.actual_visits) {
          uniqueCallsMap.set(call.md_id, { ...call, status: "new" });
        }
      });

    const uniqueCallsArray = Array.from(uniqueCallsMap.values());
    newCalls &&
      newCalls?.length > 0 &&
      newCalls.forEach((call) => {
        const existingCall = uniqueCallsArray.find(
          (uniqueCall) => uniqueCall.md_id === call.md_id
        );
        if (existingCall && existingCall.id !== call.id) {
          uniqueCallsArray.push({ ...call, status: "old" });
        }
      });

    uniqueCallsArray &&
      uniqueCallsArray?.length > 0 &&
      uniqueCallsArray.sort((a, b) => {
        if (a.md_id === b.md_id) {
          return new Date(a.date) - new Date(b.date);
        }
        return typeof a.md_id === "string" && typeof b.md_id === "string"
          ? a.md_id.localeCompare(b.md_id)
          : a.md_id - b.md_id;
      });
    return uniqueCallsArray?.length > 0 ? uniqueCallsArray : [];
  } else if (type === "mds" && mdList?.length > 0) {
    const uniqueMds = Object.values(
      mdList
        .filter((call) => call.is_summary === 1)
        .sort((a, b) => new Date(b?.record_date) - new Date(a?.record_date))
        .reduce((acc, curr) => {
          if (
            !acc[curr.md_id] ||
            curr?.actual_visits > acc[curr?.md_id]?.actual_visits
          ) {
            acc[curr?.md_id] = curr;
          }
          return acc;
        }, {})
    );
    return uniqueMds?.length > 0 ? uniqueMds : [];
  } else {
    return [];
  }
};
export const getIncidentalCalls = (dataList, sDate, type, type2) => {
  const chart = [
    { class: "A", actual_calls: 0 },
    { class: "B", actual_calls: 0 },
    { class: "C", actual_calls: 0 },
    { class: "D", actual_calls: 0 },
  ];
  let list = [];
  const isCurrDate = sDate === dateFormatting();
  if (type === "daily") {
    list = getPrevious7DaysArr(sDate).map((item) => {
      return {
        date: item,
        actual_call: 0,
      };
    });
  }
  dataList &&
    dataList?.length > 0 &&
    dataList?.forEach((entry) => {
      const { record_time, is_incidental, class_name, address, data } = entry;
      if (type === "today") {
        if (convertDefaultTimeZone(record_time) === sDate) {
          const index = chart.findIndex((item) => item.class === class_name);
          let jointCall =
            data && data?.jointCall && Object.keys(data?.jointCall)?.length > 0
              ? data?.jointCall?.notes
              : "";
          let postCall =
            data && data?.postCall && Object.keys(data?.postCall).length > 0
              ? data?.postCall?.post_call
              : "";
          let product =
            data?.productDetailing?.length > 0 ? data?.productDetailing : [];
          if (index !== -1) {
            chart[index].actual_calls++;
            list.push({
              ...entry,
              address: address ? address : "Not yet available",
              jointCall: jointCall,
              postCall: postCall,
              product: product,
            });
          }
        }
      } else if (type === "month") {
        const is_incidental1 = type2 === "cumulative" ? is_incidental : 1;
        const checkCallDate = isCurrDate
          ? convertDefaultTimeZone(record_time) < sDate
          : convertDefaultTimeZone(record_time) <= sDate;
        if (is_incidental1 && checkCallDate) {
          const newData = type2 === "cumulative" ? data?.summary_data : data;
          let jointCall =
            newData &&
            newData?.jointCall &&
            Object.keys(newData?.jointCall)?.length > 0
              ? newData?.jointCall?.notes
              : "";
          let postCall =
            newData &&
            newData?.postCall &&
            Object.keys(newData?.postCall).length > 0
              ? newData?.postCall?.post_call
              : "";
          let product =
            newData?.productDetailing?.length > 0
              ? newData?.productDetailing
              : [];
          list.push({
            ...entry,
            address: address ? address : "Not yet available",
            jointCall: jointCall,
            postCall: postCall,
            product: product,
          });
        }
      } else if (type === "daily") {
        const index = list.findIndex(
          (item) => item.date === defaultDateDashFormat(record_time)
        );
        if (index !== -1) {
          list[index].actual_call++;
        }
      }
    });
  const newList =
    (list?.length > 0 &&
      list.sort(
        (a, b) => new Date(b?.record_time) - new Date(a?.record_time)
      )) ||
    list;
  return { chart, list: newList };
};
export const getNotSubmittedSavedCalls = (mdList, sDate, type, rType) => {
  const list = [];
  const isCurrDate = sDate === dateFormatting();
  if (type === "today") {
    mdList &&
      mdList?.length > 0 &&
      mdList.forEach((entry) => {
        const {
          date,
          data,
          address,
          is_save,
          is_summary,
          isQuicksign,
          save_record_date,
          save_data,
        } = entry;
        if (is_summary !== 1 && is_save === 1) {
          const checkCallDate =
            rType === "T"
              ? convertDefaultTimeZone(save_record_date) === sDate
              : isCurrDate
              ? convertDefaultTimeZone(save_record_date) < sDate
              : convertDefaultTimeZone(save_record_date) <= sDate;
          if (!checkCallDate) return;
          const newData = JSON.parse(save_data);
          const call_date = defaultDateDashFormat(save_record_date);
          const callType = isQuicksign
            ? "Quick Call"
            : newData?.quickSignObj &&
              Object.keys(newData?.quickSignObj)?.length > 0 &&
              (newData?.quickSignObj?.signature_url ||
                newData?.quickSignObj?.signPadData)
            ? "Quick Call"
            : "Itinerary";
          const reliever_md =
            data &&
            data?.reliever_md &&
            Object.keys(data?.reliever_md).length > 0
              ? data?.reliever_md
              : "";
          const jointCall =
            newData &&
            newData?.jointCall &&
            Object.keys(newData?.jointCall).length > 0
              ? newData?.jointCall?.notes
              : "";
          const postCall =
            newData &&
            newData?.postCall &&
            Object.keys(newData?.postCall).length > 0
              ? newData?.postCall?.post_call
              : "";
          const callData = {
            ...entry,
            product: newData?.products?.length > 0 ? newData?.products : [],
            jointCall: jointCall,
            postCall: postCall,
            actual_call_date: save_record_date,
            signature_md_time: data?.signature_time,
            address: address ? address : "Not yet available",
            call_type: callType,
            reliever_md: reliever_md,
            remark:
              call_date < date
                ? "Advanced"
                : call_date > date
                ? "Make-Up"
                : "On-Time",
          };
          list.push(callData);
        }
      });
    const newList =
      (list?.length > 0 &&
        list.sort(
          (a, b) =>
            new Date(b?.actual_call_date) - new Date(a?.actual_call_date)
        )) ||
      list;
    return newList;
  } else {
    mdList &&
      mdList?.length > 0 &&
      mdList.forEach((entry) => {
        const { data, is_save, target_date, isQuicksign, record_time } = entry;
        if (is_save === 1) {
          const checkCallDate = isCurrDate
            ? convertDefaultTimeZone(record_time) < sDate
            : convertDefaultTimeZone(record_time) <= sDate;
          if (!checkCallDate) return;
          const sMD = data && data?.selectedMD;
          const newData = data && data?.summary_data;
          const call_date = defaultDateDashFormat(record_time);
          const callType = isQuicksign
            ? "Quick Call"
            : newData?.quickSignObj &&
              Object.keys(newData?.quickSignObj)?.length > 0 &&
              (newData?.quickSignObj?.signature_url ||
                newData?.quickSignObj?.signPadData)
            ? "Quick Call"
            : "Itinerary";
          const reliever_md =
            newData &&
            newData?.reliever_md &&
            Object.keys(newData?.reliever_md).length > 0
              ? newData?.reliever_md
              : "";
          const jointCall =
            newData &&
            newData?.jointCall &&
            Object.keys(newData?.jointCall).length > 0
              ? newData?.jointCall?.notes
              : "";
          const postCall =
            newData &&
            newData?.postCall &&
            Object.keys(newData?.postCall).length > 0
              ? newData?.postCall?.post_call
              : "";
          const callData = {
            ...sMD,
            product:
              newData?.productDetailing?.length > 0
                ? data?.productDetailing
                : [],
            jointCall: jointCall,
            postCall: postCall,
            actual_call_date: record_time,
            signature_md_time: newData?.signature_time
              ? newData?.signature_time
              : "",
            date: target_date,
            address: sMD?.address ? sMD?.address : "No Location Found",
            call_type: callType,
            reliever_md: reliever_md,
            remark:
              call_date < target_date
                ? "Advanced"
                : call_date > target_date
                ? "Make-Up"
                : "On-Time",
          };
          list.push(callData);
        }
      });
    const newList =
      (list?.length > 0 &&
        list.sort(
          (a, b) =>
            new Date(b?.actual_call_date) - new Date(a?.actual_call_date)
        )) ||
      list;
    return newList;
  }
};
export const getNotSignedJointCalls = (
  mdList,
  incidentalList,
  sDate,
  type,
  rType
) => {
  const list = [];
  const isCurrDate = sDate === dateFormatting();
  if (type === "today") {
    mdList &&
      mdList?.length > 0 &&
      mdList.forEach((entry) => {
        const {
          date,
          data,
          address,
          is_save,
          is_summary,
          isQuicksign,
          save_data,
          record_date,
          save_record_date,
        } = entry;
        if (is_summary === 1 || is_save === 1) {
          const checkCallDate =
            rType === "T"
              ? (is_summary === 1 &&
                  convertDefaultTimeZone(record_date) === sDate) ||
                (is_save === 1 &&
                  convertDefaultTimeZone(save_record_date) === sDate)
              : isCurrDate
              ? (is_summary === 1 &&
                  convertDefaultTimeZone(record_date) < sDate) ||
                (is_save === 1 &&
                  convertDefaultTimeZone(save_record_date) < sDate)
              : (is_summary === 1 &&
                  convertDefaultTimeZone(record_date) <= sDate) ||
                (is_save === 1 &&
                  convertDefaultTimeZone(save_record_date) <= sDate);
          if (!checkCallDate) return;
          let newData = is_summary ? data : JSON.parse(save_data);
          const call_date = defaultDateDashFormat(
            is_summary ? record_date : save_record_date
          );
          const callType = isQuicksign
            ? "Quick Call"
            : newData?.quickSignObj &&
              Object.keys(newData?.quickSignObj)?.length > 0 &&
              (newData?.quickSignObj?.signature_url ||
                newData?.quickSignObj?.signPadData)
            ? "Quick Call"
            : "Itinerary";
          const jointCall =
            newData &&
            newData?.jointCall &&
            Object.keys(newData?.jointCall)?.length > 0
              ? newData?.jointCall
              : "";
          const postCall =
            newData &&
            newData?.postCall &&
            Object.keys(newData?.postCall).length > 0
              ? newData?.postCall?.post_call
              : "";
          let product = "";
          if (is_summary) {
            product =
              newData?.productDetailing?.length > 0
                ? newData?.productDetailing
                : [];
          } else {
            product = newData?.products?.length > 0 ? newData?.products : [];
          }
          const callData = {
            ...entry,
            product: product,
            jointCall: jointCall?.notes,
            postCall: postCall,
            signature_md_time: data?.signature_time,
            address: address ? address : "Not yet available",
            actual_call_date: is_summary === 1 ? record_date : save_record_date,
            call_type: callType,
            call_status: is_summary ? "Submitted" : "Saved",
            dm_sign:
              jointCall && (jointCall?.signature_url || jointCall?.signPadData)
                ? "Signed"
                : "Not Signed",
            remark:
              call_date < date
                ? "Advanced"
                : call_date > date
                ? "Make-Up"
                : "On-Time",
          };
          list.push(callData);
        }
      });
    incidentalList &&
      incidentalList?.length > 0 &&
      incidentalList.forEach((entry) => {
        const { data, address, class_name, record_time } = entry;
        const checkCallDate =
          rType === "T"
            ? convertDefaultTimeZone(record_time) === sDate
            : isCurrDate
            ? convertDefaultTimeZone(record_time) < sDate
            : convertDefaultTimeZone(record_time) <= sDate;
        if (checkCallDate) {
          let jointCall =
            data && data?.jointCall && Object.keys(data?.jointCall)?.length > 0
              ? data?.jointCall
              : "";
          let postCall =
            data && data?.postCall && Object.keys(data?.postCall).length > 0
              ? data?.postCall?.post_call
              : "";
          let product =
            data?.productDetailing?.length > 0 ? data?.productDetailing : [];
          const callData = {
            ...entry,
            product: product,
            jointCall: jointCall?.notes,
            postCall: postCall,
            name: class_name ? class_name : "-",
            signature_md_time: data?.signature_time,
            address: address ? address : "Not yet available",
            actual_call_date: record_time,
            call_type: "-",
            call_status: "Submitted",
            dm_sign:
              jointCall && (jointCall?.signature_url || jointCall?.signPadData)
                ? "Signed"
                : "Not Signed",
            remark: "-",
          };
          list.push(callData);
        }
      });
    list.sort((a, b) => new Date(b?.record_date) - new Date(a?.record_date));
    list.sort((a, b) => {
      if (a?.dm_sign === b?.dm_sign) return 0;
      return a?.dm_sign === "Not Signed" ? -1 : 1;
    });
    return list;
  } else {
    mdList &&
      mdList?.length > 0 &&
      mdList.forEach((entry) => {
        const {
          data,
          is_save,
          target_date,
          isQuicksign,
          is_incidental,
          record_time,
        } = entry;
        const checkCallDate = isCurrDate
          ? convertDefaultTimeZone(record_time) < sDate
          : convertDefaultTimeZone(record_time) <= sDate;
        if (checkCallDate) {
          const sMD = data && data?.selectedMD;
          const newData = data && data?.summary_data;
          const call_date = defaultDateDashFormat(record_time);
          const callType = is_incidental
            ? "-"
            : isQuicksign
            ? "Quick Call"
            : newData?.quickSignObj &&
              Object.keys(newData?.quickSignObj)?.length > 0 &&
              (newData?.quickSignObj?.signature_url ||
                newData?.quickSignObj?.signPadData)
            ? "Quick Call"
            : "Itinerary";
          const reliever_md =
            newData &&
            newData?.reliever_md &&
            Object.keys(newData?.reliever_md).length > 0
              ? newData?.reliever_md
              : "";
          const jointCall =
            newData &&
            newData?.jointCall &&
            Object.keys(newData?.jointCall).length > 0
              ? newData?.jointCall
              : "";
          const callData = {
            ...sMD,
            product:
              newData?.productDetailing?.length > 0
                ? data?.productDetailing
                : [],
            actual_call_date: record_time,
            signature_md_time: newData?.signature_time
              ? newData?.signature_time
              : "",
            date: target_date,
            name: sMD?.name ? sMD?.name : sMD?.class_name,
            reliever_md: reliever_md,
            address: sMD?.address ? sMD?.address : "No Location Found",
            call_type: callType,
            call_status: is_save ? "Saved" : "Submitted",
            dm_sign:
              jointCall && (jointCall?.signature_url || jointCall?.signPadData)
                ? "Signed"
                : "Not Signed",
            remark:
              call_date < target_date
                ? "Advanced"
                : call_date > target_date
                ? "Make-Up"
                : "On-Time",
          };
          list.push(callData);
        }
      });
    return list;
  }
};
export const getVMCRequestsData = (oofList, sDate, type) => {
  const list = [];
  oofList &&
    oofList?.length > 0 &&
    oofList.forEach((entry) => {
      const { created_at } = entry;
      if (convertDefaultTimeZone(created_at) === sDate) {
        list.push(entry);
      }
    });
  return list;
};
export const getNotYetCalledMissedCalls = (mdList, oofList, sDate) => {
  const list = [];
  mdList &&
    mdList?.length > 0 &&
    mdList.forEach((entry) => {
      const { date, md_id } = entry;
      const oofMDList = getOOFMDListByDate(oofList, date);
      if (
        date === sDate &&
        oofMDList &&
        oofMDList?.length > 0 &&
        oofMDList?.includes(md_id)
      ) {
        list.push(entry);
      }
    });
  return list;
};
const getOOFMDListByDate = (oofList, sDate) => {
  let mdIdsArray = [];
  const getDisabledMds =
    oofList &&
    oofList?.length > 0 &&
    oofList?.filter((item) => item.date === sDate);
  if (getDisabledMds && getDisabledMds?.length > 0) {
    mdIdsArray = getDisabledMds[0]?.md_ids;
  }
  return mdIdsArray;
};
const getAllOOFMDList = (data) => {
  let mdIdsArray = [];
  data.forEach((item) => {
    if (item && item?.md_ids?.length > 0) {
      mdIdsArray = mdIdsArray.concat(item?.md_ids.map((id) => id));
    }
  });

  return mdIdsArray;
};
const getHolidayByDate = (holidayList, sDate) => {
  let getHoliday = "";
  if (holidayList && holidayList?.length > 0) {
    return (
      (getHoliday = holidayList?.find((item) => item.date === sDate)) || ""
    );
  } else {
    return getHoliday;
  }
};

// MANAGE MEDIA FILES
let summaryArr = [];
let activitiesArr = [];
export const extractUrls = (type, listArr) => {
  if (type === "activities" && activitiesArr?.length !== listArr?.length) {
    const productDetailings = listArr[0]?.product_detailing;
    let imageURLs = [];
    productDetailings.forEach((item) => {
      if (!item?.pdf_url.endsWith(".pdf")) {
        imageURLs.push(item.pdf_url);
      }
      if (item.subProduct && item.subProduct?.length > 0) {
        item.subProduct.forEach((subp) => {
          if (!subp?.pdf_url.endsWith(".pdf")) {
            imageURLs.push(subp.pdf_url);
          }
        });
      }
    });
    cacheImages(imageURLs);
    activitiesArr = listArr;
  }
  if (type === "summary" && summaryArr?.length !== listArr?.length) {
    const mdListArrWithData = listArr?.filter((item) => item.data);
    const imageURLs =
      mdListArrWithData?.map(
        (item) => process.env.REACT_APP_IMAGE_URL + item.data.signature_url
      ) || [];
    cacheImages(imageURLs);
    summaryArr = listArr;
  }
};
export const cacheImages = async (srcArray) => {
  const promises = await srcArray.map((src) => {
    return new Promise(async function (resolve, reject) {
      if (src.endsWith(".pdf")) {
        try {
          const response = await fetch(src);
          if (!response.ok) {
            reject(new Error("Network response was not OK"));
            return;
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      }
    });
  });
  await Promise.all(promises)
    .then(() => {})
    .catch(() => {});
};
export const fetchMediaData = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch file from ${url}: ${response.statusText}`);
  }
  return await response.blob();
};
export const mediaToBase64 = async (media) => {
  return new Promise((resolve, reject) => {
    if (!media || !media?.size) return media;
    const reader = new FileReader();
    reader.readAsDataURL(media);
    reader.onloadend = () => {
      if (reader.result) {
        resolve(reader.result);
      } else {
        reject(media);
      }
    };
    reader.onerror = () => {
      reject(media);
    };
  });
};
export const compressMediaFiles = async (file) => {
  const canvas = document.createElement("canvas");
  const img = new Image();
  img.src = URL.createObjectURL(file);

  return new Promise((resolve) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      const context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);

      canvas.toBlob(
        async (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        0.3
      );
    };
  });
};

// OTHER FUNCTIONS
export const nameFormatting = (user, type) => {
  if (type === "FL") {
    return user && user?.firstname?.trim().concat(", ", user?.lastname?.trim());
  } else if (type === "FLC") {
    return user && user?.firstname?.trim().concat(" ", user?.lastname?.trim());
  } else if (type === "LF") {
    return user && user?.lastname?.trim().concat(" ", user?.firstname?.trim());
  } else {
    return user && user?.lastname?.trim().concat(", ", user?.firstname?.trim());
  }
};
export const getDifference = (num1, num2) => {
  let maxNum = Math.max(num1, num2);
  let minNum = Math.min(num1, num2);
  let difference = maxNum - minNum;
  return difference;
};
export const getAgeOfBirthday = (age) => {
  const ageStr = age.toString();
  const lastDigit = ageStr[ageStr.length - 1];
  const lastTwoDigits = age % 100;

  let suffix = "th";
  if (lastTwoDigits < 11 || lastTwoDigits > 13) {
    switch (lastDigit) {
      case "1":
        suffix = "st";
        break;
      case "2":
        suffix = "nd";
        break;
      case "3":
        suffix = "rd";
        break;
      default:
        suffix = "th";
        break;
    }
  }
  return age + suffix;
};
export const getClassByFreq = (freq) => {
  if (freq === "4X") {
    return "A";
  } else if (freq === "3X") {
    return "B";
  } else if (freq === "2X") {
    return "C";
  } else if (freq === "1X") {
    return "D";
  } else if (freq === "0X") {
    return "OUTLET";
  } else {
    return "";
  }
};
export const manageStatus = (type) => {
  if (type === "DISAPPROVED") {
    return "Disapproved";
  } else if (type === "APPROVED") {
    return "Approved";
  } else if (type === "ADMIN_APPROVAL") {
    return "Approved";
  } else {
    return type;
  }
};
export const numberFormatting = (number) => {
  const formattedNumber = new Intl.NumberFormat().format(number && number);
  return formattedNumber;
};
export const handleVisits = (actual, target) => {
  if (target > 0) {
    return actual > 0 ? actual + " of " + target : 0 + " of " + target;
  } else {
    return 0 + " of " + 0;
  }
};
export const copyToClipboard = (item, type) => {
  if (type === "error") {
    const newItem = {};
    if (item?.response) {
      newItem.message = item.message;
      newItem.data = item?.response?.data;
      newItem.status = item?.response?.status;
      newItem.statusText = item?.response?.statusText;
      newItem.responseURL = item?.response?.request?.responseURL;
      navigator.clipboard.writeText(JSON.stringify(newItem));
    } else {
      newItem.message = item.message;
      newItem.data = item?.request?.response;
      newItem.status = item?.request?.status;
      newItem.statusText = item?.request?.statusText;
      newItem.responseURL = item?.request?.responseURL;
      navigator.clipboard.writeText(newItem);
    }
  } else {
    navigator.clipboard.writeText(JSON.stringify(item));
  }
  notify("Copied Successfully", "success");
};
export const modifyText = (data) => {
  return data ? data?.trim().toLowerCase() : data;
};
export const getSortData = (data, key) => {
  if (data && data?.length === 0) return [];
  if (key) {
    const newData =
      data &&
      data?.length > 0 &&
      data.sort((a, b) => a[key].localeCompare(b[key]));
    return newData;
  } else {
    return data;
  }
};
export const getSeconds = (data) => {
  let mSeconds = data > 0 ? data : 0;
  let seconds = Math.round(mSeconds / 1000);
  return `${seconds} ${seconds > 1 ? "seconds" : "second"}`;
};
export const createMonthCalendar = (year, month) => {
  const daysInMonth = new Date(year, month, 0).getDate();
  const firstDay = new Date(year, month - 1, 1).getDay();
  const prevMonthDays = new Date(year, month - 1, 0).getDate();
  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const calendar = [];
  for (let i = firstDay; i > 0; i--) {
    calendar.push({
      date: prevMonthDays - i + 1,
      day: dayNames[firstDay - i],
      is_month: 0,
    });
  }

  for (let day = 1; day <= daysInMonth; day++) {
    const dayName = dayNames[(firstDay + day - 1) % 7];
    calendar.push({ date: day, day: dayName, is_month: 1 });
  }

  const totalDays = calendar.length;
  const leadingDaysCount = totalDays % 7 === 0 ? 0 : 7 - (totalDays % 7);
  for (let day = 1; day <= leadingDaysCount; day++) {
    const dayName = dayNames[(firstDay + daysInMonth + day) % 7];
    calendar.push({ date: day, day: dayName, is_month: 0 });
  }

  const weeks = [];
  for (let i = 0; i < calendar.length; i += 7) {
    weeks.push(calendar.slice(i, i + 7));
  }
  return weeks;
};
