import React from "react";

const CustomImageCapture = (props) => {
  const { inputRef, handleFileChange } = props;
  return (
    <input
      type="file"
      ref={inputRef}
      accept="image/*"
      capture="user"
      onChange={handleFileChange}
      style={{ display: "none" }}
    />
  );
};

export default CustomImageCapture;
