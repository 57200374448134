import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getDisapprovalReasons,
  getTmList,
} from "../../store/slice/itinerarySlice";
import { Button, Table } from "react-bootstrap";
import moment from "moment";
import { Loader, DataNotFound } from "../../components";
import { ItineraryApproval } from "../../components/Modals";
import {
  approvalMissedcall,
  disapprovalMissedcall,
  getMissedCallList,
} from "../../store/slice/missedCallSlice";
import { nameFormatting } from "../../helpers/commonFunction";

const MissedCalls = ({ adminUser }) => {
  const dispatch = useDispatch();

  const itineraryReducer = useSelector((state) => state.itinerary);
  const tmList = itineraryReducer && itineraryReducer.tmList;
  // const itineraryList = itineraryReducer && itineraryReducer.itineraryList;
  const disapprovalReasonList =
    itineraryReducer && itineraryReducer.disapprovalReasons;

  const missedCallReducer = useSelector((state) => state.missedCallReducer);
  const missedCallList = missedCallReducer && missedCallReducer.missedCallList;

  const [selectedTM, setSelectedTM] = useState("");
  const [missedCalls, setMissedCalls] = useState(missedCallList || []);

  const [modalShow, setmodalShow] = useState(false);
  const [modalType, setModalType] = useState("");

  const [selectedResons, setSelectedResons] = useState("");
  const [remarks, setRemarks] = useState("");

  const [errors, setErrors] = useState("");
  const [allSelect, setAllSelect] = useState(false);

  useEffect(() => {
    setMissedCalls(missedCallList);
  }, [missedCallList]);

  // TM List options
  const tmListOptions =
    tmList &&
    tmList.map((item) => {
      return {
        ...item,
        value: nameFormatting(item),
        label: nameFormatting(item),
      };
    });

  // disapproval Reasons options
  const reasonsOptions =
    disapprovalReasonList &&
    disapprovalReasonList.map((item) => {
      return {
        ...item,
        value: item.id,
        label: item.name,
      };
    });

  useEffect(() => {
    dispatch(getTmList({ data: { type: adminUser ? "ADMIN" : "DM" } }));
    dispatch(getDisapprovalReasons({}));
  }, []);

  const handleOnChange = (event, type, name) => {
    if (type === "option") {
      if (name === "tm") {
        setSelectedTM(event);
        const data = {
          user_id: event.id,
        };
        if (adminUser) {
          data.type = "ADMIN";
        }
        dispatch(getMissedCallList({ data: data }));
      }
      if (name === "reason") {
        setSelectedResons(event);
        setErrors({
          ...errors,
          selectedResons: "",
        });
      }
    } else {
      if (name === "remarks") {
        const trimmedValue = event.target.value.trimStart();
        setRemarks(trimmedValue);
        setErrors({
          ...errors,
          remarks: "",
        });
      }
    }
  };

  const getUpdatedDate = (createdAt, updatedAt) => {
    if (updatedAt) {
      return (
        <span>
          {moment(updatedAt).format("MM/DD/YY")}
          <br />
          {moment(updatedAt).format("h:mm:ss A")}
        </span>
      );
    } else {
      return (
        <span>
          {moment(createdAt).format("MM/DD/YY")}
          <br />
          {moment(createdAt).format("h:mm:ss A")}
        </span>
      );
    }
  };

  const selectMisscallHandler = (clickedMisscall) => {
    const list = [...missedCalls];
    const updatedMissedCalls = list.map((misscall) => {
      if (misscall.id === clickedMisscall.id) {
        return { ...misscall, isChecked: !misscall.isChecked };
      }
      return misscall;
    });
    if (
      updatedMissedCalls &&
      updatedMissedCalls.every((item) => item.isChecked === true)
    ) {
      setAllSelect(true);
    } else {
      setAllSelect(false);
    }
    setMissedCalls(updatedMissedCalls);
  };

  const allSelectMisscallHandler = () => {
    const list = [...missedCalls];
    setAllSelect(!allSelect);
    const updatedMissedCalls = list.map((misscall) => {
      if (checkIsEnable(misscall)) {
        return { ...misscall, isChecked: !allSelect };
      }
      return misscall;
    });
    setMissedCalls(updatedMissedCalls);
  };

  const showModalHandler = (isApprove) => {
    setmodalShow(true);
    setModalType(isApprove ? "Approve" : "Disapprove");
  };

  const hideModal = () => {
    setmodalShow(false);
    setModalType("");
    setSelectedResons("");
    setRemarks("");
    setErrors("");
  };

  const checkedOrNot = () => {
    const isCheckedArr = missedCalls.filter((itinerary) => itinerary.isChecked);
    if (isCheckedArr.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const validateDisapprove = () => {
    let err = {};
    if (!selectedResons) {
      err = { ...err, selectedResons: "Please select reason!" };
    }
    if (!remarks.trim()) {
      err = { ...err, remarks: "Please add remarks!" };
    }
    setErrors(err);
    return Object.keys(err).length === 0;
  };

  const getDisapprovalList = () => {
    const DisapprovalMissedCalls = missedCalls.filter(
      (misscall) => misscall.isChecked
    );

    const finalArr = DisapprovalMissedCalls.map((item) => {
      return {
        id: item.id,
        reason: selectedResons.id,
        remark: remarks,
      };
    });

    return finalArr;
  };

  const getApprovalList = () => {
    const DisapprovalMissedCalls = missedCalls.filter(
      (misscall) => misscall.isChecked
    );

    const finalArr = DisapprovalMissedCalls.map((item) => {
      return item.id;
    });

    return finalArr;
  };

  const approveDisapproveHandler = (method) => {
    if (method === "Approve") {
      const data = {
        approval: getApprovalList(),
      };
      if (adminUser) {
        data.type = "ADMIN";
      }
      dispatch(
        approvalMissedcall({
          data: data,
          cb: (res) => {
            const getData = {
              user_id: selectedTM.id,
            };
            if (adminUser) {
              getData.type = "ADMIN";
            }
            dispatch(
              getMissedCallList({
                data: getData,
              })
            );
            hideModal();
          },
        })
      );
    } else {
      if (validateDisapprove()) {
        const data = {
          disapproval: getDisapprovalList(),
        };
        dispatch(
          disapprovalMissedcall({
            data: data,
            cb: (res) => {
              const getData = {
                user_id: selectedTM.id,
              };
              if (adminUser) {
                getData.type = "ADMIN";
              }
              dispatch(
                getMissedCallList({
                  data: getData,
                })
              );
              hideModal();
            },
          })
        );
      }
    }
  };

  const checkIsEnable = (misscall) => {
    if (
      (!adminUser && misscall.approve_request === "DISAPPROVED") ||
      (!adminUser && misscall.approve_request === "APPROVED") ||
      (!adminUser && misscall.approve_request === "ADMIN_APPROVAL") ||
      (adminUser && misscall.approve_request !== "ADMIN_APPROVAL")
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {((itineraryReducer && itineraryReducer.loader) ||
        (missedCallReducer && missedCallReducer.loader)) && <Loader />}
      <div className="tc-body tc-itinerary-body-section">
        <div className="tc-calender-search-input-row tc-itinerary-select-row mb-5">
          <div className="tc-calender-input-content">
            <span className="text-select-option">
              TERRITORIAL <br /> MANAGER:{" "}
            </span>
            <Select
              name="option"
              value={selectedTM}
              onChange={(e) => handleOnChange(e, "option", "tm")}
              options={tmListOptions}
              className={`tc-custom-select`}
              classNamePrefix="select"
              placeholder="Select/Search TM..."
            />
          </div>
        </div>
        <div>
          <h6 className="mb-3">MISSED CALL REQUESTS</h6>
        </div>
        <div className="tc-table-wrapper tc-white-table-wrapper">
          <Table responsive className="tc-table">
            <thead>
              <tr>
                <th className="text-center">
                  <input
                    className="form-check-input form-bordered-check-input"
                    type="checkbox"
                    id="toggle-check-btn"
                    checked={allSelect}
                    disabled={
                      (missedCalls && missedCalls?.length === 0) ||
                      (missedCalls &&
                        missedCalls?.every(
                          (item) => checkIsEnable(item) === false
                        )) ||
                      !selectedTM
                    }
                    onClick={() => allSelectMisscallHandler()}
                  />
                </th>
                <th className="text-center">MD CODE</th>
                <th className="text-center">MD NAME</th>
                <th className="text-center">MONTH</th>
                <th className="text-center">DAY</th>
                <th className="text-center">DATES</th>
                <th className="text-center">REASON</th>
                <th className="text-center">remark</th>
                <th className="text-center">STATUS</th>
                <th className="text-center">DATE SUBMITTED</th>
              </tr>
            </thead>
            <tbody>
              {missedCalls.length === 0 || !selectedTM ? (
                <tr>
                  <td colSpan={10} className="text-center">
                    <DataNotFound />
                  </td>
                </tr>
              ) : (
                missedCalls.map((misscall, i) => (
                  <tr
                    key={i}
                    className={`${
                      (misscall.approve_request === "APPROVED" ||
                        misscall.approve_request === "DISAPPROVED" ||
                        (!adminUser &&
                          misscall.approve_request === "ADMIN_APPROVAL")) &&
                      "bg-color-gray"
                    }`}
                  >
                    <td className="text-center">
                      {checkIsEnable(misscall) && (
                        <input
                          className="form-check-input form-bordered-check-input"
                          type="checkbox"
                          id="toggle-check-btn"
                          checked={misscall.isChecked ? true : false}
                          onClick={() => selectMisscallHandler(misscall)}
                        />
                      )}
                    </td>
                    <td className="text-center min-width-200">
                      {misscall?.md_list?.length === 0 ? (
                        <div>-</div>
                      ) : (
                        <>
                          {misscall.md_list.map((md, i) => {
                            return (
                              <div key={i}>
                                {md.code}
                                {misscall.md_list.length - 1 === i ? "" : ","}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </td>
                    <td className="text-center min-width-250">
                      {misscall?.md_list?.length === 0 ? (
                        <div>-</div>
                      ) : (
                        <>
                          {misscall.md_list.map((md, i) => {
                            return (
                              <div key={i}>
                                {md.name}
                                {misscall.md_list.length - 1 === i ? "" : ","}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </td>
                    <td className="text-center">
                      {moment(misscall.date).format("MMM").toUpperCase()}
                    </td>
                    <td className="text-center">
                      {moment(misscall.date).format("ddd").toUpperCase()}
                    </td>
                    <td className="text-center">
                      {moment(misscall.date).format("MM/DD/YY")}
                    </td>
                    <td className="text-center min-width-250">
                      {misscall.reason ? misscall.reason : "-"}
                    </td>
                    <td className="text-center min-width-250">
                      {misscall.remark ? misscall.remark : "-"}
                    </td>
                    <td className="text-center min-width-200">
                      {misscall.approve_request === "APPROVED" ||
                      misscall.approve_request === "DISAPPROVED"
                        ? misscall.approve_request
                        : misscall.approve_request === "ADMIN_APPROVAL"
                        ? !adminUser
                          ? "For Admin Approval"
                          : "For Approval"
                        : "For Approval"}
                      {(misscall.approve_request === "APPROVED" ||
                        misscall.approve_request === "DISAPPROVED") && (
                        <div>
                          {moment(misscall.updated_at).format("MM/DD/YY")}
                        </div>
                      )}
                    </td>
                    <td className="text-center">
                      {getUpdatedDate(misscall.created_at, misscall.updated_at)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
        {missedCalls.length > 0 && checkedOrNot() && (
          <div className="text-center mt-5">
            <div className="btn-row-container">
              <Button variant="danger" onClick={() => showModalHandler()}>
                Disapprove
              </Button>
              <Button variant="primary" onClick={() => showModalHandler(true)}>
                Approve
              </Button>
            </div>
          </div>
        )}
      </div>
      <ItineraryApproval
        show={modalShow}
        onHide={() => hideModal()}
        modalType={modalType}
        reasonsOptions={reasonsOptions}
        selectedResons={selectedResons}
        remarks={remarks}
        handleOnChange={handleOnChange}
        approveDisapproveHandler={approveDisapproveHandler}
        errors={errors}
        isMisscall={true}
      />
    </>
  );
};

export default MissedCalls;
