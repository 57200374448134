import React from "react";
import { Table } from "react-bootstrap";

const ReportTable = ({
  selectedReportType,
  tableHeaders,
  headerStyle,
  tableBody,
  getReportData,
  getTotalValues,
  showChart,
}) => {
  return (
    <div
      className={` w-100 report-table ${
        selectedReportType.value === "days_in_field" &&
        "tc-report-table-center-table"
      } ${showChart(selectedReportType.value) ? "" : "without-chart"}`}
    >
      <h6 className="text-end">
        {selectedReportType.value === "md_coverage" ||
        selectedReportType.value === "total_calls" ||
        selectedReportType.value === "today_not_submitted_saved_calls" ||
        selectedReportType.value === "monthly_not_submitted_saved_calls" ||
        selectedReportType.value === "today_not_signed_joint_calls" ||
        selectedReportType.value === "monthly_not_signed_joint_calls"
          ? `Total # of Calls: ${
              getReportData(selectedReportType.value)?.length
            }`
          : selectedReportType.value === "total_mds"
          ? `Total # of MDs: ${getReportData(selectedReportType.value)?.length}`
          : selectedReportType.value === "today_vmc_request" ||
            selectedReportType.value === "monthly_vmc_request"
          ? `Total # of VMC: ${getReportData(selectedReportType.value)?.length}`
          : selectedReportType.value === "monthly_missed_calls"
          ? `Total # of Missed Calls: ${
              getReportData(selectedReportType.value)?.length
            }`
          : selectedReportType.value === "incidental_calls" ||
            selectedReportType.value === "monthly_incidental_calls"
          ? `Total # of Incidentals: ${
              getReportData(selectedReportType.value)?.length
            }`
          : ""}
      </h6>
      <div
        className={`tc-dashboard-table tc-table-wrapper ${
          selectedReportType.value === "days_in_field" && "tc-report-table-sm"
        }`}
      >
        <Table responsive className="tc-table">
          <thead>
            <tr>
              {tableHeaders(selectedReportType.value).map((item, i) => (
                <th
                  key={i}
                  colSpan={`${
                    selectedReportType.value === "days_in_field" && 2
                  }`}
                  className={headerStyle(selectedReportType.value, i)}
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{tableBody(selectedReportType.value)}</tbody>
          <tfoot>{getTotalValues(selectedReportType.value)}</tfoot>
        </Table>
      </div>
    </div>
  );
};

export default ReportTable;
