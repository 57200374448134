import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { DataNotFound } from "../../components";
import { MonthlyCallDates } from "../../components/Modals";
import { SearchIcon, backBtnIcon, callIcon } from "../../icon/icon";
import { useNavigate } from "react-router-dom";
import { routes } from "../../helpers/constants";
import { handleVisits, nameFormatting } from "../../helpers/commonFunction";
import { messages } from "../../helpers/messages";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../helpers/db";

const MonthlyItinerary = () => {
  const navigate = useNavigate();

  // Get indexedDB data
  const getMDListFromDB = useLiveQuery(() => db.itineraryMDs.toArray(), []);

  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [newMdListData, setNewMdListData] = useState([]);
  const [newMdListData2, setNewMdListData2] = useState([]);

  const [selectedMD, setSelectedMD] = useState("");
  const [selectedMDCallDates, setSelectedMDCallDates] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (newMdListData && newMdListData?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [newMdListData]);

  useEffect(() => {
    handleSearchMDList();
  }, []);

  const handleSearchMDList = async (searchBy, value) => {
    if (searchBy === "name") {
      const newList =
        newMdListData2 &&
        newMdListData2?.filter(
          (item) =>
            `${item.lastname.trim()} ${item.firstname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase()) ||
            `${item.firstname.trim()} ${item.lastname.trim()}`
              .toLowerCase()
              .includes(value.toLowerCase())
        );
      const finalList =
        newList?.length > 0 ? newList : !value ? getMDListFromDB : [];
      setNewMdListData(finalList);
    } else {
      await db
        .table("itineraryMDs")
        .toArray()
        .then((entries) => {
          const mdIdMap = new Map();
          entries.forEach((entry) => {
            const {
              id,
              date,
              md_id,
              is_summary,
              is_visited,
              is_consecutive,
              ...rest
            } = entry;
            const formattedDate = {
              date,
              is_summary,
              is_visited,
              is_consecutive,
              mdData: entry,
            };
            if (mdIdMap.has(md_id)) {
              const existingEntry = mdIdMap.get(md_id);
              if (is_summary === 1) {
                existingEntry.actual_visits++;
              }
              existingEntry.dates.push(formattedDate);
            } else {
              mdIdMap.set(md_id, {
                ...rest,
                id,
                md_id,
                dates: [formattedDate],
                actual_visits: is_summary === 1 ? 1 : 0,
              });
            }
          });
          const uniqueEntries = Array.from(mdIdMap.values());
          setNewMdListData(uniqueEntries);
          setNewMdListData2(uniqueEntries);
        });
    }
  };

  const handleOnChange = (event) => {
    if (event.target.value === "") {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    } else {
      handleSearchMDList("name", event.target.value);
      setSearchValue(event.target.value);
    }
  };

  const handleScheduleModal = (data) => {
    setShowModal(true);
    setSelectedMD(data);
    setSelectedMDCallDates(data.dates);
  };

  // Data table
  const columns = [
    {
      name: "Class",
      sortable: true,
      selector: (row) => row?.name,
      cell: (row) => (
        <p className={`tc-data-table-class-text text-center w-100`}>
          {row?.name}
        </p>
      ),
    },
    {
      name: "Md Name",
      sortable: true,
      selector: (row) => row?.firstname.concat(" ", row?.lastname),
      cell: (row) => (
        <p
          role="button"
          className={`tc-data-table-md-name-text text-start`}
          onClick={() => handleScheduleModal(row)}
        >
          {nameFormatting(row)}
        </p>
      ),
    },
    {
      name: "Visits",
      sortable: true,
      selector: (row) => handleVisits(row?.actual_visits, row?.target),
      cell: (row) => (
        <p className={`tc-data-table-visits-text tc-data-table-green-text`}>
          {handleVisits(row?.actual_visits, row?.target)}
        </p>
      ),
    },
    {
      name: "SKED",
      sortable: true,
      selector: (row) => row.call,
      cell: (row) => (
        <button className="tc-data-table-button tc-data-table-icon" disabled>
          {callIcon}
        </button>
      ),
    },
  ];

  return (
    <>
      <div className="tc-body">
        <div className="tc-navbar-back-text">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => navigate(routes.itineraryPerDay)}
          >
            {backBtnIcon}
          </button>
          <p className="tc-navbar-title"></p>
          <div className="tc-navbar-right-btn"></div>
        </div>
        <div className="tc-profile-wrapper">
          <span className="tc-profile-name">MONTHLY ITINERARY</span>
        </div>
        <div className="add-scroll">
          <div className="tc-content-wrapper">
            <h2>MDs TO VISIT</h2>
            <div className="tc-calender-search-input-row">
              <div className="tc-search-input-content d-flex">
                <input
                  id="text"
                  type="text"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => handleOnChange(e)}
                />
                <button
                  type="button"
                  className="tc-search-input-btn"
                  onClick={() => handleSearchMDList("name", searchValue)}
                  disabled={searchValue === "" && true}
                >
                  {SearchIcon}
                </button>
              </div>
            </div>
            <h6 className="d-flex justify-content-end">
              Total MDs:{" "}
              {newMdListData && newMdListData?.length > 0
                ? newMdListData?.length
                : 0}
            </h6>
            {isLoading ? null : newMdListData && newMdListData?.length === 0 ? (
              <DataNotFound name={messages.toast.nodateData} />
            ) : (
              <DataTable
                columns={columns}
                data={newMdListData && newMdListData}
                className={`tc-data-table flex-grow-1 overflow-y-auto ${
                  newMdListData &&
                  newMdListData?.length === 0 &&
                  "d-flex align-items-center"
                }`}
              />
            )}
          </div>
        </div>
      </div>

      <MonthlyCallDates
        show={showModal}
        onHide={() => setShowModal(false)}
        selectedMD={selectedMD}
        disableMDs={[]}
        holidays={[]}
        selectedMDCallDates={selectedMDCallDates}
        handleCallActivitiesOfMD={() => setShowModal(false)}
      />
    </>
  );
};

export default MonthlyItinerary;
