import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";
import { forceLogout } from "./userSlice";

// Request to get promo material Data
export const reqToGetPromoMatList = createAsyncThunk(
  "reqToGetPromoMatList",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.promoMaterialList,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

// Request to get promo material Data
export const reqToGetPromoMatsReasons = createAsyncThunk(
  "reqToGetPromoMatsReasons",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.promoMatsReasons,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);
// Request to updateMaterial
export const updateMaterial = createAsyncThunk(
  "updateMaterial",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.updateMaterial,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      return error;
    }
  }
);

// Request to recive Material
export const reqToGetReciveMatList = createAsyncThunk(
  "reqToGetReciveMatList",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.reciveMatsList,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);
// Request to adjust Reasons
export const reqToGetAdjustReasons = createAsyncThunk(
  "reqToGetAdjustReasons",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.adjustReasons,
        authHeaders()
      );
      return response;
    } catch (error) {
      if (error?.response?.status === 403 || error?.response?.status === 401) {
        data.dispatch(forceLogout());
      }
      return error;
    }
  }
);

// submit Receive Mats
// Request to updateMaterial
export const submitReceiveMats = createAsyncThunk(
  "submitReceiveMats",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.submitReceiveMats,
        data.data,
        authHeaders()
      );
      if (response.status === 200 && data.cb) {
        return data.cb(null, response.data);
      }
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  promoMaterialsList: [],
  promoMatsReasons: [],
  updateComponent: "",
  addComponent: "",
  is_received: 0,
  receivedMatsList: [],
  adjustReasons: [],
};

// Create promo materials slice
const promoMaterialSlice = createSlice({
  name: "promoMaterials",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get promo materials data
    builder.addCase(reqToGetPromoMatList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetPromoMatList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.promoMaterialsList = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetPromoMatList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get promo materials Reasons
    builder.addCase(reqToGetPromoMatsReasons.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetPromoMatsReasons.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.promoMatsReasons = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetPromoMatsReasons.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Update promo materials Reasons
    builder.addCase(updateMaterial.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(updateMaterial.fulfilled, (state, action) => {
      state.loader = false;
      state.updateComponent = action.payload;
    });
    builder.addCase(updateMaterial.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Reviced materials data
    builder.addCase(reqToGetReciveMatList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetReciveMatList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.receivedMatsList = action?.payload?.data?.materials;
        state.is_received = action?.payload?.data?.is_received;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetReciveMatList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get Adjust Reasons
    builder.addCase(reqToGetAdjustReasons.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetAdjustReasons.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.adjustReasons = action?.payload?.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetAdjustReasons.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Submit promomaterials Receive
    builder.addCase(submitReceiveMats.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(submitReceiveMats.fulfilled, (state, action) => {
      state.loader = false;
      state.addComponent = action.payload;
    });
    builder.addCase(submitReceiveMats.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default promoMaterialSlice.reducer;
