import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { messages } from "../helpers/messages";
import DataNotFound from "./DataNotFound";
import { cakeIcon2 } from "../icon/icon";
import { nameFormatting } from "../helpers/commonFunction";
import { routes } from "../helpers/constants";
import { useNavigate } from "react-router-dom";

const CustomDataTable = (props) => {
  const {
    tableType,
    dataList,
    holidays,
    disableMDs,
    selectedDate,
    handleMDBirthDay,
    handleCallActivity,
  } = props;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (dataList && dataList?.length === 0) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
    }
  }, [dataList]);

  const compareDates = (birthDate) => {
    const date1 = new Date(birthDate);
    const date2 = new Date(selectedDate);

    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();

    const dayMonth1 = date1.getDate() + "-" + (date1.getMonth() + 1);
    const dayMonth2 = date2.getDate() + "-" + (date2.getMonth() + 1);

    const yearsDifference = Math.abs(year2 - year1);
    const sameDatMonth = dayMonth1 === dayMonth2;
    return {
      sameDatMonth,
      yearsDifference,
    };
  };

  const getTableColumns = () => {
    const columns = [
      {
        name: "Class",
        sortable: true,
        selector: (row) => row?.name,
        cell: (row) => (
          <p
            className={`tc-data-table-class-text text-center w-100 ${
              tableType === "mdList" &&
              row?.is_summary === 0 &&
              (disableMDs?.disable_all === 1 ||
                holidays?.holiday_all === 1 ||
                row?.is_consecutive === 1 ||
                disableMDs?.md_ids?.includes(row?.md_id) ||
                row?.is_disabled === 1)
                ? "tc-table-row-disabled"
                : ""
            }`}
          >
            {row?.name}
          </p>
        ),
      },
      {
        name: "Md Name",
        sortable: true,
        selector: (row) => row?.firstname.concat(" ", row?.lastname),
        cell: (row) => (
          <>
            {row?.is_summary === 0 ? (
              <p
                role="button"
                className={`tc-data-table-md-name-text text-start ${
                  disableMDs?.disable_all === 1 ||
                  holidays?.holiday_all === 1 ||
                  row?.is_visited === 1 ||
                  row?.is_consecutive === 1 ||
                  disableMDs?.md_ids?.includes(row?.md_id) ||
                  row?.is_disabled === 1
                    ? "tc-table-row-disabled"
                    : ""
                }`}
                onClick={() => handleCallActivity(row)}
              >
                {nameFormatting(row)}
              </p>
            ) : (
              <p
                role="button"
                className="tc-data-table-md-name-text text-start"
                onClick={() => {
                  navigate(routes.viewCallSummary, {
                    state: { mdData: row, callDate: selectedDate },
                  });
                }}
              >
                {nameFormatting(row)}
              </p>
            )}
            {row?.birthdate && compareDates(row.birthdate).sameDatMonth ? (
              <span
                className="ms-2 tc-data-table-icon3"
                role="button"
                onClick={() => handleMDBirthDay(row)}
              >
                {cakeIcon2}
              </span>
            ) : (
              <span className="ms-2 tc-data-table-icon3-nodata"></span>
            )}
          </>
        ),
      },
    ];
    if (tableType === "mdList") {
    }
    return columns;
  };
  return (
    <>
      {isLoading ? null : dataList && dataList?.length === 0 ? (
        <DataNotFound name={messages.toast.nodateData} />
      ) : (
        <DataTable
          columns={getTableColumns()}
          data={dataList && dataList}
          className={`tc-data-table flex-grow-1 overflow-y-auto ${
            dataList && dataList?.length === 0 && "d-flex align-items-center"
          }`}
        />
      )}
    </>
  );
};

export default CustomDataTable;
