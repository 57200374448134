import React, { useState, useEffect } from "react";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { dateIcon2 } from "../../../icon/icon";
import { Loader, CustomDateInput } from "../../../components";
import {
  reqToGetDMList,
  reqToGetTmListByDM,
  reqToGetMDListByTM,
  reqToGetCallList,
} from "../../../store/slice/auditReportSlice";
import {
  nameFormatting,
  dateFormatting,
} from "../../../helpers/commonFunction";

const Calls = () => {
  const dispatch = useDispatch();

  // Get reducer data
  const auditReportReducer = useSelector((state) => state.auditReportReducer);
  const { loader, dMList, tmList, mdList, callList } = auditReportReducer;

  const currentDate = dateFormatting();
  const [selectedSDate, setSelectedSDate] = useState(currentDate);
  const [selectedEDate, setSelectedEDate] = useState(currentDate);
  const [selectedDM, setSelectedDM] = useState();
  const [selectedTM, setSelectedTM] = useState();
  const [selectedMD, setSelectedMD] = useState();

  useEffect(() => {
    dispatch(reqToGetDMList({ data: "", onSuccess: () => {}, dispatch }));
  }, []);

  const dmListOptions =
    (dMList &&
      dMList?.length > 0 &&
      dMList?.map((item) => {
        return {
          ...item,
          value: nameFormatting(item),
          label: nameFormatting(item),
        };
      })) ||
    [];

  const tmListOptions =
    (tmList &&
      tmList?.length > 0 &&
      tmList?.map((item) => {
        return {
          ...item,
          value: nameFormatting(item),
          label: item?.terr_code + " - " + nameFormatting(item),
        };
      })) ||
    [];

  const mdListOptions =
    (mdList &&
      mdList?.length > 0 &&
      mdList?.map((item) => {
        return {
          ...item,
          value: nameFormatting(item),
          label: nameFormatting(item),
        };
      })) ||
    [];
  mdListOptions &&
    mdListOptions?.length > 0 &&
    mdListOptions.unshift({
      md_id: null,
      label: "All MDs",
      value: "all-mds",
    });

  const handleChangeValues = (event, type, name) => {
    if (type === "option") {
      if (name === "DM") {
        setSelectedDM(event);
        setSelectedTM("");
        setSelectedMD("");
        dispatch(
          reqToGetTmListByDM({
            data: { dm_id: event.id },
            onSuccess: () => {},
            dispatch,
          })
        );
      } else if (name === "TM") {
        setSelectedTM(event);
        setSelectedMD("");
        dispatch(
          reqToGetMDListByTM({
            data: { user_id: event.user_id },
            onSuccess: () => {},
            dispatch,
          })
        );
      } else if (name === "MD") {
        setSelectedMD(event);
        const tempObj = {
          user_id: selectedTM.user_id,
          start: selectedSDate,
          end: selectedEDate,
          md_id: event.md_id,
        };
        dispatch(
          reqToGetCallList({
            data: tempObj,
            onSuccess: () => {},
            dispatch,
          })
        );
      }
    } else if (type === "date") {
      const tempObj = {
        user_id: selectedTM?.user_id,
        start: selectedSDate,
        end: selectedEDate,
        md_id: selectedMD?.md_id,
      };
      if (name === "sDate") {
        setSelectedSDate(event);
        if (selectedMD) {
          dispatch(
            reqToGetCallList({
              data: { ...tempObj, start: event },
              onSuccess: () => {},
              dispatch,
            })
          );
        }
      } else {
        setSelectedEDate(event);
        if (selectedMD) {
          dispatch(
            reqToGetCallList({
              data: { ...tempObj, end: event },
              onSuccess: () => {},
              dispatch,
            })
          );
        }
      }
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="tc-body tc-calls-container">
        <div className="add-scroll">
          <div className="tc-content-wrapper">
            <div className="row justify-content-center justify-content-xxl-between align-items-center gap">
              <div className="col-6 col-md-6 col-lg-4 col-xxl-2 mb-2 mb-xxl-0">
                <span className="">Call Date From:</span>
                <ReactDatePicker
                  selected={moment(selectedSDate)._d}
                  onChange={(e) =>
                    handleChangeValues(
                      moment(e).format("YYYY-MM-DD"),
                      "date",
                      "sDate"
                    )
                  }
                  id="date-picker-md"
                  dateFormat="yyyy-MM-dd"
                  value={moment(selectedSDate).format("LL")}
                  maxDate={moment().clone().endOf("month")._d}
                  onKeyDown={(e) => e.preventDefault()}
                  customInput={
                    <CustomDateInput className="bg-white" icon={dateIcon2} />
                  }
                />
              </div>
              <div className="col-6 col-md-6 col-lg-4 col-xxl-2 mb-2 mb-xxl-0">
                <span className="">Call Date To:</span>
                <ReactDatePicker
                  selected={moment(selectedEDate)._d}
                  onChange={(e) =>
                    handleChangeValues(
                      moment(e).format("YYYY-MM-DD"),
                      "date",
                      "eDate"
                    )
                  }
                  id="date-picker-md"
                  dateFormat="yyyy-MM-dd"
                  value={moment(selectedEDate).format("LL")}
                  maxDate={moment().clone().endOf("month")._d}
                  onKeyDown={(e) => e.preventDefault()}
                  customInput={
                    <CustomDateInput className="bg-white" icon={dateIcon2} />
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xxl-2 mb-2 mb-xxl-0">
                <span className="">District:</span>
                <Select
                  name="option"
                  value={selectedDM}
                  onChange={(e) => handleChangeValues(e, "option", "DM")}
                  options={dmListOptions}
                  className={`tc-custom-select-2`}
                  classNamePrefix="select"
                  placeholder="Select..."
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xxl-2 mb-2 mb-xxl-0">
                <span>Territory Manager:</span>
                <Select
                  name="option"
                  value={selectedTM}
                  onChange={(e) => handleChangeValues(e, "option", "TM")}
                  options={tmListOptions}
                  className={`tc-custom-select-2`}
                  classNamePrefix="select"
                  placeholder="Select..."
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xxl-2 mb-2 mb-xxl-0">
                <span>MD:</span>
                <Select
                  name="option"
                  value={selectedMD}
                  onChange={(e) => handleChangeValues(e, "option", "MD")}
                  options={mdListOptions}
                  className={`tc-custom-select-2`}
                  classNamePrefix="select"
                  placeholder="Select..."
                />
              </div>
            </div>
            <div className="tc-table-wrapper mt-3">
              <Table responsive className="tc-table">
                <thead>
                  <tr>
                    <th>TM Code </th>
                    <th>TM Name</th>
                    <th>Clinic</th>
                    <th>MD Code</th>
                    <th>MD Name</th>
                    <th>Specialty</th>
                    <th>Class</th>
                    <th>Planned Date</th>
                    <th>Actual Date</th>
                    <th>Posted Date</th>
                    <th>Signature</th>
                    <th>Location</th>
                  </tr>
                </thead>
                {callList && callList?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan={12} className="text-center">
                        No data found
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {callList &&
                      callList?.map((item, i) => {
                        return (
                          <tr key={i}>
                            <td>{item?.terr_code}</td>
                            <td className="min-width-250">{item?.tm_name}</td>
                            <td className="min-width-350">
                              {item?.clinic_name}
                            </td>
                            <td className="min-width-200">{item?.md_code}</td>
                            <td className="min-width-350">{item?.md_name}</td>
                            <td className="min-width-250">{item?.specialty}</td>
                            <td>{item?.class_name}</td>
                            <td>
                              {item?.planned_date
                                ? moment(item?.planned_date).format("MMMM DD")
                                : "-"}
                            </td>
                            <td>
                              {item?.record_time
                                ? moment(item?.record_time).format("MMMM DD")
                                : "-"}
                            </td>
                            <td className="min-width-180">
                              {item?.record_time
                                ? moment(item?.record_time).format("LLL")
                                : "-"}
                            </td>
                            <td>
                              <img
                                style={{
                                  height: "100px",
                                  background: "#fff",
                                  padding: "8px",
                                }}
                                src={
                                  process.env.REACT_APP_IMAGE_URL +
                                  item?.signature_url
                                }
                                alt="call signature img"
                              />
                            </td>
                            <td className="min-width-450">{item?.address}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                )}
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Calls;
