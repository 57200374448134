import React, { useEffect, useState } from "react";
import moment from "moment";
import { backBtnIcon } from "../../icon/icon";
import { Button, Nav, Tab, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { nameFormatting, numberFormatting } from "../../helpers/commonFunction";
import { messages } from "../../helpers/messages";
import { DoctorIcon } from "../../images";
import { CallActivitiesMDDetails } from "../../components";
import {
  reqToUpdateProductSObj,
  clearProductSampleData,
} from "../../store/slice/callRecordingSlice";

const ProductSampling = (props) => {
  const {
    selectedMD,
    showSidebar,
    handleCallActivitiesSidebar,
    updateCallActivitieData,
  } = props;
  const dispatch = useDispatch();
  const { activities, productSObj, productSamplesData } = useSelector(
    (state) => state.callRecordingReducer
  );

  const [errors, setErrors] = useState({
    qty: "",
    quantity: [],
  });
  const [productSamples, setProductSamples] = useState([]);
  const [currProductType, setCurrProductType] = useState("PHYSICIAN");

  useEffect(() => {
    if (
      productSamplesData &&
      (productSamplesData?.physician?.length > 0 ||
        productSamplesData?.commercial?.length > 0 ||
        productSamplesData?.giveaways?.length > 0 ||
        productSamplesData?.literature?.length > 0)
    ) {
      divideProductSamples();
    } else {
      setProductSamples({
        physician: [],
        commercial: [],
        giveaways: [],
        literature: [],
      });
    }
  }, [productSamplesData]);

  const divideProductSamples = () => {
    const updatedArrList = {
      physician: [],
      commercial: [],
      giveaways: [],
      literature: [],
    };
    if (productSamplesData && productSamplesData?.physician?.length > 0) {
      const newSamplesData = productSamplesData.physician.map((item) => {
        return {
          ...item,
          quantity: 0,
        };
      });
      checkProductIsMatch(newSamplesData);
      updatedArrList.physician = newSamplesData || [];
    }
    if (productSamplesData && productSamplesData?.commercial?.length > 0) {
      const newSamplesData = productSamplesData.commercial.map((item) => {
        return {
          ...item,
          quantity: 0,
        };
      });
      checkProductIsMatch(newSamplesData);
      updatedArrList.commercial = newSamplesData || [];
    }
    if (productSamplesData && productSamplesData?.giveaways?.length > 0) {
      const newSamplesData = productSamplesData.giveaways.map((item) => {
        return {
          ...item,
          quantity: 0,
        };
      });
      checkProductIsMatch(newSamplesData);
      updatedArrList.giveaways = newSamplesData || [];
    }
    if (productSamplesData && productSamplesData?.literature?.length > 0) {
      const newSamplesData = productSamplesData.literature.map((item) => {
        return {
          ...item,
          quantity: 0,
        };
      });
      checkProductIsMatch(newSamplesData);
      updatedArrList.literature = newSamplesData || [];
    }
    setProductSamples(updatedArrList);
  };
  const checkProductIsMatch = (sampleData) => {
    if (productSObj && productSObj.length > 0) {
      productSObj.forEach((sObj) => {
        const matchingElement = sampleData.find(
          (data) =>
            data.id === sObj.id &&
            data.promomat_type === sObj.promomat_type &&
            data.lot_number === sObj.lot_number
        );
        if (matchingElement) {
          matchingElement.quantity += sObj.quantity;
        }
      });
    }
  };

  useEffect(() => {
    if (productSObj && productSObj.length > 0) {
      updateCallActivitieData(activities.productS);
    }
  }, [productSObj]);

  const checkQtyValidation = (value, data, i, event) => {
    if (event === "") {
      errors.quantity[i] = {
        id: "",
        message: "",
      };
    } else if (event === "0" || Number(event) === 0) {
      errors.qty = "";
      errors.quantity[i] = {
        id: data.id,
        message: messages.errors.quantity_err,
      };
    } else if (data.max_qty < value) {
      errors.qty = "";
      errors.quantity[i] = {
        id: data.id,
        message: messages.errors.promo_qty,
      };
    } else {
      errors.qty = "";
      errors.quantity[i] = {
        id: "",
        message: "",
      };
    }
  };

  const handleChangeQty = (event, item, i) => {
    let value = event.target.value ? Number(event.target.value) : 0;
    checkQtyValidation(value, item, i, event.target.value);
    if (currProductType === "PHYSICIAN") {
      updateProductSamplesData(
        value,
        item,
        "quantity",
        productSamples?.physician,
        "physician"
      );
    } else if (currProductType === "COMMERCIAL") {
      updateProductSamplesData(
        value,
        item,
        "quantity",
        productSamples?.commercial
      );
    } else if (currProductType === "GIVEAWAYS") {
      updateProductSamplesData(
        value,
        item,
        "quantity",
        productSamples?.giveaways
      );
    } else if (currProductType === "LITERATURE") {
      updateProductSamplesData(
        value,
        item,
        "quantity",
        productSamples?.literature
      );
    }
  };

  const updateProductSamplesData = (value, data, inputType, listData) => {
    const newProductSamplesData =
      listData &&
      listData.map((item) => {
        if (inputType === "quantity") {
          if (
            item.id === data.id &&
            item.lot_number === data.lot_number &&
            !value
          ) {
            return {
              ...item,
              quantity: "",
            };
          } else if (
            item.id === data.id &&
            item.lot_number === data.lot_number
          ) {
            return {
              ...item,
              quantity: value,
            };
          } else {
            return item;
          }
        }
      });
    if (currProductType === "PHYSICIAN") {
      setProductSamples({
        ...productSamples,
        physician: newProductSamplesData,
      });
    } else if (currProductType === "COMMERCIAL") {
      setProductSamples({
        ...productSamples,
        commercial: newProductSamplesData,
      });
    } else if (currProductType === "GIVEAWAYS") {
      setProductSamples({
        ...productSamples,
        giveaways: newProductSamplesData,
      });
    } else if (currProductType === "LITERATURE") {
      setProductSamples({
        ...productSamples,
        literature: newProductSamplesData,
      });
    }
  };

  const handleProductSamplesDataObj = () => {
    if (
      errors?.qty === "" &&
      errors.quantity.every((item) => item?.message === "")
    ) {
      const dataForStore = getAcutalChangeData();
      dispatch(reqToUpdateProductSObj(dataForStore));
      dispatch(clearProductSampleData());
      handleCallActivitiesSidebar();
      setProductSamples([]);
      setCurrProductType("PHYSICIAN");
    }
  };

  const getAcutalChangeData = () => {
    const newPhysicianList =
      productSamples &&
      productSamples?.physician?.filter((item) => item.quantity);
    const newCommercialList =
      productSamples &&
      productSamples?.commercial?.filter((item) => item.quantity);
    const newGiveawaysList =
      productSamples &&
      productSamples?.giveaways?.filter((item) => item.quantity);
    const newLiteratureList =
      productSamples &&
      productSamples?.literature?.filter((item) => item.quantity);

    const finalArr = [
      ...newPhysicianList,
      ...newCommercialList,
      ...newGiveawaysList,
      ...newLiteratureList,
    ];

    return finalArr;
  };

  const resetDataWithoutStore = () => {
    dispatch(clearProductSampleData());
    setProductSamples([]);
    setErrors({
      qty: "",
      quantity: [],
    });
    handleCallActivitiesSidebar();
    setCurrProductType("PHYSICIAN");
  };

  const checkItemIsGray = (arr, item) => {
    const matchingObjects = arr.filter((obj) => {
      return (
        obj.name === item.name &&
        obj.lot_number !== item.lot_number &&
        item.max_qty !== 0
      );
    });

    for (const obj of matchingObjects) {
      if (obj.max_qty !== obj.quantity && item.expiry_date > obj.expiry_date) {
        return true;
      }
    }

    return false;
  };

  return (
    <>
      <div className={`tc-sidebar-section ${showSidebar && "zl-sidebar-open"}`}>
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => resetDataWithoutStore()}
          >
            {backBtnIcon}
          </button>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body-wrapper">
          <div className="tc-sidebar-body">
            <div className="tc-profile-wrapper">
              <img src={DoctorIcon} alt="doctor" />
              <span className="tc-profile-name">
                {selectedMD && nameFormatting(selectedMD)}
              </span>
            </div>
            <div className="tc-content-wrapper h-96">
              <CallActivitiesMDDetails selectedMD={selectedMD} />
              <h2 className="tc-content-wrapper-title">PRODUCT SAMPLING</h2>
              <Tab.Container
                id="tc-product-sampling"
                defaultActiveKey="physicianSamples"
              >
                <div className="tc-calender-search-input-row justify-content-between align-items-center">
                  <Nav variant="pills" className="gap-2">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="physicianSamples"
                        active={currProductType === "PHYSICIAN"}
                        onClick={() => setCurrProductType("PHYSICIAN")}
                      >
                        PHYSICIAN SAMPLES
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="commercialSamples"
                        active={currProductType === "COMMERCIAL"}
                        onClick={() => setCurrProductType("COMMERCIAL")}
                      >
                        COMMERCIAL SAMPLES
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="giveaways"
                        active={currProductType === "GIVEAWAYS"}
                        onClick={() => setCurrProductType("GIVEAWAYS")}
                      >
                        GIVEAWAYS
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="literature"
                        active={currProductType === "LITERATURE"}
                        onClick={() => setCurrProductType("LITERATURE")}
                      >
                        LITERATURE
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  {productSamples &&
                    (productSamples?.physician?.length > 0 ||
                      productSamples?.commercial?.length > 0 ||
                      productSamples?.giveaways?.length > 0 ||
                      productSamples?.literature?.length > 0) && (
                      <>
                        <div className="tc-md-list-detail-bottom-btn-content text-center">
                          <Button
                            variant="primary"
                            type="button"
                            className="btn-min"
                            onClick={() => handleProductSamplesDataObj()}
                          >
                            Done
                          </Button>
                        </div>
                      </>
                    )}
                </div>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="physicianSamples"
                    active={currProductType === "PHYSICIAN"}
                  >
                    <div className="tc-table-wrapper">
                      <Table
                        className="tc-table tc-product-sampling"
                        responsive
                      >
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>BALANCE</th>
                            <th>LOT #</th>
                            <th>EXPIRY DATE</th>
                            <th>QUANTITY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productSamples &&
                          productSamples?.physician?.length === 0 ? (
                            <tr className="text-center">
                              <td colSpan={5}>No data Found</td>
                            </tr>
                          ) : (
                            <>
                              {productSamples &&
                                productSamples?.physician?.map((item, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      // className={`${
                                      //   checkItemIsGray(
                                      //     productSamples?.physician,
                                      //     item
                                      //   ) || item.max_qty === 0
                                      //     ? "color-gray-table"
                                      //     : ""
                                      // }`}
                                    >
                                      <td className="text-start">
                                        {item?.name}
                                      </td>
                                      <td>
                                        {/* {item?.max_qty
                                            ? numberFormatting(+item?.max_qty)
                                            : ""} */}
                                        -
                                      </td>
                                      <td>{/* {item?.lot_number} */}-</td>
                                      <td>
                                        {/* {item?.expiry_date
                                            ? moment(item?.expiry_date).format(
                                                "MM/DD/YY"
                                              )
                                            : ""} */}
                                        -
                                      </td>
                                      <td>
                                        {/* <input
                                            type="number"
                                            className={`m-auto form-control  tc-md-list-table-qty-input ${item?.id ===
                                              errors.quantity[i]?.id &&
                                              "error-border-right-side"
                                              }`}
                                            value={
                                              item?.quantity > 0 &&
                                              item?.quantity
                                            }
                                            onChange={(e) =>
                                              handleChangeQty(e, item, i)
                                            }
                                          /> */}
                                        <input
                                          type="number"
                                          className={`m-auto form-control tc-md-list-table-qty-input`}
                                          value={
                                            item?.quantity > 0 && item?.quantity
                                          }
                                          onChange={(e) =>
                                            handleChangeQty(e, item, i)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="commercialSamples"
                    active={currProductType === "COMMERCIAL"}
                  >
                    <div className="m-0 tc-table-wrapper">
                      <Table
                        className="tc-table tc-product-sampling"
                        responsive
                      >
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>BALANCE</th>
                            <th>QUANTITY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productSamples &&
                          productSamples?.commercial?.length === 0 ? (
                            <tr className="text-center">
                              <td colSpan={3}>No data Found</td>
                            </tr>
                          ) : (
                            <>
                              {productSamples &&
                                productSamples?.commercial?.length > 0 &&
                                productSamples?.commercial?.map((item, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      // className={`${
                                      //   checkItemIsGray(
                                      //     productSamples?.commercial,
                                      //     item
                                      //   ) || item.max_qty === 0
                                      //     ? "color-gray-table"
                                      //     : ""
                                      // }`}
                                    >
                                      <td className="text-start">
                                        {item?.name}
                                      </td>
                                      <td>
                                        {/* {item?.max_qty
                                            ? numberFormatting(+item?.max_qty)
                                            : ""} */}
                                        -
                                      </td>
                                      <td>
                                        {/* <input
                                            type="number"
                                            className={`m-auto form-control  tc-md-list-table-qty-input ${item?.id ===
                                              errors.quantity[i]?.id &&
                                              "error-border-right-side"
                                              }`}
                                            value={
                                              item?.quantity > 0 &&
                                              item?.quantity
                                            }
                                            onChange={(e) =>
                                              handleChangeQty(e, item, i)
                                            }
                                          /> */}
                                        <input
                                          type="number"
                                          className={`m-auto form-control tc-md-list-table-qty-input`}
                                          value={
                                            item?.quantity > 0 && item?.quantity
                                          }
                                          onChange={(e) =>
                                            handleChangeQty(e, item, i)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="giveaways"
                    active={currProductType === "GIVEAWAYS"}
                  >
                    <div className="m-0 tc-table-wrapper">
                      <Table
                        className="tc-table tc-product-sampling"
                        responsive
                      >
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>BALANCE</th>
                            <th>QUANTITY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productSamples &&
                          productSamples?.giveaways?.length === 0 ? (
                            <tr className="text-center">
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                                colSpan={3}
                              >
                                No data Found
                              </td>
                            </tr>
                          ) : (
                            <>
                              {productSamples &&
                                productSamples?.giveaways?.length > 0 &&
                                productSamples?.giveaways.map((item, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      // className={`${
                                      //   checkItemIsGray(
                                      //     productSamples?.giveaways,
                                      //     item
                                      //   ) || item.max_qty === 0
                                      //     ? "color-gray-table"
                                      //     : ""
                                      // }`}
                                    >
                                      <td className="text-start">
                                        {item?.name}
                                      </td>
                                      <td>
                                        {/* {item?.max_qty
                                            ? numberFormatting(+item?.max_qty)
                                            : ""} */}
                                        -
                                      </td>
                                      <td>
                                        {/* <input
                                            type="number"
                                            className={`m-auto form-control  tc-md-list-table-qty-input ${item?.id ===
                                              errors.quantity[i]?.id &&
                                              "error-border-right-side"
                                              }`}
                                            value={
                                              item?.quantity > 0 &&
                                              item?.quantity
                                            }
                                            onChange={(e) =>
                                              handleChangeQty(e, item, i)
                                            }
                                          /> */}
                                        <input
                                          type="number"
                                          className={`m-auto form-control tc-md-list-table-qty-input`}
                                          value={
                                            item?.quantity > 0 && item?.quantity
                                          }
                                          onChange={(e) =>
                                            handleChangeQty(e, item, i)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="literature"
                    active={currProductType === "LITERATURE"}
                  >
                    <div className="m-0 tc-table-wrapper">
                      <Table
                        className="tc-table tc-product-sampling"
                        responsive
                      >
                        <thead>
                          <tr>
                            <th>Product</th>
                            <th>BALANCE</th>
                            <th>QUANTITY</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productSamples &&
                          productSamples?.literature?.length === 0 ? (
                            <tr className="text-center">
                              <td colSpan={3}>No data Found</td>
                            </tr>
                          ) : (
                            <>
                              {productSamples &&
                                productSamples?.literature?.length > 0 &&
                                productSamples?.literature.map((item, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      // className={`${
                                      //   checkItemIsGray(
                                      //     productSamples?.literature,
                                      //     item
                                      //   ) || item.max_qty === 0
                                      //     ? "color-gray-table"
                                      //     : ""
                                      // }`}
                                    >
                                      <td className="text-start">
                                        {item?.name}
                                      </td>
                                      <td>
                                        {/* {item?.max_qty
                                            ? numberFormatting(+item?.max_qty)
                                            : ""} */}
                                        -
                                      </td>
                                      <td>
                                        {/* <input
                                            type="number"
                                            className={`m-auto form-control  tc-md-list-table-qty-input ${item?.id ===
                                              errors.quantity[i]?.id &&
                                              "error-border-right-side"
                                              }`}
                                            value={
                                              item?.quantity > 0 &&
                                              item?.quantity
                                            }
                                            onChange={(e) =>
                                              handleChangeQty(e, item, i)
                                            }
                                          /> */}
                                        <input
                                          type="number"
                                          className={`m-auto form-control tc-md-list-table-qty-input`}
                                          value={
                                            item?.quantity > 0 && item?.quantity
                                          }
                                          onChange={(e) =>
                                            handleChangeQty(e, item, i)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            <span className="tc-error mt-0">{errors && errors?.qty}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSampling;
