import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { reqToGetMDExcuseReason } from "../../store/slice/callRecordingSlice";

const MdExcuseModal = (props) => {
  const {
    to,
    errors,
    values,
    setValues,
    handleInputChange,
    onAddMDExcuseHandler,
    ...rest
  } = props;
  const dispatch = useDispatch();

  // Get call recording reducer data
  const callRecordingReducer = useSelector(
    (state) => state.callRecordingReducer
  );
  const mdExcuseReason =
    callRecordingReducer && callRecordingReducer.mdExcuseReason;

  useEffect(() => {
    dispatch(reqToGetMDExcuseReason({}));
  }, []);

  return (
    <>
      <Modal
        className="tc-main-modal"
        {...rest}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Record MD Excuse
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row mb-3">
              <p className="col-4">Date:</p>
              <div className="col-8">
                <ReactDatePicker
                  onChange={(e) => {
                    setValues({
                      ...values,
                      date: moment(e).format("YYYY-MM-DD"),
                    });
                  }}
                  id="date"
                  name="date"
                  dateFormat="yyyy-MM-dd"
                  value={values.date}
                  selected={new Date(values.date)}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
            <div className="row mb-3">
              <p className="col-4">Reason:</p>
              <div className="col-8">
                <Form.Select
                  name="reason"
                  id="reason"
                  placeholder="Select excuse"
                  className="form-select-input"
                  value={values.reason ? values.reason : "Select"}
                  onChange={(e) => handleInputChange(e, "reason")}
                >
                  <option disabled>Select</option>
                  {mdExcuseReason &&
                    mdExcuseReason?.map((item, i) => {
                      return (
                        <option key={i} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </Form.Select>
                <span className="tc-error">{errors.reason}</span>
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button className="tc-save" onClick={() => onAddMDExcuseHandler()}>
              SAVE
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MdExcuseModal;
