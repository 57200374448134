import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";

// Request to getMissedCallsList
export const getMissedCallList = createAsyncThunk(
  "getMissedCallList",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.getMissedCallList,
        data.data,
        authHeaders()
      );

      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to disapproval Missedcall
export const disapprovalMissedcall = createAsyncThunk(
  "disapprovalMissedcall",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.disapprovalMissedcall,
        data.data,
        authHeaders()
      );

      if (response.status === 200 && data.cb) {
        notify("Missedcall has been disapproved!", "success");
        data.cb(response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to approval Missedcall
export const approvalMissedcall = createAsyncThunk(
  "approvalMissedcall",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.approvalMissedcall,
        data.data,
        authHeaders()
      );

      if (response.status === 200 && data.cb) {
        notify("Missedcall has been Approved!", "success");
        data.cb(response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  missedCallList: [],
};

const missedCallSlice = createSlice({
  name: "missedCall",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get getItinerary LIst
    builder.addCase(getMissedCallList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMissedCallList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.missedCallList = action.payload.data;
      } else if (
        action?.payload?.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action?.payload?.message, "error");
      }
    });
    builder.addCase(getMissedCallList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // disapprovalMissedcall
    builder.addCase(disapprovalMissedcall.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(disapprovalMissedcall.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(disapprovalMissedcall.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // approvalMissedcall
    builder.addCase(approvalMissedcall.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(approvalMissedcall.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(approvalMissedcall.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default missedCallSlice.reducer;
