import axios from "axios";

export const authHeaders = () => {
  const authToken =
    localStorage.getItem("token") && JSON.parse(localStorage.getItem("token"));

  return {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
};

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

// let controllers = {};
// Axios.interceptors.request.use(
//   (config) => {
//     const { url } = config;
//     if (controllers[url]) {
//       controllers[url].abort();
//     }
//     const controller = new AbortController();
//     config.signal = controller.signal;
//     controllers[url] = controller;
//     config.headers = {
//       ...config.headers,
//       ...authHeaders().headers,
//     };
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Axios.interceptors.response.use(
//   (response) => {
//     const { url } = response.config;
//     if (controllers[url]) {
//       delete controllers[url];
//     }
//     return response;
//   },
//   (error) => {
//     const { url } = error.config;
//     if (controllers[url]) {
//       delete controllers[url];
//     }
//     return Promise.reject(error);
//   }
// );

export default Axios;
