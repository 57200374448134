import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { routes } from "../../helpers/constants";
import moment from "moment";
import { backBtnIcon } from "../../icon/icon";
import {
  nameFormatting,
  dateWithTimeFormat,
} from "../../helpers/commonFunction";
import { DoctorIcon } from "../../images";
import { CallActivitiesMDDetails } from "../../components";
import { Nav, Tab, Table } from "react-bootstrap";

const ViewCallSummary = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.mdData) {
      navigate(routes.mdList, {
        state: { selectedDate: moment().format("YYYY-MM-DD") },
      });
    }
  }, [location?.state]);

  const callDate = location?.state?.callDate;
  const selectedMD = location?.state?.mdData;
  const selectedCall = location?.state?.mdData?.data;
  const productDData = selectedCall && selectedCall?.productDetailing;
  const productSData = selectedCall && selectedCall?.ProductSampling;
  const jointCallData = selectedCall && selectedCall?.jointCall;
  const postCallData = selectedCall && selectedCall?.postCall;

  const [selectedView, setSelectedView] = useState("MDSIGNATURE");
  const handleShowCallSummary = () => {
    navigate(routes.mdList, { state: { selectedDate: callDate } });
  };

  return (
    <>
      <div className={`tc-sidebar-section zl-sidebar-open`}>
        <div className="tc-sidebar-navbar">
          <button
            type="button"
            className="tc-navbar-back-btn"
            onClick={() => handleShowCallSummary()}
          >
            {backBtnIcon}
          </button>
          <div className="tc-sidebar-right-btn"></div>
        </div>
        <div className="tc-sidebar-body-wrapper">
          <div className="tc-sidebar-body">
            <div className="tc-profile-wrapper">
              <img src={DoctorIcon} alt="doctor" />
              <span className="tc-profile-name">
                {selectedMD && nameFormatting(selectedMD)}
              </span>
            </div>
            <div className="tc-content-wrapper" style={{ height: "30px" }}>
              <CallActivitiesMDDetails selectedMD={selectedMD} />
              {/* <h2 className="tc-content-wrapper-title"></h2> */}
              <Tab.Container
                id="tc-product-sampling"
                defaultActiveKey="physicianSamples"
              >
                <div className="tc-calender-search-input-row">
                  <Nav variant="pills" className="gap-2">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="summary"
                        active={selectedView === "MDSIGNATURE"}
                        onClick={() => setSelectedView("MDSIGNATURE")}
                      >
                        MD SIGNATURE
                      </Nav.Link>
                    </Nav.Item>
                    {jointCallData &&
                      Object.values(jointCallData).length > 0 && (
                        <Nav.Item>
                          <Nav.Link
                            eventKey="jointCall"
                            active={selectedView === "JOINTCALL"}
                            onClick={() => setSelectedView("JOINTCALL")}
                          >
                            JOINT CALL
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    <Nav.Item>
                      <Nav.Link
                        eventKey="postCall"
                        active={selectedView === "POSTCALL"}
                        onClick={() => setSelectedView("POSTCALL")}
                      >
                        POST CALL
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <div className="h-100">
                  <Tab.Content className="min-height-390">
                    <Tab.Pane
                      eventKey="summary"
                      active={selectedView === "MDSIGNATURE"}
                    >
                      <div className="tc-table-wrapper mb-2">
                        <Table
                          className="tc-table tc-border-bottom-table"
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>Activity</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-start min-width-200">
                                Product Detailing
                              </td>
                              <td className="text-start">
                                {productDData && productDData?.length > 0
                                  ? productDData.map((item, i) => {
                                    return <p key={i}>{item?.name}</p>;
                                  })
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start min-width-200">
                                Product Sampling
                              </td>
                              <td className="text-start">
                                {productSData && productSData?.length > 0
                                  ? productSData.map((item, i) => {
                                    return (
                                      <span key={i} className="d-flex gap-4">
                                        <p>{item?.name}</p>
                                        <p>{item?.quantity}</p>
                                      </span>
                                    );
                                  })
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start min-width-200">
                                Joint Call
                              </td>
                              <td className="text-start">
                                <p>
                                  {`${jointCallData && jointCallData?.dm_name
                                    ? "with " + jointCallData?.dm_name
                                    : jointCallData?.name
                                      ? "with " + jointCallData?.name
                                      : "-"
                                    }`}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start min-width-200">
                                Signature
                              </td>
                              <td className={`pb-0`}>
                                {selectedCall && selectedCall?.signature_url ? (
                                  <div className="tc-md-list-behavior-input-box">
                                    <div className="tc-call-summary-signature-box tc-call-summary-photo-upload-box tc-call-summary-photo-upload-img-box">
                                      <img
                                        className="tc-call-summary-photo-upload-img img-fluid"
                                        src={
                                          selectedCall?.signature_url?.size
                                            ? URL.createObjectURL(
                                              selectedCall.signature_url
                                            )
                                            : selectedCall?.signPadData?.includes(
                                              "base64"
                                            ) || selectedCall?.signature_url?.includes(
                                              "base64"
                                            )
                                              ? selectedCall?.signPadData || selectedCall?.signature_url
                                              : process.env.REACT_APP_IMAGE_URL +
                                              selectedCall.signature_url
                                        }
                                        alt="Signature"
                                        draggable={false}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center mt-2">
                                      <p className="tc-signaturepad-time-text">
                                        {dateWithTimeFormat(
                                          selectedMD?.data?.signature_time
                                            ? selectedMD?.data?.signature_time
                                            : new Date()
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <td className="text-start">-</td>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="jointCall"
                      active={selectedView === "JOINTCALL"}
                    >
                      <div className="tc-table-wrapper">
                        <Table
                          className="tc-table tc-border-bottom-table"
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>Questions</th>
                              <th>Answers</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-start min-width-200">
                                Who joined this call?
                              </td>
                              <td className="text-start">
                                {jointCallData && jointCallData?.dm_name}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start min-width-200">
                                Notes
                              </td>
                              <td>
                                <textarea
                                  name="notes"
                                  value={jointCallData?.notes || ""}
                                  spellCheck="false"
                                  className="form-control"
                                  style={{ height: "144px" }}
                                  disabled
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start min-width-200">
                                SIGNATURE
                              </td>
                              {jointCallData && (jointCallData.signature_url || jointCallData?.signPadData) ? (
                                <td className="pb-0">
                                  <div className="tc-call-summary-signature-box tc-call-summary-photo-upload-box tc-call-summary-photo-upload-img-box">
                                    <img
                                      className="tc-call-summary-photo-upload-img img-fluid"
                                      src={
                                        jointCallData?.signature_url?.size
                                          ? URL.createObjectURL(
                                            jointCallData.signature_url
                                          )
                                          : jointCallData?.signPadData?.includes(
                                            "base64"
                                          ) || jointCallData?.signature_url?.includes(
                                            "base64"
                                          )
                                            ? jointCallData?.signPadData || jointCallData?.signature_url
                                            : process.env.REACT_APP_IMAGE_URL +
                                            jointCallData.signature_url
                                      }
                                      alt="Signature"
                                      draggable={false}
                                    />
                                  </div>
                                </td>
                              ) : (
                                <td className="text-start text-capitalize">
                                  The DM did not sign!
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="postCall"
                      active={selectedView === "POSTCALL"}
                    >
                      <div className="tc-table-wrapper h-100">
                        <Table
                          className="tc-table tc-border-bottom-table"
                          responsive
                        >
                          <thead>
                            <tr>
                              <th>Activity</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-start min-width-200">
                                Product Detailing
                              </td>
                              <td className="text-start">
                                {productDData && productDData?.length > 0
                                  ? productDData.map((item, i) => {
                                    return <p key={i}>{item?.name}</p>;
                                  })
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start min-width-200">
                                Product Sampling
                              </td>
                              <td className="text-start">
                                {productSData && productSData?.length > 0
                                  ? productSData.map((item, i) => {
                                    return (
                                      <span key={i} className="d-flex gap-4">
                                        <p>{item?.name}</p>
                                        <p>{item?.quantity}</p>
                                      </span>
                                    );
                                  })
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start min-width-200">
                                Joint Call
                              </td>
                              <td className="text-start">
                                <p>
                                  {`${jointCallData && jointCallData?.dm_name
                                    ? "with " + jointCallData?.dm_name
                                    : "-"
                                    }`}
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td className="text-start min-width-200">
                                POST CALL ANALYSIS
                              </td>
                              <td>
                                <div className="tc-md-list-behavior-input-box">
                                  <div className="tc-md-list-behavior-input-tectarea-box">
                                    <textarea
                                      className="form-control"
                                      style={{ height: "180px" }}
                                      name="post_call"
                                      value={postCallData?.post_call || ""}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCallSummary;
