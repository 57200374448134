export const messages = {
  toast: {
    logout: "Logout Successfully!",
    login: "Welcome Back!",
    nodata: "Data not found",
    nodateData: "No ltinerary for the said date!",
    noitinerary: "No itinerary to display",
    alreadyLogin: `Sorry, you are currently \nsigned in to another device.`,
  },
  errors: {
    user_req: "Username is required!",
    pass_req: "Password is required!",
    product_name_err: "Name is required!",
    quantity_err: "Quantity is required!",
    reason_err: "Reason is required!",
    sample_lot_err: "Lot is required!",
    product_req: "Please select any one product",
    answer_req: "Please answer any one question",
    sample_req: "Please edit qty any one product",
    behavior_req: "MD Behavior is required!",
    product_behav_req: "Please select any one product and Write MD Behavior",
    sample_behav_req: "Please edit qty any one product and Write MD Behavior",
    answer_behav_req: "Please answer any one question and Write MD Behavior",
    join_call_req: "Who joined this call is required!",
    sign_req: "Please Draw signature first!",
    post_call_req: "Post Call Analysis is required!",
    next_call_req: "Next Call Objective is required!",
    md_select_req: "Please select MD!",
    md_name_req: "Firstname and Lastname is required!",
    image_req: "Please select screenshot is required!",
    sign_selfie_req: "Please do signature or Take selfie",
    promo_qty: "Given quantity cannot be greater than actual balance",
    img_type: "Only png image allowed!",
    sign_count:
      "11th signature not allowed anymore - perform matching first on any of the 10 signatures!",
    camera_permission:
      "If you've granted permission but we're still unable to find your camera!",
    camera_not_support: "Browser is not supporting camera!",
    location_not_support: "Browser is not supporting geolocation!",
    location_permission_issue:
      "If you've granted permission but we're still unable to find your location!",
    location_position_issue:
      "We're having trouble finding your location. Double-check your device settings and give it another try!",
    location_timeout_issue:
      "Taking a bit too long to find your location. Let's give it another shot!",
    location_unknown_issue:
      "Oops! Something went wrong while fetching your location. Please try again in a moment!",
  },
  apiMessage: {
    cycle: "Checking Active Cycle!",
    notificationCount: "Getting new notifications!",
    notification: "Getting new notifications!",
    mdBirthday: "Making sure you don't miss MD birthdays!",
    itinerary: "Preparing your work load - Itineraries!",
    cumulativeReport: "Generating Cumulative reports!",
    reports: "Generating some more reports of your good work!",
    quickSign: "Quickly syncing QuickSigns!",
    masterMDList: "Updating Master MDs!",
    nextMItinerary: "Getting Next Month's Itinerary!",
    mdInfoDetails: "Getting MD Info Requests!",
    mdInfoRequest: "Getting MD Info Requests!",
    vmcList: "Getting updated VMC list!",
    vmcModalData: "Getting updated VMC list!",
    timeInOut: "Making sure TimeIn/Out is reported!",
  },
};
