import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios, { authHeaders } from "../../helpers/axios";
import { notify } from "../../helpers/commonFunction";
import { apiendpoints } from "../../helpers/constants";

// Request to add itinerary
export const addItinerary = createAsyncThunk("addItinerary", async (data) => {
  try {
    const response = await Axios.post(
      apiendpoints.addItinerary,
      data.data,
      authHeaders()
    );

    if (response.status === 200 && data.cb) {
      notify("Itinerary has been added!", "success");
      data.cb(response.data);
    }
    return response;
  } catch (error) {
    return error;
  }
});

// Request to get holidays
export const getHolidays = createAsyncThunk("getHolidays", async (data) => {
  try {
    const response = await Axios.post(
      apiendpoints.getHolidays,
      data.data,
      authHeaders()
    );
    data.onSuccess(response.data);
    return response;
  } catch (error) {
    return error;
  }
});

// Request to get itinerary
export const getItinerary = createAsyncThunk("getItinerary", async (data) => {
  try {
    const response = await Axios.post(
      apiendpoints.getItinerary,
      data.data,
      authHeaders()
    );

    if (response.status === 200 && data.cb) {
      data.cb(response.data);
    }
    return response;
  } catch (error) {
    if (error.response.status === 400) {
      data.cb("no_data");
    }
    return error;
  }
});

// Request to edit itinerary
export const editItinerary = createAsyncThunk("editItinerary", async (data) => {
  try {
    const response = await Axios.post(
      apiendpoints.editItinerary,
      data.data,
      authHeaders()
    );

    if (response.status === 200 && data.cb) {
      notify("Itinerary has been Updated!", "success");
      data.cb(response.data);
    }
    return response;
  } catch (error) {
    return error;
  }
});

// Request to remove itinerary
export const removeItinerary = createAsyncThunk(
  "removeItinerary",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.removeItinerary,
        data.data,
        authHeaders()
      );

      if (response.status === 200 && data.cb) {
        notify("Itinerary has been Removed!", "success");
        data.cb(response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get MD list for itinerary
export const getMDListForItinerary = createAsyncThunk(
  "getMDListForItinerary",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.getMDListForItinerary,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to getTmList
export const getTmList = createAsyncThunk("getTmList", async ({ data }) => {
  try {
    const response = await Axios.post(
      apiendpoints.getTmList,
      data,
      authHeaders()
    );
    return response;
  } catch (error) {
    return error;
  }
});

// Request to get disapproval reasons
export const getDisapprovalReasons = createAsyncThunk(
  "getDisapprovalReasons",
  async (data) => {
    try {
      const response = await Axios.get(
        apiendpoints.getDisapprovalReasons,
        authHeaders()
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to get itinerary list
export const getItineraryList = createAsyncThunk(
  "getItineraryList",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.getItineraryList,
        data.data,
        authHeaders()
      );

      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to disapproval itinerary
export const disapprovalItinerary = createAsyncThunk(
  "disapprovalItinerary",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.disapprovalItinerary,
        data.data,
        authHeaders()
      );

      if (response.status === 200 && data.cb) {
        notify("Itineraries has been disapproved!", "success");
        data.cb(response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Request to approval itinerary
export const approvalItinerary = createAsyncThunk(
  "approvalItinerary",
  async (data) => {
    try {
      const response = await Axios.post(
        apiendpoints.approvalItinerary,
        data.data,
        authHeaders()
      );

      if (response.status === 200 && data.cb) {
        notify("Itineraries has been Approved!", "success");
        data.cb(response.data);
      }
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get itinerary summary data
export const reqToGetItinerarySummaryData = createAsyncThunk(
  "reqToGetItinerarySummaryData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getItinerarySummaryData,
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get summary report data
export const reqToGetSummaryReportData = createAsyncThunk(
  "reqToGetSummaryReportData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getSummaryReportData,
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get itinerary call-plan
export const reqToGetItineraryCallPlan = createAsyncThunk(
  "reqToGetItineraryCallPlan",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getItineraryCallPlan,
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get itinerary weekly data
export const reqToGetItineraryWeeklyData = createAsyncThunk(
  "reqToGetItineraryWeeklyData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getItineraryWeeklyData,
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Get weekly report data
export const reqToGetWeeklyReportData = createAsyncThunk(
  "reqToGetWeeklyReportData",
  async ({ data, onSuccess }) => {
    try {
      const response = await Axios.post(
        apiendpoints.getWeeklyReportData,
        data,
        authHeaders()
      );
      onSuccess(response);
      return response;
    } catch (error) {
      return error;
    }
  }
);

// Create initial state
const initialState = {
  loader: false,
  error: "",
  holidays: [],
  mdList: [],
  tmList: [],
  itineraryList: [],
  disapprovalReasons: [],
  itinerarySummaryData: [],
  summaryReportData: [],
  itineraryCallPlans: [],
  itinerarySWeeklyData: [],
  weeklyReportData: [],
};

const itinerarySlice = createSlice({
  name: "itinerary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add itinerary
    builder.addCase(addItinerary.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(addItinerary.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(addItinerary.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // get holidays
    builder.addCase(getHolidays.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getHolidays.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.holidays = action.payload.data.data;
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(getHolidays.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // getItinerary
    builder.addCase(getItinerary.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getItinerary.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200 &&
        action.payload.response.status !== 400
      ) {
        notify(action.payload.response.data, "error");
      } else {
        if (action.payload.response && action.payload.response.status !== 400) {
          notify(action.payload.message, "error");
        }
      }
    });
    builder.addCase(getItinerary.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // editItinerary
    builder.addCase(editItinerary.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(editItinerary.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(editItinerary.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // removeItinerary
    builder.addCase(removeItinerary.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(removeItinerary.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(removeItinerary.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // get tm LIst
    builder.addCase(getMDListForItinerary.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getMDListForItinerary.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.mdList = action.payload.data;
      } else if (
        action?.payload?.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action?.payload?.message, "error");
      }
    });
    builder.addCase(getMDListForItinerary.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // get tm LIst
    builder.addCase(getTmList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getTmList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.tmList = action.payload.data.data;
      } else if (
        action?.payload?.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action?.payload?.message, "error");
      }
    });
    builder.addCase(getTmList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // get disapproval Reasons
    builder.addCase(getDisapprovalReasons.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getDisapprovalReasons.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.disapprovalReasons = action.payload.data;
      } else if (
        action?.payload?.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action?.payload?.message, "error");
      }
    });
    builder.addCase(getDisapprovalReasons.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
    // get getItinerary LIst
    builder.addCase(getItineraryList.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(getItineraryList.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
        state.itineraryList = action.payload.data.data;
      } else if (
        action?.payload?.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action?.payload?.message, "error");
      }
    });
    builder.addCase(getItineraryList.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // disapprove itinerary
    builder.addCase(disapprovalItinerary.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(disapprovalItinerary.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(disapprovalItinerary.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // approve itinerary
    builder.addCase(approvalItinerary.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(approvalItinerary.fulfilled, (state, action) => {
      state.loader = false;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(approvalItinerary.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get itinerary summary data
    builder.addCase(reqToGetItinerarySummaryData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetItinerarySummaryData.fulfilled, (state, action) => {
      state.loader = false;
      state.itinerarySummaryData = action.payload?.data;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetItinerarySummaryData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get summary report data
    builder.addCase(reqToGetSummaryReportData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetSummaryReportData.fulfilled, (state, action) => {
      state.loader = false;
      state.summaryReportData = action.payload?.data;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetSummaryReportData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get itinerary call-plan
    builder.addCase(reqToGetItineraryCallPlan.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetItineraryCallPlan.fulfilled, (state, action) => {
      state.loader = false;
      state.itineraryCallPlans = action.payload?.data;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetItineraryCallPlan.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get itinerary weekly data
    builder.addCase(reqToGetItineraryWeeklyData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetItineraryWeeklyData.fulfilled, (state, action) => {
      state.loader = false;
      state.itinerarySWeeklyData = action.payload?.data;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetItineraryWeeklyData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });

    // Get weekly report data
    builder.addCase(reqToGetWeeklyReportData.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(reqToGetWeeklyReportData.fulfilled, (state, action) => {
      state.loader = false;
      state.summaryReportData = action.payload?.data;
      if (action?.payload?.status === 200) {
      } else if (
        action.payload.response &&
        action.payload.response.status !== 200
      ) {
        notify(action.payload.response.data, "error");
      } else {
        notify(action.payload.message, "error");
      }
    });
    builder.addCase(reqToGetWeeklyReportData.rejected, (state, action) => {
      state.loader = false;
      state.error = action.payload;
    });
  },
});

export default itinerarySlice.reducer;
